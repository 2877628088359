import { momentES } from "../utils/handler_dates";
import {
  fetchDELETE,
  fetchGET,
  fetchPOST,
  fetchPUT,
} from "../utils/handler_http";
import {
  encoJSON,
  encoURI,
  encoUriJson,
  getNotNegativeNumber,
} from "../utils/handler_validations";

/**
 * Deshabilitar un cupon
 */
export const disabledCoupon = async (code = "", jwt = "") => {
  const paramsBody = {
    action: "DISABLED",
    code: encoURI(code),
  };
  const response = await fetchPUT(
    process.env.REACT_APP_COUPON_ENDPOINT,
    paramsBody,
    { Authorization: jwt }
  );
  return response;
};

/**
 * Pagar un cupon implementado
 */
export const payCoupon = async (code = "", comment = "", jwt = "") => {
  const paramsBody = {
    action: "PAID",
    code: encoURI(code),
    comment: encoURI(comment),
  };
  const response = await fetchPUT(
    process.env.REACT_APP_COUPON_ENDPOINT,
    paramsBody,
    { Authorization: jwt }
  );
  return response;
};

/**
 * Checar si el nuevo codigo de cupon ya existe en la BD
 */
export const checkCode = async (code = "", jwt = "") => {
  const resp = await fetchGET(
    process.env.REACT_APP_CHECK_COUPON_ENDPOINT,
    { code: encoURI(code) },
    { Authorization: jwt }
  );
  return resp;
};

/**
 * Generar dinamicamente un nuevo codigo de cupon
 */
export const generateCode = async (jwt = "") => {
  const resp = await fetchGET(
    process.env.REACT_APP_GENERATE_COUPON_ENDPOINT,
    {},
    { Authorization: jwt }
  );
  return resp;
};

/**
 * Generar dinamicamente un nuevo codigo de cupon
 */
export const createCoupon = async (
  code = "",
  expDate = momentES(),
  amount = "",
  jwt = ""
) => {
  const expDateUTC = expDate?.clone().utc();
  const amountNum = getNotNegativeNumber(amount);
  const paramsBody = {
    code: encoURI(code),
    expirationDate: encoURI(expDateUTC?.format()),
    amount: encoJSON(amountNum),
  };
  const resp = await fetchPOST(
    process.env.REACT_APP_COUPON_ENDPOINT,
    paramsBody,
    { Authorization: jwt }
  );
  return resp;
};

/**
 * Obtener la lista de cupones filtrada por codigo o estatus
 */
export const getListCoupons = async (
  filterValue = "",
  filterStatus = {},
  jwt = ""
) => {
  const paramsQuery = {
    filterValue: encoURI(filterValue),
    filterStatus: encoUriJson(filterStatus),
  };
  const resp = await fetchGET(
    process.env.REACT_APP_ADMIN_COUPONS_ENDPOINT,
    paramsQuery,
    { Authorization: jwt }
  );
  return resp;
};
