import { useEffect, useState } from "react";
import classCharts from "../components/dashboard-page/classCharts";
import {
  getArray,
  getFormattedNumber,
  getMomentES,
  getNotNegativeNumber,
} from "../utils/handler_validations";
import { dashboardDB } from "../services";
import { useSessionContext } from "../context/session.context";
import { getAmPm_S1, getAmPm_S2, getDay_S1 } from "../utils/handler_dates";

const getLoadErrorTemplate = (initialLoading = false) => ({
  loading: initialLoading === true,
  error: { message: "", details: "" },
});

const getInProgressTripsTemplate = () => ({
  total: 0,
  earning: 0,
  coupons: { total: 0, amount: 0 },
});

export const useDashboard = () => {
  const [value, setValue] = useState({
    page: getLoadErrorTemplate(true),
    activeDrivers: 0,
    connectedDrivers: 35,
    waitingTrips: 0,
    totalUsers: 0,
    inProgressTrips: getInProgressTripsTemplate(),
    trips: { today: [], week: [], month: [] },
    drivers: { today: [], week: [], month: [] },
  });
  const [filters, setFilters] = useState({
    earning: "",
    report: "",
    tripsChart: "",
    driversChart: "",
  });
  const session = useSessionContext();

  useEffect(() => {
    getDataInitial();
  }, []);

  const changeFilters = (newsFilters = {}) => {
    setFilters((prev) => ({
      ...prev,
      ...newsFilters,
    }));
  };

  const getTotalEarning = () => {
    let items = [classCharts.getItemTripTemplate()];
    switch (filters.earning) {
      case "TODAY":
        items = value.trips.today;
        break;
      case "WEEK":
        items = value.trips.week;
        break;
      case "MONTH":
        items = value.trips.month;
        break;
      default:
        items = [];
    }
    let total = 0;
    items.forEach((item) => {
      total += getNotNegativeNumber(item.completed?.earning);
    });
    return total;
  };

  const getReport = () => {
    let items = [classCharts.getItemTripTemplate()];
    switch (filters.report) {
      case "TODAY":
        items = value.trips.today;
        break;
      case "WEEK":
        items = value.trips.week;
        break;
      case "MONTH":
        items = value.trips.month;
        break;
      default:
        items = [];
    }
    let resp = { completed: 0, canceled: 0, rejected: 0 };
    items.forEach((item) => {
      resp.completed += getNotNegativeNumber(item.completed?.total);
      resp.canceled += getNotNegativeNumber(item.canceled);
      resp.rejected += getNotNegativeNumber(item.rejected);
    });
    return resp;
  };

  const getDataDriversChart = () => {
    switch (filters.driversChart) {
      case "TODAY":
        return value.drivers.today;
      case "WEEK":
        return value.drivers.week;
      case "MONTH":
        return value.drivers.month;
      default:
        return [];
    }
  };

  const getDataTripsChart = () => {
    switch (filters.tripsChart) {
      case "TODAY":
        return value.trips.today;
      case "WEEK":
        return value.trips.week;
      case "MONTH":
        return value.trips.month;
      default:
        return [];
    }
  };

  const formatTripsResume = (tripsResume = {}) => {
    const resume = {
      today: [classCharts.getItemTripTemplate()],
      week: [classCharts.getItemTripTemplate()],
      month: [classCharts.getItemTripTemplate()],
    };
    // formatear mes
    resume.month = getArray(tripsResume?.month).map((item) => {
      const itemTrip = classCharts.getItemTripTemplate();
      const date = getMomentES(item.timestamp);
      itemTrip.label = `${getDay_S1(date)} ${date.format("DD")}`;
      itemTrip.completed.total = getNotNegativeNumber(item.completed?.total);
      itemTrip.completed.earning = getNotNegativeNumber(
        item.completed?.earning
      );
      itemTrip.canceled = getNotNegativeNumber(item.canceled);
      itemTrip.rejected = getNotNegativeNumber(item.rejected);
      return itemTrip;
    });
    // formatear hoy
    resume.today = getArray(tripsResume?.today).map((item) => {
      const itemTrip = classCharts.getItemTripTemplate();
      const date = getMomentES(item.timestamp);
      itemTrip.label = `${date.format("hh")} ${getAmPm_S2(date).toLowerCase()}`;
      itemTrip.completed.total = getNotNegativeNumber(item.completed?.total);
      itemTrip.completed.earning = getNotNegativeNumber(
        item.completed?.earning
      );
      itemTrip.canceled = getNotNegativeNumber(item.canceled);
      itemTrip.rejected = getNotNegativeNumber(item.rejected);
      return itemTrip;
    });
    // formatear semana
    resume.week = getArray(tripsResume?.week).map((item) => {
      const itemTrip = classCharts.getItemTripTemplate();
      const date = getMomentES(item.timestamp);
      itemTrip.label = `${getDay_S1(date)}`;
      itemTrip.completed.total = getNotNegativeNumber(item.completed?.total);
      itemTrip.completed.earning = getNotNegativeNumber(
        item.completed?.earning
      );
      itemTrip.canceled = getNotNegativeNumber(item.canceled);
      itemTrip.rejected = getNotNegativeNumber(item.rejected);
      return itemTrip;
    });
    return resume;
  };

  const formatDriversResume = (driversResume = {}) => {
    const resume = {
      today: [classCharts.getItemDriverTemplate()],
      week: [classCharts.getItemDriverTemplate()],
      month: [classCharts.getItemDriverTemplate()],
    };
    // formatear mes
    resume.month = getArray(driversResume?.month).map((item) => {
      const itemDriver = classCharts.getItemDriverTemplate();
      const date = getMomentES(item.timestamp);
      itemDriver.label = `${getDay_S1(date)} ${date.format("DD")}`;
      itemDriver.connected = getNotNegativeNumber(item.connected);
      return itemDriver;
    });
    // formatear hoy
    resume.today = getArray(driversResume?.today).map((item) => {
      const itemDriver = classCharts.getItemDriverTemplate();
      const date = getMomentES(item.timestamp);
      itemDriver.label = `${date.format("hh")} ${getAmPm_S2(
        date
      ).toLowerCase()}`;
      itemDriver.connected = getNotNegativeNumber(item.connected);
      return itemDriver;
    });
    // formatear semana
    resume.week = getArray(driversResume?.week).map((item) => {
      const itemDriver = classCharts.getItemDriverTemplate();
      const date = getMomentES(item.timestamp);
      itemDriver.label = `${getDay_S1(date)}`;
      itemDriver.connected = getNotNegativeNumber(item.connected);
      return itemDriver;
    });
    return resume;
  };

  const formatInProgressTrips = (data = {}) => {
    const inProgressTrips = getInProgressTripsTemplate();
    inProgressTrips.total = getNotNegativeNumber(data?.total);
    inProgressTrips.earning = getNotNegativeNumber(data?.earning);
    inProgressTrips.coupons = {
      total: getNotNegativeNumber(data?.coupons?.total),
      amount: getNotNegativeNumber(data?.coupons?.amount),
    };
    return inProgressTrips;
  };

  const getDataInitial = async () => {
    setValue((prev) => ({
      ...prev,
      page: getLoadErrorTemplate(true),
    }));
    const resp = await dashboardDB.getData(session.value.jwt);
    // existe error ?
    if (resp.error) {
      setValue((prev) => ({
        ...prev,
        page: {
          loading: false,
          error: { message: resp.error.message, details: resp.error.details },
        },
      }));
      return;
    }
    // success
    setValue((prev) => ({
      ...prev,
      page: getLoadErrorTemplate(false),
      trips: formatTripsResume(resp.body.tripsResume),
      drivers: formatDriversResume(resp.body.driversResume),
      activeDrivers: getNotNegativeNumber(resp.body.activeDrivers),
      connectedDrivers: getNotNegativeNumber(resp.body.connectedDrivers),
      waitingTrips: getNotNegativeNumber(resp.body.waitingTrips),
      totalUsers: getNotNegativeNumber(resp.body.totalUsers),
      inProgressTrips: formatInProgressTrips(resp.body.inProgressTrips),
    }));
  };

  return {
    value,
    filters,

    getTotalEarning,
    getReport,
    getDataDriversChart,
    getDataTripsChart,

    changeFilters,
  };
};
