import styled from "styled-components";
import stylesLogin from "../../../pages/login/styles";

const DivMain = styled(stylesLogin.DivPanel)`
  //border: 1px solid red;
`;

export default {
  DivMain,
};
