import { useState, useEffect } from "react";
import { isStringEmpty, isPositiveInt } from "../../utils/handler_validations";
import {
  getFileSendDB,
  getFileTemplate,
  testFile,
} from "../../utils/handler_files";
import templates from "./templates";
import { useVeryNotificationsContext } from "../../components/common/v2/VeryNotifications/useVeryNotifications";
import { adminDB } from "../../services";
import { useSessionContext } from "../../context/session.context";
import { testTel } from "../../utils/handler_tel";

export const useFormAdmin = () => {
  const [value, setValue] = useState({
    show: false,
    loading: false,
    modeEdit: false,
  });
  const [originalData, setOriginalData] = useState(templates.getData());
  const [data, setData] = useState(templates.getData());
  const [errorData, setErrorData] = useState(templates.getErrorData());
  const [steps, setSteps] = useState({
    personal: { label: "DATOS PERSONALES", isCompleted: false },
  });
  const notis = useVeryNotificationsContext();
  const session = useSessionContext();

  // cada que cambie un dato
  useEffect(() => {
    setSteps((prev) => ({
      personal: { ...prev.personal, isCompleted: isStepPersonalCompleted() },
    }));
  }, [data]);

  /*=================================
	CAMBIO DE DATOS
	===================================*/
  const setInitialData = (initialData = templates.getData()) => {
    const newData = templates.getData();
    // set profile img
    if (!isStringEmpty(initialData.profileImg?.src)) {
      newData.profileImg = { ...initialData.profileImg };
    }
    // set name
    if (!isStringEmpty(initialData.name)) {
      newData.name = initialData.name.trim();
    }
    // set lastName
    if (!isStringEmpty(initialData.lastName)) {
      newData.lastName = initialData.lastName.trim();
    }
    // set email
    if (!isStringEmpty(initialData.email)) {
      newData.email = initialData.email.trim();
    }
    // set tel
    if (!isStringEmpty(initialData.tel)) {
      newData.tel = initialData.tel.trim();
    }
    setOriginalData(newData);
    updatePersonalData({
      ...newData,
      profileImg: { ...newData.profileImg },
    });
    setValue((prev) => ({
      ...prev,
      show: false,
      loading: false,
      modeEdit: true,
    }));
  };

  const setShowModal = (isVisible = false) => {
    // mostrar modal
    if (isVisible === true) {
      setValue((prev) => ({
        ...prev,
        show: true,
      }));
    }
    // ocultar modal
    else {
      setValue((prev) => ({
        ...prev,
        show: false,
        loading: false,
      }));
      setTimeout(() => {
        if (value.modeEdit) {
          updatePersonalData({
            ...originalData,
            profileImg: { ...originalData.profileImg },
          });
        } else {
          setData(templates.getData());
          setErrorData(templates.getErrorData());
        }
      }, 300);
    }
  };

  const updatePersonalData = (newData = templates.getData()) => {
    const newErrorData = { ...errorData };
    // img actualizada ??
    if (newData.profileImg !== undefined) {
      newErrorData.profileImg = testFile(
        newData.profileImg,
        false,
        ["jpeg", "jpg", "png"],
        "Fotografía requerida"
      ).message;
    }
    // name actualizado ??
    if (newData.name !== undefined) {
      newErrorData.name = testText(newData.name).message;
    }
    // apellido actualizado ??
    if (newData.lastName !== undefined) {
      newErrorData.lastName = testText(newData.lastName).message;
    }
    // email actualizado ??
    if (newData.email !== undefined) {
      newErrorData.email = testEmail(newData.email).message;
    }
    // tel actualizado ??
    if (newData.tel !== undefined) {
      newErrorData.tel = testTel(newData.tel, false).message;
    }
    setData((prev) => ({
      ...prev,
      ...newData,
    }));
    setErrorData(newErrorData);
  };

  const updateErrorData = (newErrorData = templates.getErrorData()) => {
    setErrorData((prev) => ({
      ...prev,
      ...newErrorData,
    }));
  };

  /*=================================
	EVENTOS
	===================================*/
  const onClickBtnMain = async () => {
    setValue((prev) => ({
      ...prev,
      loading: true,
    }));
    // editar perfil
    if (value.modeEdit) {
      await session.update(
        data.name,
        data.lastName,
        data.tel,
        getFileSendDB(data.profileImg, originalData.profileImg)
      );
      setValue((prev) => ({
        ...prev,
        loading: false,
      }));
      return;
    }
    // add new admin
    const respDB = await adminDB.createUser(data, session.value.jwt);
    // error
    if (respDB.error) {
      notis.createNotiError(respDB.error.message, respDB.error.details);
      setValue((prev) => ({
        ...prev,
        loading: false,
      }));
      return;
    }
    // success
    notis.createNotiSuccess(
      "Nuevo usuario administrador agregado",
      respDB.messageSuccess
    );
    setValue((prev) => ({
      ...prev,
      loading: false,
    }));
    updatePersonalData(templates.getData());
    setOriginalData(templates.getData());
  };

  /*=================================
	VALIDACIONES
	===================================*/
  const testText = (newText = "") => {
    const resp = templates.getTestData();
    if (isStringEmpty(newText)) {
      resp.error = true;
      resp.message = "Este campo es obligatorio";
    }
    return resp;
  };

  const testEmail = (email = "") => {
    const resp = templates.getTestData();
    if (isStringEmpty(email)) {
      resp.error = true;
      resp.message = "Este campo es obligatorio";
    } else if (/\s/.test(email)) {
      resp.error = true;
      resp.message = "El correo no debe tener espacios";
    } else if (!/.+@.+/.test(email)) {
      resp.error = true;
      resp.message = "El correo electrónico es incorrecto";
    }
    return resp;
  };

  const isStepPersonalCompleted = () => {
    if (
      testFile(data.profileImg, false, ["jpeg", "jpg", "png"]).error ||
      testText(data.name).error ||
      testText(data.lastName).error ||
      testEmail(data.email).error ||
      testTel(data.tel, false).error
    ) {
      return false;
    }
    return true;
  };

  return {
    value,
    originalData,
    data,
    errorData,
    steps,
    setInitialData,
    setShowModal,
    updatePersonalData,
    updateErrorData,
    onClickBtnMain,
  };
};
