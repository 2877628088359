import styled from "styled-components";
import { VeryImg } from "../VeryImg";

const DivMain = styled(VeryImg)`
  width: ${({ sizeImg }) => sizeImg}px;
  height: ${({ sizeImg }) => sizeImg}px;
  border-radius: 100%;
  background-color: #eeeeee;
  //border: 1px solid blue;
  .iconProfile {
    padding-bottom: 2px;
  }
`;

export default {
  DivMain,
};
