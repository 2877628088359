import {
  getFormattedNumber,
  getNotNegativeNumber,
  getString,
  isArrayEmpty,
  isPositiveInt,
  isStringEmpty,
} from "../../../utils/handler_validations";
import styles from "./styles";
import { VeryIconGoogle } from "../../common/v2/VeryIconGoogle";
import { useMemo } from "react";

const getPropsTemplate = () => ({
  title: "",
  quantity: 0,
  icon: { name: "", type: "", color: "", size: 10 },
});
const CustomCard = (props = getPropsTemplate()) => {
  const clasesCSS = [];
  const formattedQuantity = useMemo(() => {
    return getFormattedNumber(getNotNegativeNumber(props.quantity), true, 0);
  }, [props.quantity]);

  if (!isStringEmpty(props.className)) clasesCSS.push(props.className);

  return (
    <styles.MainContainer className={clasesCSS.join(" ")}>
      <div className="div-icon">
        {!isStringEmpty(props.icon?.name) && (
          <VeryIconGoogle
            CSS={["icon"]}
            name={props.icon.name}
            type={props.icon.type}
            color={props.icon.color}
            size={props.icon.size}
          />
        )}
      </div>
      <div className="panel-text">
        <label className="label-quantity">{formattedQuantity}</label>
        <label className="label-title">{getString(props.title)}</label>
      </div>
    </styles.MainContainer>
  );
};

export default CustomCard;
