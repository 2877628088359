import styled from "styled-components";
import { VeryModal } from "../v2/VeryModal";

const DivMain = styled.div`
  width: 100%;
  flex: 1;
  padding: 20px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  background-color: #f8f8fa;
  //border: 1px solid red;
  > .divError {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    //border: 2px solid orange;
    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
      border-radius: 100%;
      margin-bottom: 17px;
    }
    .txtMessage {
      font-family: "Roboto", Helvetica, sans-serif;
      font-size: 18px;
      font-weight: 700;
      color: #b42d48;
      text-align: center;
      line-height: 1.2;
      margin-bottom: 11px;
    }
    .divButtons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 12px;
      .btnMoreInfo {
        width: fit-content;
      }
      .btnRetry {
        width: fit-content;
        padding-top: 9px;
        padding-bottom: 10px;
      }
    }
  }
`;

const ModalError = styled(VeryModal)`
  .divContentModal {
    max-width: 350px;
    .divBodyModal {
      .txtError {
        font-family: "Roboto", Helvetica, sans-serif;
        font-size: 15px;
        font-weight: 400;
        color: #13131d;
        text-align: left;
        line-height: 1.2;
      }
    }
  }
`;

export default {
  DivMain,
  ModalError,
};
