import { TripsProvider } from "../../context";
import { ContentTripsPage } from "./content";
import { APIProvider } from "@vis.gl/react-google-maps";

const TripsPage = (props) => {
  return (
    <APIProvider apiKey={process.env.REACT_APP_MAPS_API}>
      <TripsProvider>
        <ContentTripsPage />
      </TripsProvider>
    </APIProvider>
  );
};

export default TripsPage;
