import styled from "styled-components";

const DivPreview = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 25px 10px 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  //border: 1px solid red;
  .divContentPreview {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //border: 1px solid blue;
    .btnDelete,
    .btnFullScreen {
      width: 38px;
      height: 38px;
      padding: 0px;
      position: absolute;
      top: calc(50% - 19px);
      border-radius: 100%;
      z-index: 5;
    }
    .btnDelete {
      right: -19px;
    }
    .btnFullScreen {
      left: -19px;
    }
  }
`;

export default {
  DivPreview,
};
