export default class classCharts {
  static getItemDriverTemplate = (label = "", totalConnected = 0) => ({
    label,
    connected: totalConnected,
  });

  static getItemTripTemplate = () => ({
    label: "",
    completed: { total: 0, earning: 0 },
    canceled: 0,
    rejected: 0,
  });

  static getRandomItemTrip = (label = "") => ({
    label,
    completed: {
      total: this.getNum(15000),
      earning: this.getNum(60000),
    },
    canceled: this.getNum(7000),
    rejected: this.getNum(2000),
  });

  static getNum = (max = 0) => {
    return Math.round(Math.random() * max);
  };
}
