export default class classCustomSelect {
  static propsType = {
    classNameSelect: "", // clase del container select
    classNameDropdown: "", // clase del container tooltip
    positionDropdown: "", // consultar posiciones en esta clase
    multiple: false, // se permite multiple seleccion??
    items: [{ label: "item", value: "" }], // value: string | number
    initialSelectedItems: {}, // { value: true , ... }  -> value = string | number
    triggerType: 0, // consultar tipos en esta clase
    maxWidthDropdown: "",
    maxHeightDropdown: "",
    maxWidthItems: "",
    alignmentItemsOverflow: "", // consultar tipos en esta clase
    itemsSelectType: "",
    borderType: "", // consultar bordes en esta clase
    defaultItem: {
      label: "",
      itemType: "", // consultar tipos en esta clase
      showLabelInSelect: true,
    },
    selectButton: {
      className: "",
      color: "", // consultar la clase del CustomButton
      transparent: false,
      isDisabled: false,
      label: "",
      size: 18,
      iconPosition: "", // consultar la clase del CustomButton
    },
    onChange: (selectedItems = {}) => {}, // obj con los values de los items seleccionados
  };

  // modo open/close del select
  static triggerType = {
    hover: 1,
    click: 2,
  };

  // tipos de item
  static itemType = {
    checkbox: 1,
    marginCheckbox: 2,
    labelOnly: 3,
  };

  // formas de alinear los items
  static alignItemsType = {
    columns: "columns",
    rows: "rows",
  };
  static isValidAlignItems(alignTypeValue = "") {
    const values = Object.values(this.alignItemsType);
    return values.includes(alignTypeValue);
  }

  // posiciones del menu desplegable del select
  static positionDropdown = {
    topStart: "top-start",
    topEnd: "top-end",
    topCenter: "top",

    rightStart: "right-start",
    rightEnd: "right-end",
    rightCenter: "right",

    bottomStart: "bottom-start",
    bottomEnd: "bottom-end",
    bottomCenter: "bottom",

    leftStart: "left-start",
    leftEnd: "left-end",
    leftCenter: "left",
  };
  static isValidpositionDropdown(positionValue = "") {
    const values = Object.values(this.positionDropdown);
    return values.includes(positionValue);
  }

  // tipos de bordes del container del select
  static borderType = {
    rectangle: "rectangle",
    oval: "oval",
    circle: "circle",
  };
}
