import styled from "styled-components";

const DivMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
  //border: 1px solid red;

  .btnNavBack {
    width: fit-content;
    //border: 1px solid blue;
  }
  .btnNavClose {
    width: fit-content;
    border-radius: 100%;
    transition: all 0.4s;
    padding: 5px;
    //border: 1px solid blue;
    &:hover {
      box-shadow: 0px 0px 4px 0px #13131d4f;
    }
  }
`;

export default {
  DivMain,
};
