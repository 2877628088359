import {
  isArrayEmpty,
  isNumberPositive,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import styles from "./styles";
import { VeryIconGoogle } from "../VeryIconGoogle";
import classVeryRating from "./classVeryRating";
import { classVeryIconGoogle } from "../VeryIconGoogle/classVeryIconGoogle";

export const VeryRating = (props = classVeryRating.getPropsTemplate()) => {
  const CSS = ["veryRating"];
  const sizeIcon = isNumberPositive(props.sizeIcon)
    ? Number(props.sizeIcon)
    : 20;
  // add custom style
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) CSS.push(props.className);
  // add color

  return (
    <styles.DivRating
      readOnly={!(props.isEditable === true)}
      precision={
        isNumberPositive(props.stepValue) ? Number(props.stepValue) : 1
      }
      max={isNumberPositive(props.maxValue) ? Number(props.maxValue) : 5}
      value={isNumberPositive(props.value) ? Number(props.value) : 0}
      onChange={(event, newValue) => {
        props.onChangeValue && props.onChangeValue(newValue);
      }}
      icon={
        <VeryIconGoogle
          CSS={["customIcon"]}
          name="star"
          color={classVeryIconGoogle.ICON_COLOR.YELLOW_S1}
          type={classVeryIconGoogle.ICON_TYPE.ROUNDED}
          size={sizeIcon}
        />
      }
      emptyIcon={
        <VeryIconGoogle
          CSS={["customIcon"]}
          name="star"
          color={classVeryIconGoogle.ICON_COLOR.YELLOW_S1}
          type={classVeryIconGoogle.ICON_TYPE.ROUNDED}
          size={sizeIcon}
        />
      }
    />
  );
};
