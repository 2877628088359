import { Checkbox } from "@mui/material";
import { isStringEmpty } from "../../../../utils/handler_validations";
import { classCustomIcon } from "../../custom-icon/utils/classCustomIcon";
import styles from "./styles";

const propsType = {
  className: "",
  label: "", // texto visible del item
  value: "", // valor del item -> string | number
  checkbox: undefined,
  selected: false, // item seleccionado??
  onClick: (isSelected = false, value = "") => {},
};

const SelectItem = (props = propsType) => {
  const clasesCSS = ["selectItem"];
  if (!isStringEmpty(props.className)) clasesCSS.push(props.className);
  // cuando es item con checkbox
  if (props.checkbox) {
    return (
      <styles.Container
        className={clasesCSS.join(" ")}
        checkbox={props.checkbox}
        onClick={(event) => props.onClick(!props.selected, props.value)}
      >
        <Checkbox
          className={"checkbox" + (props.checkbox ? "" : " hidden")}
          checked={props.selected}
        />
        <label className="label">{props.label}</label>
      </styles.Container>
    );
  }
  // cuando es item labelOnly
  if (props.selected) clasesCSS.push("selectedItem");

  return (
    <styles.ContainerButton
      className={clasesCSS.join(" ")}
      checkbox={props.checkbox === false}
      label={props.label}
      icon={{
        name: "done",
        type: classCustomIcon.iconType.filled,
        size: 16,
        color: classCustomIcon.color.blackS1,
      }}
      onClick={(event) => props.onClick(!props.selected, props.value)}
    />
  );
};

export default SelectItem;
