import styled from "styled-components";

const divMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 35px;
  //border: 1px solid blue;
  .separatorTitle {
    width: 100%;
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #a0a0a0;
    text-align: center;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 15px;
    &::before {
      content: "";
      border: 0.3px solid #dbdce17b;
      width: 100%;
    }
    &::after {
      content: "";
      border: 0.3px solid #dbdce17b;
      width: 100%;
    }
  }
  > .divInputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
  > .txtError {
    width: 100%;
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #a72323;
    line-height: 1.2;
    text-align: left;
    white-space: pre-line;
    margin-top: 18px;
    //border: 1px solid red;
  }
`;

export default {
  divMain,
};
