import styles from "./styles";
import {
  isArrayEmpty,
  isNumberPositive,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import { InputBase } from "@mui/material";
import { useState, useEffect, createRef } from "react";

const propsTemplate = () => ({
  CSS: [],
  isDisabled: false,
  error: "",
  sizeLabels: { input: 0, error: 0 },
  inputs: [{ value: "", validValuesER: / / }],
  onChangeValue: (newValue = "", indexInput = 0) => {},
  onEnter: () => {},
  onLostFocus: () => {},
});

export const VeryGroupInput = (props = propsTemplate()) => {
  const [refs, setRefs] = useState([createRef()]);
  const [indexInputFocus, setIndexInputFocus] = useState(undefined);
  const [disabledOnKeyUp, setDisabledOnKeyUp] = useState(false);
  const customCSS = ["veryGroupInput"];
  const sizeLabels = {
    input: isNumberPositive(props.sizeLabels?.input)
      ? Number(props.sizeLabels.input)
      : 48,
    error: isNumberPositive(props.sizeLabels?.error)
      ? Number(props.sizeLabels.error)
      : 12,
  };
  const existError = !isStringEmpty(props.error);

  useEffect(() => {
    setRefs(Array.from({ length: props.inputs.length }, () => createRef()));
  }, [props.inputs.length]);

  useEffect(() => {
    if (indexInputFocus === -1 && props.onLostFocus) props.onLostFocus();
  }, [indexInputFocus]);

  // custom CSS
  if (!isArrayEmpty(props.CSS)) customCSS.push(props.CSS);
  if (!isStringEmpty(props.className)) customCSS.push(props.className);
  if (existError) customCSS.push("veryGroupInputError");

  if (isArrayEmpty(props.inputs)) return null;
  return (
    <styles.DivMain
      className={customCSS.join(" ")}
      sizeTxtError={sizeLabels.error}
    >
      {/* CONTAINER INPUTS */}
      <styles.DivInputs className="divInputs" sizeTxtInput={sizeLabels.input}>
        {props.inputs.map((item, index) => {
          return (
            <InputBase
              className="divInputBase"
              key={"input-" + index}
              inputRef={refs[index]}
              value={indexInputFocus === index ? "" : item.value}
              placeholder={indexInputFocus === index ? item.value : ""}
              disabled={props.isDisabled}
              inputProps={{
                size: 1,
                maxLength: 1,
              }}
              onPaste={(event) => {
                setDisabledOnKeyUp(true);
                const textPasted = event.clipboardData.getData("text");
                let indexInput = index;
                for (const char of textPasted) {
                  if (!isStringEmpty(char) && props.onChangeValue) {
                    if (props.inputs[indexInput].validValuesER) {
                      if (props.inputs[indexInput].validValuesER.test(char)) {
                        props.onChangeValue(char, indexInput);
                      }
                    } else {
                      props.onChangeValue(char, indexInput);
                    }
                    if (indexInput + 1 === props.inputs.length) return;
                    else indexInput++;
                  }
                }
              }}
              onKeyUp={(event) => {
                if (disabledOnKeyUp) {
                  setDisabledOnKeyUp(false);
                  return;
                }
                const character = event.key;
                const isLastInput = index + 1 === props.inputs.length;
                // tecla (=>)
                if (event.key === "ArrowRight" && !isLastInput) {
                  refs[index + 1]?.current?.focus();
                }
                // tecla (<=)
                else if (event.key === "ArrowLeft" && index > 0) {
                  refs[index - 1]?.current?.focus();
                }
                // tecla (borrar <===)
                else if (event.key === "Backspace" && props.onChangeValue) {
                  props.onChangeValue("", index);
                }
                // tecla (borrar <===)
                else if (event.key === "Enter" && props.onEnter) {
                  props.onEnter();
                }
                // cuando no hay caracter (tecla especial)
                else if (character.length !== 1) {
                  return;
                }
                // solo caracteres validos
                else if (item.validValuesER) {
                  if (
                    item.validValuesER.test(character) &&
                    props.onChangeValue
                  ) {
                    props.onChangeValue(character, index);
                    if (!isLastInput) {
                      refs[index + 1]?.current?.focus();
                    }
                  }
                }
                // cualquier caracter es aceptado
                else if (props.onChangeValue) {
                  props.onChangeValue(character, index);
                  if (!isLastInput) {
                    refs[index + 1]?.current?.focus();
                  }
                }
              }}
              onFocus={() => setIndexInputFocus(index)}
              onBlur={() => setIndexInputFocus(-1)}
            />
          );
        })}
      </styles.DivInputs>
      {/* MESSAGE ERROR */}
      {!isStringEmpty(props.error) && (
        <label className="txtError">{props.error}</label>
      )}
    </styles.DivMain>
  );
};
