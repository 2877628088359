import styles from "./styles";
import "react-activity/dist/library.css";
import {
  isStringEmpty,
  isValidNaturalNumber,
} from "../../../utils/handler_validations";
import { classCustomIcon } from "./utils/classCustomIcon";

const CustomIcon = (props = classCustomIcon.propsType) => {
  const {
    className, // (string)
    size, // (number) -> tamaño del icono en px
    color, // (string)  -> consultar colores en la clase
    iconName, // (string)	-> consultar nombres en google icons
    iconType, // (string) -> consultar tipos de iconos en la clase
    transparent, // (boolean)
    spin, // (boolean) -> true genera una animacion circular infinita
  } = props;

  const classesCSS = [];
  const icon_types = Object.values(classCustomIcon.iconType);
  classesCSS.push(
    !icon_types.includes(iconType) ? classCustomIcon.iconType.filled : iconType
  );
  const iconSize = isValidNaturalNumber(size) ? size : 18;
  if (!isStringEmpty(className)) classesCSS.push(className);
  const icon_colors = Object.values(classCustomIcon.color);
  if (!icon_colors.includes(color)) {
    classesCSS.push(classCustomIcon.color.whiteS1);
  } else {
    classesCSS.push(color);
  }
  if (transparent === true) classesCSS.push("transparent");
  else classesCSS.push("fill");
  // poner animacion circular ??
  if (spin === true) classesCSS.push("iconSpin");

  return isStringEmpty(iconName) ? null : (
    <styles.MainContainer
      className={classesCSS.join(" ")}
      transparent={transparent}
      icon={iconName}
      iconSize={iconSize}
    >
      {iconName}
    </styles.MainContainer>
  );
};

export default CustomIcon;
