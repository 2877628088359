export default class classLoginPage {
  static STEP_RECOVERY_PASS = {
    EMAIL: 1,
    CODE: 2,
    NEW_PASS: 3,
  };
  static getInputsCode() {
    return Array.from({ length: 6 }, () => ({
      value: "",
      validValuesER: /^\d$/,
    }));
  }
}
