import { classVeryIconGoogle } from "../VeryIconGoogle/classVeryIconGoogle";

export class classVeryLoading {
  // props recibidas
  static propsTemplate = () => ({
    color: "",
    size: 0,
    weight: 0,
    CSS: [],
  });
  // colores del loading
  static COLOR = {
    WHITE_S1: "WHITE_S1",
    GRAY_S1: "GRAY_S1",
    GRAY_S2: "GRAY_S2",
    BLACK_S1: "BLACK_S1",
    GREEN_S1: "GREEN_S1",
    ORANGE_S1: "ORANGE_S1",
  };
  static getColor(color = "") {
    return Object.values(this.COLOR).includes(color)
      ? color
      : this.COLOR.BLACK_S1;
  }
}
