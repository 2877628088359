import CSS from "./styles";
import { useEffect, useState, useRef } from "react";
import FormLoginPage from "../FormLogin";
import FooterLogin from "../FooterLogin";
import {
  NewPassProvider,
  RecoveryPassProvider,
  useLoginContext,
} from "../../../context/login.context";
import HeaderLoginPage from "../HeaderLogin";
import { VeryInput } from "../../common/v2/VeryInput";
import { VeryBtnTransparent } from "../../common/v2/VeryBtnTransparent";
import { classVeryBtnTransparent } from "../../common/v2/VeryBtnTransparent/classVeryBtnTransparent";
import { VeryInputPass } from "../../common/v2/VeryInputPass";
import RecoveryPass from "../RecoveryPass";
import stylesLogin from "../../../pages/login/styles";
import PanelNewPass from "../PanelNewPass";

const propsTemplate = () => ({
  refTransition: undefined,
});

export default function ContentLoginPage(props = propsTemplate()) {
  const dataLogin = useLoginContext();
  const [refInputPass, setRefInputPass] = useState(useRef());

  return (
    <>
      {/* FORM SIGN-IN */}
      <stylesLogin.DivScrollPanel>
        <CSS.DivMain>
          {/* HEADER */}
          <HeaderLoginPage
            title="Iniciar sesión"
            subtitle="Inicia sesión con tu cuenta administrador de Very Control"
          />

          {/* INPUTS */}
          <FormLoginPage title="Ingresa tus datos">
            {/* INPUT EMAIL */}
            <VeryInput
              isEditable
              text={dataLogin.data.email}
              onChangeText={(newValue) =>
                dataLogin.setInputData({ email: newValue })
              }
              title="Correo electrónico"
              textError={dataLogin.errorData.email}
              placeHolder="Ingresa tu correo"
              onLostFocus={() => dataLogin.setInputError({ email: "" })}
              onEnter={() => {
                if (refInputPass.current) refInputPass.current.focus();
              }}
            />
            {/* INPUT PASSWORD */}
            <VeryInputPass
              onChangeRefInput={(ref) => setRefInputPass(ref)}
              isEditable
              text={dataLogin.data.pass}
              onChangeText={(newValue) =>
                dataLogin.setInputData({ pass: newValue })
              }
              title="Contraseña"
              textError={dataLogin.errorData.pass}
              placeHolder="Ingresa tu contraseña"
              onLostFocus={() => dataLogin.setInputError({ pass: "" })}
              onEnter={() => dataLogin.onClickBtnSignIn()}
            />
          </FormLoginPage>

          {/* FOOTER */}
          <FooterLogin
            btnMain={{
              text: "Iniciar sesión",
              isLoading: dataLogin.value.loading,
              onClick: () => dataLogin.onClickBtnSignIn(),
            }}
          >
            <CSS.DivFooter>
              <div className="divBtnRecoveryPass">
                <label className="txtBtnRecoveryPass">
                  ¿Olvidaste tu contraseña?
                </label>
                <VeryBtnTransparent
                  CSS={["btn"]}
                  colorTheme={classVeryBtnTransparent.COLOR_THEME.BLACK_S1}
                  label="Recupérala aquí"
                  isDisabled={dataLogin.value.loading}
                  onClick={() => dataLogin.setShowScreenRecoveryPass(true)}
                  textSize={12}
                />
              </div>
            </CSS.DivFooter>
          </FooterLogin>
        </CSS.DivMain>
      </stylesLogin.DivScrollPanel>

      {/* SCREEN RECOVERY PASSWORD */}
      <RecoveryPassProvider>
        <RecoveryPass refTransition={props.refTransition} />
      </RecoveryPassProvider>

      {/* SCREEN SET NEW PASSWORD */}
      <NewPassProvider>
        <PanelNewPass refTransition={props.refTransition} />
      </NewPassProvider>
    </>
  );
}
