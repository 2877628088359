import { FormAdminProvider } from "../../context";
import ContentProfilePage from "./content";
import ModalAdmin from "../../components/modal-admin";

const Profile = () => {
  return (
    <FormAdminProvider>
      <ContentProfilePage />
      <ModalAdmin />
    </FormAdminProvider>
  );
};

export default Profile;
