import styled from "styled-components";
import { VeryModal } from "../v2/VeryModal";

const Modal = styled(VeryModal)`
  // container card
  .divContentModal {
    max-width: 400px;

    // body
    .divBodyModal {
      align-items: flex-start !important;
      .txt-message {
        font-family: "Roboto", Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        line-height: 1.2;
        white-space: pre-line;
      }
    }

    // buttons
    .divButtons .btnModal {
      padding-top: 11px;
      padding-bottom: 11px;
      border-radius: 10px;
    }
  }
`;

export default {
  Modal,
};
