import { getFileTemplate } from "../../utils/handler_files";

const getData = () => ({
  // personal data
  id: 0,
  profileImg: getFileTemplate(),
  name: "",
  lastName: "",
  email: "",
  tel: "",
  // vehicle data
  vehicleModel: "",
  vehicleYear: 0,
  vehicleColor: "",
  vehiclePlates: "",
  // documents data
  ine: getFileTemplate(),
  driverLicence: getFileTemplate(),
  criminalLetter: getFileTemplate(),
  address: getFileTemplate(),
});

const getErrorsData = () => ({
  // personal data
  profileImg: "",
  name: "",
  lastName: "",
  email: "",
  tel: "",
  // vehicle data
  vehicleModel: "",
  vehicleYear: "",
  vehicleColor: "",
  vehiclePlates: "",
  // documents data
  ine: "",
  driverLicence: "",
  criminalLetter: "",
  address: "",
});

const getTestData = () => ({
  error: false,
  message: "",
});

export default {
  getData,
  getErrorsData,
  getTestData,
};
