import styled from "styled-components";

const DivMain = styled.div`
  width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  overflow: auto;
  //border: 1px solid blue;
  * {
    box-sizing: border-box;
  }
  > .divContent {
    width: 100%;
    flex-grow: 2;
    flex-shrink: 2;
    height: 200px;
    border-radius: 10px;
    border: 2px dashed #eaeaea;
  }
  > .txtError {
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: 400;
    font-size: ${({ sizeError }) => sizeError}px;
    color: #a72323;
    text-align: left;
    white-space: pre-line;
    margin-top: 6px;
  }
  .divPreview {
    img {
      display: ${({ isLoading }) => (isLoading ? "none" : "flex")};
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const DivUpload = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ isDragActive }) => (isDragActive ? 8 : 0)}px;
  //border: 1px solid red;
  .iconPhoto {
    margin-bottom: 10px;
  }
  .btnRestore {
    width: 38px !important;
    height: 38px !important;
    padding: 0px 16px 1px 16px !important;
    border-radius: 10px !important;
    margin-bottom: 10px !important;
  }
  .txtTitle,
  .txtDescription {
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: 400;
    text-align: center;
    line-height: 1.2;
  }
  .txtTitle {
    font-size: ${({ sizeTitle }) => sizeTitle}px;
    color: #13131dcc;
    margin-bottom: 3px;
  }
  .txtDescription {
    font-size: ${({ sizeDescription }) => sizeDescription}px;
    color: #13131d4d;
  }
  .divIconUpload {
    flex: 1;
    overflow: hidden;
    display: grid;
    place-items: center;
    //border: 1px solid red;
    .iconUpload {
      animation-duration: 0.8s;
      animation-name: animationUpload;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: linear;
      //border: 1px solid blue;
    }
    @keyframes animationUpload {
      from {
        transform: translateY(-5px);
      }
      to {
        transform: translateY(5px);
      }
    }
  }
  .divPadding {
    flex: 1;
    //border: 1px solid red;
  }
  .btnUpload {
    margin-top: 10px;
    border-radius: 10px;
  }
`;

export default {
  DivMain,
  DivUpload,
};
