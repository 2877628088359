import styled from "styled-components";
import { classCustomImg } from "./utils/classCustomImg";

const Container = styled.div`
  display: grid;
  place-items: center;
  width: ${({ width }) => (width ? width : "min-content")};
  min-width: min-content;
  overflow: hidden;
  border-radius: ${({ borderType }) => {
    return borderType === classCustomImg.borderType.rectangle
      ? "6px"
      : borderType === classCustomImg.borderType.circle
      ? "50%"
      : "0px"; // default none
  }};
`;

const Img = styled.img`
  display: ${({ show }) => (show ? "grid" : "none")};
  width: 100%;
  object-fit: contain;
  border-radius: inherit;
`;

export default {
  Container,
  Img,
};
