import { useState } from "react";
import { VeryBtnFill } from "../../VeryBtnFill";
import { classVeryBtnFill } from "../../VeryBtnFill/classVeryBtnFill";
import { classVeryIconGoogle } from "../../VeryIconGoogle/classVeryIconGoogle";
import styles from "./styles";
import { FullScreen } from "../FullScreen";
import { getFileTemplate } from "../../../../../utils/handler_files";

const getPropsTemplate = () => ({
  isLoading: false,
  existSelectedFile: false,
  file: getFileTemplate(),
  onClickBtnDelete: () => {},
  title: "",
});

export const DivPreview = (props = getPropsTemplate()) => {
  const [showFullScreen, setShowFullScreen] = useState(false);

  return (
    <>
      <styles.DivPreview className="divPreview">
        <div className="divContentPreview">
          {/* PREVIEW BTN FULL SCREEN */}
          {!props.isLoading && props.existSelectedFile && (
            <VeryBtnFill
              CSS={["btnFullScreen"]}
              colorTheme={classVeryBtnFill.COLOR_THEME.GRAY_S2}
              icon={{
                name: "fullscreen",
                size: 22,
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
              }}
              onClick={() => setShowFullScreen(true)}
            />
          )}
          {/* PREVIEW BTN DELETE */}
          {!props.isLoading && props.existSelectedFile && (
            <VeryBtnFill
              CSS={["btnDelete"]}
              colorTheme={classVeryBtnFill.COLOR_THEME.GRAY_S2}
              icon={{
                name: "delete_forever",
                size: 22,
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
              }}
              onClick={props.onClickBtnDelete}
            />
          )}
          {/* MORE COMPONENTS */}
          {props.children}
        </div>
      </styles.DivPreview>

      {/* FULL SCREEN */}
      <FullScreen
        isVisible={showFullScreen}
        onClickClose={() => setShowFullScreen(false)}
        file={props.file}
        title={props.title}
      />
    </>
  );
};
