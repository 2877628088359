import { readAndCompressImage } from "browser-image-resizer";
import { Base64 } from "js-base64";
import { isArrayEmpty, isStringEmpty } from "./handler_validations";
import axios from "axios";

/*====================================================================
            UTILERIAS PARA EL MANEJO DE ARCHIVOS
======================================================================*/
/*------------------------------------
Objeto template para el manejo de archivos
--------------------------------------*/
export const getFileTemplate = () => ({ src: "", ext: "" });

/*------------------------------------
Verificar si el archivo es PDF
--------------------------------------*/
export const isFilePDF = (extension = "") => extension === "pdf";

/*------------------------------------
devolver el base64 completo de un file
--------------------------------------*/
export const getBase64 = async (file) => {
  return new Promise((success, reject) => {
    const reader = new FileReader();
    reader.onloadend = (event) => {
      success(reader.result);
    };
    reader.onerror = () => {
      console.error("getBase64() error");
      success(undefined);
    };
    reader.readAsDataURL(file);
  });
};

/*------------------------------------
devolver el base64 completo de una imagen comprimida
--------------------------------------*/
export const getImgCompressBase64 = async (
  fileImg = new File(),
  config = {
    quality: 0.8,
    maxWidth: 1200,
    maxHeight: 1200,
  }
) => {
  return new Promise(async (success, reject) => {
    try {
      const resizedImg = await readAndCompressImage(fileImg, {
        ...config,
        mimeType: fileImg.type,
      });
      const reader = new FileReader();
      reader.onloadend = (event) => {
        success(reader.result);
      };
      reader.onerror = () => {
        //console.error("getImgCompressBase64() error");
        success(undefined);
      };
      reader.readAsDataURL(resizedImg);
    } catch (err) {
      console.error("getImgCompressBase64() error");
      console.error(err);
      success(undefined);
    }
  });
};

/*-----------------------------------
devolver la data de un string base64
-------------------------------------*/
export const getDataBase64 = (base64Full = "") => {
  try {
    const base64 = base64Full?.split("base64,");
    return base64?.length ? base64[1] : undefined;
  } catch (err) {
    return undefined;
  }
};

/*----------------------------------------
devolver un data Buffer a partir de un data base64
------------------------------------------*/
export const getDataBuffer = (dataBase64 = "") => {
  try {
    const dataBuffer = Buffer.from(dataBase64, "base64");
    return dataBuffer;
  } catch (err) {
    return undefined;
  }
};

/*--------------------------------
devolver la extension de un file
a partir de un string: "name.ext"
----------------------------------*/
export const getFileExtension = (fileName = "") => {
  if (isStringEmpty(fileName)) return undefined;
  const ext = fileName.match(/\.[^.\\/:\*\?"<>\|\r\n]+$/);
  return isArrayEmpty(ext) ? undefined : ext[0].slice(1);
};

/*--------------------------------
devolver la ultima extension de un file
a partir de un string: "name.ext"
----------------------------------*/
export const getLastFileExtension = (fileName = "") => {
  const nameParts = fileName.split(".");
  let ext = "";
  if (nameParts.length > 0) ext = nameParts[nameParts.length - 1];
  return ext === "" ? undefined : ext;
};

/*----------------------------------
devolver el nombre de un file
a partir de un string: "name.ext"
------------------------------------*/
export const getFileName = (fileName = "") => {
  const nameParts = fileName.split(".");
  if (nameParts.length) return nameParts[0];
  return undefined;
};

/*-----------------------------------
obtener la URL de una imagen con un peso mas 
pequeño al peso original pasando como
parametros:
=> bucket: <string> bucket en donde se encuentra la img 
=> key: <string> ruta y nombre completa de la img (keyS3)
=> width: <number> largo deseado de la img en px
=> height: <number> altura deseada de la img en px
-------------------------------------*/
export const getImageURL = (
  bucket = "",
  keyS3 = "",
  width = 200,
  height = 200
) => {
  try {
    if (isStringEmpty(keyS3)) return undefined;
    const configAWS = {
      bucket: bucket,
      key: keyS3,
      edits: {
        resize: {
          width: width,
          height: height,
          fit: "contain",
        },
      },
    };
    let url = "https://d954wi4n8olec.cloudfront.net/";
    url += Base64.encode(JSON.stringify(configAWS));
    return url;
  } catch (err) {
    console.log(`ERROR al obtener la URL de la imagen`);
    return undefined;
  }
};

export const getFileURL = (bucket = "", key = "") => {
  try {
    const configAWS = {
      bucket: bucket,
      key: key,
    };
    let url = "https://d954wi4n8olec.cloudfront.net/";
    url += Base64.encode(JSON.stringify(configAWS));
    return url;
  } catch (err) {
    console.log(`ERROR al obtener la URL del archivo`);
    return undefined;
  }
};

/*-----------------------------------------
obtener la dimension de una imagen 
a partir de un objeto <File>
retorno: {
=> width: <number> largo original de la img en px
=> height: <number> altura original de la img en px
} 
------------------------------------------*/
export const getImageSize = (file) => {
  return new Promise(async (response) => {
    const img = new Image();
    img.onload = () => response({ width: img.width, height: img.height });
    img.onerror = () => response(undefined);
    const base64 = await getBase64(file);
    img.src = base64;
  });
};

/*----------------------------------------
obtener una redimension (height) proporcional a la 
dimension original (height) a partir de un width deseado
retorno:
=> newHeight: <number> altura de la img en px
-----------------------------------------*/
export const getImgResizeHeight = (
  originalWidth = 1,
  originalHeight = 0,
  newWidth = 0
) => {
  const scale = (newWidth * 100) / originalWidth / 100;
  return Math.round(originalHeight * scale);
};

export const getFileSendDB = (
  newFile = getFileTemplate(),
  originalFile = getFileTemplate()
) => {
  const fileDB = { fullBase64: "", ext: newFile.ext, remove: false };
  if (newFile.src !== originalFile.src) fileDB.fullBase64 = newFile.src;
  if (isStringEmpty(newFile.src) && !isStringEmpty(originalFile.src)) {
    fileDB.remove = true;
  }
  return fileDB;
};

export const testFile = (
  file = getFileTemplate(),
  isRequired = false,
  validExtensions = ["jpeg", "jpg", "png", "pdf"],
  emptyMsg = ""
) => {
  // resp template
  const resp = (messageError = "") => ({
    error: !isStringEmpty(messageError),
    message: messageError,
  });
  // dato requerido no existe
  if (isRequired === true && isStringEmpty(file?.src)) {
    return resp(isStringEmpty(emptyMsg) ? "Archivo obligatorio" : emptyMsg);
  }
  // no analizar
  if (isRequired !== true && isStringEmpty(file?.src)) {
    return resp();
  }
  // extension no valida
  if (!validExtensions.includes(file.ext)) {
    return resp("El archivo no tiene extension valida");
  }
  return resp();
};

/**
 * Descagar un archivo con una URL
 */
export const downloadFile = async (url = "", name = "", ext = "") => {
  const resp = (msgError = "") => ({
    error: !isStringEmpty(msgError),
    message: msgError,
  });
  if (isStringEmpty(url)) {
    return resp("La URL del archivo a descargar no fue especificada");
  }
  try {
    const respFile = await axios.get(url, {
      responseType: "blob",
    });
    if (respFile.status !== 200) {
      //console.log("resp downloadFile => ", respFile.statusText);
      return resp(
        "Algo salio mal al descargar el archivo. Comprueba tu conexión de internet"
      );
    }
    const downloadURL = window.URL.createObjectURL(new Blob([respFile.data]));
    const link = document.createElement("a");
    link.href = downloadURL;
    link.setAttribute("download", `${name}.${ext}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadURL);
  } catch (err) {
    return resp(err.message);
  }
};
