import styled from "styled-components";

const MainContainer = styled.div`
  --size: ${({ size }) => size}px;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

  .loadingDots {
    font-size: var(--size) !important;
    &.orangeS1 {
      color: #f9ad33;
    }
    &.whiteS1 {
      color: whitesmoke;
    }
  }
`;

export default {
  MainContainer,
};
