import CSS from "./styles";
import { useEffect, useRef, useState } from "react";
import { LoginProvider } from "../../context/login.context";
import ContentLoginPage from "../../components/login-page/Content";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination, EffectCreative, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
import { VeryBtnFill } from "../../components/common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../../components/common/v2/VeryBtnFill/classVeryBtnFill";
import { classVeryIconGoogle } from "../../components/common/v2/VeryIconGoogle/classVeryIconGoogle";
import imgD1 from "../../assets/login-page/veryD1.png";
import imgD2 from "../../assets/login-page/veryD2.png";
import imgD3 from "../../assets/login-page/veryD3.png";
import imgD4 from "../../assets/login-page/veryD4.png";
import imgP1 from "../../assets/login-page/veryP1.png";
import imgP2 from "../../assets/login-page/veryP2.png";
import imgP3 from "../../assets/login-page/veryP3.png";
import imgP4 from "../../assets/login-page/veryP4.png";
import { isStringEmpty } from "../../utils/handler_validations";

export default function LoginPage(props) {
  const swiper = useRef();
  const refContainerForm = useRef();

  const renderItemSlide = (
    img = "",
    message = "",
    title = "",
    description = "",
    opacityCSS = ""
  ) => {
    let cssDivDataSlide = "divDataSlide";
    if (!isStringEmpty(opacityCSS)) cssDivDataSlide += ` ${opacityCSS}`;
    return (
      <SwiperSlide className="divSlide">
        <img src={img} className="imgSlide" />
        <div className={cssDivDataSlide}>
          <label className="txtMessage">{message}</label>
          <div className="divInfo">
            <label className="txtTitle">{title}</label>
            <label className="txtDesc">{description}</label>
          </div>
        </div>
      </SwiperSlide>
    );
  };

  return (
    <LoginProvider>
      <CSS.DivPage>
        {/* CONTAINER SLIDE */}
        <Swiper
          className="swiperLogin"
          effect="creative"
          grabCursor={true}
          speed={1000}
          loop={true}
          modules={[Pagination, EffectCreative, Autoplay]}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: ["-20%", 0, -1],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          onSwiper={(swiperLogin) => (swiper.current = swiperLogin)}
        >
          {renderItemSlide(
            imgP1,
            `"¡Wow! Como administrador de 'Very', he sido testigo de cómo esta aplicación ha transformado la movilidad en Tepic. La eficiencia y la transparencia que ofrece son incomparables. ¡Únete a la revolución de los viajes en taxi con 'Very'!"`,
            "Testimonio 1",
            "Administrador de Very"
          )}
          {renderItemSlide(
            imgD1,
            `"¡Convertirte en administrador de 'Very' es una decisión que cambiará tu negocio para siempre! Con 'Very', obtendrás un acceso exclusivo a una audiencia más amplia, aumentando tus ganancias y visibilidad. Únete a nosotros y experimenta la revolución de los servicios de taxi en Tepic."`,
            "Testimonio 2",
            "Dirigido a Dueños de Unidades de Taxi",
            "divOpacity_LOW"
          )}
          {renderItemSlide(
            imgP2,
            `"Como administrador, estoy orgulloso de formar parte de 'Very'. Nuestra plataforma cambia la forma en que los residentes locales y visitantes experimentan los taxis en Tepic. Ofrecemos tarifas justas y seguridad a cada paso del viaje."`,
            "Testimonio 3",
            "Administrador de Very"
          )}
          {renderItemSlide(
            imgD2,
            `"Como dueño de permisos de taxi, encontrar una plataforma confiable es crucial. 'Very' te brinda la oportunidad de expandir tu negocio y generar más ingresos. Únete a nosotros y descubre cómo 'Very' puede potenciar tus permisos de taxi."`,
            "Testimonio 4",
            "Dirigido a Dueños de Permisos de Taxi",
            "divOpacity_LOW"
          )}
          {renderItemSlide(
            imgP3,
            `"La innovación detrás de 'Very' es asombrosa. Como administrador, puedo decir que nuestra aplicación hace que los taxis sean más accesibles y eficientes que nunca. Tu comodidad y satisfacción son nuestra prioridad número uno."`,
            "Testimonio 5",
            "Administrador de Very",
            "divOpacity_HIGH"
          )}
          {renderItemSlide(
            imgD3,
            `"Ser parte de 'Very' ha sido un cambio de juego para nuestra flota de taxis. La plataforma nos ha permitido administrar y hacer crecer nuestro negocio de manera más eficiente que nunca. ¡No pierdas la oportunidad de unirte a esta emocionante transformación!"`,
            "Testimonio 6",
            "Dirigido a Dueños de Flotas de Taxis"
          )}
          {renderItemSlide(
            imgP4,
            `"Trabajar como administrador para 'Very' es un privilegio. Nuestro compromiso con la calidad y la seguridad en cada viaje es insuperable. Únete a nosotros y descubre cómo los viajes en taxi pueden ser más sencillos y confiables."`,
            "Testimonio 7",
            "Administrador de Very"
          )}
          {renderItemSlide(
            imgD4,
            `"La plataforma 'Very' es perfecta para dueños de unidades de taxi individuales como yo. He experimentado un aumento significativo en el número de clientes y ganancias desde que me uní. Únete a 'Very' y lleva tu negocio al siguiente nivel."`,
            "Testimonio 8",
            "Dirigido a Dueños de Unidades Individuales",
            "divOpacity_HIGH"
          )}
          {/* CONTAINER BUTTONS SWIPER BACK / NEXT */}
          <div className="divCustomNavigation">
            <VeryBtnFill
              CSS={["btnNav"]}
              colorTheme={classVeryBtnFill.COLOR_THEME.GRAY_S1}
              icon={{
                name: "chevron_left",
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED,
                size: 20,
              }}
              onClick={() => swiper?.current?.slidePrev()}
            />
            <VeryBtnFill
              CSS={["btnNav"]}
              colorTheme={classVeryBtnFill.COLOR_THEME.GRAY_S1}
              icon={{
                name: "chevron_right",
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED,
                size: 20,
              }}
              onClick={() => swiper?.current?.slideNext()}
            />
          </div>
        </Swiper>
        {/* CONTENT */}
        <CSS.DivForm ref={refContainerForm}>
          <ContentLoginPage refTransition={refContainerForm} />
        </CSS.DivForm>
      </CSS.DivPage>
    </LoginProvider>
  );
}
