import { useState, useEffect } from "react";
import {
  isStringEmpty,
  isPositiveInt,
  getString,
  getNotNegativeNumber,
  testEmail,
} from "../../utils/handler_validations";
import {
  getFileSendDB,
  getFileTemplate,
  testFile,
} from "../../utils/handler_files";
import templates from "./templates";
import { useVeryNotificationsContext } from "../../components/common/v2/VeryNotifications/useVeryNotifications";
import { useSessionContext } from "../../context/session.context";
import { testTel } from "../../utils/handler_tel";
import { driverDB } from "../../services";

export const useFormDriver = () => {
  const [value, setValue] = useState({
    show: false,
    stepSelected: 0,
    loading: false,
    modeEdit: false,
  });
  const [originalData, setOriginalData] = useState(templates.getData());
  const [data, setData] = useState(templates.getData());
  const [errorData, setErrorData] = useState(templates.getErrorsData());
  const [steps, setSteps] = useState({
    personal: { label: "DATOS PERSONALES", isCompleted: false },
    vehicle: { label: "DATOS DE VEHÍCULO", isCompleted: false },
    documents: { label: "DOCUMENTOS OFICIALES", isCompleted: false },
  });
  const notis = useVeryNotificationsContext();
  const session = useSessionContext();

  // cada que cambie un dato
  useEffect(() => {
    // actualizar check de los steps
    setSteps((prev) => ({
      personal: { ...prev.personal, isCompleted: isStepPersonalCompleted() },
      vehicle: { ...prev.vehicle, isCompleted: isStepVehicleCompleted() },
      documents: { ...prev.documents, isCompleted: isStepDocumentsCompleted() },
    }));
    // actualizar errores
    refreshErrorsData();
  }, [data, value.modeEdit]);

  /*=================================
	SHOW / HIDE MODAL
	===================================*/
  const showModalModeEdit = (initialData = templates.getData()) => {
    setOriginalData(initialData);
    updateData(initialData);
    setTimeout(() => {
      setValue({
        show: true,
        stepSelected: 0,
        loading: false,
        modeEdit: true,
      });
    }, 300);
  };

  const showModalModeAdd = () => {
    setOriginalData(templates.getData());
    updateData(templates.getData());
    setTimeout(() => {
      setValue({
        show: true,
        stepSelected: 0,
        loading: false,
        modeEdit: false,
      });
    }, 300);
  };

  const hideModal = () => {
    setValue((prev) => ({
      ...prev,
      show: false,
      loading: false,
    }));
  };

  /*=================================
	ACTUALIZACION DE DATOS
	===================================*/
  const updateStep = (step = 0) => {
    setValue((prev) => ({
      ...prev,
      stepSelected: step,
    }));
  };

  const updateData = (newData = templates.getData()) => {
    setData((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  // actualizar errores especificos directamente
  const updateErrorsData = (newErrorData = templates.getErrorsData()) => {
    setErrorData((prev) => ({
      ...prev,
      ...newErrorData,
    }));
  };

  // actualizar TODOS los errores al cambiar los datos
  const refreshErrorsData = () => {
    const newErrorsData = templates.getErrorsData();
    // profileIMG
    const respTestProfileImg = testFile(
      data.profileImg,
      true,
      ["jpeg", "jpg", "png"],
      "Fotografía requerida"
    );
    if (respTestProfileImg.error) {
      newErrorsData.profileImg = respTestProfileImg.message;
    }
    // name
    const respTestName = testText(data.name);
    if (respTestName.error) {
      newErrorsData.name = respTestName.message;
    }
    // lastName
    const respTestLastName = testText(data.lastName);
    if (respTestLastName.error) {
      newErrorsData.lastName = respTestLastName.message;
    }
    // email
    const respTestEmail = testEmail(data.email, false);
    if (respTestEmail.error && !value.modeEdit) {
      newErrorsData.email = respTestEmail.message;
    }
    // tel
    const respTestTel = testTel(data.tel, true);
    if (respTestTel.error && !value.modeEdit) {
      newErrorsData.tel = respTestTel.message;
    }
    // vehicle model
    const respTestModel = testText(data.vehicleModel);
    if (respTestModel.error) {
      newErrorsData.vehicleModel = respTestModel.message;
    }
    // vehicle color
    const respTestColor = testVehicleColor(data.vehicleColor);
    if (respTestColor.error) {
      newErrorsData.vehicleColor = respTestColor.message;
    }
    // vehicle year
    if (!isPositiveInt(data.vehicleYear)) {
      newErrorsData.vehicleYear = "Campo obligatorio";
    }
    // vehicle plates
    const respTestPlates = testVehiclePlates(data.vehiclePlates);
    if (respTestPlates.error) {
      newErrorsData.vehiclePlates = respTestPlates.message;
    }
    // INE
    const respTestINE = testFile(
      data.ine,
      true,
      ["jpeg", "jpg", "png", "pdf"],
      "Documento requerido"
    );
    if (respTestINE.error) {
      newErrorsData.ine = respTestINE.message;
    }
    // driver licence
    const respTestLicence = testFile(
      data.driverLicence,
      true,
      ["jpeg", "jpg", "png", "pdf"],
      "Documento requerido"
    );
    if (respTestLicence.error) {
      newErrorsData.driverLicence = respTestLicence.message;
    }
    // criminal letter
    const respTestCriminal = testFile(data.criminalLetter, false, [
      "jpeg",
      "jpg",
      "png",
      "pdf",
    ]);
    if (respTestCriminal.error) {
      newErrorsData.criminalLetter = respTestCriminal.message;
    }
    // comprobante address
    const respTestAddress = testFile(data.address, false, [
      "jpeg",
      "jpg",
      "png",
      "pdf",
    ]);
    if (respTestAddress.error) {
      newErrorsData.address = respTestAddress.message;
    }
    // set
    setErrorData(newErrorsData);
  };

  /*=================================
	VALIDACIONES
	===================================*/
  const testText = (newText = "") => {
    const resp = templates.getTestData();
    if (isStringEmpty(newText)) {
      resp.error = true;
      resp.message = "Campo obligatorio";
    }
    return resp;
  };

  const testVehiclePlates = (plates = "") => {
    const resp = templates.getTestData();
    if (isStringEmpty(plates)) {
      resp.error = true;
      resp.message = "Campo obligatorio";
    } else if (!/^(\w|\d|-)+$/.test(plates)) {
      resp.error = true;
      resp.message = "Solamente puedes utilizar letras, dígitos y guiones";
    } else if (/^-|-$/.test(plates)) {
      resp.error = true;
      resp.message =
        "Las placas del auto no pueden comenzar o terminar con guiones";
    }
    return resp;
  };

  const testVehicleColor = (color = "") => {
    const resp = templates.getTestData();
    if (isStringEmpty(color)) {
      resp.error = true;
      resp.message = "Campo obligatorio";
    } else if (!["AMARILLO", "ROJO", "VERDE", "BLANCO"].includes(color)) {
      resp.error = true;
      resp.message = "El color no es valido";
    }
    return resp;
  };

  const isStepPersonalCompleted = () => {
    if (
      testFile(data.profileImg, true, ["jpeg", "jpg", "png"]).error ||
      testText(data.name).error ||
      testText(data.lastName).error ||
      (!value.modeEdit && testEmail(data.email).error) ||
      (!value.modeEdit && testTel(data.tel, true).error)
    ) {
      return false;
    }
    return true;
  };

  const isStepVehicleCompleted = () => {
    if (
      testText(data.vehicleModel).error ||
      !isPositiveInt(data.vehicleYear) ||
      testVehicleColor(data.vehicleColor).error ||
      testVehiclePlates(data.vehiclePlates).error
    ) {
      return false;
    }
    return true;
  };

  const isStepDocumentsCompleted = () => {
    const validExt = ["jpeg", "jpg", "png", "pdf"];
    if (
      testFile(data.ine, true, validExt).error ||
      testFile(data.driverLicence, true, validExt).error ||
      testFile(data.criminalLetter, false, validExt).error ||
      testFile(data.address, false, validExt).error
    ) {
      return false;
    }
    return true;
  };

  /*=================================
	FUNCTIONS
	===================================*/
  const dbChangeINE = (driverId = 0) => {
    const fileDB = getFileSendDB(data.ine, originalData.ine);
    if (fileDB.remove) {
      return driverDB.deleteFile(
        driverId,
        driverDB.FILE_TYPE.INE,
        session.value.jwt
      );
    }
    if (!isStringEmpty(fileDB.fullBase64)) {
      return driverDB.updateFile(
        driverId,
        driverDB.FILE_TYPE.INE,
        fileDB,
        session.value.jwt
      );
    }
    return new Promise((success) => success(undefined));
  };

  const dbChangeDriverLicence = (driverId = 0) => {
    const fileDB = getFileSendDB(
      data.driverLicence,
      originalData.driverLicence
    );
    if (fileDB.remove) {
      return driverDB.deleteFile(
        driverId,
        driverDB.FILE_TYPE.DRIVER_LICENCE,
        session.value.jwt
      );
    }
    if (!isStringEmpty(fileDB.fullBase64)) {
      return driverDB.updateFile(
        driverId,
        driverDB.FILE_TYPE.DRIVER_LICENCE,
        fileDB,
        session.value.jwt
      );
    }
    return new Promise((success) => success(undefined));
  };

  const dbChangeCriminalLetter = (driverId = 0) => {
    const fileDB = getFileSendDB(
      data.criminalLetter,
      originalData.criminalLetter
    );
    if (fileDB.remove) {
      return driverDB.deleteFile(
        driverId,
        driverDB.FILE_TYPE.CRIMINAL_LETTER,
        session.value.jwt
      );
    }
    if (!isStringEmpty(fileDB.fullBase64)) {
      return driverDB.updateFile(
        driverId,
        driverDB.FILE_TYPE.CRIMINAL_LETTER,
        fileDB,
        session.value.jwt
      );
    }
    return new Promise((success) => success(undefined));
  };

  const dbChangeAddress = (driverId = 0) => {
    const fileDB = getFileSendDB(data.address, originalData.address);
    if (fileDB.remove) {
      return driverDB.deleteFile(
        driverId,
        driverDB.FILE_TYPE.ADDRESS,
        session.value.jwt
      );
    }
    if (!isStringEmpty(fileDB.fullBase64)) {
      return driverDB.updateFile(
        driverId,
        driverDB.FILE_TYPE.ADDRESS,
        fileDB,
        session.value.jwt
      );
    }
    return new Promise((success) => success(undefined));
  };

  const dbChangeProfileImg = (driverId = 0) => {
    const fileDB = getFileSendDB(data.profileImg, originalData.profileImg);
    if (fileDB.remove) {
      return driverDB.deleteFile(
        driverId,
        driverDB.FILE_TYPE.PROFILE_IMG,
        session.value.jwt
      );
    }
    if (!isStringEmpty(fileDB.fullBase64)) {
      return driverDB.updateFile(
        driverId,
        driverDB.FILE_TYPE.PROFILE_IMG,
        fileDB,
        session.value.jwt
      );
    }
    return new Promise((success) => success(undefined));
  };

  /*=================================
	EVENTOS
	===================================*/
  const onClickBtnMain = async () => {
    setValue((prev) => ({
      ...prev,
      loading: true,
    }));
    let driverId = 0;
    // mode EDIT driver
    if (value.modeEdit) {
      const respEdit = await driverDB.updateDriver(
        {
          user: {
            id: originalData.id,
            name: data.name,
            lastName: data.lastName,
          },
          vehicle: {
            model: data.vehicleModel,
            year: data.vehicleYear,
            color: data.vehicleColor,
            plates: data.vehiclePlates,
          },
        },
        session.value.jwt
      );
      // error
      if (respEdit.error) {
        notis.createNotiError(respEdit.error.message, respEdit.error.details);
        setValue((prev) => ({
          ...prev,
          loading: false,
        }));
        return;
      }
      // success
      notis.createNotiSuccess(
        "Los datos del conductor y vehículo fueron actualizados",
        respEdit.messageSuccess
      );
      driverId = originalData.id;
    }
    // mode ADD driver
    else {
      const respCreate = await driverDB.create(
        {
          user: {
            name: data.name,
            lastName: data.lastName,
            tel: data.tel,
            email: data.email,
          },
          vehicle: {
            model: data.vehicleModel,
            year: data.vehicleYear,
            color: data.vehicleColor,
            plates: data.vehiclePlates,
          },
        },
        session.value.jwt
      );
      // error
      if (respCreate.error) {
        notis.createNotiError(
          respCreate.error.message,
          respCreate.error.details
        );
        setValue((prev) => ({
          ...prev,
          loading: false,
        }));
        return;
      }
      // success
      notis.createNotiSuccess(
        "El conductor ha sido registrado",
        "Subiendo archivos ..."
      );
      driverId = respCreate.body.driverId;
    }

    // upload/delete files
    const [respProfileImg, respINE, respLicence, respCriminal, respAddress] =
      await Promise.all([
        dbChangeProfileImg(driverId),
        dbChangeINE(driverId),
        dbChangeDriverLicence(driverId),
        dbChangeCriminalLetter(driverId),
        dbChangeAddress(driverId),
      ]);
    // resp profileIMG
    if (respProfileImg) {
      if (respProfileImg.error) {
        notis.createNotiError(
          respProfileImg.error.message,
          respProfileImg.error.details
        );
      } else {
        notis.createNotiSuccess(
          "Se ha guardado la imagen de perfil del conductor"
        );
      }
    }
    // resp INE
    if (respINE) {
      if (respINE.error) {
        notis.createNotiError(respINE.error.message, respINE.error.details);
      } else {
        notis.createNotiSuccess("Se ha guardado la INE del conductor");
      }
    }
    // resp licence
    if (respLicence) {
      if (respLicence.error) {
        notis.createNotiError(
          respLicence.error.message,
          respLicence.error.details
        );
      } else {
        notis.createNotiSuccess(
          "Se ha guardado la licencia de manejo del conductor"
        );
      }
    }
    // resp criminalLetter
    if (respCriminal) {
      if (respCriminal.error) {
        notis.createNotiError(
          respCriminal.error.message,
          respCriminal.error.details
        );
      } else {
        notis.createNotiSuccess(
          "Se ha guardado la carta de antecedentes penales del conductor"
        );
      }
    }
    // resp compAddress
    if (respAddress) {
      if (respAddress.error) {
        notis.createNotiError(
          respAddress.error.message,
          respAddress.error.details
        );
      } else {
        notis.createNotiSuccess(
          "Se ha guardado el comprobante de domicilio del conductor"
        );
      }
    }
    // success
    if (value.modeEdit) hideModal();
    else showModalModeAdd();
  };

  return {
    value,
    originalData,
    data,
    errorData,
    steps,

    hideModal,
    showModalModeEdit,
    showModalModeAdd,

    updateStep,
    updateData,
    updateErrorsData,
    onClickBtnMain,
  };
};
