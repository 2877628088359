import { useEffect, useRef, useState } from "react";
import { ClickAwayListener, Fade, Tooltip as TooltipMUI } from "@mui/material";
import styles from "./styles";
import SelectItem from "./custom-item/index";
import classCustomSelect from "./classCustomSelect";
import {
  isArrayEmpty,
  isObject,
  isStringEmpty,
} from "../../../utils/handler_validations";
import CustomButton from "../custom-button";
import { classCustomIcon } from "../custom-icon/utils/classCustomIcon";

const CustomSelect = (props = classCustomSelect.propsType) => {
  const [selectedItems, setSelectedItems] = useState(); // {}
  const [customItems, setCustomItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [labelSelect, setLabelSelect] = useState("Sin titulo");

  // cada que cambie la lista de items
  useEffect(() => {
    if (isArrayEmpty(props.items) || !isObject(props.initialSelectedItems)) {
      setSelectedItems({});
      renderItems();
      renderLabelSelect();
      return;
    }
    // cuando solo se permite seleccionar 1 a la vez
    if (!props.multiple) {
      const values = Object.keys(props.initialSelectedItems);
      if (values.length) setSelectedItems({ [values[0]]: true });
      else setSelectedItems({});
    }
    // cuando se permiten multiples selecciones
    else {
      setSelectedItems(props.initialSelectedItems);
    }
    renderItems();
    renderLabelSelect();
  }, [props.items]);

  // cada que cambien los items seleccionados
  useEffect(() => {
    if (!isObject(selectedItems)) return;
    renderItems();
    renderLabelSelect();
  }, [selectedItems]);

  const getItemType = (itemTypeValue = 0) => {
    if (itemTypeValue === classCustomSelect.itemType.checkbox) return true;
    else if (itemTypeValue === classCustomSelect.itemType.marginCheckbox) {
      return false;
    } else return undefined;
  };

  // evento click de cada item de la lista
  const onClickItem = (isSelected, value) => {
    let selectedItemsCopy = { ...selectedItems };
    if (isSelected) {
      if (props.multiple) selectedItemsCopy[value] = true;
      else selectedItemsCopy = { [value]: true };
    } else {
      delete selectedItemsCopy[value];
    }
    setSelectedItems(selectedItemsCopy);
    props.onChange(selectedItemsCopy);
  };

  const renderLabelSelect = () => {
    if (!isObject(selectedItems)) return;
    const selectedItemsCopy = Object.keys(selectedItems);
    if (selectedItemsCopy.length > 1) {
      setLabelSelect("Personalizado");
    } else if (selectedItemsCopy.length === 1) {
      let labelItem = undefined;
      for (const item of props.items) {
        if (item.value == selectedItemsCopy[0]) {
          labelItem = item.label;
          break;
        }
      }
      if (!isStringEmpty(labelItem)) setLabelSelect(labelItem);
      else setLabelSelect("Sin titulo");
    } else {
      if (
        !isStringEmpty(props.defaultItem?.label) &&
        props.defaultItem?.showLabelInSelect
      ) {
        setLabelSelect(props.defaultItem.label);
      } else if (!isStringEmpty(props.selectButton?.label)) {
        setLabelSelect(props.selectButton.label);
      } else {
        setLabelSelect("Sin titulo");
      }
    }
  };

  const renderItems = () => {
    if (isArrayEmpty(props.items)) {
      setCustomItems([]);
      return;
    }
    setCustomItems(
      props.items.map((item, index) => {
        let selected = false;
        if (isObject(selectedItems) && selectedItems[item.value]) {
          selected = true;
        }
        return (
          <SelectItem
            key={"item-" + index}
            label={item.label}
            value={item.value}
            checkbox={getItemType(props.itemsSelectType)}
            selected={selected}
            onClick={onClickItem}
          />
        );
      })
    );
  };

  // posicion del dropdown
  let positionDropdown = classCustomSelect.positionDropdown.bottomCenter;
  if (classCustomSelect.isValidpositionDropdown(props.positionDropdown)) {
    positionDropdown = props.positionDropdown;
  }

  // es trigger hover
  let isTriggerHover = false;
  if (props.triggerType === classCustomSelect.triggerType.hover) {
    isTriggerHover = true;
  }

  // clases CSS del dropdown
  const clasesDropdownCSS = ["dropdownSelect"];
  if (!isStringEmpty(props.classNameDropdown)) {
    clasesDropdownCSS.push(props.classNameDropdown);
  }

  // alineacion de los items
  let alignmentItems = classCustomSelect.alignItemsType.rows;
  if (classCustomSelect.isValidAlignItems(props.alignmentItemsOverflow)) {
    alignmentItems = props.alignmentItemsOverflow;
  }

  // clases CSS del boton select
  const clasesBtnSelectCSS = ["buttonSelect"];
  if (!isStringEmpty(props.selectButton?.className)) {
    clasesBtnSelectCSS.push(props.selectButton?.className);
  }

  return (
    <ClickAwayListener
      onClickAway={(event) => {
        if (!isTriggerHover) setShowDropdown(false);
      }}
    >
      <styles.Container
        className={
          !isStringEmpty(props.classNameSelect) ? props.classNameSelect : ""
        }
        borderType={props.borderType}
      >
        <TooltipMUI
          placement={positionDropdown}
          open={!isTriggerHover ? showDropdown : undefined}
          arrow={true}
          componentsProps={{
            tooltip: { className: clasesDropdownCSS.join(" ") },
            popper: { disablePortal: true },
            transition: { timeout: 300 },
          }}
          TransitionComponent={Fade}
          enterDelay={100}
          followCursor={false}
          disableFocusListener
          disableTouchListener
          disableHoverListener={
            !isTriggerHover || props.selectButton?.isDisabled
          }
          title={
            <>
              {/* item por default */}
              {props.defaultItem && (
                <SelectItem
                  className="itemDefault"
                  label={props.defaultItem.label}
                  value={null}
                  checkbox={getItemType(props.defaultItem.itemType)}
                  selected={
                    isObject(selectedItems) && Object.keys(selectedItems).length
                      ? false
                      : true
                  }
                  onClick={(isSelected, value) => {
                    if (
                      isObject(selectedItems) &&
                      Object.keys(selectedItems).length
                    ) {
                      setSelectedItems({});
                      props.onChange({});
                    }
                  }}
                />
              )}

              {/* lista de items */}
              <div className="container-items">{customItems}</div>
            </>
          }
        >
          <styles.ContainerBtnSelect
            maxWidthDropdown={props.maxWidthDropdown}
            maxHeightDropdown={props.maxHeightDropdown}
            alignmentItemsOverflow={alignmentItems}
            maxWidthItems={props.maxWidthItems}
          >
            <CustomButton
              className={clasesBtnSelectCSS.join(" ")}
              isDisabled={props.selectButton?.isDisabled}
              color={props.selectButton?.color}
              transparent={props.selectButton?.transparent}
              label={labelSelect}
              visibleBorde
              borderType={props.borderType}
              size={props.selectButton?.size}
              icon={{
                name: "arrow_drop_down",
                type: classCustomIcon.iconType.filled,
                size: 22,
                color: classCustomIcon.color.blackS1,
                position: props.selectButton?.iconPosition,
              }}
              iconPosition={
                ["left", "right", "top", "bottom"].includes(
                  props.selectButton?.iconPosition
                )
                  ? props.selectButton.iconPosition
                  : "right"
              }
              onClick={(event) => {
                if (!isTriggerHover) setShowDropdown((prev) => !prev);
              }}
            />
          </styles.ContainerBtnSelect>
        </TooltipMUI>
      </styles.Container>
    </ClickAwayListener>
  );
};

export default CustomSelect;
