import { useEffect, useState } from "react";
import {
  concatStrings,
  getMomentES,
  getNotNegativeNumber,
  getObject,
  getString,
  isArrayEmpty,
  isObject,
  isStringEmpty,
} from "../utils/handler_validations";
import { tripDB } from "../services";
import { useSessionContext } from "../context/session.context";
import { classVeryIconGoogle } from "../components/common/v2/VeryIconGoogle/classVeryIconGoogle";
import { getDate_S1, getTime_S1 } from "../utils/handler_dates";
import {
  getAddress,
  getAddressTemplate,
  getCoordsTemplate,
  getFormattedAddress_S1,
  getFormattedAddress_S2,
} from "../utils/handler_location";
import { useVeryNotificationsContext } from "../components/common/v2/VeryNotifications/useVeryNotifications";
import { useMapsLibrary } from "@vis.gl/react-google-maps";

const getLoadErrorTemplate = (initialLoading = false) => ({
  loading: initialLoading === true,
  error: { message: "", details: "" },
});

const getRowTemplate = () => ({
  rowId: -1,
  trip: {
    id: 0,
    cost: 0,
    distance: 0, // km
    origin: getCoordsTemplate(),
    destination: getCoordsTemplate(),
    passengerId: 0,
    driverId: 0,
    requestDate: null, // timestamp
    status: 0,
  },
  coupon: {
    code: "",
    amount: 0,
  },
});

export const useTrips = () => {
  const [value, setValue] = useState({
    page: getLoadErrorTemplate(true),
    list: [getRowTemplate()], // lista con la data de los trips
    users: {}, // lista con la data de los passengers
    drivers: {}, // lista con la data de los drivers
  });
  const [selectedRow, setSelectedRow] = useState(-1);
  const [rowAddresses, setRowAddresses] = useState({}); // { [rowID]: { origin: {...} , destination: {...} } , ... }
  const session = useSessionContext();
  const notis = useVeryNotificationsContext();
  const geocodingAPI = useMapsLibrary("geocoding");

  useEffect(() => {
    getListInitial();
  }, []);

  const getEmptyMsgPage = () => {
    const emptyObj = { text: "", icon: { name: "", type: "" } };
    if (value.list === undefined) {
      emptyObj.text = "No se encontraron viajes";
      emptyObj.icon.name = "content_paste";
      emptyObj.icon.type = classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED;
    } else if (isArrayEmpty(value.list)) {
      emptyObj.text = "Ningún viaje coincide con la búsqueda";
      emptyObj.icon.name = "content_paste_search";
      emptyObj.icon.type = classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED;
    }
    return emptyObj;
  };

  // get fullName passenger
  const getPassengerName = (passengerId = 0) => {
    const userData = value.users[passengerId];
    return concatStrings([userData?.name, userData?.lastName], "Desconocido");
  };

  // get fullName driver
  const getDriverName = (driverId = 0, statusTrip = 0) => {
    if (["WAITING", "REJECTED", ""].includes(getStatusTrip(statusTrip))) {
      return "N/A";
    }
    const userData = value.users[driverId];
    return concatStrings([userData?.name, userData?.lastName], "Desconocido");
  };

  // get profile image passenger
  const getProfileImgUser = (userId = 0) => {
    const userData = value.users[userId];
    return isStringEmpty(userData?.profileImg) ? "" : userData.profileImg;
  };

  // get profile image driver
  const getProfileImgDriver = (driverId = 0) => {
    const driverData = value.drivers[driverId];
    return isStringEmpty(driverData?.profileImg) ? "" : driverData.profileImg;
  };

  // get status trip (label de la columna)
  const getFormattedStatusTrip = (status = 0) => {
    switch (status) {
      case 1:
        return "EN ESPERA";
      case 2:
      case 3:
      case 4:
        return "EN TRANSCURSO";
      case 5:
        return "COMPLETADO";
      case 6:
        return "CANCELADO";
      case 7:
        return "RECHAZADO";
      default:
        return "---";
    }
  };

  // get status trip (CONSTANTE string para definir colores de la columna)
  const getStatusTrip = (status = 0) => {
    switch (status) {
      case 1:
        return "WAITING";
      case 2:
      case 3:
      case 4:
        return "IN_PROGRESS";
      case 5:
        return "COMPLETED";
      case 6:
        return "CANCELED";
      case 7:
        return "REJECTED";
      default:
        return "";
    }
  };

  // get lista de admins formateada para ser manipulada por la tabla
  const getFormattedList = (tripsList = [], initial = false) => {
    let list = isArrayEmpty(tripsList) ? undefined : tripsList;
    return list?.map((row, index) => {
      const rowData = getRowTemplate();
      rowData.rowId = index;
      rowData.trip = {
        id: getNotNegativeNumber(row.id),
        cost: getNotNegativeNumber(row.cost),
        distance: getNotNegativeNumber(row.distance),
        passengerId: getNotNegativeNumber(row.passengerId),
        driverId: getNotNegativeNumber(row.driverId),
        status: getNotNegativeNumber(row.status),
        origin: row.origin,
        destination: row.destination,
        requestDate: row.requestDate,
      };
      rowData.coupon = {
        code: getString(row.coupon?.code),
        amount: getNotNegativeNumber(row.coupon?.amount),
      };
      return rowData;
    });
  };

  const getRequestDateTrip = (requestDate = null) => {
    const resp = { date: "Sin fecha", time: "" };
    const date = getMomentES(requestDate);
    if (!date) return resp;
    if (!date.isValid()) {
      resp.date = "--/---/----";
      resp.time = "--:--";
      return resp;
    }
    resp.date = getDate_S1(date, " ");
    resp.time = getTime_S1(date);
    return resp;
  };

  const getListInitial = async () => {
    setValue((prev) => ({ ...prev, page: getLoadErrorTemplate(true) }));
    const resp = await tripDB.getListTrips(session.value.jwt);
    if (resp.error) {
      setValue((prev) => ({
        ...prev,
        page: {
          loading: false,
          error: { message: resp.error.message, details: resp.error.details },
        },
      }));
      return;
    }
    // success
    setValue((prev) => ({
      ...prev,
      page: getLoadErrorTemplate(false),
      list: getFormattedList(resp.body.trips),
      users: getObject(resp.body.users),
      drivers: getObject(resp.body.drivers),
    }));
  };

  const getTripAddresses = (rowId = 0) => {
    const resp = {
      origin: { title: "", subtitle: "" },
      destination: { title: "", subtitle: "" },
    };
    const addresses = rowAddresses[rowId];
    if (!addresses) return undefined; // show skeleton
    // set origin
    if (addresses.origin === null) {
      resp.origin = {
        title: "Dirección desconocida",
        subtitle: undefined,
      };
    } else {
      resp.origin = {
        title: getFormattedAddress_S1(addresses.origin, false),
        subtitle: getFormattedAddress_S2(addresses.origin),
      };
    }
    // set destination
    if (addresses.destination === null) {
      resp.destination = {
        title: "Dirección desconocida",
        subtitle: undefined,
      };
    } else {
      resp.destination = {
        title: getFormattedAddress_S1(addresses.destination, false),
        subtitle: getFormattedAddress_S2(addresses.destination),
      };
    }
    // finally
    return resp;
  };

  const onChangeSelectedRow = async (newRowId = 0) => {
    setSelectedRow(newRowId);
    if (newRowId < 0) return;
    let originData = rowAddresses[newRowId]?.origin;
    let destData = rowAddresses[newRowId]?.destination;
    if (isObject(originData) && isObject(destData)) return;
    // GET origin address
    if (!isObject(originData)) {
      const respOrigin = await getAddress(
        geocodingAPI,
        value.list[newRowId].trip.origin
      );
      if (respOrigin.data) originData = respOrigin.data;
      else {
        notis.createNotiError(
          respOrigin.error.message,
          respOrigin.error.details
        );
        originData = null;
      }
    }
    // destination address
    if (!isObject(destData)) {
      const respDest = await getAddress(
        geocodingAPI,
        value.list[newRowId].trip.destination
      );
      if (respDest.data) destData = respDest.data;
      else {
        notis.createNotiError(respDest.error.message, respDest.error.details);
        destData = null;
      }
    }
    // save addresses
    setRowAddresses((prev) => ({
      ...prev,
      [newRowId]: { origin: originData, destination: destData },
    }));
  };

  return {
    value,
    selectedRow,
    rowAddresses,
    getListInitial,
    getEmptyMsgPage,
    getPassengerName,
    getDriverName,
    getProfileImgUser,
    getProfileImgDriver,
    getFormattedStatusTrip,
    getStatusTrip,
    getRequestDateTrip,
    getTripAddresses,

    onChangeSelectedRow,
  };
};
