import styles from "./styles";
import { HeaderSearch } from "../../components/HeaderSearch";
import { useRef } from "react";
import {
  getFormattedFullName,
  getFormattedNumber,
} from "../../utils/handler_validations";
import { DivTablePage } from "../../components/common/DivTablePage";
import { classVeryIconGoogle } from "../../components/common/v2/VeryIconGoogle/classVeryIconGoogle";
import { VeryBtnFill } from "../../components/common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../../components/common/v2/VeryBtnFill/classVeryBtnFill";
import { useDriversContext, useFormDriverContext } from "../../context";
import { VeryTable } from "../../components/common/v2/VeryTable";
import classVeryTable from "../../components/common/v2/VeryTable/classVeryTable";
import { ModalAsk } from "../../components/common/ModalAsk";
import { PanelProfile } from "../../components/drivers-page/PanelProfile";
import { CellTooltip } from "../../components/common/v2/VeryTable/CellTooltip";
import StylesCellTooltip from "../../components/common/v2/VeryTable/CellTooltip/styles";

const ContentDriversPage = () => {
  const formDriver = useFormDriverContext();
  const drivers = useDriversContext();
  const refDivMain = useRef();

  return (
    <>
      <styles.DivMain
        reff={refDivMain}
        isLoading={drivers.value.page.loading}
        error={drivers.value.page.error}
        onClickBtnRetry={() => drivers.getListInitial()}
      >
        {/* HEADER */}
        <HeaderSearch
          inputSearch={{
            placeHolder: "Buscar conductor",
            text: drivers.textInputSearch,
            onChangeText: (newText) => drivers.onChangeTextInputSearch(newText),
            onClick: () => drivers.getListFiltered(),
          }}
        >
          <VeryBtnFill
            CSS={["btnAddDriver"]}
            colorTheme={classVeryBtnFill.COLOR_THEME.BLACK_S1}
            icon={{
              name: "group_add",
              size: 18,
              type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
            }}
            onClick={() => formDriver.showModalModeAdd()}
          />
        </HeaderSearch>

        {/* CONTENT */}
        <DivTablePage
          isLoading={drivers.value.contentPage.loading}
          error={drivers.value.contentPage.error}
          emptyMessage={drivers.getEmptyMsgPage()}
          onClickBtnRetry={() => drivers.getListFiltered()}
        >
          {/* DRIVERS TABLE */}
          <VeryTable
            CSS={["driversTable"]}
            rows={drivers.value.list}
            getRowId={(row) => row.rowId}
            disabledRowSelection={false}
            rowSelected={drivers.rowSelected}
            onChangeRowSelected={(newRowSelected) => {
              drivers.onChangeRowSelected(newRowSelected);
            }}
            columns={{
              // column profile
              col_name: classVeryTable.getColumnTemplate(
                "Conductor",
                "Nombre del conductor",
                1,
                150,
                130,
                true,
                true,
                (params) => {
                  return getFormattedFullName(
                    params.row.driver?.name,
                    params.row.driver?.lastName
                  );
                },
                (params) => {
                  return classVeryTable.renderColProfile(
                    params.row.driver?.profileImg,
                    params.value
                  );
                }
              ),
              // column tel
              col_tel: classVeryTable.getColumnTemplate(
                "Teléfono",
                "Teléfono del conductor",
                0,
                120,
                100,
                true,
                true,
                (params) => {
                  return params.row?.driver?.tel;
                },
                (params) => {
                  return classVeryTable.renderColTel(params?.value, " ");
                }
              ),
              // column total trips
              col_trips: classVeryTable.getColumnTemplate(
                "Viajes",
                "Total de viajes realizados",
                0,
                100,
                80,
                true,
                true,
                (params) => {
                  return drivers.getCompletedTrips(params.row.driver?.id);
                },
                (params) => {
                  return classVeryTable.renderColNumber(params?.value, 0);
                }
              ),
              // column index cancellation
              col_cancellation: classVeryTable.getColumnTemplate(
                "Indice de cancelación",
                "Viajes cancelados por el conductor",
                0,
                180,
                160,
                true,
                true,
                (params) => {
                  return drivers.getCanceledTrips(params.row.driver?.id)
                    .quantity;
                },
                (params) => {
                  const canceledTrips = drivers.getCanceledTrips(
                    params.row.driver?.id
                  );
                  return (
                    <CellTooltip
                      divMainCSS={["cellIndexCancellation"]}
                      divCellCSS={["cell-content"]}
                      contentCell={
                        <>
                          <StylesCellTooltip.LabelCell className="txt-labelCell">
                            {params.value}
                            <label className="txt-slash">/</label>
                            {`${canceledTrips.total}`}
                          </StylesCellTooltip.LabelCell>
                        </>
                      }
                      contentTooltip={
                        <div className="div-contentTooltip">
                          <div className="div-rowTxt">
                            <label className="txt-title">
                              Total de viajes cancelados:
                            </label>
                            <label className="txt-value">{params.value}</label>
                          </div>
                          <div className="div-rowTxt">
                            <label className="txt-title">
                              Total de viajes atendidos:
                            </label>
                            <label className="txt-value">
                              {canceledTrips.total}
                            </label>
                          </div>
                          <div className="div-rowTxt">
                            <label className="txt-title">
                              Porcentaje de cancelación:
                            </label>
                            <label className="txt-value">
                              {getFormattedNumber(
                                (params.value / canceledTrips.total) * 100,
                                false,
                                1
                              )}
                              %
                            </label>
                          </div>
                        </div>
                      }
                    />
                  );
                }
              ),
              // column total earned
              col_total_earned: classVeryTable.getColumnTemplate(
                "Cobrado",
                "Ganancia total del conductor en los viajes finalizados",
                0,
                100,
                80,
                true,
                true,
                (params) => {
                  return drivers.getTripsEarning(params.row.driver?.id);
                },
                (params) => {
                  return classVeryTable.renderColMoney(params?.value, 0);
                }
              ),
              // column rating
              col_rating: classVeryTable.getColumnTemplate(
                "Calificación",
                "Calificación del conductor",
                0,
                120,
                100,
                true,
                true,
                (params) => {
                  return drivers.getRating(params.row.driver?.id);
                },
                (params) => {
                  return classVeryTable.renderColRating(params?.value);
                }
              ),
              // column enabled
              col_enabled: classVeryTable.getColumnTemplate(
                "Activo",
                "Conductor habilitado / deshabilitado",
                0,
                80,
                60,
                false,
                true,
                (params) => {
                  return drivers.getIsActiveDriver(params.row.driver?.id);
                },
                (params) => {
                  const driverId = params?.row?.driver?.id;
                  return classVeryTable.renderColSwitch(
                    params.value,
                    drivers.getIsLoadingActiveDriver(driverId),
                    () => {
                      const driverName = getFormattedFullName(
                        params.row.driver?.name,
                        params.row.driver?.lastName,
                        "Nombre desconocido"
                      );
                      if (params.value) {
                        drivers.onDisableToDriver(driverId, driverName);
                      } else {
                        drivers.onEnableToDriver(driverId, driverName);
                      }
                    }
                  );
                }
              ),
              // column menu
              col_menu: classVeryTable.getColumnTemplate(
                "",
                "",
                0,
                60,
                40,
                false,
                false,
                undefined,
                (params) => {
                  return classVeryTable.renderColMenu([
                    {
                      label: "Actualizar perfil",
                      loading: false,
                      onClick: () => {
                        const dataDriver = drivers.getDriverToEdit(params.id);
                        formDriver.showModalModeEdit({
                          // personal data
                          id: dataDriver.id,
                          profileImg: dataDriver.profileImg,
                          name: dataDriver.name,
                          lastName: dataDriver.lastName,
                          email: dataDriver.email,
                          tel: dataDriver.tel,
                          // vehicle data
                          vehicleModel: dataDriver.vehicle.model,
                          vehicleYear: dataDriver.vehicle.year,
                          vehicleColor: dataDriver.vehicle.color,
                          vehiclePlates: dataDriver.vehicle.plates,
                          // documents data
                          ine: dataDriver.documents.ine,
                          driverLicence: dataDriver.documents.driverLicence,
                          criminalLetter: dataDriver.documents.criminalLetter,
                          address: dataDriver.documents.address,
                        });
                      },
                    },
                    {
                      label: "Eliminar perfil",
                      loading: false,
                      onClick: () => {
                        drivers.onDeleteToDriver(params.row.driver);
                      },
                    },
                  ]);
                }
              ),
            }}
          />
        </DivTablePage>
        {/* PANEL PROFILE */}
        <PanelProfile refSlide={refDivMain} />
      </styles.DivMain>

      {/* MODAL CONFIRM ACTION */}
      <ModalAsk />
    </>
  );
};

export default ContentDriversPage;
