import {
  isArrayEmpty,
  isNumberPositive,
  isObject,
  isPositiveInt,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import React, { useRef, useEffect, useState } from "react";
import styles from "./styles";
import { classVeryInput } from "./classVeryInput";
import { InputBase } from "@mui/material";

export const VeryInput = (props = classVeryInput.propsTemplate()) => {
  const [lineBreakMultiline, setLineBreakMultiline] = useState();
  const [isDisabledOnKeyUp, setIsDisabledOnKeyUp] = useState(false);
  const ref = useRef();
  const size = {
    ...classVeryInput.propsTemplate().size,
    title: isNumberPositive(props.size?.title) ? Number(props.size.title) : 12,
    text: isNumberPositive(props.size?.text) ? Number(props.size.text) : 14,
    textError: isNumberPositive(props.size?.textError)
      ? Number(props.size.textError)
      : 12,
  };
  const existError = !isStringEmpty(props.textError);
  const existText = !isStringEmpty(props.text);

  useEffect(() => {
    props.onChangeRefInput && props.onChangeRefInput(ref);
  }, [ref]);

  useEffect(() => {
    if (lineBreakMultiline !== undefined) {
      ref.current?.setSelectionRange(lineBreakMultiline, lineBreakMultiline);
      setLineBreakMultiline();
    }
  }, [props.text]);

  // clases del icon
  const CSS = [];
  // custom CSS
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS);
  // add style ERROR
  if (existError) CSS.push("inputError");
  // add style DISABLED
  if (props.isDisabled) CSS.push("inputDisabled");
  // add style READ-ONLY
  if (!props.isEditable) CSS.push("inputNoEditable");

  return (
    <styles.divMain className={CSS.join(" ")} size={size}>
      {/* TITLE */}
      {!isStringEmpty(props.title) && (
        <label className="txtTitle">{`${props.title}${
          props.isRequired ? " *" : ""
        }`}</label>
      )}
      {/* CONTENT */}
      <InputBase
        inputRef={ref}
        type={classVeryInput.getTypeInput(props.type)}
        startAdornment={props.inLeftComponent && props.inLeftComponent}
        endAdornment={props.inRightComponent && props.inRightComponent}
        value={existText ? props.text : ""}
        placeholder={isStringEmpty(props.placeHolder) ? "" : props.placeHolder}
        readOnly={!props.isEditable}
        disabled={props.isDisabled}
        multiline={isPositiveInt(props.multiline)}
        rows={isPositiveInt(props.multiline) ? Number(props.multiline) : 3}
        onBlur={() => props.onLostFocus && props.onLostFocus()}
        onFocus={() => props.onWonFocus && props.onWonFocus()}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            if (event.shiftKey && props.onChangeText && ref.current) {
              setLineBreakMultiline(ref.current.selectionStart + 1);
              setIsDisabledOnKeyUp(true);
              let newText = existText ? props.text : "";
              newText = `${newText.slice(
                0,
                ref.current.selectionStart
              )}\n${newText.slice(ref.current.selectionEnd)}`;
              props.onChangeText(newText);
            }
          }
        }}
        onKeyUp={(event) => {
          if (isDisabledOnKeyUp) {
            setIsDisabledOnKeyUp(false);
            return;
          }
          if (event.key === "Enter" && props.onEnter) props.onEnter();
        }}
        onChange={(event) => {
          props.onChangeText && props.onChangeText(event.target.value);
        }}
      />
      {/* ERROR */}
      {existError && !props.isDisabled && (
        <label className="txtError">{props.textError}</label>
      )}
    </styles.divMain>
  );
};
