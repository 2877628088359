import { Rating } from "@mui/material";
import styled from "styled-components";

const DivRating = styled(Rating)`
  //border: 1px solid red;
  .MuiRating-iconEmpty {
    .customIcon {
      opacity: 0.4;
    }
  }
`;

export default {
  DivRating,
};
