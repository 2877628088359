import styles from "./styles";
import { useState, useEffect } from "react";
import { DivTablePage } from "../../components/common/DivTablePage";
import { useTripsContext } from "../../context";
import classVeryTable from "../../components/common/v2/VeryTable/classVeryTable";
import { VeryTable } from "../../components/common/v2/VeryTable";
import { CellTooltip } from "../../components/common/v2/VeryTable/CellTooltip";
import StylesCellTooltip from "../../components/common/v2/VeryTable/CellTooltip/styles";
import {
  getFormattedNumber,
  getMomentES,
  getNotNegativeNumber,
  isStringEmpty,
} from "../../utils/handler_validations";
import { VeryIconGoogle } from "../../components/common/v2/VeryIconGoogle";
import { classVeryIconGoogle } from "../../components/common/v2/VeryIconGoogle/classVeryIconGoogle";
import { ColRoute } from "../../components/drivers-page/ColRoute";

export const ContentTripsPage = (props) => {
  const trips = useTripsContext();

  return (
    <>
      <styles.DivMain
        isLoading={trips.value.page.loading}
        error={trips.value.page.error}
        onClickBtnRetry={() => trips.getListInitial()}
      >
        <DivTablePage emptyMessage={trips.getEmptyMsgPage()}>
          <VeryTable
            CSS={["tripsTable"]}
            rows={trips.value.list}
            getRowId={(row) => row.rowId}
            disabledRowSelection={false}
            rowSelected={trips.selectedRow}
            onChangeRowSelected={(rowId) => trips.onChangeSelectedRow(rowId)}
            columns={{
              // column profile passenger
              col_passenger: classVeryTable.getColumnTemplate(
                "Pasajero",
                "Nombre del pasajero",
                2,
                150,
                130,
                true,
                true,
                (params) => {
                  return trips.getPassengerName(params.row.trip?.passengerId);
                },
                (params) => {
                  return classVeryTable.renderColProfile(
                    trips.getProfileImgUser(params.row.trip?.passengerId),
                    params.value
                  );
                }
              ),
              // column profile driver
              col_driver: classVeryTable.getColumnTemplate(
                "Conductor",
                "Nombre del conductor",
                2,
                150,
                130,
                true,
                true,
                (params) => {
                  return trips.getDriverName(
                    params.row.trip?.driverId,
                    params.row.trip?.status
                  );
                },
                (params) => {
                  return classVeryTable.renderColProfile(
                    trips.getProfileImgDriver(params.row.trip?.driverId),
                    params.value
                  );
                }
              ),
              // column distance trip
              col_distance: classVeryTable.getColumnTemplate(
                "Distancia",
                "Distance del origen al destino del viaje",
                0,
                100,
                80,
                true,
                true,
                (params) => {
                  return params.row.trip?.distance;
                },
                (params) => {
                  const formattedDistance = getFormattedNumber(
                    params.value,
                    true,
                    1
                  );
                  return (
                    <CellTooltip
                      divCellCSS={["cellDistance"]}
                      contentCell={
                        <StylesCellTooltip.LabelCell>
                          {formattedDistance} km
                        </StylesCellTooltip.LabelCell>
                      }
                      contentTooltip={
                        <StylesCellTooltip.LabelTooltip>
                          {formattedDistance} km
                        </StylesCellTooltip.LabelTooltip>
                      }
                    />
                  );
                }
              ),
              // column cost trip
              col_cost: classVeryTable.getColumnTemplate(
                "Ingreso",
                "Costo del viaje",
                0,
                100,
                80,
                true,
                true,
                (params) => {
                  return params.row.trip?.cost;
                },
                (params) => {
                  const formattedCost = getFormattedNumber(
                    params.value,
                    true,
                    2
                  );
                  const existCoupon = !isStringEmpty(params.row.coupon?.code);
                  const amountCoupon = getNotNegativeNumber(
                    params.row.coupon?.amount
                  );
                  const formattedAmount = getFormattedNumber(
                    amountCoupon > params.value ? params.value : amountCoupon,
                    true,
                    2
                  );
                  return (
                    <CellTooltip
                      divMainCSS={["cellCost"]}
                      divCellCSS={["cell-content"]}
                      contentCell={
                        <>
                          <StylesCellTooltip.LabelCell>
                            ${formattedCost}
                          </StylesCellTooltip.LabelCell>
                          <VeryIconGoogle
                            name="confirmation_number"
                            type={classVeryIconGoogle.ICON_TYPE.FILLED}
                            size={13}
                            color={
                              existCoupon
                                ? classVeryIconGoogle.ICON_COLOR.GREEN_S2
                                : classVeryIconGoogle.ICON_COLOR.GRAY_S2
                            }
                          />
                        </>
                      }
                      contentTooltip={
                        <div className="div-contentTooltip">
                          <label className="txt-light">
                            {"Costo del viaje: "}
                            <label className="txt-bold">${formattedCost}</label>
                          </label>
                          {existCoupon && (
                            <>
                              <label className="txt-light">
                                {"Nombre del cupón: "}
                                <label className="txt-bold">
                                  {params.row.coupon.code}
                                </label>
                              </label>
                              <label className="txt-light">
                                {"Descuento: "}
                                <label className="txt-bold txt-amount">
                                  -${formattedAmount}
                                </label>
                              </label>
                            </>
                          )}
                        </div>
                      }
                    />
                  );
                }
              ),
              // column status trip
              col_status: classVeryTable.getColumnTemplate(
                "Estatus",
                "Estatus del viaje",
                2,
                120,
                100,
                true,
                true,
                (params) => {
                  return trips.getFormattedStatusTrip(params.row.trip?.status);
                },
                (params) => {
                  const cellCSS = ["txtStatus"];
                  const status = trips.getStatusTrip(params.row.trip?.status);
                  if (!isStringEmpty(status)) cellCSS.push(status);
                  return (
                    <CellTooltip
                      divCellCSS={["cellStatus"]}
                      contentCell={
                        <div className={cellCSS.join(" ")}>{params.value}</div>
                      }
                      contentTooltip={
                        <StylesCellTooltip.LabelTooltip>
                          {params.value}
                        </StylesCellTooltip.LabelTooltip>
                      }
                    />
                  );
                }
              ),
              // column request date trip
              col_date: classVeryTable.getColumnTemplate(
                "Fecha y hora",
                "Fecha de solicitud del viaje",
                0,
                220,
                200,
                true,
                true,
                (params) => {
                  const formattedDate = trips.getRequestDateTrip(
                    params.row.trip?.requestDate
                  );
                  return `${formattedDate.date}, ${formattedDate.time}`;
                },
                (params) => {
                  const formattedDate = trips.getRequestDateTrip(
                    params.row.trip?.requestDate
                  );
                  return (
                    <CellTooltip
                      divCellCSS={["cellDate"]}
                      contentCell={
                        <label className="txt-fullDate">
                          <label className="txt-date">
                            {formattedDate.date},
                          </label>
                          {` ${formattedDate.time}`}
                        </label>
                      }
                      contentTooltip={
                        <StylesCellTooltip.LabelTooltip>
                          {`${formattedDate.date}, ${formattedDate.time}`}
                        </StylesCellTooltip.LabelTooltip>
                      }
                    />
                  );
                }
              ),
              // column btn route (origin/dest)
              col_route: classVeryTable.getColumnTemplate(
                "Ruta",
                "Origen y destino del viaje",
                0,
                75,
                60,
                true,
                false,
                undefined,
                (params) => {
                  return (
                    <StylesCellTooltip.DivCell className="cellRoute">
                      <ColRoute
                        rowId={params.row.rowId}
                        selectedRowId={trips.selectedRow}
                      />
                    </StylesCellTooltip.DivCell>
                  );
                }
              ),
            }}
          />
        </DivTablePage>
      </styles.DivMain>
    </>
  );
};
