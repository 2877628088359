import styled from "styled-components";

const DivMain = styled.div`
  display: grid;
  place-items: center;
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 0px;
  //border: 1px solid blue;
  > .customImg {
    display: ${({ showImg }) => (showImg ? "grid" : "none")};
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .divSkeleton {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    transform: none;
    background-color: #13131d4b;
    &::after {
      animation-duration: 1.35s;
      animation-delay: 0s;
      background: linear-gradient(90deg, transparent, #dadadad3, transparent);
    }
  }
`;

export default {
  DivMain,
};
