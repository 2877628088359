import CSS from "./styles";
import { useEffect, useState, useRef } from "react";
import FormLoginPage from "../FormLogin";
import FooterLogin from "../FooterLogin";
import {
  useLoginContext,
  useNewPassContext,
} from "../../../context/login.context";
import HeaderLoginPage from "../HeaderLogin";
import { VeryInputPass } from "../../common/v2/VeryInputPass";
import { Slide } from "@mui/material";
import NavLogin from "../NavLogin";
import stylesLogin from "../../../pages/login/styles";

const propsTemplate = () => ({
  refTransition: undefined,
});

export default function PanelNewPass(props = propsTemplate()) {
  const dataNewPass = useNewPassContext();
  const dataLogin = useLoginContext();
  const [refInputConfirmPass, setRefInputConfirmPass] = useState(useRef());

  return (
    <Slide
      direction="left"
      in={dataLogin.value.showScreenNewPass === true}
      container={props.refTransition?.current}
      timeout={700}
      unmountOnExit
    >
      <stylesLogin.DivScrollPanel>
        {/* NAV */}
        <NavLogin
          btnBack={undefined}
          btnClose={{
            onClick: () => {
              setTimeout(() => dataNewPass.init(), 500);
              dataLogin.setShowScreenNewPass(false);
            },
            isDisabled: dataNewPass.value.loading,
          }}
        />

        <CSS.DivMain>
          {/* HEADER */}
          <HeaderLoginPage
            title="Establecer contraseña"
            subtitle={"Cambia tu contraseña temporal para iniciar sesión"}
          />

          {/* INPUTS */}
          <FormLoginPage title={"Contraseña nueva"}>
            {/* INPUT NEW PASSWORD */}
            <VeryInputPass
              isEditable
              title="Contraseña"
              text={dataNewPass.data.newPass}
              onChangeText={(newValue) =>
                dataNewPass.setInputData({ newPass: newValue })
              }
              textError={dataNewPass.errorData.newPass}
              placeHolder="Ingresa contraseña"
              onLostFocus={() => dataNewPass.setInputError({ newPass: "" })}
              onEnter={() => {
                if (refInputConfirmPass.current) {
                  refInputConfirmPass.current.focus();
                }
              }}
            />
            {/* INPUT CONFIRM PASSWORD */}
            <VeryInputPass
              onChangeRefInput={(ref) => setRefInputConfirmPass(ref)}
              isEditable
              title="Confirmar contraseña"
              text={dataNewPass.data.confirmPass}
              onChangeText={(newValue) =>
                dataNewPass.setInputData({ confirmPass: newValue })
              }
              textError={dataNewPass.errorData.confirmPass}
              placeHolder="Confirma contraseña"
              onLostFocus={() => dataNewPass.setInputError({ confirmPass: "" })}
              onEnter={() => dataNewPass.onClickBtnMain()}
            />
          </FormLoginPage>

          {/* FOOTER */}
          <FooterLogin
            btnMain={{
              text: "Establecer",
              isLoading: dataNewPass.value.loading,
              onClick: () => dataNewPass.onClickBtnMain(),
            }}
          />
        </CSS.DivMain>
      </stylesLogin.DivScrollPanel>
    </Slide>
  );
}
