import styled from "styled-components";
import { DivContentPage } from "../../components/common/DivContentPage";

const DivMain = styled(DivContentPage)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 50px 30px;
  gap: 25px;
  //border: 1px solid red;
  img {
    width: 90px;
    border-radius: 50%;
  }
  label {
    font-family: "Roboto", Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #a0a0a0;
    text-align: center;
    line-height: 1.2;
  }
`;

export default {
  DivMain,
};
