import styles from "./styles";
import {
  isArrayEmpty,
  isStringEmpty,
} from "../../../../../utils/handler_validations";
import { VeryBtnTransparent } from "../../VeryBtnTransparent";
import { classVeryBtnTransparent } from "../../VeryBtnTransparent/classVeryBtnTransparent";
import { classVeryIconGoogle } from "../../VeryIconGoogle/classVeryIconGoogle";
import { Grow } from "@mui/material";
import { Viewer } from "@react-pdf-viewer/core";
import { useState, useEffect } from "react";
import { VeryLoading } from "../../VeryLoading";
import { classVeryLoading } from "../../VeryLoading/classVeryLoading";
import { getFileTemplate, isFilePDF } from "../../../../../utils/handler_files";

const propsTemplate = () => ({
  CSS: [],
  isVisible: false,
  title: "",
  file: getFileTemplate(),
  onClickClose: () => {},
});

export const FullScreen = (props = propsTemplate()) => {
  const [value, setValue] = useState({ loading: true, error: "" });

  const customCSS = ["divFullScreen"];
  if (!isArrayEmpty(props.CSS)) customCSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) customCSS.push(props.className);

  useEffect(() => {
    setValue({ loading: true, error: "" });
  }, [props.file]);

  if (isStringEmpty(props.file?.src)) return null;
  return (
    <styles.DivMain
      className={customCSS}
      open={props.isVisible}
      onClose={() => null}
      disablePortal
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      isLoading={value.loading}
    >
      <Grow in={props.isVisible} timeout={400}>
        <div className="divContent">
          {/* HEADER */}
          <div className="divHeader">
            <label className="txtTitle">
              {isStringEmpty(props.title) ? "" : props.title}
            </label>
            <VeryBtnTransparent
              CSS={["btnClose"]}
              colorTheme={classVeryBtnTransparent.COLOR_THEME.BLACK_S1}
              icon={{
                name: "close",
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
                size: 26,
              }}
              onClick={props.onClickClose}
            />
          </div>
          {/* PREVIEW */}
          <div className="divPreviewFull">
            {!isStringEmpty(value.error) ? (
              <label>{value.error}</label>
            ) : isFilePDF(props.file.ext) ? (
              <>
                <Viewer
                  fileUrl={props.file.src}
                  onDocumentLoad={(event) => {
                    setValue({ loading: false, error: "" });
                  }}
                  renderLoader={() => {
                    // PREVIEW LOADING
                    return (
                      <VeryLoading
                        CSS={["loadingPreviewFull"]}
                        size={80}
                        weight={5}
                        color={classVeryLoading.COLOR.WHITE_S1}
                      />
                    );
                  }}
                  renderError={(errorPDF) => {
                    console.log("errorPDF => ", errorPDF);
                    setValue({
                      loading: false,
                      error: "No fue posible cargar el documento PDF",
                    });
                    return null;
                  }}
                />
              </>
            ) : (
              <>
                {/* IMAGE */}
                <img
                  src={props.file.src}
                  onLoad={() => {
                    setValue({ loading: false, error: "" });
                  }}
                  onError={() => {
                    console.log("<img /> onErrorFull()");
                    setValue({
                      loading: false,
                      error: "No fue posible cargar la imagen",
                    });
                  }}
                />
                {/* LOADING */}
                {value.loading && (
                  <VeryLoading
                    CSS={["loadingPreview"]}
                    size={80}
                    weight={5}
                    color={classVeryLoading.COLOR.WHITE_S1}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Grow>
    </styles.DivMain>
  );
};
