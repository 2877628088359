import { ButtonBase, ClickAwayListener, Grow, Tooltip } from "@mui/material";
import styles from "./styles";
import CustomImg from "../../common/custom-img";
import { classCustomImg } from "../../common/custom-img/utils/classCustomImg";
import { classCustomLoading } from "../../common/custom-loading/utils/classCustomLoading";
import { useSessionContext } from "../../../context";
import { VeryIconGoogle } from "../../common/v2/VeryIconGoogle";
import { classVeryIconGoogle } from "../../common/v2/VeryIconGoogle/classVeryIconGoogle";
import { useState } from "react";
import { forwardRef } from "react";
import ItemMenuProfile from "../ItemMenuProfile";
import { useNavigate } from "react-router-dom";

const propsTemplate = () => ({
  show: false,
  onChangeShow: (isVisible) => {},
});
const MenuProfile = (props = propsTemplate()) => {
  const session = useSessionContext();
  const navigate = useNavigate();

  return (
    <ClickAwayListener onClickAway={() => props.onChangeShow(false)}>
      <styles.DivMain>
        <ButtonBase
          className="btnProfile"
          onClick={(event) => props.onChangeShow(!props.show)}
        >
          <CustomImg
            className="imgProfile"
            borderType={classCustomImg.borderType.circle}
            propsLoading={{
              size: 20,
              color: classCustomLoading.color.orangeS1,
            }}
            src={session.getProfileImgURL(30, 30)}
            componentDefault={
              <span className="material-icons iconDefault">person</span>
            }
          />
          <label className="txtBtnProfile">{session.getShortName()}</label>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            open={props.show}
            placement="right-start"
            disableFocusListener
            disableHoverListener
            disableTouchListener
            TransitionComponent={Grow}
            TransitionProps={{
              timeout: 500,
            }}
            title={
              <>
                <ItemMenuProfile
                  onClick={() => {
                    props.onChangeShow(false);
                    navigate("/perfil", { replace: true });
                  }}
                >
                  Ver perfil
                </ItemMenuProfile>
                <ItemMenuProfile
                  onClick={() => {
                    props.onChangeShow(false);
                    session.setShowModalLogOut(true);
                  }}
                >
                  Cerrar sesion
                </ItemMenuProfile>
              </>
            }
          >
            <div>
              <VeryIconGoogle
                name="expand_more"
                type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED}
                color={classVeryIconGoogle.ICON_COLOR.BLACK_S2}
                size={20}
              />
            </div>
          </Tooltip>
        </ButtonBase>
      </styles.DivMain>
    </ClickAwayListener>
  );
};

export default MenuProfile;
