import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";

const DivMain = styled(DataGrid)`
  &.veryTable {
    width: 100%;
    border: none;
    border-radius: 0px;

    // div con border
    .MuiDataGrid-withBorderColor {
      border-bottom: 1px solid #eaeaea;
    }

    // div (title + icon sort)
    .MuiDataGrid-columnHeaderTitleContainer {
      gap: 7px;
      // title
      .MuiDataGrid-columnHeaderTitle {
        font-family: "Roboto", Helvetica, sans-serif;
        font-weight: 700;
        font-size: 15px;
        color: #13131d;
      }
    }

    // div row
    .MuiDataGrid-row {
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        background-color: #f0f0f0;
      }
      &.Mui-selected {
        background-color: #dedede;
        &:hover {
          background-color: #dedede;
        }
      }

      // div cell
      .MuiDataGrid-cell {
        cursor: default;
        &:focus {
          outline: none;
        }
      }
    }
  }

  .iconDESC {
    transform: rotate(-90deg);
  }
  .iconASC {
    transform: rotate(90deg);
  }

  // CONTAINER CELL
  .divCell {
    // column profile, email, tel, number y money
    &.fullName,
    &.tel,
    &.email,
    &.money,
    &.number,
    &.rating,
    &.date {
      * {
        cursor: inherit;
      }
    }
    // column rating
    &.rating {
      gap: 7px;
      overflow: auto;
    }
    // column date
    &.date {
      gap: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      //border: 1px solid blue;
      .txtNormal,
      .txtBold {
        flex: unset;
        text-overflow: unset;
        overflow: unset;
        white-space: unset;
      }
      .txtBold {
        font-weight: 700;
      }
    }
    // column menu
    &.menu {
      justify-content: flex-end;
      cursor: default;
      //border: 1px solid green;
    }
    // column switch, checkbox
    &.switch,
    &.checkbox {
      cursor: default;
    }
  }
`;

export default {
  DivMain,
};
