import styled from "styled-components";
import { SnackbarContent } from "notistack";

const DivMain = styled(SnackbarContent)`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow: hidden;
  box-shadow: 0px 0px 7px 1px #13131d58;
  * {
    box-sizing: border-box;
  }
  .divNotiHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 8px 13px 10px 13px;
    .txtNotiTitle {
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 700;
      font-size: 15px;
      text-align: left;
      color: #ffffff;
      line-height: 1.2;
      flex: 1;
      margin: 0px 9px 0px 10px;
    }
    .divNotiButtons {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 3px;
      .btn {
        width: fit-content;
        padding: 2px;
        border-radius: 100%;
      }
      .btnExpanded {
        transition: transform 0.5s;
        transform: rotate(180deg);
      }
    }
  }
  .divNotiContent {
    width: 100%;
    padding: 8px 13px 10px 13px;
    display: flex;
    .txtNotiDetails {
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 400;
      font-size: 13px;
      text-align: left;
      color: #13131d;
      line-height: 1.2;
    }
  }

  /*======================================
	VARIANTES DE NOTIFICACIONES
	=========================================*/
  &.notiSuccess {
    .divNotiHeader {
      background-color: #2f9a3f;
    }
    .divNotiContent {
      background-color: #daeddd;
    }
  }

  &.notiError {
    .divNotiHeader {
      background-color: #9a2f2f;
    }
    .divNotiContent {
      background-color: #eddada;
    }
  }
`;

export default {
  DivMain,
};
