import { ButtonBase } from "@mui/material";
import styled from "styled-components";

const divMain = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 100%;
    min-width: min-content;
    height: fit-content;
    display: flex;
    flex-direction: ${({ iconPosition = "" }) => iconPosition};
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 10px 10px;
    box-shadow: unset;
    border-radius: 25px;
    background-color: transparent;
    transition: background-color 0.4s;
    cursor: pointer;
    .txtLabel {
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 700;
      font-size: ${({ textSize = 0 }) => textSize}px;
      line-height: ${({ textSize = 0 }) => textSize + 2}px;
      text-align: center;
      cursor: inherit;
    }

    /*==========================================
		ESTILOS DE COLORES
		============================================*/
    &.BLACK_S1 {
      background-color: #13131d;
      .txtLabel {
        color: #ffffff;
      }
      &:hover {
        background-color: #38383b;
      }
    }
    &.GRAY_S1 {
      background-color: #d9d9d9;
      .txtLabel {
        color: #ffffff;
      }
      &:hover {
        background-color: #b9b7b7;
      }
    }
    &.GRAY_S2 {
      background-color: #f6f8fa;
      .txtLabel {
        color: #a0a0a0;
      }
      &:hover {
        background-color: #e1e3e7;
      }
    }
    &.GREEN_S1 {
      background-color: #16ca5e;
      .txtLabel {
        color: #ffffff;
      }
      &:hover {
        background-color: #13a64e;
      }
    }
    &.WHITE_S1 {
      background-color: #ffffff;
      .txtLabel {
        color: #13131d;
      }
      &:hover {
        background-color: #eceaea;
      }
    }

    // MODO DESHABILLITADO
    &.Mui-disabled {
      background-color: #d9d9d9;
      .txtLabel {
        color: #13131d;
      }
    }

    // MODO CARGANDO
    &.isLoading {
      pointer-events: none;
      cursor: default;
    }
  }
`;

export default {
  divMain,
};
