import styled from "styled-components";

const Container = styled.div`
  width: fit-content;
  //height: 100%;
  border-radius: ${({ borderType }) => {
    return borderType === "rectangle"
      ? "0px"
      : borderType === "oval"
      ? "18px"
      : borderType === "circle"
      ? "50%"
      : "8px"; // default none
  }};

  > * {
    border-radius: inherit;
  }
`;

const ContainerBtnSelect = styled.div`
  width: fit-content;
  height: 40px;
  background-color: #f8f8fa;
  .buttonSelect {
    width: 100%;
    height: 100%;
    gap: 2px;
    .icon {
      font-weight: bolder;
    }
  }

  + .MuiTooltip-popper {
    background-color: transparent;

    .dropdownSelect {
      --background: antiquewhite;
      box-shadow: 0px 0px 10px 1px gray;
      background-color: #ffffff;
      padding: 0px 0px;
      border-radius: 7px;
      display: flex;
      flex-direction: column;
      gap: 0px;
      max-width: ${({ maxWidthDropdown }) =>
        maxWidthDropdown ? maxWidthDropdown : "400px"};
      max-height: ${({ maxHeightDropdown }) =>
        maxHeightDropdown ? maxHeightDropdown : "80vh"};

      .selectItem {
        width: 100%;
        justify-content: flex-start;
        background-color: transparent;
        .label {
          font-size: 14px;
        }
        &:hover {
          background-color: #dadada;
        }
      }
      .itemDefault {
        width: 100%;
        justify-content: flex-start;
        background-color: transparent;
        color: black;
        font-size: 14px;
      }
      .container-items {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        flex-wrap: ${({ alignmentItemsOverflow }) =>
          alignmentItemsOverflow === "columns" ? "wrap" : "nowrap"};
        overflow: auto;
        .selectItem {
          width: ${({ maxWidthItems }) =>
            maxWidthItems ? maxWidthItems : "100%"};
        }
      }
    }

    .MuiTooltip-arrow {
      color: #a0a0a0;
    }

    &[data-popper-placement*="bottom"] .dropdownSelect {
      margin-top: 10px;
    }
    &[data-popper-placement*="top"] .dropdownSelect {
      margin-bottom: 10px;
    }
    &[data-popper-placement*="left"] .dropdownSelect {
      margin-right: 10px;
    }
    &[data-popper-placement*="right"] .dropdownSelect {
      margin-left: 10px;
    }
  }
`;

export default {
  Container,
  ContainerBtnSelect,
};
