import styled from "styled-components";

const DivMain = styled.div`
  border-radius: 25px;
  //border: 1px solid red;
  .btnProfile.MuiButtonBase-root {
    width: 235px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 8px 12px 9px 10px;
    box-shadow: unset;
    border-radius: 25px;
    background-color: #f8f8fa;
    color: #bdbdc1;
    transition: background-color 0.4s;
    cursor: pointer;
    &:hover {
      background-color: #e8e8eb;
    }
    .imgProfile {
      width: fit-content;
    }
    .txtBtnProfile {
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 1.2;
      text-align: left;
      color: #13131d;
      cursor: inherit;
      flex: 1;
    }
  }

  .MuiTooltip-tooltipPlacementBottom {
    margin-top: 0px !important;
  }
  .MuiTooltip-tooltipPlacementRight {
    margin-right: 0px !important;
  }
  .MuiTooltip-tooltip {
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 7px 0px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  }
`;

export default {
  DivMain,
};
