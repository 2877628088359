import styled from "styled-components";
import stylesLogin from "../../../pages/login/styles";

const DivMain = styled(stylesLogin.DivPanel)`
  //border: 1px solid red;
`;

const DivFooter = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .divBtnRecoveryPass {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
    //border: 1px solid red;
    .txtBtnRecoveryPass {
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.2;
      color: #a0a0a0;
    }
    .btn {
      width: fit-content;
      padding: 3px 2px;
      label {
        white-space: nowrap;
      }
    }
  }
`;

export default {
  DivMain,
  DivFooter,
};
