import {
  isArrayEmpty,
  isNumberPositive,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import styles from "./styles";
import classVerySwitch from "./classVerySwitch";
import { Switch } from "@mui/material";
import { VeryLoading } from "../VeryLoading";
import { classVeryLoading } from "../VeryLoading/classVeryLoading";

export const VerySwitch = (props = classVerySwitch.getPropsTemplate()) => {
  const CSS = ["verySwitch"];
  // add custom style
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) CSS.push(props.className);

  return (
    <styles.DivMain className={CSS.join(" ")}>
      <Switch
        className="btnSwitch"
        disabled={props.isDisabled === true}
        checked={props.selected === true}
        onChange={(event, selected) => {
          if (props.isLoading === true) return;
          props.onChangeSelected && props.onChangeSelected(selected);
        }}
      />
      {props.isLoading === true && (
        <VeryLoading
          CSS={["loadingSwitch"]}
          color={classVeryLoading.COLOR.BLACK_S1}
          size={15}
          weight={3}
        />
      )}
    </styles.DivMain>
  );
};
