import { useEffect, useState } from "react";
import {
  getFormattedEmail,
  getFormattedFullName,
  getFormattedNumber,
  getNotNegativeNumber,
  getObject,
  getString,
  isArrayEmpty,
  isObject,
  isPositiveInt,
  isStringEmpty,
} from "../utils/handler_validations";
import { driverDB } from "../services";
import { useSessionContext } from "../context/session.context";
import { useSocketContext } from "../context/socket";
import { useVeryNotificationsContext } from "../components/common/v2/VeryNotifications/useVeryNotifications";
import { classVeryIconGoogle } from "../components/common/v2/VeryIconGoogle/classVeryIconGoogle";
import { getFormattedTel } from "../utils/handler_tel";
import { useModalAskContext } from "../context/modalAsk.context";
import {
  getFileExtension,
  getFileTemplate,
  getFileURL,
  getImageURL,
  isFilePDF,
} from "../utils/handler_files";
import useWebSocket from "react-use-websocket";

const getLoadErrorTemplate = (initialLoading = false) => ({
  loading: initialLoading === true,
  error: { message: "", details: "" },
});

const getRowDriverTemplate = () => ({
  rowId: -1,
  driver: {
    id: 0,
    inTrip: false,
    name: "",
    lastName: "",
    tel: "",
    email: "",
    profileImg: "",
  },
  documents: { ine: "", driverLicence: "", criminalLetter: "", address: "" },
  vehicle: { model: "", year: 0, color: "", plates: "" },
});

export const useDrivers = () => {
  const [textInputSearch, setTextInputSearch] = useState("");
  const [rowSelected, setRowSelected] = useState(-1);
  const [value, setValue] = useState({
    page: getLoadErrorTemplate(true),
    contentPage: getLoadErrorTemplate(false),
    list: [getRowDriverTemplate()], // lista con la data de los drivers
    statistics: {}, // estadisticas de cada conductor
  });
  // handle enable/disable driver
  const [activeList, setActiveList] = useState({}); // { [driverID]: { value: boolean , loading: boolean } , ... }
  const [enableToDriver, setEnableToDriver] = useState({ id: -1, name: "" });
  const [disableToDriver, setDisableToDriver] = useState({
    id: -1,
    name: "",
  });
  // handle delete driver
  const [deleteToDriver, setDeleteToDriver] = useState({ id: -1, name: "" });
  // hooks
  const session = useSessionContext();
  const notis = useVeryNotificationsContext();
  const modalAsk = useModalAskContext();

  useEffect(() => {
    getListInitial();
  }, []);

  // accion confirmada
  useEffect(() => {
    if (modalAsk.value.execute) {
      switch (modalAsk.value.action) {
        case "ENABLE":
          execEnableToDriver();
          break;
        case "DISABLE":
          execDisableToDriver();
          break;
        case "DELETE":
          execDeleteToDriver();
          break;
      }
    }
  }, [modalAsk.value.execute]);

  /*==========================================================
    HANDLE SOCKET
  ============================================================*/
  const socketData = useSocketContext();
  const socket = useWebSocket(socketData.url, { share: true });

  useEffect(() => {
    const respSocket = socketData.getResponse(socket.lastMessage);
    switch (respSocket.type) {
      case "ADMIN_ENABLE_DRIVER":
        //socketData.showResponse(respSocket);
        onRespEnableToDriver(respSocket.data);
        return;
      case "ADMIN_DISABLE_DRIVER":
        //socketData.showResponse(respSocket);
        onRespDisableToDriver(respSocket.data);
        return;
      case "ADMIN_DELETE_DRIVER":
        //socketData.showResponse(respSocket);
        onRespDeleteToDriver(respSocket.data);
        return;
    }
  }, [socket.lastMessage]);

  /*===============================================================
	  HANDLE ACTION DELETE TO DRIVER
	=================================================================*/
  // guardar driverID clicked
  const onDeleteToDriver = (driverData = {}) => {
    setDeleteToDriver({
      id: driverData?.id,
      name: getFormattedFullName(
        driverData?.name,
        driverData?.lastName,
        "Nombre desconocido"
      ),
    });
  };

  // abrir modal de confirmacion
  useEffect(() => {
    if (isPositiveInt(deleteToDriver.id)) {
      modalAsk.show(
        "DELETE",
        "Eliminar conductor",
        `Se eliminará de forma permanente al conductor "${deleteToDriver.name}".\n\nFavor de confirmar.`,
        true
      );
    }
  }, [deleteToDriver]);

  // send change to backend
  const execDeleteToDriver = async () => {
    const submitted = socketData.send("wsAdmin-update-driver-account", true, {
      action: "DELETE_DRIVER",
      driverId: deleteToDriver.id,
    });
    if (!submitted) modalAsk.clearExecute();
  };

  // receive resp backend
  const onRespDeleteToDriver = (data = {}) => {
    if (data.error) {
      notis.createNotiError(data.error.message, data.error.details);
      modalAsk.clearExecute();
      return;
    }
    // success
    notis.createNotiSuccess(`El conductor "${data.driverName}" fue eliminado`);
    modalAsk.close();
    const newList = value.list.filter((row) => {
      return row.driver.id === data.driverId ? false : true;
    });
    setValue((prev) => ({ ...prev, list: newList }));
  };

  /*===============================================================
	  HANDLE ACTION ENABLE TO DRIVER
	=================================================================*/
  // abrir modal de confirmacion
  useEffect(() => {
    if (isPositiveInt(enableToDriver.id)) {
      modalAsk.show(
        "ENABLE",
        "Habilitar conductor",
        `¿Quieres volver activar al conductor "${enableToDriver.name}" en la aplicación?\n\nFavor de confirmar.`,
        false
      );
    }
  }, [enableToDriver]);

  // guardar driverID del switch clicked
  const onEnableToDriver = (driverId = 0, driverFullName = "") => {
    setEnableToDriver({ id: driverId, name: driverFullName });
  };

  // send change to backend
  const execEnableToDriver = async () => {
    const submitted = socketData.send("wsAdmin-update-driver-account", true, {
      action: "ENABLE_DRIVER",
      driverId: enableToDriver.id,
    });
    if (submitted) {
      // loading switch
      const newActiveList = { ...activeList };
      newActiveList[enableToDriver.id].loading = true;
      setActiveList(newActiveList);
    }
  };

  // receive resp backend
  const onRespEnableToDriver = (data = {}) => {
    const newActiveList = { ...activeList };
    // ERROR backend
    if (data.error) {
      notis.createNotiError(data.error.message, data.error.details);
      // delete loading switch
      if (!isObject(newActiveList[data.driverId])) return;
      newActiveList[data.driverId].loading = false;
      setActiveList(newActiveList);
      return;
    }
    // success
    notis.createNotiSuccess(`El conductor "${data.driverName}" fue habilitado`);
    // change value switch
    if (!isObject(newActiveList[data.driverId])) return;
    newActiveList[data.driverId] = { value: true, loading: false };
    setActiveList(newActiveList);
  };

  /*===============================================================
	  HANDLE ACTION DISABLE TO DRIVER
	=================================================================*/
  // abrir modal de confirmacion
  useEffect(() => {
    if (isPositiveInt(disableToDriver.id)) {
      modalAsk.show(
        "DISABLE",
        "Deshabilitar conductor",
        `¿Quieres desactivar al conductor "${disableToDriver.name}" de la aplicación?\n\nFavor de confirmar.`,
        false
      );
    }
  }, [disableToDriver]);

  // guardar driverID del switch clicked
  const onDisableToDriver = (driverId = 0, driverFullName = "") => {
    setDisableToDriver({ id: driverId, name: driverFullName });
  };

  // send change to backend
  const execDisableToDriver = async () => {
    const submitted = socketData.send("wsAdmin-update-driver-account", true, {
      action: "DISABLE_DRIVER",
      driverId: disableToDriver.id,
    });
    if (submitted) {
      // loading switch
      const newActiveList = { ...activeList };
      newActiveList[disableToDriver.id].loading = true;
      setActiveList(newActiveList);
    }
  };

  // receive resp backend
  const onRespDisableToDriver = (data = {}) => {
    const newActiveList = { ...activeList };
    // ERROR backend
    if (data.error) {
      notis.createNotiError(data.error.message, data.error.details);
      // delete loading switch
      if (!isObject(newActiveList[data.driverId])) return;
      newActiveList[data.driverId].loading = false;
      setActiveList(newActiveList);
      return;
    }
    // success
    notis.createNotiSuccess(
      `El conductor "${data.driverName}" fue deshabilitado`
    );
    // change value switch
    if (!isObject(newActiveList[data.driverId])) return;
    newActiveList[data.driverId] = { value: false, loading: false };
    setActiveList(newActiveList);
  };

  /*===============================================================
    HANDLE GETS
	=================================================================*/
  const getCompletedTrips = (driverId = 0) => {
    return getNotNegativeNumber(value.statistics[driverId]?.completedTrips);
  };

  const getCanceledTrips = (driverId = 0) => {
    const driverStatistics = getObject(value.statistics[driverId]);
    return {
      quantity: getNotNegativeNumber(driverStatistics.canceledTrips?.driver),
      total:
        getNotNegativeNumber(driverStatistics.canceledTrips?.driver) +
        getNotNegativeNumber(driverStatistics.completedTrips),
    };
  };

  const getTripsEarning = (driverId = 0) => {
    return getNotNegativeNumber(value.statistics[driverId]?.tripsEarning);
  };

  const getCouponsEarning = (driverId = 0) => {
    return getNotNegativeNumber(value.statistics[driverId]?.couponsEarning);
  };

  const getRating = (driverId = 0) => {
    return getNotNegativeNumber(value.statistics[driverId]?.rating);
  };

  const getEmptyMsgPage = () => {
    const emptyObj = { text: "", icon: { name: "", type: "" } };
    if (value.list === undefined) {
      emptyObj.text = "No se encontraron conductores";
      emptyObj.icon.name = "content_paste";
      emptyObj.icon.type = classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED;
    } else if (isArrayEmpty(value.list)) {
      emptyObj.text = "Ningún conductor coincide con la búsqueda";
      emptyObj.icon.name = "content_paste_search";
      emptyObj.icon.type = classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED;
    }
    return emptyObj;
  };

  const getDocumentURL = (keyS3 = "") => {
    const ext = getFileExtension(keyS3);
    return {
      src: isFilePDF(ext)
        ? getFileURL(process.env.REACT_APP_BUCKET, keyS3)
        : getImageURL(process.env.REACT_APP_BUCKET, keyS3, 1000, 1000),
      ext: ext,
    };
  };

  // obtener la data requerida para mostrar en el panel lateral
  const getDriverToEdit = (rowId = -1) => {
    const data = {
      id: 0,
      name: "",
      lastName: "",
      tel: "",
      email: "",
      profileImg: getFileTemplate(),
      documents: {
        ine: getFileTemplate(),
        driverLicence: getFileTemplate(),
        criminalLetter: getFileTemplate(),
        address: getFileTemplate(),
      },
      vehicle: { model: "", year: 0, plates: "", color: "" },
    };
    if (isArrayEmpty(value.list) || rowId < 0) return data;
    const dataRow = value.list[rowId];
    if (!isPositiveInt(dataRow.driver?.id)) return data;
    // data personal
    data.id = getNotNegativeNumber(dataRow.driver.id);
    data.name = getString(dataRow.driver.name);
    data.lastName = getString(dataRow.driver.lastName);
    data.profileImg = getDocumentURL(dataRow.driver.profileImg);
    data.email = getFormattedEmail(dataRow.driver.email);
    data.tel = getFormattedTel(dataRow.driver.tel, "-");
    // documents
    data.documents.ine = getDocumentURL(dataRow.documents.ine);
    data.documents.driverLicence = getDocumentURL(
      dataRow.documents.driverLicence
    );
    data.documents.criminalLetter = getDocumentURL(
      dataRow.documents.criminalLetter
    );
    data.documents.address = getDocumentURL(dataRow.documents.address);
    // data vehicle
    data.vehicle.model = getString(dataRow.vehicle.model);
    data.vehicle.plates = getString(dataRow.vehicle.plates);
    data.vehicle.year = getNotNegativeNumber(dataRow.vehicle.year);
    if (
      ["AMARILLO", "ROJO", "BLANCO", "VERDE"].includes(dataRow.vehicle.color)
    ) {
      data.vehicle.color = dataRow.vehicle.color;
    }

    return data;
  };

  // obtener la data requerida para mostrar en el panel lateral
  const getDriverSelected = () => {
    const data = {
      id: 0,
      inTrip: false,
      fullName: "",
      tel: "",
      email: "",
      profileImg: "",
      documents: {
        ine: "",
        driverLicence: "",
        criminalLetter: "",
        address: "",
      },
      vehicle: { model: "", plates: "", color: "", colorCSS: "" },
      completedTrips: 0,
      tripsEarning: 0,
      couponsEarning: 0,
      rating: "",
    };
    if (isArrayEmpty(value.list) || rowSelected < 0) return data;
    const dataRow = value.list[rowSelected];
    if (!isPositiveInt(dataRow.driver?.id)) return data;
    data.id = dataRow.driver.id;
    data.inTrip = dataRow.driver.inTrip;
    // profileImg
    data.profileImg = dataRow.driver.profileImg;
    // fullName
    data.fullName = getFormattedFullName(
      dataRow.driver.name,
      dataRow.driver.lastName
    );
    // email
    data.email = getString(dataRow.driver.email);
    // rating
    data.rating = getRating(data.id).toFixed(1);
    // tel
    data.tel = getString(dataRow.driver.tel);
    // documents
    data.documents.ine = dataRow.documents.ine;
    data.documents.driverLicence = dataRow.documents.driverLicence;
    data.documents.criminalLetter = dataRow.documents.criminalLetter;
    data.documents.address = dataRow.documents.address;
    // vehicle model
    const vehicleModel = [];
    if (isStringEmpty(dataRow.vehicle.model))
      vehicleModel.push("Modelo no especificado");
    else vehicleModel.push(dataRow.vehicle.model);
    if (isPositiveInt(dataRow.vehicle.year))
      vehicleModel.push(dataRow.vehicle.year);
    data.vehicle.model = vehicleModel.join(" ");
    // vehicle plates
    if (isStringEmpty(dataRow.vehicle.plates))
      data.vehicle.plates = "Sin placas";
    else data.vehicle.plates = dataRow.vehicle.plates;
    // vehicle color
    const formattedColor =
      dataRow.vehicle.color.charAt(0)?.toUpperCase() +
      dataRow.vehicle.color.slice(1).toLowerCase();
    switch (dataRow.vehicle.color) {
      case "AMARILLO":
      case "ROJO":
      case "BLANCO":
      case "VERDE":
        data.vehicle.color = formattedColor;
        data.vehicle.colorCSS = "fill" + formattedColor;
        break;
      default:
        data.vehicle.color = "Sin Color";
        data.vehicle.colorCSS = "";
        break;
    }
    // completed trips
    data.completedTrips = getFormattedNumber(
      getCompletedTrips(data.id),
      true,
      0
    );
    // trips earning
    data.tripsEarning = getFormattedNumber(getTripsEarning(data.id), true, 2);
    // coupons earning
    data.couponsEarning = getFormattedNumber(
      getCouponsEarning(data.id),
      true,
      2
    );

    return data;
  };

  /**
   * Obtener la lista de conductores formateada para ser manipulada por la tabla
   */
  const getFormattedList = (driverList = [], initial = false) => {
    let list = isArrayEmpty(driverList)
      ? initial
        ? undefined
        : []
      : driverList;
    const activeList = {};
    const newList = list?.map((row, index) => {
      const data = getRowDriverTemplate();
      data.rowId = index;
      data.driver = {
        id: getNotNegativeNumber(row.id),
        inTrip: row.inTrip === true,
        name: getString(row.name),
        lastName: getString(row.lastName),
        tel: getString(row.tel),
        email: getString(row.email),
        profileImg: getString(row.profileImg),
      };
      data.documents = {
        ine: getString(row.documents?.ine),
        driverLicence: getString(row.documents?.driverLicence),
        criminalLetter: getString(row.documents?.criminalLetter),
        address: getString(row.documents?.address),
      };
      data.vehicle = {
        model: getString(row.vehicle?.model),
        year: getNotNegativeNumber(row.vehicle?.year),
        color: getString(row.vehicle?.color),
        plates: getString(row.vehicle?.plates),
      };
      // finally
      activeList[data.driver.id] = {
        value: row.enabled === true,
        loading: false,
      };
      return data;
    });
    return { driverList: newList, activeList };
  };

  const getListFiltered = async () => {
    setValue((prev) => ({
      ...prev,
      contentPage: getLoadErrorTemplate(true),
    }));
    setRowSelected(-1);
    const resp = await driverDB.getListDrivers(
      textInputSearch,
      session.value.jwt
    );
    if (resp.error) {
      setValue((prev) => ({
        ...prev,
        contentPage: {
          loading: false,
          error: { message: resp.error.message, details: resp.error.details },
        },
      }));
      return;
    }
    // success
    const lists = getFormattedList(resp.body.drivers, false);
    setActiveList(lists.activeList);
    setValue((prev) => ({
      ...prev,
      contentPage: getLoadErrorTemplate(false),
      list: lists.driverList,
      statistics: getObject(resp.body.statistics),
    }));
  };

  const getListInitial = async () => {
    setValue((prev) => ({
      ...prev,
      page: getLoadErrorTemplate(true),
    }));
    const resp = await driverDB.getListDrivers("", session.value.jwt);
    if (resp.error) {
      setValue((prev) => ({
        ...prev,
        page: {
          loading: false,
          error: { message: resp.error.message, details: resp.error.details },
        },
      }));
      return;
    }
    // success
    const lists = getFormattedList(resp.body.drivers, true);
    setActiveList(lists.activeList);
    setValue((prev) => ({
      ...prev,
      page: getLoadErrorTemplate(false),
      list: lists.driverList,
      statistics: getObject(resp.body.statistics),
    }));
  };

  const onChangeRowSelected = (newRowSelected = -1) => {
    setRowSelected(newRowSelected);
  };

  const onChangeTextInputSearch = (newText = "") => {
    setTextInputSearch(newText);
  };

  const getIsActiveDriver = (driverId = 0) => {
    const activeDriver = getObject(activeList[driverId]);
    if (activeDriver.value === undefined) return false;
    return activeDriver.loading === true
      ? !activeDriver.value
      : activeDriver.value;
  };

  const getIsLoadingActiveDriver = (driverId = 0) => {
    const activeDriver = getObject(activeList[driverId]);
    return activeDriver.loading === true;
  };

  return {
    value,
    activeList,
    textInputSearch,
    rowSelected,
    getCompletedTrips,
    getCanceledTrips,
    getTripsEarning,
    getRating,
    getListFiltered,
    getListInitial,
    getEmptyMsgPage,
    getDriverSelected,
    getDriverToEdit,
    getIsActiveDriver,
    getIsLoadingActiveDriver,

    onChangeTextInputSearch,
    onChangeRowSelected,

    onEnableToDriver,
    onDisableToDriver,
    onDeleteToDriver,
  };
};
