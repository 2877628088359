import { createRef } from "react";

export class classVeryInputTel {
  // props recibidas
  static propsTemplate = () => ({
    onChangeRefInput: (ref = createRef()) => {},
    CSS: [],
    text: "",
    textError: "",
    size: { title: 14, text: 16, textError: 12 },
    isDisabled: false,
    isEditable: false,
    isRequired: false,
    onLostFocus: () => {},
    onWonFocus: () => {},
    onEnter: () => {},
    onChangeText: (newText = "") => {},
  });
}
