import { ButtonBase } from "@mui/material";
import styled from "styled-components";

const DivMain = styled(ButtonBase)`
  &.MuiButtonBase-root {
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 14px;
    box-shadow: unset;
    border-radius: 0px;
    background-color: transparent;
    color: #bdbdc1;
    transition: background-color 0.4s;
    cursor: pointer;
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    color: #13131d;
    cursor: inherit;
    &:hover {
      background-color: #e8e8eb;
    }
    .MuiTouchRipple-root {
      color: #919195;
    }
  }
`;

export default {
  DivMain,
};
