import styled from "styled-components";

const DivMain = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  //width: 300px;
  min-width: 325px;
  border-radius: 5px;
  right: 30px;
  top: 20px;
  bottom: 20px;
  gap: 5px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  padding: 5px 20px 10px 20px;
  overflow: auto;

  .div-header {
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //border: solid blue 1px;
    .btnClose {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      padding: 0px;
    }
    .txtTitle {
      font-size: 13px;
      font-weight: 700;
      font-family: "Roboto", Helvetica, sans-serif;
    }
  }

  .div-userDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px 18px 15px 18px;
    border-radius: 10px;
    background: #f6f8fa;
    gap: 3px;
    //border: solid red 1px;
    .txt-name {
      font-size: 13px;
      font-weight: 700;
      font-family: "Roboto", Helvetica, sans-serif;
      margin-top: 4px;
    }
    .txt-email {
      font-size: 14px;
      font-weight: 300;
      font-family: "Roboto", Helvetica, sans-serif;
    }
    .div-rating {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 5px 0px 12px 0px;
      gap: 8px;
      //border: solid red 1px;
      .txt-rating {
        font-size: 13px;
        font-weight: 500;
        font-family: "Roboto", Helvetica, sans-serif;
      }
    }
    .div-btns {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      //border: solid gray 1px;
      // TOOLTIP
      > .MuiTooltip-popper {
        &.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip {
          margin-bottom: 5px !important;
        }
        &.MuiTooltip-popper[data-popper-placement*="bottom"]
          .MuiTooltip-tooltip {
          margin-top: 5px !important;
        }
        .MuiTooltip-tooltip {
          background-color: #f4f4f4;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          border: 1px solid #cacaca;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
          cursor: default;
          font-family: "Roboto", Helvetica, sans-serif;
          font-weight: 700;
          font-size: 11px;
          line-height: 1.2;
          text-align: center;
          color: #13131d;
        }
      }
      .btnCopy {
        width: 30px;
        height: 30px;
        padding: 0px;
        border-radius: 50%;
      }
    }
  }

  .div-completedTrips {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    gap: 12px;
    //border: solid red 1px;
    .txt-title {
      font-size: 12px;
      font-weight: 700;
      font-family: "Roboto", Helvetica, sans-serif;
    }
    .div-data {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      //border: 1px solid red;
      .div-color {
        width: 15px;
        height: 15px;
        border-radius: 4px;
        margin-right: 10px;
        background-color: #46bec3;
      }
      .txt-number {
        font-size: 22px;
        font-weight: 700;
        font-family: "Roboto", Helvetica, sans-serif;
        margin-right: 17px;
      }
      .txt-endTravels {
        flex: 1;
        font-size: 12px;
        font-weight: 400;
        font-family: "Roboto", Helvetica, sans-serif;
        //margin-right: 60px;
      }
      .btn-completeTrips {
        width: 25px;
        height: 25px;
        padding: 0px;
        border-radius: 100%;
      }
    }
  }

  .div-payments {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    gap: 10px;
    //border: solid red 1px;
    .txt-title {
      font-size: 12px;
      font-weight: 700;
      font-family: "Roboto", Helvetica, sans-serif;
    }
    .div-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      //border: solid red 1px;
      .txt-item {
        width: 75%;
        font-size: 13px;
        font-weight: 400;
        font-family: "Roboto", Helvetica, sans-serif;
        text-align: left;
        line-height: 1.2;
        //border: solid red 1px;
      }
      .txt-money {
        flex-grow: 1;
        font-size: 13px;
        font-weight: 700;
        font-family: "Roboto", Helvetica, sans-serif;
        text-align: right;
        line-height: 1.2;
        //border: solid red 1px;
      }
    }
  }

  .div-coupons {
    width: 100%;
    min-height: 150px;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    gap: 0px;
    //border: 1px solid red;
    > .txt-title {
      font-size: 12px;
      font-weight: 700;
      font-family: "Roboto", Helvetica, sans-serif;
    }
    .div-table {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      //border: solid gray 1px;
      .div-rowCoupon {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        border-bottom: 1px solid #eaeaea;
        // TOOLTIP EXP DATE
        > .MuiTooltip-popper {
          &.MuiTooltip-popper[data-popper-placement*="top"]
            .MuiTooltip-tooltip {
            margin-bottom: 0px !important;
          }
          &.MuiTooltip-popper[data-popper-placement*="bottom"]
            .MuiTooltip-tooltip {
            margin-top: 0px !important;
          }
          .MuiTooltip-tooltip {
            background-color: #f4f4f4;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 3px;
            padding: 8px 12px;
            border: 1px solid #cacaca;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
            cursor: default;
            .txt-title {
              font-family: "Roboto", Helvetica, sans-serif;
              font-weight: 700;
              font-size: 12px;
              color: #13131d;
              line-height: 1.2;
              text-align: left;
            }
            .txt-date {
              font-family: "Roboto", Helvetica, sans-serif;
              font-weight: 400;
              font-size: 12px;
              color: #13131d;
              line-height: 1.2;
              text-align: left;
            }
          }
        }
        .div-colCode,
        .div-colAmount,
        .div-colBtn {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 0px;
          font-family: "Roboto", Helvetica, sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 1.2;
          color: #13131d;
          cursor: default;
        }
        .div-colCode {
          width: 74px;
          font-weight: 700;
          //border: 1px solid red;
        }
        .div-colAmount {
          flex: 1;
          //border: 1px solid green;
        }
        .div-colBtn {
          justify-content: flex-end;
          width: 110px;
          //border: 1px solid blue;
          .txt-canceled {
            font-family: inherit;
            font-weight: 700;
            font-size: inherit;
            line-height: inherit;
            cursor: inherit;
            color: #f21f26;
          }
          .btnDisable {
            width: fit-content;
            opacity: 0.75;
            .txtLabel {
              text-decoration: none;
            }
          }
        }

        // ROW DISABLED
        &.couponCanceled {
          .div-colCode,
          .div-colAmount {
            color: rgba(19, 19, 29, 0.3);
            text-decoration: line-through;
          }
        }
      }
    }
  }
`;

export default {
  DivMain,
};
