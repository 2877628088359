import styled from "styled-components";

const DivMain = styled.div`
  //border: 1px solid red;
  .iconRoute {
    rotate: 45deg;
  }
  .MuiTooltip-tooltip {
    max-width: 475px;
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
    padding: 12px 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    //border: 2px solid green;
    .divAddress {
      flex-basis: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      //border: 1px solid green;
      .circleAddress {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        border: 1.5px solid #f8f8fa;
      }
      .circleOrigin {
        background-color: #f7af36;
      }
      .circleDest {
        background-color: #13131d;
      }
      .divInfo {
        //height: 100%;
        flex-shrink: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 3px;
        //border: 1px solid red;
        .skeAddressS1 {
          width: 100%;
          min-width: 150px;
          font-size: 14px;
        }
        .txtAddressS1 {
          font-family: "Roboto";
          font-weight: 400;
          font-size: 14px;
          line-height: 1.2;
          color: #13131d;
        }
        .skeAddressS2 {
          width: 100%;
          font-size: 12px;
        }
        .txtAddressS2 {
          font-family: "Roboto";
          font-weight: 300;
          font-size: 12px;
          line-height: 1.2;
          color: #a0a0a0;
        }
      }
    }
  }
`;

export default {
  DivMain,
};
