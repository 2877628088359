import styles from "./styles";
import { VeryInput } from "../common/v2/VeryInput";
import { VeryInputTel } from "../common/v2/VeryInputTel";
import { useState, useEffect } from "react";
import { classVeryInput } from "../common/v2/VeryInput/classVeryInput";
import {
  isArrayEmpty,
  isPositiveInt,
  isStringEmpty,
} from "../../utils/handler_validations";
import { VeryPickerImgPdf } from "../common/v2/VeryPickerImgPdf";
import { useFormAdminContext } from "../../context/formAdmin.context";
import { useVeryNotificationsContext } from "../common/v2/VeryNotifications/useVeryNotifications";

const propsTemplate = () => ({
  CSS: [],
});

const ModalAdmin = (props = propsTemplate()) => {
  const formAdmin = useFormAdminContext();
  const customCSS = ["modalAdmin"];

  if (!isArrayEmpty(props.CSS)) customCSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) customCSS.push(props.className);

  return (
    <styles.DivMain
      CSS={customCSS}
      isVisible={formAdmin.value.show}
      title={
        formAdmin.value.modeEdit ? "Editar perfil" : "Registrar administrador"
      }
      btnMain={{
        label: formAdmin.value.modeEdit
          ? "Editar perfil"
          : "Registrar administrador",
        isLoading: formAdmin.value.loading,
        onClick: () => formAdmin.onClickBtnMain(),
      }}
      stepSelected={0}
      onClickBtnClose={() => formAdmin.setShowModal(false)}
      steps={Object.values(formAdmin.steps)}
    >
      <styles.PanelPersonal>
        {/* PICKER IMG PROFILE */}
        <VeryPickerImgPdf
          title={"Fotografía del administrador"}
          disabledImgs={false}
          disabledPdfs={true}
          error={formAdmin.errorData.profileImg}
          originalFile={formAdmin.originalData.profileImg}
          onChangeFile={(selectedFile) => {
            formAdmin.updatePersonalData({
              profileImg: selectedFile,
            });
          }}
          onError={(msgError) => {
            formAdmin.updateErrorData({ profileImg: msgError });
          }}
        />
        {/* INPUT NAME + LASTNAME */}
        <div className="divName">
          <VeryInput
            CSS={["divInputName"]}
            isEditable
            isRequired
            placeHolder="Ingresa el nombre"
            text={formAdmin.data.name}
            textError={formAdmin.errorData.name}
            type={classVeryInput.TYPE_INPUT.NORMAL}
            title="Nombre"
            onChangeText={(newText) =>
              formAdmin.updatePersonalData({ name: newText })
            }
          />
          <VeryInput
            CSS={["divInputLastName"]}
            isEditable
            isRequired
            placeHolder="Ingresa los apellidos"
            text={formAdmin.data.lastName}
            textError={formAdmin.errorData.lastName}
            type={classVeryInput.TYPE_INPUT.NORMAL}
            title="Apellidos"
            onChangeText={(newText) =>
              formAdmin.updatePersonalData({ lastName: newText })
            }
          />
        </div>
        {/* INPUT EMAIL */}
        <VeryInput
          CSS={["divInputEmail"]}
          isRequired
          isEditable
          isDisabled={formAdmin.value.modeEdit}
          placeHolder="Ingresa el correo"
          text={formAdmin.data.email}
          textError={formAdmin.errorData.email}
          type={classVeryInput.TYPE_INPUT.NORMAL}
          title="Correo electrónico"
          onChangeText={(newText) => {
            formAdmin.updatePersonalData({ email: newText });
          }}
        />
        {/* INPUT TEL */}
        <VeryInputTel
          CSS={["divInputTel"]}
          isEditable
          text={formAdmin.data.tel}
          textError={formAdmin.errorData.tel}
          onChangeText={(newText) => {
            formAdmin.updatePersonalData({ tel: newText });
          }}
        />
      </styles.PanelPersonal>
    </styles.DivMain>
  );
};

export default ModalAdmin;
