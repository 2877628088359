import { useEffect, useState } from "react";
import {
  getFormattedNumber,
  getMomentES,
  getNotNegativeNumber,
  getObject,
  getString,
  isArrayEmpty,
  isObject,
  isPositiveInt,
  isStringEmpty,
  isValidNaturalNumber,
} from "../../utils/handler_validations";
import { couponDB } from "../../services";
import { useSessionContext } from "../../context/session.context";
import { useVeryNotificationsContext } from "../../components/common/v2/VeryNotifications/useVeryNotifications";
import { classVeryIconGoogle } from "../../components/common/v2/VeryIconGoogle/classVeryIconGoogle";
import templates from "./templates";
import {
  getDate_S1,
  getDate_S2,
  getDate_S3,
  momentES,
  testDate,
} from "../../utils/handler_dates";

export const useCoupons = () => {
  // modal disable
  const [modalDisabled, setModalDisabled] = useState({
    show: false,
    loading: false,
    code: "",
    amount: "",
    userName: "",
  });
  // modal pay
  const [inputCommentPay, setInputCommentPay] = useState("");
  const [modalPay, setModalPay] = useState({
    show: false,
    loading: false,
    code: "",
    quantity: "",
    driverName: "",
  });
  // modal create
  const [modalCreate, setModalCreate] = useState({
    show: false,
    loading: false,
    expirationDate: null, // Moment
    amount: "",
  });
  const [newCode, setNewCode] = useState("");
  const [creatingNewCode, setCreatingNewCode] = useState(false);
  const [checkNewCode, setCheckNewCode] = useState({
    isValid: false,
    inputError: "",
    loading: false,
  });
  const [errorModalCreate, setErrorModalCreate] = useState({
    expirationDate: "",
    amount: "",
  });
  // filters
  const [inputSearch, setInputSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState({});
  // data
  const [rowSelected, setRowSelected] = useState(-1);
  const [value, setValue] = useState({
    page: templates.getLoadError(true),
    contentPage: templates.getLoadError(false),
    list: [templates.getRowCoupon()],
    trips: {},
    logs: {},
    admins: {},
    users: {},
  });
  // hooks
  const session = useSessionContext();
  const notis = useVeryNotificationsContext();

  useEffect(() => {
    getListInitial();
  }, []);

  useEffect(() => {
    if (!value.page.loading && isStringEmpty(value.page.error.message)) {
      getListFiltered();
    }
  }, [filterStatus]);

  const getStatusCoupon_S1 = (status = "", enabled = true) => {
    switch (status) {
      case "CREATED":
        return enabled ? "REGISTRADO" : "CANCELADO";
      case "IN_WALLET":
        return enabled ? "RECLAMADO" : "CANCELADO";
      case "NEW":
        return enabled ? "AGREGADO" : "CANCELADO";
      case "REDEEMED":
        return "EN USO";
      case "IMPLEMENTED":
        return "IMPLEMENTADO";
      case "PAID":
        return "PAGADO";
      default:
        return "---";
    }
  };

  const getStatusCoupon_S2 = (status = "", enabled = true) => {
    switch (status) {
      case "NEW":
      case "CREATED":
      case "IN_WALLET":
        return enabled ? status : "DISABLED";
      case "REDEEMED":
      case "IMPLEMENTED":
      case "PAID":
        return status;
      default:
        return "";
    }
  };

  const getLogsHistory = (code = "") => {
    let history = [templates.getLogCoupon()];
    history = value.logs[code];
    return isArrayEmpty(history) ? [] : history;
  };

  const getTripCost = (tripId = 0) => {
    return getNotNegativeNumber(value.trips[tripId]?.cost);
  };

  const getLogUserName = (code = "", status = "") => {
    const logs = getLogsHistory(code);
    for (const log of logs) {
      if (log.status === status) {
        switch (status) {
          case "CREATED":
          case "PAID":
          case "DISABLED":
            return getString(value.admins[log.userId]);
          default:
            return getString(value.users[log.userId]);
        }
      }
    }
    return undefined;
  };

  const getLogDate = (code = "", status = "") => {
    const logs = getLogsHistory(code);
    for (const log of logs) {
      if (log.status === status) {
        return testDate(log.date).error ? undefined : getMomentES(log.date);
      }
    }
    return undefined;
  };

  const getCustomItemLog = (log = templates.getLogCoupon()) => {
    const itemLog = {
      userType: "",
      userName: "(desconocido)",
      statusType: "",
      date: "--/---/----",
    };
    // date
    const date = getMomentES(log.date);
    if (date && date.isValid()) itemLog.date = getDate_S3(date);
    // evaluar status
    let userName = "";
    switch (log.status) {
      case "CREATED":
        userName = value.admins[log.userId];
        itemLog.userType = "Administrador(a)";
        itemLog.statusType = "lo agregó";
        break;
      case "IN_WALLET":
        userName = value.users[log.userId];
        itemLog.userType = "Usuario(a)";
        itemLog.statusType = "lo reclamó";
        break;
      case "REDEEMED":
        userName = value.users[log.userId];
        itemLog.userType = "Usuario(a)";
        itemLog.statusType = "lo utilizó";
        break;
      case "IMPLEMENTED":
        userName = value.users[log.userId];
        itemLog.userType = "Conductor(a)";
        itemLog.statusType = "lo implementó";
        break;
      case "PAID":
        userName = value.admins[log.userId];
        itemLog.userType = "Administrador(a)";
        itemLog.statusType = "lo pagó";
        break;
      case "DISABLED":
        userName = value.admins[log.userId];
        itemLog.userType = "Administrador(a)";
        itemLog.statusType = "lo canceló";
        break;
      default:
        return undefined;
    }
    if (!isStringEmpty(userName)) itemLog.userName = userName;
    return itemLog;
  };

  // obtener la data requerida para mostrar en el panel lateral
  const getCouponSelected = () => {
    const data = {
      code: "",
      amount: "",
      creationDate: { short: "--/--/----", full: "--/----/----" },
      customDate: {
        short: "--/--/----",
        full: "--/----/----",
        title: "",
        titleTooltip: "",
        isExpiratedDate: false,
      },
      logsHistory: [templates.getLogCoupon()],
    };
    if (isArrayEmpty(value.list) || rowSelected < 0) return data;
    const dataRow = value.list[rowSelected];
    if (isStringEmpty(dataRow.code)) return data;
    data.code = dataRow.code;
    data.amount = getFormattedNumber(dataRow.amount, true, 2);
    // creation date
    const creationDate = getLogDate(dataRow.code, "CREATED");
    if (creationDate) {
      data.creationDate.short = getDate_S2(creationDate);
      data.creationDate.full = getDate_S1(creationDate, "/");
    }
    // custom date
    let customDate = undefined;
    const todayDate = momentES();
    switch (getStatusCoupon_S2(dataRow.status, dataRow.enabled)) {
      case "DISABLED":
        customDate = getLogDate(dataRow.code, "DISABLED");
        data.customDate.title = "Fecha de cancelación";
        data.customDate.titleTooltip = "Fecha de cancelación";
        break;
      case "IMPLEMENTED":
        customDate = getLogDate(dataRow.code, "IMPLEMENTED");
        data.customDate.title = "Fecha de implementación";
        data.customDate.titleTooltip = "Fecha de implementación";
        break;
      case "PAID":
        customDate = getLogDate(dataRow.code, "PAID");
        data.customDate.title = "Fecha de pagado";
        data.customDate.titleTooltip = "Fecha de pagado";
        break;
      default:
        customDate = getMomentES(dataRow.expirationDate);
        if (
          !testDate(dataRow.expirationDate).error &&
          customDate.isBefore(todayDate)
        ) {
          data.customDate.isExpiratedDate = true;
        }
        data.customDate.title = "Fecha de expiración";
        data.customDate.titleTooltip = "Fecha de expiración";
        break;
    }
    if (customDate) {
      data.customDate.short = getDate_S2(customDate);
      data.customDate.full = getDate_S1(customDate, "/");
    }
    // logs history
    data.logsHistory = getLogsHistory(dataRow.code);
    return data;
  };

  const getEmptyMsgPage = () => {
    const emptyObj = { text: "", icon: { name: "", type: "" } };
    if (value.list === undefined) {
      emptyObj.text = "No se encontraron cupones";
      emptyObj.icon.name = "content_paste";
      emptyObj.icon.type = classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED;
    } else if (isArrayEmpty(value.list)) {
      emptyObj.text = "Ningún cupón coincide con la búsqueda";
      emptyObj.icon.name = "content_paste_search";
      emptyObj.icon.type = classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED;
    }
    return emptyObj;
  };

  /*===========================================
    MODAL DISABLED
  =============================================*/
  const hideModalDisabled = () => {
    setModalDisabled((prev) => ({ ...prev, show: false, loading: false }));
  };

  const showModalDisabled = (code = "", amount = 0, status = "") => {
    const customStatus = getStatusCoupon_S2(status, true);
    const userName = getLogUserName(code, customStatus);
    setModalDisabled({
      show: true,
      loading: false,
      code,
      amount: getFormattedNumber(amount, true, 2),
      userName:
        customStatus === "IN_WALLET"
          ? isStringEmpty(userName)
            ? "No identificado"
            : userName
          : "",
    });
  };

  const onClickBtnDisabled = async () => {
    setModalDisabled((prev) => ({
      ...prev,
      loading: true,
    }));
    const resp = await couponDB.disabledCoupon(
      modalDisabled.code,
      session.value.jwt
    );
    // error
    if (resp.error) {
      notis.createNotiError(resp.error.message, resp.error.details);
      setModalDisabled((prev) => ({
        ...prev,
        loading: false,
      }));
      return;
    }
    // success
    updateCoupon(resp.body, modalDisabled.code, { isEnabled: false });
    notis.createNotiSuccess(
      `El cupón "${modalDisabled.code}" fue cancelado`,
      "El cupón no podrá ser utilizado por ningún usuario"
    );
    setModalDisabled((prev) => ({
      ...prev,
      show: false,
      loading: false,
    }));
  };

  /*===========================================
    MODAL PAY
  =============================================*/
  const hideModalPay = () => {
    setModalPay((prev) => ({ ...prev, show: false, loading: false }));
    setTimeout(() => setInputCommentPay(""), 400);
  };

  const showModalPay = (code = "", amount = 0, tripId = 0) => {
    const driverName = getLogUserName(code, "IMPLEMENTED");
    const tripCost = getTripCost(tripId);
    const quantityPay = tripCost > amount ? amount : tripCost;
    setModalPay({
      show: true,
      loading: false,
      code,
      quantity: getFormattedNumber(quantityPay, true, 2),
      driverName: isStringEmpty(driverName) ? "No identificado" : driverName,
    });
  };

  const onClickBtnPay = async () => {
    setModalPay((prev) => ({
      ...prev,
      loading: true,
    }));
    const resp = await couponDB.payCoupon(
      modalPay.code,
      inputCommentPay,
      session.value.jwt
    );
    // error
    if (resp.error) {
      notis.createNotiError(resp.error.message, resp.error.details);
      setModalPay((prev) => ({
        ...prev,
        loading: false,
      }));
      return;
    }
    // success
    updateCoupon(resp.body, modalPay.code, { status: "PAID" });
    notis.createNotiSuccess(
      `El cupón "${modalPay.code}" fue pagado al taxista "${modalPay.driverName}"`,
      "El taxista podrá verificar la nota del pago desde la aplicación móvil"
    );
    setModalPay((prev) => ({
      ...prev,
      show: false,
      loading: false,
    }));
    setTimeout(() => setInputCommentPay(""), 400);
  };

  const onChangeInputCommentPay = (newText = "") => {
    setInputCommentPay(newText);
  };

  /*===========================================
    MODAL CREATE
  =============================================*/
  const hideModalCreate = () => {
    setModalCreate((prev) => ({
      ...prev,
      show: false,
      loading: false,
    }));
  };

  const showModalCreate = () => {
    setModalCreate({
      show: true,
      loading: false,
      amount: "",
      expirationDate: null,
    });
    setNewCode("");
    setErrorModalCreate({ expirationDate: "", amount: "" });
    setCheckNewCode({ isValid: false, inputError: "", loading: false });
  };

  const onClickGenerateNewCode = async () => {
    setCreatingNewCode(true);
    // ir al backend a generar el codigo
    const resp = await couponDB.generateCode(session.value.jwt);
    setCreatingNewCode(false);
    // cuando hay error
    if (resp.error) {
      notis.createNotiError(
        "No fue posible generar dinamicamente un nuevo código de cupón",
        resp.error.details
      );
      return;
    }
    // success
    onChangeNewCodeCoupon(resp.body.generatedCode, false);
  };

  // cuando se da click en crear cupon
  const onClickBtnCreateCoupon = async () => {
    const errors = { code: "", expDate: "", amount: "" };
    // validar code
    errors.code = testNewCode(newCode, true);
    // cuando no hay fecha de expiracion seleccionada
    if ([undefined, null].includes(modalCreate.expirationDate)) {
      errors.expDate = "Debes establecer una fecha de expiración para el cupón";
    }
    // cuando la fecha es invalida
    else if (!modalCreate.expirationDate.isValid()) {
      errors.expDate =
        "La fecha es incorrecta, seleccione una fecha dando click en el icono calendario";
    }
    // cuando no existe descuento
    if (isStringEmpty(modalCreate.amount)) {
      errors.amount = "Debes establecer el descuento promocional del cupón ";
    }
    // cuando no es numero
    else if (!isValidNaturalNumber(modalCreate.amount)) {
      errors.amount =
        "El descuento del cupón no es valido. Solo se permiten dígitos";
    }
    // cuando no es numero positivo
    else if (!isPositiveInt(modalCreate.amount)) {
      errors.amount = "La cantidad del descuento del cupón no puede ser 0";
    }
    // cuando hay error
    if (
      !isStringEmpty(errors.code) ||
      !isStringEmpty(errors.expDate) ||
      !isStringEmpty(errors.amount)
    ) {
      setCheckNewCode({
        isValid: isStringEmpty(errors.code),
        inputError: errors.code,
        loading: false,
      });
      setErrorModalCreate({
        expirationDate: errors.expDate,
        amount: errors.amount,
      });
      return;
    }
    // ir al backend a crear el nuevo cupon
    setModalCreate((prev) => ({ ...prev, loading: true }));
    const resp = await couponDB.createCoupon(
      newCode,
      modalCreate.expirationDate,
      modalCreate.amount,
      session.value.jwt
    );
    if (resp.error) {
      notis.createNotiError(resp.error.message, resp.error.details);
      setModalCreate((prev) => ({ ...prev, loading: false }));
      setCheckNewCode({ isValid: false, inputError: "", loading: false });
      return;
    }
    // success
    notis.createNotiSuccess(
      "El cupón fue creado correctamente",
      "Ahora esta disponible para ser usado por algún usuario"
    );
    // add table new coupon
    const newList = value.list.slice();
    const newRow = templates.getRowCoupon();
    newRow.code = newCode;
    newRow.status = "NEW";
    newRow.amount = getNotNegativeNumber(modalCreate.amount);
    newRow.enabled = true;
    newRow.expirationDate = modalCreate.expirationDate.clone().utc();
    newList.unshift(newRow);
    newList.forEach((row, index) => (row.rowId = index));
    if (rowSelected >= 0) setRowSelected(rowSelected + 1);
    setValue((prev) => ({
      ...prev,
      list: newList,
      logs: { ...prev.logs, ...resp.body.logs },
      admins: { ...prev.admins, ...resp.body.admins },
    }));
    hideModalCreate();
  };

  // cada que cambie el codigo
  const onChangeNewCodeCoupon = async (code = "", applyCheck = true) => {
    setNewCode(code);
    // no check code
    if (!applyCheck) {
      setCheckNewCode({ isValid: true, inputError: "", loading: false });
      return;
    }
    // code empty
    if (isStringEmpty(code)) {
      setCheckNewCode({
        isValid: false,
        inputError: "",
        loading: false,
      });
      return;
    }
    const respTestCode = testNewCode(code, false);
    // cuando hay error
    if (!isStringEmpty(respTestCode)) {
      setCheckNewCode({
        isValid: false,
        inputError: respTestCode,
        loading: false,
      });
      return;
    }
    // verificar en backend
    setCheckNewCode({ isValid: false, inputError: "", loading: true });
    const resp = await couponDB.checkCode(code, session.value.jwt);
    // cuando hay error
    if (resp.error) {
      notis.createNotiError(resp.error.message, resp.error.details);
      setCheckNewCode({ isValid: false, inputError: "", loading: false });
      return;
    }
    // success check
    setCheckNewCode({ isValid: true, inputError: "", loading: false });
  };

  // cada que cambie la fecha de expiracion
  const onChangeExpDateNewCoupon = async (newDate = momentES(null)) => {
    setModalCreate((prev) => ({
      ...prev,
      expirationDate: newDate,
    }));
    setErrorModalCreate((prev) => ({
      ...prev,
      expirationDate: "",
    }));
  };

  // cada que cambie el monto
  const onChangeAmountNewCoupon = async (newAmount = "") => {
    setModalCreate((prev) => ({
      ...prev,
      amount: newAmount,
    }));
    setErrorModalCreate((prev) => ({
      ...prev,
      amount: "",
    }));
  };

  const testNewCode = (code = "", isRequired = true) => {
    // cuando es requerido y no existe
    if (isRequired && isStringEmpty(code)) {
      return "Debes establecer el código del cupón";
    }
    // no requerido
    if (!isRequired && isStringEmpty(code)) return "";
    // cuando no tiene 8 caracteres
    if (code.length !== 8) {
      return "El código debe estar formado por 8 caracteres";
    }
    // cuando no tiene formato valido
    if (!/^(\w|\d|[!¡@#$%&?¿ñÑ]){8}$/.test(code)) {
      return "El código no es valido. Solo se permiten letras, dígitos y los caracteres:   ! ¡ @ # $ % & ? ¿";
    }
    // success
    return "";
  };

  /*===========================================
    HANDLE COUPONS TABLE
  =============================================*/
  // Obtener la lista de cupones formateada para ser manipulada por la tabla
  const getFormattedList = (couponsList = [], initial = false) => {
    const list = isArrayEmpty(couponsList)
      ? initial
        ? undefined
        : []
      : couponsList;
    return list?.map((coupon, index) => {
      const dataRow = templates.getRowCoupon();
      dataRow.rowId = index;
      dataRow.code = getString(coupon.code);
      dataRow.status = getString(coupon.status);
      dataRow.amount = getNotNegativeNumber(coupon.amount);
      dataRow.enabled = coupon.enabled === true;
      dataRow.expirationDate = coupon.expirationDate;
      dataRow.tripId = getNotNegativeNumber(coupon.tripId);
      return dataRow;
    });
  };

  const getListFiltered = async () => {
    setValue((prev) => ({
      ...prev,
      contentPage: templates.getLoadError(true),
    }));
    setRowSelected(-1);
    const resp = await couponDB.getListCoupons(
      inputSearch,
      filterStatus,
      session.value.jwt
    );
    if (resp.error) {
      setValue((prev) => ({
        ...prev,
        contentPage: {
          loading: false,
          error: { message: resp.error.message, details: resp.error.details },
        },
      }));
      return;
    }
    // success
    setValue((prev) => ({
      ...prev,
      contentPage: templates.getLoadError(false),
      list: getFormattedList(resp.body.coupons, true),
      trips: getObject(resp.body.trips),
      logs: getObject(resp.body.logs),
      admins: getObject(resp.body.admins),
      users: getObject(resp.body.users),
    }));
  };

  const getListInitial = async () => {
    setValue((prev) => ({
      ...prev,
      page: templates.getLoadError(true),
    }));
    const resp = await couponDB.getListCoupons("", {}, session.value.jwt);
    if (resp.error) {
      setValue((prev) => ({
        ...prev,
        page: {
          loading: false,
          error: { message: resp.error.message, details: resp.error.details },
        },
      }));
      return;
    }
    // success
    setValue((prev) => ({
      ...prev,
      page: templates.getLoadError(false),
      list: getFormattedList(resp.body.coupons, true),
      trips: getObject(resp.body.trips),
      logs: getObject(resp.body.logs),
      admins: getObject(resp.body.admins),
      users: getObject(resp.body.users),
    }));
  };

  // Actualizar la data de un cupon cuando se cancela o se paga
  const updateCoupon = (
    respBody = {},
    code = "",
    newData = { status: "", isEnabled: true }
  ) => {
    const newList = value.list.slice();
    newList.forEach((rowCoupon) => {
      if (rowCoupon.code === code) {
        if (!isStringEmpty(newData.status)) rowCoupon.status = newData.status;
        if ([false, true].includes(newData.isEnabled)) {
          rowCoupon.enabled = newData.isEnabled;
        }
      }
    });
    setValue((prev) => ({
      ...prev,
      list: newList,
      logs: { ...prev.logs, ...respBody.logs },
      admins: { ...prev.admins, ...respBody.admins },
    }));
  };

  const onChangeInputSearch = (newText = "") => {
    setInputSearch(newText);
  };

  const onChangeFilterStatus = (newFilterStatus = {}) => {
    setFilterStatus(newFilterStatus);
  };

  const onChangeRowSelected = (newRowSelected = -1) => {
    setRowSelected(newRowSelected);
  };

  const isCouponPaid = (status = "", enabled = true) => {
    return getStatusCoupon_S2(status, enabled) === "PAID";
  };

  const isCouponUsed = (status = "", enabled = true) => {
    return ["IMPLEMENTED", "PAID"].includes(
      getStatusCoupon_S2(status, enabled)
    );
  };

  const isCouponDisableable = (status = "", enabled = true) => {
    return ["NEW", "CREATED", "IN_WALLET"].includes(
      getStatusCoupon_S2(status, enabled)
    );
  };

  return {
    value,
    modalDisabled,
    modalPay,
    inputCommentPay,
    rowSelected,
    inputSearch,
    getListFiltered,
    getListInitial,
    getCouponSelected,
    getCustomItemLog,
    getStatusCoupon_S1,
    getStatusCoupon_S2,
    getEmptyMsgPage,

    hideModalDisabled,
    hideModalPay,
    showModalDisabled,
    showModalPay,

    onChangeInputSearch,
    onChangeInputCommentPay,
    onChangeFilterStatus,
    onChangeRowSelected,
    onClickBtnDisabled,
    onClickBtnPay,

    isCouponPaid,
    isCouponUsed,
    isCouponDisableable,

    modalCreate,
    newCode,
    creatingNewCode,
    checkNewCode,
    errorModalCreate,
    hideModalCreate,
    showModalCreate,
    onClickGenerateNewCode,
    onClickBtnCreateCoupon,
    onChangeNewCodeCoupon,
    onChangeExpDateNewCoupon,
    onChangeAmountNewCoupon,
  };
};
