import { momentES } from "../../utils/handler_dates";

// pagina cargando o con error
const getLoadError = (initialLoading = false) => ({
  loading: initialLoading === true,
  error: { message: "", details: "" },
});

// data de un row cupon
const getRowCoupon = () => ({
  rowId: -1,
  code: "",
  status: "",
  amount: 0,
  enabled: false,
  expirationDate: null, // timestamp
  tripId: 0,
});

// data de un coupon log
const getLogCoupon = () => ({
  id: 0,
  userId: 0,
  date: "",
  status: "",
});

const getNewCoupon = () => ({
  code: "",
  validCode: false,
  loadingCheckCode: false,
  expirationDate: momentES(),
  amount: "",
});
const getErrorsNewCoupon = () => ({
  code: "",
  expirationDate: "",
  amount: "",
});
const getModalMessage = () => ({
  show: false,
  title: "prueba titulo",
  message: "prueba mensaje",
});
const getFilters = () => ({
  inputSearch: "",
  status: {},
});

const tableRowsTemplate = [{ code: "", amount: "", status: "" }];

export default {
  tableRowsTemplate,
  getLoadError,
  getRowCoupon,
  getLogCoupon,

  getNewCoupon,
  getErrorsNewCoupon,
  getModalMessage,
  getFilters,
};
