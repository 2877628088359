import React from "react";
import PropTypes from "prop-types";
import {
  isArrayEmpty,
  isNumberPositive,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import styles from "./styles";
import { classVeryLoading } from "./classVeryLoading";

/**
 * Loading animado indefinido
 */
export const VeryLoading = (props = classVeryLoading.propsTemplate()) => {
  const CSS = [];
  // add custom style
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS);
  // add color
  CSS.push(classVeryLoading.getColor(props.color));

  return (
    <styles.divMain
      className={CSS.join(" ")}
      size={isNumberPositive(props.size) ? Number(props.size) : 18}
      thickness={isNumberPositive(props.weight) ? Number(props.weight) : 6}
    />
  );
};
