import { ClickAwayListener, Grow, Tooltip } from "@mui/material";
import styles from "./styles";
import { classVeryIconGoogle } from "../../common/v2/VeryIconGoogle/classVeryIconGoogle";
import { useState, useEffect } from "react";
import { VeryIconGoogle } from "../../common/v2/VeryIconGoogle";
import { isStringEmpty } from "../../../utils/handler_validations";

const getColorItemTemplate = () => ({ label: "", value: "" });

const propsTemplate = () => ({
  textError: "",
  selectedValue: "",
  onChangeValue: (newValue = "") => {},
});

const SelectColor = (props = propsTemplate()) => {
  const [showMenu, setShowMenu] = useState(false);
  const [options] = useState([
    { label: "Amarillo", value: "AMARILLO" },
    { label: "Verde", value: "VERDE" },
    { label: "Rojo", value: "ROJO" },
    { label: "Blanco", value: "BLANCO" },
  ]);

  const getColor = (value = "") => {
    const item = { label: "", divColorCSS: "divColor" };
    switch (value) {
      case "AMARILLO":
        item.divColorCSS += " YELLOW";
        item.label = "Amarillo";
        break;
      case "VERDE":
        item.divColorCSS += " GREEN";
        item.label = "Verde";
        break;
      case "ROJO":
        item.divColorCSS += " RED";
        item.label = "Rojo";
        break;
      case "BLANCO":
        item.divColorCSS += " WHITE";
        item.label = "Blanco";
        break;
    }
    return item;
  };

  const renderOption = (colorItem = getColorItemTemplate()) => {
    const classesCSS = ["btnOption"];
    if (props.selectedValue === colorItem.value)
      classesCSS.push("colorSelected");
    const color = getColor(colorItem.value);

    return (
      <styles.BtnColor
        className={classesCSS.join(" ")}
        onClick={() => {
          setShowMenu(false);
          props.onChangeValue && props.onChangeValue(colorItem.value);
        }}
      >
        <div className={color.divColorCSS} />
        <label>{color.label}</label>
      </styles.BtnColor>
    );
  };

  const selectedColor = getColor(props.selectedValue);

  return (
    <ClickAwayListener onClickAway={() => setShowMenu(false)}>
      <styles.DivMain>
        <Tooltip
          PopperProps={{ disablePortal: true }}
          open={showMenu}
          placement="bottom-start"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          TransitionComponent={Grow}
          TransitionProps={{ timeout: 500 }}
          title={options.map((color) => renderOption(color))}
        >
          <div className="divSelect">
            <label className="txt-title">Color</label>
            <styles.BtnColor
              className={"btnSelect"}
              onClick={() => setShowMenu(!showMenu)}
            >
              <div className={selectedColor.divColorCSS} />
              <label>
                {isStringEmpty(selectedColor.label)
                  ? "Elige un color"
                  : selectedColor.label}
              </label>
              <VeryIconGoogle
                name="expand_more"
                type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED}
                size={21}
              />
            </styles.BtnColor>
            {!isStringEmpty(props.textError) && (
              <label className="txt-error">{props.textError}</label>
            )}
          </div>
        </Tooltip>
      </styles.DivMain>
    </ClickAwayListener>
  );
};

export default SelectColor;
