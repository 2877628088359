import { ButtonBase } from "@mui/material";
import styled from "styled-components";

const divMain = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 100%;
    min-width: min-content;
    height: fit-content;
    display: flex;
    flex-direction: ${({ iconPosition = "" }) => iconPosition};
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 3px 7px;
    box-shadow: unset;
    border-radius: 3px;
    background-color: transparent;
    transition: all 0.4s;
    cursor: pointer;
    .txtLabel {
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 700;
      font-size: ${({ textSize = 0 }) => textSize}px;
      line-height: ${({ textSize = 0 }) => textSize + 2}px;
      text-align: center;
      text-decoration: underline;
      cursor: inherit;
    }
    > * {
      transition: inherit;
    }

    /*==========================================
		ESTILOS DE COLORES
		============================================*/
    &.BLACK_S1 {
      .txtLabel {
        color: #13131d;
      }
      &:hover {
        .txtLabel {
          color: #717178;
        }
        .btnIcon {
          color: #717178;
        }
      }
    }
    &.BLACK_S2 {
      .txtLabel {
        color: #1c1b1f;
      }
      &:hover {
        .txtLabel {
          color: #717178;
        }
        .btnIcon {
          color: #717178;
        }
      }
    }
    &.GRAY_S1 {
      .txtLabel {
        color: #a0a0a0;
      }
      &:hover {
        .txtLabel {
          color: #65656c;
        }
        .btnIcon {
          color: #65656c;
        }
      }
    }
    &.WHITE_S1 {
      .txtLabel {
        color: #ffffff;
      }
      &:hover {
        .txtLabel {
          color: #c9c9c9;
        }
        .btnIcon {
          color: #c9c9c9;
        }
      }
    }

    // MODO DESHABILLITADO
    &.Mui-disabled {
      .txtLabel {
        color: #a0a0a0;
      }
    }

    // MODO CARGANDO
    &.isLoading {
      pointer-events: none;
      cursor: default;
    }
  }
`;

export default {
  divMain,
};
