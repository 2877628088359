import {
  fetchDELETE,
  fetchGET,
  fetchPOST,
  fetchPUT,
} from "../utils/handler_http";
import { encoJSON, encoURI, encoUriJson } from "../utils/handler_validations";
import { momentES } from "../utils/handler_dates";

/**
 * Obtener la data completa del dashboard
 */
export const getData = async (jwt = "") => {
  const today = momentES();
  const initialDateMonth = today.clone().startOf("month").utc();
  const monthDates = {
    initial: initialDateMonth.format(),
    end: initialDateMonth.clone().add(1, "months").format(),
  };
  const initialDateWeek = today.clone().startOf("week").utc();
  const weekDates = {
    initial: initialDateWeek.format(),
    end: initialDateWeek.clone().add(1, "weeks").format(),
  };
  const initialDateToday = today.clone().startOf("day").utc();
  const todayDates = {
    initial: initialDateToday.format(),
    end: initialDateToday.clone().add(1, "days").format(),
  };
  today.utc();
  const resp = await fetchGET(
    process.env.REACT_APP_ADMIN_DASHBOARD_ENDPOINT,
    {
      monthDates: encoUriJson(monthDates),
      weekDates: encoUriJson(weekDates),
      todayDates: encoUriJson(todayDates),
      requestDate: encoURI(today.format()),
    },
    { Authorization: jwt }
  );
  return resp;
};
