import { useRef } from "react";

export class classVeryIconGoogle {
  // props recibidas
  static propsTemplate = () => ({
    refIcon: useRef(),
    CSS: [],
    size: 0,
    color: "",
    name: "",
    type: "",
    enabledSpin: false,
  });
  // tipos de iconos disponibles
  static ICON_TYPE = {
    OUTLINED: "material-icons-outlined",
    FILLED: "material-icons",
    ROUNDED: "material-icons-round",
    SHARP: "material-icons-sharp",
    SYMBOLS_OUTLINED: "material-symbols-outlined",
    SYMBOLS_ROUNDED: "material-symbols-rounded",
    SYMBOLS_SHARP: "material-symbols-sharp",
    //twoTone: "material-icons-two-tone",
  };
  static getIconType(type = "") {
    return Object.values(this.ICON_TYPE).includes(type)
      ? type
      : this.ICON_TYPE.OUTLINED;
  }
  // colores para el icono
  static ICON_COLOR = {
    WHITE_S1: "WHITE_S1",
    BLACK_S1: "BLACK_S1",
    BLACK_S2: "BLACK_S2",
    GRAY_S1: "GRAY_S1",
    GRAY_S2: "GRAY_S2",
    GRAY_S3: "GRAY_S3",
    GREEN_S1: "GREEN_S1",
    GREEN_S2: "GREEN_S2",
    YELLOW_S1: "YELLOW_S1",
    ORANGE_S1: "ORANGE_S1",
  };
  static getIconColor(color = "") {
    return Object.values(this.ICON_COLOR).includes(color)
      ? color
      : this.ICON_COLOR.BLACK_S1;
  }
}
