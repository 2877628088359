import { ClickAwayListener, Grow, Tooltip } from "@mui/material";
import classVeryTable from "../classVeryTable";
import Styles from "./styles";
import { VeryBtnTransparent } from "../../VeryBtnTransparent";
import { classVeryBtnTransparent } from "../../VeryBtnTransparent/classVeryBtnTransparent";
import { VeryBtnFill } from "../../VeryBtnFill";
import { classVeryBtnFill } from "../../VeryBtnFill/classVeryBtnFill";
import { classVeryIconGoogle } from "../../VeryIconGoogle/classVeryIconGoogle";
import { useState } from "react";

export const MenuActions = (props = classVeryTable.getPropsMenuActions()) => {
  const [show, setShow] = useState(false);
  const existItemLoading = props.items.some((item) => item.loading === true);
  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <Styles.DivMain>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          open={show}
          placement="right-start"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          TransitionComponent={Grow}
          TransitionProps={{
            timeout: 500,
          }}
          title={props.items.map((item, index) => {
            return (
              <VeryBtnFill
                CSS={["btnItem"]}
                colorTheme={classVeryBtnFill.COLOR_THEME.WHITE_S1}
                isLoading={item.loading}
                label={item.label}
                textSize={14}
                onClick={() => {
                  setShow(false);
                  item.onClick && item.onClick();
                }}
              />
            );
          })}
        >
          <div>
            <VeryBtnTransparent
              CSS={["btnMenu"]}
              colorTheme={
                existItemLoading
                  ? classVeryBtnTransparent.COLOR_THEME.ORANGE_S1
                  : classVeryBtnTransparent.COLOR_THEME.BLACK_S2
              }
              icon={{
                name: "more_horiz",
                type: classVeryIconGoogle.ICON_TYPE.FILLED,
                size: 22,
              }}
              onClick={() => setShow(true)}
            />
          </div>
        </Tooltip>
      </Styles.DivMain>
    </ClickAwayListener>
  );
};
