import styled from "styled-components";

const DivMain = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //border: 2px solid red;
`;

export default {
  DivMain,
};
