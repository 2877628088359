import styles from "./styles";
import {
  isArrayEmpty,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import { VeryBtnTransparent } from "../VeryBtnTransparent";
import { classVeryBtnTransparent } from "../VeryBtnTransparent/classVeryBtnTransparent";
import { classVeryIconGoogle } from "../VeryIconGoogle/classVeryIconGoogle";
import { Grow } from "@mui/material";

const propsTemplate = () => ({
  CSS: [],
  isVisible: false,
  title: "",
  buttons: [],
  onClickClose: () => {},
});

export const VeryModal = (props = propsTemplate()) => {
  const customCSS = ["veryModal"];
  if (!isArrayEmpty(props.CSS)) customCSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) customCSS.push(props.className);

  return (
    <styles.DivMain
      className={customCSS}
      open={props.isVisible}
      onClose={() => null}
      disablePortal
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Grow in={props.isVisible} timeout={400}>
        <div className="divContentModal">
          {/* HEADER */}
          <div className="divHeaderModal">
            <label className="txtTitle">
              {isStringEmpty(props.title) ? "" : props.title}
            </label>
            <VeryBtnTransparent
              CSS={["btnCloseModal"]}
              colorTheme={classVeryBtnTransparent.COLOR_THEME.BLACK_S2}
              icon={{
                name: "close",
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
                size: 22,
              }}
              onClick={props.onClickClose}
            />
          </div>

          {/* DIVIDER TITLE */}
          <div className="dividerTitle" />

          {/* CONTENT MODAL */}
          <div className="divBodyModal">{props.children}</div>

          {/* BUTTONS */}
          {!isArrayEmpty(props.buttons) && (
            <div className="divButtons">{props.buttons}</div>
          )}
        </div>
      </Grow>
    </styles.DivMain>
  );
};
