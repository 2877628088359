import styled from "styled-components";
import { DivContentPage } from "../../components/common/DivContentPage";

const DivMain = styled(DivContentPage)`
  //border: 4px solid red;
  .btnAddDriver {
    width: 40px;
    height: 100%;
    border-radius: 10px;
    padding: 10px;
  }

  .driversTable {
    .cellIndexCancellation {
      .cell-content {
        .txt-labelCell {
          display: flex;
          flex-direction: row;
          align-items: center;
          .txt-slash {
            font-family: inherit;
            font-weight: 700;
            font-size: 22px !important;
            font-size: inherit;
            cursor: inherit;
          }
        }
      }
      .div-contentTooltip {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 3px;
        //border: 1px solid red;
        .div-rowTxt {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
        }
        .txt-title,
        .txt-value {
          font-family: "Roboto", Helvetica, sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.2;
          color: #13131d;
          text-align: left;
        }
        .txt-value {
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
  }
`;

export default {
  DivMain,
};
