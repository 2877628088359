import styled from "styled-components";
import { DivContentPage } from "../../components/common/DivContentPage";

const DivMain = styled(DivContentPage)`
  gap: 25px;
  //border: 2px solid red;
  .panel-cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    gap: 13px 25px;
    //border: 1px solid blue;
  }
  .panel-data {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    gap: 15px 25px;
    //overflow: auto;
    //border: 1px solid green;
    .panel-graph {
      flex: 1;
      min-width: 400px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      //overflow: auto;
      //border: 1px solid red;
    }
    .panel-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      gap: 20px;
      //overflow: auto;
      //border: 1px solid red;
      .panel-viajes {
        width: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        .btnViajes {
          width: 100%;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          gap: 7px;
        }
        .panel-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 22px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 17px 17px 22px 17px;
          .txtTripsQuantity {
            width: 100%;
            font-size: 32px;
            font-weight: 700;
            font-family: "Roboto", Helvetica, sans-serif;
            text-align: center;
          }
          .panel-recuadros {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            gap: 14px;
            //border: 1px solid green;
            .divPanel {
              flex-basis: 50%;
              max-width: 120px;
              background-color: #f8f8fa;
              border-radius: 10px;
              padding: 10px;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              gap: 12px;
              .txtTitle {
                font-family: "Roboto", Helvetica, sans-serif;
                font-weight: 400;
                font-size: 12px;
                color: #13131d;
                line-height: 1.2;
                text-align: center;
                //border: 1px solid blue;
              }
              .divContent {
                width: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                gap: 3px;
                //border: 1px solid blue;
                .divAmount {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  //border: 1px solid red;
                  gap: 4px;
                  .txtMXN {
                    font-family: "Roboto", Helvetica, sans-serif;
                    font-weight: 400;
                    font-size: 11px;
                  }
                  .txtAmount {
                    font-family: "Roboto", Helvetica, sans-serif;
                    font-weight: 700;
                    font-size: 14px;
                  }

                  &.divAmountCoupon {
                    .txtMXN {
                      font-size: 9px;
                    }
                    .txtAmount {
                      font-size: 12px;
                    }
                  }
                }
                .txtCouponsQuantity {
                  font-family: "Roboto", Helvetica, sans-serif;
                  font-weight: 700;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      .panel-reporte {
        width: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        flex: 1;
        //border: 2px solid green;
        .panel-sup {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 10px 15px 10px 25px;
          border-bottom: solid #eaeaea 1px;
          //border: 1px solid blue;
          .txtTitle {
            font-size: 13px;
            font-weight: 700;
            font-family: "Roboto", Helvetica, sans-serif;
          }
          .period {
            width: 45%;
            height: 100%;
            border: solid black 1px;
          }
        }
        .panel-inf {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-top: 6px;
          gap: 12px;
        }
      }
    }
  }
`;

export default {
  DivMain,
};
