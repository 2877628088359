import React from "react";
import styles from "./styles";
import {
  isArrayEmpty,
  isNumberPositive,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import { classVeryIconGoogle } from "./classVeryIconGoogle.jsx";

export const VeryIconGoogle = (props = classVeryIconGoogle.propsTemplate()) => {
  const size = isNumberPositive(props.size) ? Number(props.size) : 18;

  // clases del icon
  const CSS = [];
  // type icon
  CSS.push(classVeryIconGoogle.getIconType(props.type));
  // add animacion circular ??
  if (props.enabledSpin === true) CSS.push("iconSpin");
  // color icon
  CSS.push(classVeryIconGoogle.getIconColor(props.color));
  // custom CSS
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS);

  return isStringEmpty(props.name) ? null : (
    <styles.divMain
      className={CSS.join(" ")}
      ref={props.refIcon}
      size={size}
      {...props}
    >
      {props.name}
    </styles.divMain>
  );
};
