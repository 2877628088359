import { momentES } from "../utils/handler_dates";
import { fetchGET } from "../utils/handler_http";
import { encoURI } from "../utils/handler_validations";

/**
 * Obtener la data de un pasajero de la lista
 */
export const getPassengerTemplate = () => ({
  id: 0,
  fullName: "",
  profileImg: "",
  tel: "",
});

/**
 * Obtener la lista de passengers filtrada por nombre, apellido o tel
 */
export const getListPassengers = async (filterValue = "", jwt = "") => {
  const paramsQuery = {
    filterValue: encoURI(filterValue),
    date: encoURI(momentES().utc().format()),
  };
  const response = await fetchGET(
    process.env.REACT_APP_PASSENGERS_LIST_ENDPOINT,
    paramsQuery,
    { Authorization: jwt }
  );
  return response;
};
