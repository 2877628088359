import styled from "styled-components";

const divMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  //border: 1px solid red;

  .txtTitle {
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: 700;
    font-size: ${({ size }) => size.title}px;
    color: #13131d;
    text-align: left;
    margin-bottom: 5px;
  }
  .MuiInputBase-root {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 9px;
    padding: 4px 15px 4px 15px;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    transition: all 0.5s;
    font-family: "Roboto", Helvetica, sans-serif;
    font-size: ${({ size }) => size.text}px;
    font-weight: 400;
    color: #13131d;
    &:hover {
      border-color: #a0a0a0;
    }
    &.Mui-focused {
      border-color: #a0a0a0;
      box-shadow: 0px 0px 6px 1px #13131d55;
    }
  }

  /*============================================
	ESTILO ERROR
	==============================================*/
  &.inputError {
    .txtTitle {
      color: #a72323;
    }
    .MuiInputBase-root {
      border-color: #a72323;
      &:hover {
        border-color: #591313;
      }
    }
  }

  &.inputDisabled {
    .txtTitle {
      color: #13131d;
    }
    .MuiInputBase-root {
      border-color: #eaeaea;
      &:hover {
        border-color: inherit;
      }
    }
  }

  .MuiInputBase-root.Mui-readOnly {
    box-shadow: none;
  }
  /*
  .divContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 9px;
    padding: 4px 15px 4px 15px;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    transition: border-color 0.4s;
    &.Mui-focused {
      border-color: #a0a0a0;
    }
    .MuiInputBase-root {
      flex: 1;
      font-size: ${({ size }) => size.text}px;
    }
  }
	*/
  .txtError {
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: 400;
    font-size: ${({ size }) => size.textError}px;
    color: #a72323;
    text-align: left;
    margin-top: 6px;
  }

  /*------------------------------
	COLORES
	--------------------------------*/
  &.BLACK_S1 {
    color: #13131d;
  }
  &.WHITE_S1 {
    color: #ffffff;
  }
  &.GRAY_S1 {
    color: #a0a0a0;
  }
`;

export default {
  divMain,
};
