import { Modal } from "@mui/material";
import styled from "styled-components";

const DivMain = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  //border: 3px solid green;
  * {
    box-sizing: border-box;
  }
  .MuiModal-backdrop {
    background-color: #13131db2;
  }
  .divContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    //border: 4px solid yellow;
    .divHeader {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      background-color: #13131dc5;
      padding: 9px 9px 9px 14px;
      //border: 1px solid red;
      .txtTitle {
        flex: 1;
        font-family: "Roboto", Helvetica, sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.2;
        color: #eaeaea;
        text-align: left;
      }
      .btnClose {
        width: fit-content;
        border-radius: 100%;
        padding: 1px;
        .btnIcon {
          color: #eaeaea;
          &:hover {
            color: #a0a0a0;
          }
        }
      }
    }
    .divPreviewFull {
      width: 100%;
      flex: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //border: 1px solid yellow;
      img {
        display: ${({ isLoading }) => (isLoading ? "none" : "flex")};
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
`;

export default {
  DivMain,
};
