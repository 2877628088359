import styled from "styled-components";
import { DivContentPage } from "../../components/common/DivContentPage";

const DivMain = styled(DivContentPage)`
  //border: 4px solid red;
  .divHeader {
    margin: unset;
    .btnAddCoupon {
      width: fit-content;
      padding: 12px 21px 13px 21px;
      border-radius: 10px;
      .txtLabel {
        font-weight: 400;
      }
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 18px;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 12px 0px 0px 0px;

  .couponsTable {
    flex: 1;
    min-width: 400px;
    .txtCode {
      font-weight: 700;
      //border: 1px solid green;
    }
    .txtStatus {
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.2;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border-radius: 25px;
      color: #ffffff;
      background-color: #cecece;
      padding: 5px 9px 4px 9px;
      &.NEW {
        background-color: #46bec3;
      }
      &.CREATED {
        background-color: #fecd56;
      }
      &.IN_WALLET {
        background-color: #9e9e9e;
      }
      &.DISABLED {
        background-color: #f04465;
      }
      &.REDEEMED {
        background-color: #212121;
      }
      &.IMPLEMENTED {
        background-color: #88c053;
      }
      &.PAID {
        background-color: #9479da;
      }
    }
  }
`;

export default {
  DivMain,
  ContentContainer,
};
