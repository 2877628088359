import styled from "styled-components";
import { DivContentPage } from "../../components/common/DivContentPage";

const DivMain = styled(DivContentPage)`
  //border: 4px solid red;
  position: relative;
`;

export default {
  DivMain,
};
