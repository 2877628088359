import { ButtonBase, ClickAwayListener, Grow, Tooltip } from "@mui/material";
import styles from "./styles";
import { classVeryIconGoogle } from "../../common/v2/VeryIconGoogle/classVeryIconGoogle";
import { useState, useEffect } from "react";
import { VeryBtnFill } from "../../common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../../common/v2/VeryBtnFill/classVeryBtnFill";
import moment from "moment";
import { VeryIconGoogle } from "../../common/v2/VeryIconGoogle";
import {
  getNotNegativeNumber,
  isPositiveInt,
  isStringEmpty,
  isValidNaturalNumber,
} from "../../../utils/handler_validations";

const propsTemplate = () => ({
  textError: "",
  selectedYear: 0,
  onChangeYear: (newYear = "") => {},
});

const SelectYear = (props = propsTemplate()) => {
  const [showMenu, setShowMenu] = useState(false);
  const [options, setOptions] = useState(
    Array.from({ length: 25 }, (_, index) => {
      const yearRef = moment().get("year") + 1;
      return yearRef - index;
    })
  );

  // cada que cambie el año seleccionado
  useEffect(() => {
    const currentYear = moment().get("year");
    const nextYear = currentYear + 1;
    if (
      props.selectedYear > nextYear ||
      !isValidNaturalNumber(props.selectedYear)
    ) {
      props.onChangeYear && props.onChangeYear(0);
      return;
    }
    if (props.selectedYear !== 0 && !isValidSelectedYear()) {
      setOptions(
        Array.from(
          { length: currentYear - props.selectedYear + 2 },
          (_, index) => {
            return nextYear - index;
          }
        )
      );
      return;
    }
  }, [props.selectedYear]);

  const isValidSelectedYear = () => {
    return options.includes(props.selectedYear);
  };

  const renderOption = (year = 0) => {
    const classesCSS = ["btnYear"];
    if (props.selectedYear === year) classesCSS.push("yearSelected");

    return (
      <VeryBtnFill
        CSS={classesCSS}
        colorTheme={classVeryBtnFill.COLOR_THEME.WHITE_S1}
        label={`${year}`}
        textSize={14}
        onClick={() => {
          setShowMenu(false);
          props.onChangeYear && props.onChangeYear(year);
        }}
      />
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setShowMenu(false)}>
      <styles.DivMain>
        <Tooltip
          PopperProps={{ disablePortal: true }}
          open={showMenu}
          placement="bottom-start"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          TransitionComponent={Grow}
          TransitionProps={{ timeout: 500 }}
          title={options.map((year) => renderOption(year))}
        >
          <div className="divSelect">
            <label className="txt-title">Año</label>
            <ButtonBase
              className="btnSelect"
              onClick={() => setShowMenu(!showMenu)}
            >
              <label>
                {isValidSelectedYear() ? props.selectedYear : "Elige un año"}
              </label>
              <VeryIconGoogle
                name="expand_more"
                type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED}
                size={21}
              />
            </ButtonBase>
            {!isStringEmpty(props.textError) && (
              <label className="txt-error">{props.textError}</label>
            )}
          </div>
        </Tooltip>
      </styles.DivMain>
    </ClickAwayListener>
  );
};

export default SelectYear;
