import React from "react";
import {
  isArrayEmpty,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import { classVeryInputTel } from "./classVeryInputTel";
import { VeryInput } from "../VeryInput";
import flagMexico from "../../../../assets/flagMexico.png";
import styles from "./styles";
import { classVeryInput } from "../VeryInput/classVeryInput";

export const VeryInputTel = (props = classVeryInputTel.propsTemplate()) => {
  // clases del icon
  const CSS = [];
  // custom CSS
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS);

  return (
    <VeryInput
      type={classVeryInput.TYPE_INPUT.NORMAL}
      onChangeRefInput={props.onChangeRefInput}
      CSS={CSS.join(" ")}
      size={props.size}
      title={"Teléfono"}
      text={props.text}
      textError={props.textError}
      placeHolder={"Número sin lada"}
      inLeftComponent={<styles.imgFlagMexico src={flagMexico} />}
      isEditable={props.isEditable}
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
      onLostFocus={props.onLostFocus}
      onWonFocus={props.onWonFocus}
      onEnter={props.onEnter}
      onChangeText={props.onChangeText}
    />
  );
};
