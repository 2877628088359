import { useCouponsContext } from "../../../context";
import { isStringEmpty } from "../../../utils/handler_validations";
import { VeryBtnFill } from "../../common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../../common/v2/VeryBtnFill/classVeryBtnFill";
import { VeryBtnOutlined } from "../../common/v2/VeryBtnOutlined";
import { classVeryBtnOutlined } from "../../common/v2/VeryBtnOutlined/classVeryBtnOutlined";
import Styles from "./styles";

export const ModalDisabled = (props) => {
  const coupons = useCouponsContext();

  const renderItemData = (title = "", data = "") => {
    return (
      <div className="div-data">
        <label className="txt-title">{title}</label>
        <label className="txt-data">{data}</label>
      </div>
    );
  };

  return (
    <Styles.Modal
      isVisible={coupons.modalDisabled.show}
      title="Cancelar un cupón de usuario"
      onClickClose={() => coupons.hideModalDisabled()}
      buttons={[
        <VeryBtnOutlined
          CSS={["btnModal"]}
          colorTheme={classVeryBtnOutlined.COLOR_THEME.BLACK_S1}
          label="Regresar"
          textSize={15}
          isDisabled={coupons.modalDisabled.loading}
          onClick={() => coupons.hideModalDisabled()}
        />,
        <VeryBtnFill
          CSS={["btnModal"]}
          colorTheme={classVeryBtnFill.COLOR_THEME.BLACK_S1}
          label="Aceptar"
          textSize={15}
          isLoading={coupons.modalDisabled.loading}
          onClick={() => coupons.onClickBtnDisabled()}
        />,
      ]}
    >
      <label className="txt-message">
        Estas a punto de cancelar un cupón de usuario, esta acción ya no es
        reversible.{"\n\n"}Favor de confirmar.
      </label>
      <div className="div-details">
        {renderItemData("Cupón", coupons.modalDisabled.code)}
        {renderItemData("Descuento", `$${coupons.modalDisabled.amount}`)}
        {!isStringEmpty(coupons.modalDisabled.userName) &&
          renderItemData("Usuario", coupons.modalDisabled.userName)}
      </div>
    </Styles.Modal>
  );
};
