import styled from "styled-components";

const divMain = styled.span`
  width: fit-content;
  font-size: ${({ size }) => size}px;
  display: grid;
  place-items: center;
  /*------------------------------
	ANIMACION CIRCULAR INFINITA
	--------------------------------*/
  &.iconSpin {
    animation-duration: 1s;
    animation-name: iconSpin;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: linear;
  }
  @keyframes iconSpin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  /*------------------------------
	COLORES
	--------------------------------*/
  &.BLACK_S1 {
    color: #13131d;
  }
  &.BLACK_S2 {
    color: #1c1b1f;
  }
  &.WHITE_S1 {
    color: #ffffff;
  }
  &.GRAY_S1 {
    color: #a0a0a0;
  }
  &.GRAY_S2 {
    color: #d9d9d9;
  }
  &.GRAY_S3 {
    color: #d2d2d2;
  }
  &.GREEN_S1 {
    color: #26ff00;
  }
  &.GREEN_S2 {
    color: #16ca5e;
  }
  &.YELLOW_S1 {
    color: #ffcc00;
  }
  &.ORANGE_S1 {
    color: #d4cbb9;
  }
`;

export default {
  divMain,
};
