import styled from "styled-components";

const DivMain = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  //border: 1px solid red;

  .btnSwitch {
    width: 45px;
    height: 25px;
    padding: 0px;
    * {
      box-sizing: border-box;
    }
    // FONDO
    .MuiSwitch-track {
      border-radius: 25px;
      background-color: #eaeaea;
      opacity: 1;
      transition: background-color 0.3s;
    }
    // CIRCLE
    .MuiSwitch-switchBase {
      top: 0px;
      bottom: 0px;
      left: 0px;
      padding: 0px 3px;
      //right: 10px;
      .MuiSwitch-thumb {
        width: 18px;
        height: 18px;
        background-color: #ffffff;
      }
      .MuiTouchRipple-root {
        color: #dadada;
      }
    }
    &:hover .MuiSwitch-switchBase {
      background-color: transparent;
    }

    // DISABLED SWITCH
    // FONDO
    .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
      opacity: 0.4;
      background-color: #d4cbb9;
    }
    // CIRCLE
    .MuiSwitch-switchBase.Mui-disabled {
      .MuiSwitch-thumb {
        background-color: #d4cbb9;
      }
    }

    // SELECTED SWITCH
    // FONDO
    .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      background-color: #16ca5e;
      opacity: 1;
    }
    // CIRCLE
    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(21px);
      .MuiTouchRipple-root {
        color: #6ce79d;
      }
    }

    // HOVER SWITCH
    &:hover {
      // NORMAL
      .MuiSwitch-track {
        background-color: #d3d3d3;
      }
      // SELECTED
      .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: #0f9343;
      }
    }
  }
`;

export default {
  DivMain,
};
