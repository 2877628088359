import { DriversProvider, ModalAskProvider } from "../../context";
import ContentDriversPage from "./content";

const DriversPage = (props) => {
  return (
    <ModalAskProvider>
      <DriversProvider>
        <ContentDriversPage />
      </DriversProvider>
    </ModalAskProvider>
  );
};

export default DriversPage;
