import { VeryBtnTransparent } from "../../common/v2/VeryBtnTransparent";
import { classVeryBtnTransparent } from "../../common/v2/VeryBtnTransparent/classVeryBtnTransparent";
import { classVeryIconGoogle } from "../../common/v2/VeryIconGoogle/classVeryIconGoogle";
import styles from "./styles";

const propsTemplate = () => ({
  btnBack: { isDisabled: false, onClick: () => {} },
  btnClose: { isDisabled: false, onClick: () => {} },
});

const NavLogin = (props = propsTemplate()) => {
  return (
    <styles.DivMain>
      {/* BTN REGRESAR */}
      {props.btnBack?.onClick ? (
        <VeryBtnTransparent
          CSS={["btnNavBack"]}
          colorTheme={classVeryBtnTransparent.COLOR_THEME.BLACK_S1}
          label="Atrás"
          textSize={15}
          onClick={props.btnBack.onClick}
          isDisabled={props.btnBack.isDisabled}
        />
      ) : (
        <div />
      )}
      {/* BTN CLOSE */}
      {props.btnClose?.onClick && (
        <VeryBtnTransparent
          CSS={["btnNavClose"]}
          colorTheme={classVeryBtnTransparent.COLOR_THEME.BLACK_S1}
          icon={{
            name: "close",
            type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED,
            size: 22,
          }}
          onClick={props.btnClose.onClick}
          isDisabled={props.btnClose.isDisabled}
        />
      )}
    </styles.DivMain>
  );
};

export default NavLogin;
