import { createContext, useContext } from "react";
import { useAdmins } from "../hooks";
const Context = createContext();

export const AdminsProvider = ({ children }) => {
  return (
    <Context.Provider value={useAdmins()}>{children}</Context.Provider>
  );
};

export const useAdminsContext = () => useContext(Context);