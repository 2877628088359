import { useEffect, useState } from "react";
import {
  isObject,
  isStringEmpty,
  isValidNaturalNumber,
} from "../utils/handler_validations";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "../context/session.context";
import { useVeryNotificationsContext } from "../components/common/v2/VeryNotifications/useVeryNotifications";

const getValueTemplate = () => ({
  showScreenRecoveryPass: false,
  showScreenNewPass: false,
  loading: false,
});
const getDataTemplate = () => ({
  email: "",
  pass: "",
});

export const useLogin = () => {
  const [value, setValue] = useState(getValueTemplate());
  const [data, setData] = useState(getDataTemplate());
  const [errorData, setErrorData] = useState(getDataTemplate());
  const navigate = useNavigate();
  const session = useSessionContext();
  const notis = useVeryNotificationsContext();

  const isValidData = (fields = getDataTemplate()) => {
    const newError = { ...errorData };
    let valid = true;
    // valid email
    if (fields.email) {
      if (/\s/.test(data.email)) {
        newError.email = "El correo ingresado no debe tener espacios";
        valid = false;
      } else if (!/.+@.+/.test(data.email)) {
        newError.email = "El correo electrónico es incorrecto.";
        valid = false;
      }
    }
    // valid password
    if (fields.pass) {
      if (isStringEmpty(data.pass)) {
        newError.pass = "Favor de ingresar su contraseña";
        valid = false;
      } else if (/\s/.test(data.pass)) {
        newError.pass = "Su contraseña no debe tener espacios";
        valid = false;
      } else if (data.pass.length < 8) {
        newError.pass = "Su contraseña debe tener al menos 8 caracteres";
        valid = false;
      }
    }
    // valid newPassword
    if (fields.newPass) {
      if (isStringEmpty(data.newPass)) {
        newError.newPass = "Favor de ingresar su nueva contraseña";
        valid = false;
      } else if (!/\d/.test(data.newPass)) {
        newError.newPass = "Su contraseña debe tener al menos un digito";
        valid = false;
      } else if (!/[A-Z]|Ñ/.test(data.newPass)) {
        newError.newPass =
          "Su contraseña debe tener al menos una letra mayúscula";
        valid = false;
      } else if (!/[a-z]|ñ/.test(data.newPass)) {
        newError.newPass =
          "Su contraseña debe tener al menos una letra minúscula";
        valid = false;
      } else if (/\s/.test(data.newPass)) {
        newError.newPass = "Su contraseña no debe tener espacios";
        valid = false;
      } else if (data.newPass.length < 8) {
        newError.newPass = "Su contraseña debe tener al menos 8 caracteres";
        valid = false;
      }
    }
    // valid confirm password
    if (fields.confirmPass) {
      if (isStringEmpty(data.confirmPass)) {
        newError.confirmPass = "Favor de ingresar de nuevo su contraseña";
        valid = false;
      }
      if (data.confirmPass !== data.newPass) {
        newError.confirmPass = "Su contraseña no coincide";
        valid = false;
      }
    }
    // valid code
    if (fields.code) {
      if (isStringEmpty(data.code)) {
        newError.code = "Favor de ingresar el código de verificación";
        valid = false;
      } else if (!/^\d+$/.test(data.code)) {
        newError.code = "El código de verificación solo debe contener digitos";
        valid = false;
      }
    }
    setErrorData(newError);
    return valid;
  };

  const setShowScreenRecoveryPass = (visible = false) => {
    setValue((prev) => ({
      ...prev,
      showScreenRecoveryPass: visible === true,
      showScreenNewPass: false,
    }));
  };

  const setShowScreenNewPass = (visible = false) => {
    setValue((prev) => ({
      ...prev,
      showScreenNewPass: visible === true,
      showScreenRecoveryPass: false,
    }));
  };

  // onClick en el btn iniciar sesion
  const onClickBtnSignIn = async () => {
    if (isValidData({ email: true, pass: true })) {
      // activar loading
      setValue((prev) => ({ ...prev, loading: true }));
      // create session
      const resp = await session.create(data.email, data.pass);
      if (resp.error) {
        setValue((prev) => ({ ...prev, loading: false }));
        notis.createNotiError(resp.error.message, resp.error.details);
        return;
      }
      // success
      // inicial password required ?
      if (resp.newPassRequired) {
        setValue((prev) => ({
          ...prev,
          loading: false,
          showScreenRecoveryPass: false,
          showScreenNewPass: true,
        }));
        notis.createNotiError(
          "Se requiere un cambio de contraseña",
          "Favor de cambiar su contraseña temporal para iniciar sesión"
        );
        return;
      }
      // login normal
      setValue((prev) => ({ ...prev, loading: false }));
      navigate("/viajes", { replace: true });
    }
  };

  const setInputData = (values = getDataTemplate()) => {
    setData((prev) => ({
      ...prev,
      ...values,
    }));
  };

  const setInputError = (values = getDataTemplate()) => {
    setErrorData((prev) => ({
      ...prev,
      ...values,
    }));
  };

  return {
    value,
    data,
    errorData,
    setInputData,
    setInputError,
    setShowScreenRecoveryPass,
    setShowScreenNewPass,

    onClickBtnSignIn,
  };
};
