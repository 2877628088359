import styled from "styled-components";
import ModalFormSteps from "../common/v2/ModalFormSteps";

const DivMain = styled(ModalFormSteps)`
  .divContentModal {
    width: calc(100% - 40px);
    max-width: 720px;
    height: calc(100% - 40px);
    max-height: 775px;
  }
`;

const PanelPersonal = styled.div`
  width: 100%;
  flex: 1;
  display: ${({ isActiveStep }) => (isActiveStep ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  gap: 21px;
  padding: 3px 15px;
  //border: 1px solid red;
  .select-img {
    width: 300px;
    flex-shrink: 0;
    height: 185px;
    border-radius: 10px;
    border: 1px dashed gray;
  }
  .divName {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    > * {
      flex: 1;
      min-width: 200px;
    }
  }
`;

const PanelVehicle = styled.div`
  width: 100%;
  flex: 1;
  display: ${({ isActiveStep }) => (isActiveStep ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  gap: 21px;
  padding: 3px 15px;
  //border: 1px solid red;
  .divSelects {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    > * {
      flex: 1;
      min-width: 200px;
    }
  }
`;

const PanelDocuments = styled.div`
  width: 100%;
  flex: 1;
  display: ${({ isActiveStep }) => (isActiveStep ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  gap: 25px;
  padding: 3px 15px;
  .txtWarning {
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: #13131dcc;
    line-height: 1.2;
    text-align: center;
  }
  //border: 1px solid red;
  .divPickersFile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    .rowPickersFile {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      gap: 30px;
    }
  }
`;

export default {
  DivMain,
  PanelPersonal,
  PanelVehicle,
  PanelDocuments,
};
