import SideMenu from "../../components/side-menu";
import styles from "./styles";
import { ModalLogOut } from "../../components/modal-logout";
import ModalDriver from "../../components/modal-driver";
import { FormDriverProvider } from "../../context";
import ContentHomePage from "./content";
import { SocketProvider } from "../../context/socket";

export default function PageHome() {
  return (
    <SocketProvider>
      <FormDriverProvider>
        <styles.DivMain>
          {/* MENU LATERAL */}
          <SideMenu />

          {/* MODAL LOG-OUT */}
          <ModalLogOut />

          {/* MODAL (ADD | EDIT) DRIVER */}
          <ModalDriver />

          {/* CONTENIDO DE LA PAGINA */}
          <ContentHomePage />
        </styles.DivMain>
      </FormDriverProvider>
    </SocketProvider>
  );
}
