import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const divMain = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    /*==========================================
		ESTILOS DE COLORES
		============================================*/
    &.WHITE_S1 {
      color: #ffffff;
    }
    &.BLACK_S1 {
      color: #13131d;
    }
    &.GRAY_S1 {
      color: #d9d9d9;
    }
    &.GRAY_S2 {
      color: #a0a0a0;
    }
    &.GREEN_S1 {
      color: #16ca5e;
    }
    &.ORANGE_S1 {
      color: #d4cbb9;
    }
  }
`;

export default {
  divMain,
};
