import styled from "styled-components";

const divBtnEye = styled.div`
  .btnEye {
    width: fit-content;
    padding: 1px 2px;
    border-radius: 100%;
    //border: 1px solid red;
  }
`;

export default {
  divBtnEye,
};
