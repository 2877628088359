import { useEffect, useMemo, useRef, useState } from "react";
import {
  getFormattedNumber,
  getNotNegativeNumber,
  isArrayEmpty,
  isStringEmpty,
} from "../../../utils/handler_validations";
import styles from "./styles";
import * as echarts from "echarts";
import classCharts from "../classCharts";
import { useDashboardContext } from "../../../context";
import SelectTime from "../SelectTime";

const getPropsTemplate = () => ({
  CSS: [""],
});

export const DriversChart = (props = getPropsTemplate()) => {
  const [chart, setChart] = useState();
  const dataDashboard = useDashboardContext();
  const CSS = ["div-tripsGrap"];
  const refDivChart = useRef();

  // cada que cambie el filtro => update chart
  useEffect(() => {
    const selectedData = getSelectedData();
    if (!chart) return;
    chart.setOption({
      grid: {
        top: 20,
        left: 60,
        right: 5,
      },
      xAxis: {
        type: "category",
        axisLabel: {
          interval: 0, // Muestra todas las etiquetas del eje x
          rotate: 0, // Rota las etiquetas
        },
      },
      yAxis: {},
      dataset: {
        dimensions: ["labelX", "connected"],
        source: getFormattedDataset(selectedData.items),
      },
      series: [
        {
          type: "line",
          smooth: true,
          lineStyle: {
            color: "#16CA5E",
            width: 3,
          },
          itemStyle: {
            color: "#000000",
          },
          tooltip: {
            position: "top",
            backgroundColor: "#13131D",
            borderWidth: 0,
            padding: [0, 11, 0, 10],
            extraCssText: `border-radius: 20px;`,
            formatter: (params) => {
              const data = selectedData.items[params.dataIndex];
              return `
							<label style="
								color: #FFFFFF; 
								font-size: 14px;
								font-family: Roboto;
								font-weight: 700;
							">
								${getFormattedNumber(getNotNegativeNumber(data.connected), true, 0)}
							</label>`;
            },
          },
        },
      ],
      dataZoom:
        selectedData.scroll === 0
          ? undefined
          : [
              {
                type: "inside",
                start: 0,
                end: selectedData.scroll,
              },
              {
                start: 0,
                end: selectedData.scroll,
              },
            ],
    });
  }, [dataDashboard.filters?.driversChart, chart]);

  // inializar chart
  useEffect(() => {
    if (!refDivChart?.current) setChart(undefined);
    const newChart = echarts.init(refDivChart.current);
    newChart.setOption({ tooltip: {} });
    setChart(newChart);
  }, [refDivChart?.current]);

  // cada que se establezca un nuevo obj chart
  useEffect(() => {
    window.addEventListener("resize", onChangeSizeWindow);
    return () => {
      if (chart) chart.dispose();
      window.removeEventListener("resize", onChangeSizeWindow);
    };
  }, [chart]);

  const getSelectedData = () => {
    let items = [classCharts.getItemDriverTemplate()];
    items = dataDashboard.getDataDriversChart();
    switch (dataDashboard.filters?.driversChart) {
      case "TODAY":
        return { items, scroll: 30 };
      case "WEEK":
        return { items, scroll: 100 };
      case "MONTH":
        return { items, scroll: 30 };
      default:
        return { items: [], scroll: 0 };
    }
  };

  const getFormattedDataset = (
    items = [classCharts.getItemDriverTemplate()]
  ) => {
    return items.map((item) => {
      return {
        labelX: item.label,
        connected: getNotNegativeNumber(item.connected),
      };
    });
  };

  const onChangeSizeWindow = () => {
    if (chart) chart.resize();
  };

  // add custom style
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) CSS.push(props.className);

  return (
    <styles.DivMain className={CSS.join(" ")}>
      <div className="div-header">
        <label className="txt-title">
          Actividad de conductores conectados por tiempo
        </label>
        <SelectTime
          onChangeValue={(newValue) => {
            dataDashboard.changeFilters({
              driversChart: newValue,
            });
          }}
        />
      </div>
      <div className="div-separator" />
      <div className="div-chart" ref={refDivChart} />
    </styles.DivMain>
  );
};
