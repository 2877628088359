import { ButtonBase } from "@mui/material";
import styled from "styled-components";

const DivMain = styled.div`
  //border-radius: 25px;
  //border: 1px solid red;
  .divSelect {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .txt-title {
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 700;
      font-size: 12px;
      text-align: left;
      margin-bottom: 5px;
    }
    .btnSelect {
      padding: 7px 10px 6px 12px;
      border-radius: 10px;
      border: 1px solid #eaeaea;
      label {
        flex: 1;
        font-weight: 400;
        font-size: 13px;
        color: #13131d;
        line-height: 1.2;
        text-align: left;
        cursor: inherit;
        transition: color 0.4s;
        margin: 0px 12px 0px 7px;
      }
      .MuiTouchRipple-root {
        color: #13131d78;
      }
      &:hover {
        border-color: #a0a0a0;
        background-color: unset;
        label {
          color: #797878;
        }
      }
    }
    .txt-error {
      margin-top: 6px;
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 400;
      font-size: 12px;
      color: rgb(167, 35, 35);
      text-align: left;
    }
  }

  .MuiTooltip-tooltipPlacementBottom {
    margin-top: 0px !important;
  }
  .MuiTooltip-tooltip {
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 7px 0px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.194);
    //border: 1px solid blue;
    .btnOption {
      &.colorSelected {
        cursor: default;
        pointer-events: none;
        background-color: #c7c7c7;
        &:hover {
          background-color: #c7c7c7;
        }
      }
    }
  }
`;

const BtnColor = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 100%;
    min-width: min-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 7px 12px;
    box-shadow: unset;
    border-radius: 0px;
    background-color: transparent;
    transition: all 0.4s;
    cursor: pointer;
    .divColor {
      width: 17px;
      height: 17px;
      border-radius: 5px;
      cursor: inherit;
      &.YELLOW {
        background-color: #ffff00;
        border: 1px solid #bdbd02;
      }
      &.GREEN {
        background-color: #10e710;
        border: 1px solid #016501;
      }
      &.RED {
        background-color: #ed4040;
        border: 1px solid #5c0101;
      }
      &.WHITE {
        background-color: #ffffff;
        border: 1px solid #b5b5b5;
      }
    }
    label {
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 400;
      font-size: 12px;
      color: #13131d;
      line-height: 1.2;
      text-align: left;
      cursor: inherit;
    }
    .MuiTouchRipple-root {
      color: #13131d78;
    }
    &:hover {
      background-color: #eaeaea;
    }
  }
`;

export default {
  DivMain,
  BtnColor,
};
