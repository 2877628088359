import { Grow, Skeleton, Tooltip } from "@mui/material";
import { classVeryIconGoogle } from "../../common/v2/VeryIconGoogle/classVeryIconGoogle";
import { useMemo } from "react";
import { VeryIconGoogle } from "../../common/v2/VeryIconGoogle";
import Styles from "./styles";
import { useTripsContext } from "../../../context";

const getPropsTemplate = () => ({
  rowId: -1,
  selectedRowId: -1,
});

export const ColRoute = (props = getPropsTemplate()) => {
  const trips = useTripsContext();

  const isSelectedRow = useMemo(() => {
    return props.rowId === props.selectedRowId;
  }, [props.selectedRowId]);

  const addresses = useMemo(() => {
    return trips.getTripAddresses(props.rowId);
  }, [trips.rowAddresses]);

  return (
    <Styles.DivMain>
      <Tooltip
        PopperProps={{ disablePortal: true }}
        open={isSelectedRow}
        placement="top"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
        TransitionComponent={Grow}
        TransitionProps={{ timeout: 500 }}
        title={
          <>
            {/* ORIGIN */}
            <div className="divAddress">
              <div className="circleAddress circleOrigin" />
              <div className="divInfo">
                {!addresses?.origin ? (
                  <>
                    <Skeleton className="skeAddressS1" variant="text" />
                    <Skeleton className="skeAddressS2" variant="text" />
                  </>
                ) : (
                  <>
                    <label className="txtAddressS1">
                      {addresses.origin.title}
                    </label>
                    {addresses.origin.subtitle && (
                      <label className="txtAddressS2">
                        {addresses.origin.subtitle}
                      </label>
                    )}
                  </>
                )}
              </div>
            </div>
            {/* DESTINATION */}
            <div className="divAddress">
              <div className="circleAddress circleDest" />
              <div className="divInfo">
                {!addresses?.destination ? (
                  <>
                    <Skeleton className="skeAddressS1" variant="text" />
                    <Skeleton className="skeAddressS2" variant="text" />
                  </>
                ) : (
                  <>
                    <label className="txtAddressS1">
                      {addresses.destination.title}
                    </label>
                    {addresses.destination.subtitle && (
                      <label className="txtAddressS2">
                        {addresses.destination.subtitle}
                      </label>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        }
      >
        <div>
          <VeryIconGoogle
            CSS={["iconRoute"]}
            type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED}
            name="route"
            size={22}
            color={
              isSelectedRow
                ? classVeryIconGoogle.ICON_COLOR.BLACK_S1
                : classVeryIconGoogle.ICON_COLOR.GRAY_S3
            }
          />
        </div>
      </Tooltip>
    </Styles.DivMain>
  );
};
