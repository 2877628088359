import {
  isArrayEmpty,
  isNumberPositive,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import styles from "./styles";
import { VeryIconGoogle } from "../VeryIconGoogle";
import { classVeryIconGoogle } from "../VeryIconGoogle/classVeryIconGoogle";
import { VeryBtnFill } from "../VeryBtnFill";
import { classVeryBtnFill } from "../VeryBtnFill/classVeryBtnFill";
import { useDropzone } from "react-dropzone";
import classVeryPickerImgPdf from "./classVeryPickerImgPdf";
import { useState, useEffect } from "react";
import { VeryLoading } from "../VeryLoading";
import { classVeryLoading } from "../VeryLoading/classVeryLoading";
import { DivPreview } from "./DivPreview";
// viewer PDF
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  getBase64,
  getFileExtension,
  getFileTemplate,
  getImgCompressBase64,
  isFilePDF,
} from "../../../../utils/handler_files";

export const VeryPickerImgPdf = (
  props = classVeryPickerImgPdf.getPropsTemplate()
) => {
  const [value, setValue] = useState({
    step: classVeryPickerImgPdf.STEP.UPLOAD,
    loading: false,
  });
  const [selectedFile, setSelectedFile] = useState(getFileTemplate());
  const customCSS = ["divPickerImgPdf"];
  const sizesLabel = {
    title: isNumberPositive(props.sizesLabel?.title)
      ? Number(props.sizesLabel.title)
      : 15,
    description: isNumberPositive(props.sizesLabel?.description)
      ? Number(props.sizesLabel.description)
      : 11,
    error: isNumberPositive(props.sizesLabel?.error)
      ? Number(props.sizesLabel.error)
      : 12,
  };
  const existSelectedFile = !isStringEmpty(selectedFile.src);

  useEffect(() => {
    resetOriginalFile();
  }, [props.originalFile]);

  useEffect(() => {
    props.onChangeFile && props.onChangeFile(selectedFile);
  }, [selectedFile]);

  const resetOriginalFile = () => {
    // existe original file
    if (!isStringEmpty(props.originalFile?.src)) {
      setSelectedFile({
        src: props.originalFile.src,
        ext: props.originalFile.ext,
      });
      setValue({
        step: isFilePDF(props.originalFile.ext)
          ? classVeryPickerImgPdf.STEP.PREVIEW_PDF
          : classVeryPickerImgPdf.STEP.PREVIEW_IMG,
        loading: true,
      });
    }
    // NO hay original file
    else {
      setSelectedFile({ src: "", ext: "" });
      setValue({
        step: classVeryPickerImgPdf.STEP.UPLOAD,
        loading: false,
      });
    }
  };

  // add custom style
  if (!isArrayEmpty(props.CSS)) customCSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) customCSS.push(props.className);

  //tipos validos
  const validTypesFile = {};
  if (!props.disabledImgs) {
    validTypesFile["image/jpeg"] = [];
    validTypesFile["image/png"] = [];
  }
  if (!props.disabledPdfs) {
    validTypesFile["application/pdf"] = [];
  }

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    disabled: false,
    noClick: true,
    noKeyboard: true,
    accept: validTypesFile,
    maxSize: 5000000, // 5 Mb
    multiple: false,
    onDropAccepted: async (files) => {
      props.onError && props.onError("");
      // es file tipo imagen ?
      if (["image/jpeg", "image/png"].includes(files[0]?.type)) {
        setValue({
          step: classVeryPickerImgPdf.STEP.PREVIEW_IMG,
          loading: true,
        });
        const imgBase64 = await getImgCompressBase64(files[0], {
          quality: 0.8,
          maxWidth: 1200,
          maxHeight: 1200,
        });
        // error
        if (isStringEmpty(imgBase64)) {
          console.error("error al obtener la imagen seleccionada");
          if (props.onError) {
            props.onError("No fue posible cargar la imagen seleccionada");
          }
          setValue({
            step: classVeryPickerImgPdf.STEP.UPLOAD,
            loading: false,
          });
        }
        // success
        else {
          setSelectedFile({
            src: imgBase64,
            ext: getFileExtension(files[0].name),
          });
          setValue({
            step: classVeryPickerImgPdf.STEP.PREVIEW_IMG,
            loading: true,
          });
        }
      }
      // file tipo PDF
      else {
        setValue({
          step: classVeryPickerImgPdf.STEP.PREVIEW_PDF,
          loading: true,
        });
        const fileBase64 = await getBase64(files[0]);
        // error
        if (isStringEmpty(fileBase64)) {
          console.error("error al obtener el PDF seleccionado");
          if (props.onError) {
            props.onError("No fue posible cargar el PDF seleccionado");
          }
          setValue({
            step: classVeryPickerImgPdf.STEP.UPLOAD,
            loading: false,
          });
        }
        // success
        else {
          setSelectedFile({ src: fileBase64, ext: "pdf" });
          setValue({
            step: classVeryPickerImgPdf.STEP.PREVIEW_PDF,
            loading: true,
          });
        }
      }
    },
    onDropRejected: (files) => {
      if (files.length > 0 && files[0].errors.length > 0 && props.onError) {
        switch (files[0].errors[0].code) {
          case "file-invalid-type":
            props.onError("El tipo de archivo seleccionado no es valido");
            break;
          case "file-too-large":
            props.onError("El archivo seleccionado supera los 5 Mb");
            break;
          default:
            props.onError("No fue posible cargar el archivo seleccionado");
        }
      }
    },
  });

  const getTitle = () => {
    if (isStringEmpty(props.title)) return null;
    let customTitle = props.title.trim();
    if (props.isRequired === true) customTitle += "*";
    return <label className="txtTitle">{customTitle}</label>;
  };

  const getDescription = () => {
    let customTxt = "";
    if (!props.disabledImgs && !props.disabledPdfs) {
      customTxt = "JPG, PNG o PDF";
    } else if (props.disabledImgs) {
      customTxt = "PDF";
    } else {
      customTxt = "JPG o PNG";
    }
    customTxt += " menor a 5 Mb";
    return <label className="txtDescription">{customTxt}</label>;
  };

  const renderBtnRestore = () => {
    if (isDragActive) return null;
    return !isStringEmpty(props.originalFile?.src) ? (
      <VeryBtnFill
        CSS={["btnRestore"]}
        colorTheme={classVeryBtnFill.COLOR_THEME.GRAY_S2}
        icon={{
          name: "rotate_right",
          size: 22,
          type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
        }}
        onClick={() => resetOriginalFile()}
      />
    ) : (
      <VeryIconGoogle
        CSS={["iconPhoto"]}
        name="add_a_photo"
        type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED}
        size={30}
        color={classVeryIconGoogle.ICON_COLOR.GRAY_S1}
      />
    );
  };

  return (
    <>
      <styles.DivMain
        className={customCSS.join(" ")}
        sizeError={sizesLabel.error}
        isLoading={value.loading}
      >
        <div className="divContent">
          {value.step === classVeryPickerImgPdf.STEP.UPLOAD ? (
            <styles.DivUpload
              {...getRootProps()}
              className="divUpload"
              sizeTitle={sizesLabel.title}
              sizeDescription={sizesLabel.description}
              isDragActive={isDragActive}
            >
              <input {...getInputProps()} />
              {renderBtnRestore()}
              {getTitle()}
              {isDragActive ? (
                <div className="divIconUpload">
                  <VeryIconGoogle
                    CSS={["iconUpload"]}
                    name="upload_file"
                    type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED}
                    size={60}
                    color={classVeryIconGoogle.ICON_COLOR.GRAY_S1}
                  />
                </div>
              ) : (
                <>
                  {getDescription()}
                  <div className="divPadding" />
                  <VeryBtnFill
                    CSS={["btnUpload"]}
                    colorTheme={classVeryBtnFill.COLOR_THEME.GRAY_S2}
                    label="Adjuntar"
                    textSize={12}
                    onClick={() => open()}
                  />
                </>
              )}
            </styles.DivUpload>
          ) : (
            <DivPreview
              isLoading={value.loading}
              existSelectedFile={existSelectedFile}
              file={selectedFile}
              title={props.title}
              onClickBtnDelete={() => {
                setValue((prev) => ({
                  step: classVeryPickerImgPdf.STEP.UPLOAD,
                  loading: false,
                }));
                setSelectedFile({ src: "", ext: "" });
              }}
            >
              {value.step === classVeryPickerImgPdf.STEP.PREVIEW_IMG ? (
                <>
                  {/* PREVIEW IMG */}
                  {existSelectedFile && (
                    <img
                      src={selectedFile.src}
                      onLoad={() => {
                        setValue({
                          step: classVeryPickerImgPdf.STEP.PREVIEW_IMG,
                          loading: false,
                        });
                      }}
                      onError={() => {
                        console.log("<img /> onError()");
                        if (props.onError) {
                          props.onError("No fue posible cargar la imagen");
                        }
                        setValue({
                          step: classVeryPickerImgPdf.STEP.UPLOAD,
                          loading: false,
                        });
                      }}
                    />
                  )}
                  {/* PREVIEW LOADING */}
                  {value.loading && (
                    <VeryLoading
                      CSS={["loadingPreview"]}
                      size={45}
                      weight={4}
                      color={classVeryLoading.COLOR.BLACK_S1}
                    />
                  )}
                </>
              ) : (
                <>
                  {/* PREVIEW PDF */}
                  {existSelectedFile && (
                    <>
                      <Viewer
                        fileUrl={selectedFile.src}
                        onDocumentLoad={(event) => {
                          setValue({
                            step: classVeryPickerImgPdf.STEP.PREVIEW_PDF,
                            loading: false,
                          });
                        }}
                        renderLoader={() => {
                          // PREVIEW LOADING
                          return (
                            <VeryLoading
                              CSS={["loadingPreview"]}
                              size={45}
                              weight={4}
                              color={classVeryLoading.COLOR.BLACK_S1}
                            />
                          );
                        }}
                        renderError={(errorPDF) => {
                          console.log("errorPDF => ", errorPDF);
                          if (props.onError) {
                            props.onError("No fue posible cargar el PDF");
                          }
                          setValue({
                            step: classVeryPickerImgPdf.STEP.UPLOAD,
                            loading: false,
                          });
                          return null;
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </DivPreview>
          )}
        </div>
        {/* ERROR */}
        {!isStringEmpty(props.error) && (
          <label className="txtError">{props.error}</label>
        )}
      </styles.DivMain>
    </>
  );
};
