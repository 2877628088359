import { createContext, useContext } from "react";
import { useLogin, useRecoveryPass, useNewPass } from "../hooks";
const ContextLogin = createContext();
const ContextRecoveryPass = createContext();
const ContextNewPass = createContext();

// hook useSession
export const LoginProvider = ({ children }) => {
  return (
    <ContextLogin.Provider value={useLogin()}>{children}</ContextLogin.Provider>
  );
};
export const useLoginContext = () => useContext(ContextLogin);

// hook useRecoveryPass
export const RecoveryPassProvider = ({ children }) => {
  return (
    <ContextRecoveryPass.Provider value={useRecoveryPass()}>
      {children}
    </ContextRecoveryPass.Provider>
  );
};
export const useRecoveryPassContext = () => useContext(ContextRecoveryPass);

// hook useNewPass
export const NewPassProvider = ({ children }) => {
  return (
    <ContextNewPass.Provider value={useNewPass()}>
      {children}
    </ContextNewPass.Provider>
  );
};
export const useNewPassContext = () => useContext(ContextNewPass);
