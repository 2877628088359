import { DashboardProvider } from "../../context/dashboard.context";
import ContentDashboardPage from "./content";

const Dashboard = () => {
  return (
    <DashboardProvider>
      <ContentDashboardPage />
    </DashboardProvider>
  );
};

export default Dashboard;
