import styles from "./styles";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import {
  isObject,
  isStringEmpty,
  isValidNaturalNumber,
} from "../../../utils/handler_validations";
import classCustomButton from "./utils/classCustomButton";
import CustomIcon from "../custom-icon";

const CustomButton = (props = classCustomButton.propsType) => {
  const {
    className, // (string)
    size,
    label,
    transparent, // (boolean)
    borderType, // (string) -> consultar tipos de bordes en la clase
    isLoading, // (boolean)
    isDisabled, // (boolean)
    visibleBorde, // (boolean)
    onClick, // () => {}
  } = props;

  const renderIcon = () => {
    if (!isObject(props.icon) || isStringEmpty(props.icon.name)) {
      return null;
    }
    return (
      <CustomIcon
        iconName={props.icon.name}
        iconType={props.icon.type}
        size={props.icon.size}
        color={props.icon.color}
        transparent
        className={"icon"}
      />
    );
  };

  const fontSize = isValidNaturalNumber(size) ? size : 14;
  const classesCSS = [];
  if (!isStringEmpty(className)) classesCSS.push(className);

  // estilo de color del boton
  if (classCustomButton.isValidcolor(props.color)) {
    classesCSS.push(props.color);
  } else {
    classesCSS.push(classCustomButton.color.orangeS1);
  }
  if (transparent === true) classesCSS.push("transparent");
  else classesCSS.push("fill");

  // posicion del icono
  let positionIcon = classCustomButton.positionIcon.left;
  if (classCustomButton.isValidpositionIcon(props.icon?.position)) {
    positionIcon = props.icon.position;
  }

  return isStringEmpty(label) ? null : (
    <styles.MainContainer
      className={classesCSS.join(" ")}
      borderType={borderType}
      iconPosition={positionIcon}
      visibleBorde={visibleBorde}
      transparent={transparent}
      fontSize={fontSize}
      disabled={isDisabled}
      isLoading={isLoading}
      onClick={() => {
        if (onClick && !isLoading) onClick();
      }}
    >
      {isLoading ? (
        <Dots className="loading" />
      ) : (
        <>
          {renderIcon()}
          <label>{label}</label>
        </>
      )}
    </styles.MainContainer>
  );
};

export default CustomButton;
