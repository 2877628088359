import styled from "styled-components";
const MainContainer = styled.div`
  position: relative;
  margin-top: 15px;
  ${(props) => (props.height ? `height:${props.height}` : "")};
  margin-bottom: ${(props) => props.mb ?? "5px"};
  margin-right: ${(props) => props.mr ?? "0"};
  width: ${(props) => props.width ?? "100%"};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  &.blackS1 {
    background-color: #ffffff;
    color: #13131d;
    font-weight: 300 ;
    .label-error {
      color: #ff7676;
    }
  }
  &.grayS1 {
    background-color: #4e4d4d;
    color: lightgray;
    font-weight: 300 ;
    .label-error {
      color: #ff7676;
    }
  }
  &.whiteS1 {
    background-color: white;
    color: black;
    font-weight: 300 ;
    .label-error {
      color: #c10808;
    }
  }
`;

const Input = styled.input`
  width: 100%;
  height: 45px;
  padding: 5px 15px 0px 15px;
  font-weight: 500;
  border-radius: 7px;
  border: 1px solid #eaeaea;
  font-family: "Helvetica";
  box-sizing: border-box;
  background-color: #f8f8f8;
  color: black;
  font-size: ${(props) => props.fontSize ?? "14"}px;
  text-align: ${(props) => props.textAlign ?? "left"};
  transition: border 0.3s;
  :focus {
    outline: none !important;
    border: 1px solid #f9ad33;
  }
  :hover {
    outline: none !important;
    border: 1px solid #f9ad33;
  }
`;

const InputLabel = styled.div`
  top: -10px;
  left: 5px;
  z-index: 1;
  padding: 0 5px;
  border-radius: 7px;
  position: absolute;
  background-color: inherit;
  color: inherit;
`;

const ErrorLabel = styled.span`
  font-family: "Helvetica";
  font-size: 13px;
  font-weight: 400;
  padding: 0px 3px 0px 3px;
  white-space: pre-wrap;
  text-align: left;
`;
export default {
  Input,
  InputLabel,
  ErrorLabel,
  MainContainer,
};
