import styled from "styled-components";
import CustomButton from "../../custom-button";

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 11px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  transition: background 0.25s;
  //border: 1px solid lightgray;

  &:hover {
    background-color: #f0f0f0;
  }

  .checkbox {
    padding: 3px;
    &.hidden {
      visibility: hidden;
    }
    &.Mui-checked {
      color: #d58b16;
    }
    .MuiSvgIcon-root {
      font-size: 16px;
    }
  }

  .label {
    cursor: inherit;
    font-family: "Helvetica";
    font-size: 12px;
    text-align: left;
    color: #262626;
    line-height: 1.25;
    padding: ${({ checkbox }) => (checkbox === undefined ? "3" : "0")}px;
  }

  // para item sin checkbox seleccionado
  &.selectedItem {
    background-color: #8e8e8e;
    .label {
      font-family: "Helvetica";
      color: white;
    }
  }
`;

const ContainerButton = styled(CustomButton)`
  width: fit-content;
  border: none;
  border-radius: 0px;
  padding: 8px;
  gap: 11px;
  .icon {
    padding: 3px;
    color: #d58b16 !important;
    visibility: hidden;
    font-weight: bolder;
  }

  // para item sin checkbox seleccionado
  &.selectedItem {
    .icon {
      visibility: visible;
    }
  }
`;

export default {
  Container,
  ContainerButton,
};
