import styled from "styled-components";

const DivMain = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  //border: 1px solid red;

  .btnCheckbox {
    padding: 3px;
    box-sizing: border-box;
    transition: all 0.4s;
    color: #d9d9d9;
    * {
      box-sizing: border-box;
    }
    &:hover {
      background-color: transparent;
    }
    .MuiSvgIcon-root {
      width: 26px;
      height: 26px;
    }

    // DISABLED CHECKBOX
    &.Mui-disabled {
      color: #d4cbb9 !important;
    }

    // SELECTED CHECKBOX
    &.Mui-checked {
      color: #a0a0a0;
    }

    // HOVER CHECKBOX
    &:hover {
      // NORMAL
      color: #a0a0a0;
      // SELECTED
      &.Mui-checked {
        color: #7b7b7b;
      }
    }
  }
`;

export default {
  DivMain,
};
