export default class classCustomButton {
  // props recibidas
  static propsType = {
    className: "",
    color: "",
    transparent: false,
    borderType: "",
    isLoading: false,
    isDisabled: false,
    visibleBorde: false,
    size: 14,
    label: "",
    icon: {
      name: "",
      type: "", // consultar tipos en la clase customIcon
      size: 0,
      color: "", // consultar colores en la clase customIcon
      position: "", // consultar posiciones en esta clase
    },
    onClick: (event) => {},
  };
  // estilos de colores del boton
  static color = {
    orangeS1: "orangeS1",
    orangeS2: "orangeS2",
    whiteS1: "whiteS1",
    whiteS2: "whiteS2",
    greenS1: "greenS1",
    greenS2: "greenS2",
    redS1: "redS1",
    blackS1: "blackS1",
  };
  static isValidcolor(colorValue = "") {
    const values = Object.values(this.color);
    return values.includes(colorValue);
  }

  // tipos de bordes del boton
  static borderType = {
    rectangle: "rectangle",
    oval: "oval",
    circle: "circle",
  };

  // posiciones del icono
  static positionIcon = {
    left: "left",
    right: "right",
    top: "top",
    bottom: "bottom",
  };
  static isValidpositionIcon(positionValue = "") {
    const values = Object.values(this.positionIcon);
    return values.includes(positionValue);
  }
}
