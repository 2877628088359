export default class classVeryRating {
  static getPropsTemplate = () => ({
    CSS: [],
    sizeIcon: 20,
    isEditable: false,
    stepValue: 0,
    maxValue: 5,
    value: 0,
    onChangeValue: (newValue = 0) => {},
  });
}
