export default class classCustomInput {
  static styles = {
    blackS1: "blackS1",
    grayS1: "grayS1",
    whiteS1: "whiteS1",
  };
  static isValidStyle(styleValue = "") {
    const values = Object.values(this.styles);
    return values.includes(styleValue);
  }
}
