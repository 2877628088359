import {
  isArrayEmpty,
  isNumberPositive,
  isPositiveInt,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import { SnackbarProvider } from "notistack";
import { VeryNotificationsProvider } from "./useVeryNotifications";
import classVeryNotifications from "./classVeryNotifications";
import { CustomNotification } from "./CustomNotification";

export const VeryNotifications = (
  props = classVeryNotifications.getPropsTemplate()
) => {
  const maxNotis = isPositiveInt(props.maxNotis) ? Number(props.maxNotis) : 3;
  const durationNotis =
    props.fixedNotis === true
      ? null
      : isPositiveInt(props.duration)
      ? Number(props.duration)
      : 7000;
  return (
    <SnackbarProvider
      maxSnack={maxNotis}
      anchorOrigin={classVeryNotifications.getPosition(props.position)}
      autoHideDuration={durationNotis}
      preventDuplicate={props.disabledDuplicateNotis === true}
      Components={{
        success: CustomNotification,
        error: CustomNotification,
      }}
    >
      <VeryNotificationsProvider>{props.children}</VeryNotificationsProvider>
    </SnackbarProvider>
  );
};
