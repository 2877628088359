import styled from "styled-components";
import { VeryModal } from "../VeryModal";

const DivMain = styled(VeryModal)`
  .divBodyModal {
    .divSteps {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 8px 18px;
      margin-bottom: 26px;
      //border: 1px solid red;
      .btnStep {
        width: fit-content;
        gap: 6px;
        .btnIcon {
          border-radius: 100%;
          padding: 2px;
          color: #1c1b1f3e;
          background-color: #eaeaea;
        }
        .txtLabel {
          text-decoration: none;
          font-weight: 400;
        }
        .MuiTouchRipple-root {
          color: #1c1b1f62;
        }
        &.btnStepCompleted {
          .btnIcon {
            color: #16ca5e;
          }
        }
        &.btnStepSelected {
          .txtLabel {
            font-weight: 700;
          }
        }
      }
    }
  }
  .divButtons {
    .btnMain {
      max-width: 420px;
    }
  }
`;

export default {
  DivMain,
};
