import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import {
  decoJSON,
  encoJSON,
  inPage,
  isObject,
  isStringEmpty,
} from "../utils/handler_validations";
import { getImageURL } from "../utils/handler_files";
import { useMatch, useNavigate } from "react-router-dom";
import { sessionDB, adminDB } from "../services";
import utilsSession from "../utils/handler_session";
import { useVeryNotificationsContext } from "../components/common/v2/VeryNotifications/useVeryNotifications";

const getValueTemplate = () => ({
  user: utilsSession.getDataUserTemplate(),
  jwt: "",
  showModalLogOut: false,
});

const getRespCreateTemplate = (existError = true) => ({
  error: existError === true ? { message: "", details: "" } : undefined,
  newPassRequired: false,
});

const getRespUpdateTemplate = (existError = true) => ({
  error: existError === true ? { message: "", details: "" } : undefined,
});

export const useSession = () => {
  const [isUserAuth, setIsUserAuth] = useState(undefined);
  const [waitTimerSplashScreen, setWaitTimerSplashScreen] = useState(true);
  const [value, setValue] = useState(getValueTemplate());
  const navigate = useNavigate();
  const loginPage = inPage(useMatch("/login"));
  const notis = useVeryNotificationsContext();

  useEffect(() => {
    init();
  }, []);

  // verificar si el usuario tiene sesion iniciada
  const init = async () => {
    setTimeout(() => setWaitTimerSplashScreen(false), 2500);
    let isAuth = false;
    try {
      await Auth.currentAuthenticatedUser();
      isAuth = true;
    } catch (err) {}
    const user = utilsSession.getDataUser(
      decoJSON(sessionStorage.getItem("user"))
    );
    const jwt = sessionStorage.getItem("jwt");
    // sesion iniciada ?
    if (isAuth && !isStringEmpty(jwt)) {
      setValue({
        user,
        jwt,
        showModalLogOut: false,
      });
      setIsUserAuth(true);
      if (loginPage) navigate("/viajes");
    } else {
      setIsUserAuth(false);
      if (!loginPage) navigate("/login");
    }
  };

  // crear session (loguear en AWS y traer la data del user del backend)
  const create = async (email = "", password = "") => {
    const respCreate = getRespCreateTemplate(false);
    // singIn en AWS
    const respAWS = await sessionDB.signInAWS(email, password);
    // exist error ?
    if (respAWS.existError) {
      respCreate.error = {
        message: "Datos incorrectos",
        details: respAWS.messageError,
      };
      return respCreate;
    }
    // success
    // inicial password required ?
    if (respAWS.newPassRequired) {
      setValue((prev) => ({
        ...prev,
        jwt: respAWS.jwt,
      }));
      respCreate.newPassRequired = true;
      return respCreate;
    }
    // login normal
    const respDB = await adminDB.getUser(respAWS.jwt);
    if (respDB.error) {
      sessionStorage.clear();
      respCreate.error = {
        message: respDB.error.message,
        details: respDB.error.details,
      };
      return respCreate;
    }
    const user = isObject(respDB.body.user) ? respDB.body.user : {};
    const userData = utilsSession.getDataUser(user);
    sessionStorage.setItem("user", encoJSON(userData));
    sessionStorage.setItem("jwt", respAWS.jwt);
    setValue((prev) => ({
      ...prev,
      user: userData,
      jwt: respAWS.jwt,
    }));
    return respCreate;
  };

  // actualizar datos del user
  const update = async (
    name = "",
    lastName = "",
    tel = "",
    profileImg = { fullBase64: "", ext: "", remove: false }
  ) => {
    const respDB = await adminDB.updateUser(
      name,
      lastName,
      tel,
      profileImg,
      value.jwt
    );
    // error
    if (respDB.error) {
      notis.createNotiError(respDB.error.message, respDB.error.details);
      return;
    }
    // success
    notis.createNotiSuccess(
      "Tu perfil ha sido actualizado",
      respDB.messageSuccess
    );
    const user = isObject(respDB.body.user) ? respDB.body.user : {};
    const userData = utilsSession.getDataUser(user);
    sessionStorage.setItem("user", encoJSON(userData));
    setValue((prev) => ({
      ...prev,
      user: userData,
    }));
  };

  // cerrar session (desloguear y limpiar data)
  const close = async () => {
    sessionStorage.clear();
    setValue(getValueTemplate());
    setIsUserAuth(false);
    navigate("/login", { replace: true });
  };

  /*===============================
  OBTENCION DE LOS DATOS DEL USUARIO
  =================================*/
  const getEmail = (useDefault = false) => {
    if (!isStringEmpty(value.user.email)) return value.user.email;
    return useDefault === true ? "No especificado" : "";
  };
  const getName = (useDefault = false) => {
    if (!isStringEmpty(value.user.name)) return value.user.name;
    return useDefault === true ? "Desconocido" : "";
  };
  const getFirstName = () => {
    const partsName = value.user.name.split(" ");
    for (const part of partsName) {
      if (!isStringEmpty(part)) return part.trim();
    }
    return "";
  };
  const getLastName = (useDefault = false) => {
    if (!isStringEmpty(value.user.lastName)) return value.user.lastName;
    return useDefault === true ? "Desconocido" : "";
  };
  const getFirstLastName = () => {
    const partsLastName = value.user.lastName.split(" ");
    for (const part of partsLastName) {
      if (!isStringEmpty(part)) return part.trim();
    }
    return "";
  };
  const getShortName = () => {
    const name = [];
    const fName = getFirstName();
    const fLastName = getFirstLastName();
    if (!isStringEmpty(fName)) name.push(fName);
    if (!isStringEmpty(fLastName)) name.push(fLastName);
    return name.join(" ");
  };
  const getProfileImgURL = (width = 0, height = 0) => {
    const url = getImageURL(
      process.env.REACT_APP_BUCKET,
      value.user.profileImg,
      width,
      height
    );
    return url;
  };

  const setShowModalLogOut = (isVisible = false) => {
    setValue((prev) => ({
      ...prev,
      showModalLogOut: isVisible === true,
    }));
  };

  return {
    isUserAuth,
    waitTimerSplashScreen,
    value,

    getName,
    getFirstName,
    getLastName,
    getFirstLastName,
    getShortName,
    getEmail,
    getProfileImgURL,

    setShowModalLogOut,
    close,
    create,
    update,
  };
};
