import { useEffect, useState } from "react";
import {
  isArrayEmpty,
  isObject,
  isPositiveInt,
  isStringEmpty,
  isValidNaturalNumber,
} from "../utils/handler_validations";
import { adminDB } from "../services";
import { useSessionContext } from "../context/session.context";
import { useModalAskContext } from "../context/modalAsk.context";
import { useVeryNotificationsContext } from "../components/common/v2/VeryNotifications/useVeryNotifications";
import { classVeryIconGoogle } from "../components/common/v2/VeryIconGoogle/classVeryIconGoogle";

const getLoadErrorTemplate = (initialLoading = false) => ({
  loading: initialLoading === true,
  error: { message: "", details: "" },
});

const getAdminTemplate = () => ({
  id: 0,
  fullName: "",
  tel: "",
  email: "",
  profileImg: "",
});

export const useAdmins = () => {
  const [textInputSearch, setTextInputSearch] = useState("");
  const [value, setValue] = useState({
    page: getLoadErrorTemplate(true),
    contentPage: getLoadErrorTemplate(false),
    list: [getAdminTemplate()], // lista con la data de los admins
  });
  const [actionDelete, setActionDelete] = useState({ adminId: -1 });
  const session = useSessionContext();
  const notis = useVeryNotificationsContext();
  const modalAsk = useModalAskContext();

  useEffect(() => {
    getListInitial();
  }, []);

  useEffect(() => {
    if (modalAsk.value.execute) {
      switch (modalAsk.value.action) {
        case "DELETE":
          execDeleteAdmin();
          break;
      }
    }
  }, [modalAsk.value.execute]);

  const getEmptyMsgPage = () => {
    const emptyObj = { text: "", icon: { name: "", type: "" } };
    if (value.list === undefined) {
      emptyObj.text = "No se encontraron administradores";
      emptyObj.icon.name = "content_paste";
      emptyObj.icon.type = classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED;
    } else if (isArrayEmpty(value.list)) {
      emptyObj.text = "Ningún administrador coincide con la búsqueda";
      emptyObj.icon.name = "content_paste_search";
      emptyObj.icon.type = classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED;
    }
    return emptyObj;
  };

  /**
   * Obtener la lista de admins formateada para ser manipulada por la tabla
   */
  const getFormattedList = (adminsList = [], initial = false) => {
    let list = isArrayEmpty(adminsList)
      ? initial
        ? undefined
        : []
      : adminsList;
    return list?.map((row, index) => {
      return {
        id: index,
        admin: {
          id: row.id,
          fullName: row.fullName,
          tel: row.tel,
          email: row.email,
          profileImg: row.profileImg,
        },
      };
    });
  };

  const getListFiltered = async () => {
    setValue((prev) => ({
      ...prev,
      contentPage: getLoadErrorTemplate(true),
    }));
    const resp = await adminDB.getListAdmins(
      textInputSearch,
      session.value.jwt
    );
    // existe error ?
    if (resp.error) {
      setValue((prev) => ({
        ...prev,
        contentPage: {
          loading: false,
          error: { message: resp.error.message, details: resp.error.details },
        },
      }));
      return;
    }
    // success
    setValue((prev) => ({
      ...prev,
      contentPage: getLoadErrorTemplate(false),
      list: getFormattedList(resp.body.admins, false),
    }));
  };

  const getListInitial = async () => {
    setValue((prev) => ({
      ...prev,
      page: getLoadErrorTemplate(true),
    }));
    const resp = await adminDB.getListAdmins("", session.value.jwt);
    // existe error ?
    if (resp.error) {
      setValue((prev) => ({
        ...prev,
        page: {
          loading: false,
          error: { message: resp.error.message, details: resp.error.details },
        },
      }));
      return;
    }
    // success
    setValue((prev) => ({
      ...prev,
      page: getLoadErrorTemplate(false),
      list: getFormattedList(resp.body.admins, true),
    }));
  };

  const setActionDeleteAdmin = (adminId = -1, fullName = "") => {
    const formattedFullName = isStringEmpty(fullName)
      ? "Nombre desconocido"
      : fullName;
    setActionDelete({ adminId });
    modalAsk.show(
      "DELETE",
      "Eliminar administrador",
      "Se eliminará de forma permanente al administrador " +
        `"${formattedFullName}".\n\nFavor de confirmar`
    );
  };

  const onChangeTextInputSearch = (newText = "") => {
    setTextInputSearch(newText);
  };

  const execDeleteAdmin = async () => {
    const resp = await adminDB.deleteUser(
      actionDelete.adminId,
      session.value.jwt
    );
    // cuando hubo error
    if (resp.error) {
      notis.createNotiError(resp.error.message, resp.error.details);
      modalAsk.clearExecute();
      return;
    }
    // success
    notis.createNotiSuccess("Administrador eliminado", resp.messageSuccess);
    modalAsk.close();
    const newList = value.list.filter((row) => {
      return row.admin.id == actionDelete.adminId ? false : true;
    });
    setValue((prev) => ({
      ...prev,
      list: newList,
    }));
  };

  const isCurrentAdmin = (adminId = 0) => {
    return (
      isPositiveInt(session.value.user.id) && session.value.user.id == adminId
    );
  };

  return {
    value,
    textInputSearch,
    getListFiltered,
    getListInitial,
    getEmptyMsgPage,

    onChangeTextInputSearch,
    setActionDeleteAdmin,

    isCurrentAdmin,
  };
};
