import {
  fetchDELETE,
  fetchGET,
  fetchPOST,
  fetchPUT,
} from "../utils/handler_http";
import { encoJSON, encoURI, encoUriJson } from "../utils/handler_validations";
import templatesAdmin from "../hooks/form-admin/templates";
import { getFileSendDB } from "../utils/handler_files";

/**
 * Obtener la data del user logueado
 */
export const getUser = async (jwt = "") => {
  const resp = await fetchGET(
    process.env.REACT_APP_ADMIN_ENDPOINT,
    {},
    { Authorization: jwt }
  );
  return resp;
};

/**
 * Crear un registro de nuevo usuario administrador
 */
export const createUser = async (data = templatesAdmin.getData(), jwt = "") => {
  const img = getFileSendDB(data.profileImg, undefined);
  const paramsBody = {
    name: encoURI(data.name),
    lastName: encoURI(data.lastName),
    tel: encoURI(data.tel),
    email: encoURI(data.email),
    img: encoUriJson(img),
  };
  const response = await fetchPOST(
    process.env.REACT_APP_ADMIN_ENDPOINT,
    paramsBody,
    { Authorization: jwt }
  );
  return response;
};

/**
 * Actualizar la data del user logueado
 */
export const updateUser = async (
  name = "",
  lastName = "",
  tel = "",
  profileImg = { fullBase64: "", ext: "", remove: false },
  jwt = ""
) => {
  const paramsBody = {
    name: encoURI(name),
    lastName: encoURI(lastName),
    tel: encoURI(tel),
    img: encoUriJson(profileImg),
  };
  const response = await fetchPUT(
    process.env.REACT_APP_ADMIN_ENDPOINT,
    paramsBody,
    { Authorization: jwt }
  );
  return response;
};

/**
 * Eliminar admin seleccionado
 */
export const deleteUser = async (adminId = 0, jwt = "") => {
  const paramsBody = {
    adminId: encoJSON(adminId),
  };
  const response = await fetchDELETE(
    process.env.REACT_APP_ADMIN_ENDPOINT,
    paramsBody,
    { Authorization: jwt }
  );
  return response;
};

/**
 * Obtener la lista de admins filtrada por nombre, apellido o tel
 */
export const getListAdmins = async (filterValue = "", jwt = "") => {
  const paramsQuery = {
    filterValue: encoURI(filterValue),
  };
  const response = await fetchGET(
    process.env.REACT_APP_ADMINS_LIST_ENDPOINT,
    paramsQuery,
    { Authorization: jwt }
  );
  return response;
};
