import { LinearProgress } from "@mui/material";
import styled from "styled-components";

const divMain = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #13131d;
  .imgLogo {
    height: 35%;
    object-fit: contain;
    margin-bottom: 27px;
    //border: 1px solid red;
  }
  @keyframes labelAnimation {
  0% {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: #cecece;
  }
  50% {
    text-shadow: 0 0 20px #ff9500;
    color: #ffffff;
  }
  100% {
    text-shadow: 0 0 10px rgb(249, 199, 0);
    color: #bababa;
  }
}

.txtLoading {
  animation: labelAnimation 3s ease-in-out infinite;
  font-family: "Roboto", Helvetica, sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: white;
}
`;


const linearProgress = styled(LinearProgress)`
  &.MuiLinearProgress-root {
    width: 75%;
    max-width: 600px;
    height: 14px;
    background-color: #ebd7b5;
    margin-top: 50px;
    margin-bottom: 12px;
    border-radius: 20px;
    // background animado
    > * {
      background-color: #f7af36;
    }
  }
`;

export default {
  divMain,
  linearProgress,
};
