import styled from "styled-components";
import { VeryModal } from "../../common/v2/VeryModal";

const Modal = styled(VeryModal)`
  .divContentModal {
    width: 500px;
  }
  .divBodyModal {
    align-items: flex-start !important;
    gap: 15px;
    padding: 0px 3px 3px 3px;
    .div-actionsCode {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 7px;
      .btnAction {
        padding: 1px;
      }
    }
  }

  .divButtons .btnModal {
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 10px;
  }
`;

export default {
  Modal,
};
