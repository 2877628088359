import {
  getFormattedNumber,
  getNotNegativeNumber,
  getString,
  isStringEmpty,
} from "../../../utils/handler_validations";
import styles from "./styles";
import { VeryIconGoogle } from "../../common/v2/VeryIconGoogle";
import { useMemo } from "react";

const getpropsTemplate = () => ({
  title: "",
  quantity: 0,
  icon: { name: "", type: "", color: "", size: 10 },
});

export const ItemReport = (props = getpropsTemplate()) => {
  const clasesCSS = ["containerContent"];
  const quantity = useMemo(() => {
    return getFormattedNumber(getNotNegativeNumber(props.quantity), true, 0);
  }, [props.quantity]);

  if (!isStringEmpty(props.className)) clasesCSS.push(props.className);

  return (
    <styles.DivMain className={clasesCSS.join(" ")}>
      {!isStringEmpty(props.icon?.name) && (
        <VeryIconGoogle
          CSS={["icon"]}
          name={props.icon.name}
          type={props.icon.type}
          color={props.icon.color}
          size={props.icon.size}
        />
      )}
      <label className="txtTitle">{getString(props.title)}</label>
      <label className="txtQuantity">{quantity}</label>
    </styles.DivMain>
  );
};
