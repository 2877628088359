import styles from "./styles";
import { HeaderSearch } from "../../components/HeaderSearch";
import { isArrayEmpty, isStringEmpty } from "../../utils/handler_validations";
import { VeryBtnFill } from "../../components/common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../../components/common/v2/VeryBtnFill/classVeryBtnFill";
import { classVeryIconGoogle } from "../../components/common/v2/VeryIconGoogle/classVeryIconGoogle";
import { DivTablePage } from "../../components/common/DivTablePage";
import { useAdminsContext, useFormAdminContext } from "../../context";
import { VeryTable } from "../../components/common/v2/VeryTable";
import classVeryTable from "../../components/common/v2/VeryTable/classVeryTable";
import { ModalAsk } from "../../components/common/ModalAsk";

const ContentAdminsPage = () => {
  const admins = useAdminsContext();
  const formAdmin = useFormAdminContext();

  return (
    <>
      <styles.DivMain
        isLoading={admins.value.page.loading}
        error={admins.value.page.error}
        onClickBtnRetry={() => admins.getListInitial()}
      >
        {/* HEADER */}
        <HeaderSearch
          inputSearch={{
            placeHolder: "Buscar administrador",
            text: admins.textInputSearch,
            onChangeText: (newText) => admins.onChangeTextInputSearch(newText),
            onClick: () => admins.getListFiltered(),
          }}
        >
          <VeryBtnFill
            CSS={["btnAddAdmin"]}
            colorTheme={classVeryBtnFill.COLOR_THEME.BLACK_S1}
            icon={{
              name: "group_add",
              size: 18,
              type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
            }}
            onClick={() => formAdmin.setShowModal(true)}
          />
        </HeaderSearch>

        {/* CONTENT */}
        <DivTablePage
          isLoading={admins.value.contentPage.loading}
          error={admins.value.contentPage.error}
          emptyMessage={admins.getEmptyMsgPage()}
          onClickBtnRetry={() => admins.getListFiltered()}
        >
          {/* ADMINS TABLE */}
          <VeryTable
            CSS={["adminsTable"]}
            rows={admins.value.list}
            getRowId={(row) => row.id}
            disabledRowSelection={true}
            columns={{
              // column profile
              col_name: classVeryTable.getColumnTemplate(
                "Usuario",
                "Nombre del administrador",
                2,
                150,
                130,
                true,
                true,
                (params) => {
                  return isStringEmpty(params?.row?.admin?.fullName)
                    ? "Desconocido"
                    : params.row.admin.fullName;
                },
                (params) => {
                  return classVeryTable.renderColProfile(
                    params?.row?.admin?.profileImg,
                    params?.value
                  );
                }
              ),
              // column tel
              col_tel: classVeryTable.getColumnTemplate(
                "Teléfono",
                "Teléfono del administrador",
                0,
                120,
                100,
                true,
                true,
                (params) => {
                  return params.row?.admin?.tel;
                },
                (params) => {
                  return classVeryTable.renderColTel(params?.value, " ");
                }
              ),
              // column email
              col_email: classVeryTable.getColumnTemplate(
                "Email",
                "Correo del administrador",
                1,
                170,
                150,
                true,
                true,
                (params) => {
                  return params?.row?.admin?.email;
                },
                (params) => {
                  return classVeryTable.renderColEmail(params?.value);
                }
              ),
              // column menu
              col_menu: classVeryTable.getColumnTemplate(
                "",
                "",
                0,
                80,
                60,
                false,
                false,
                undefined,
                (params) => {
                  const items = [];
                  if (!admins.isCurrentAdmin(params?.row?.admin?.id)) {
                    items.push({
                      label: "Eliminar usuario",
                      loading: false,
                      onClick: () =>
                        admins.setActionDeleteAdmin(
                          params?.row?.admin?.id,
                          params?.row?.admin?.fullName
                        ),
                    });
                  }
                  return classVeryTable.renderColMenu(items);
                }
              ),
            }}
          />
        </DivTablePage>
      </styles.DivMain>

      {/* MODAL CONFIRM ACTION */}
      <ModalAsk />
    </>
  );
};

export default ContentAdminsPage;
