import momentJS from "moment";
import { getMomentES } from "./handler_validations";
import { sendError } from "./handler_error";
momentJS.locale("es");

export const momentES = momentJS;

/**
 * Evaluar si un timestamp backend es valido
 */
export const testDate = (timestamp) => {
  const date = getMomentES(timestamp);
  if (!date) return sendError("La fecha no fue especificada");
  if (!date.isValid()) return sendError("Fecha incorrecta");
  return sendError();
};

/**
 * Obtener el dia de la semana con formato ddd
 * example => Lun
 */
export const getDay_S1 = (date = momentES()) => {
  const day = date.format("ddd").replace(".", "");
  return day.charAt(0).toUpperCase() + day.substring(1).toLowerCase();
};

/**
 * Obtener el mes de una fecha con formato MMM
 * example => Ene
 */
const getMonth_S1 = (date = momentES()) => {
  const month = date.format("MMM").replace(".", "");
  return month.charAt(0).toUpperCase() + month.substring(1).toLowerCase();
};

/**
 * Obtener el mes de una fecha con formato MMMM en minuscula
 * example => enero
 */
const getMonth_S2 = (date = momentES()) => {
  return date.format("MMMM").replace(".", "").toLowerCase();
};

/**
 * Obtener el tipo de hora con formato (minusculas + puntos)
 * example => a.m.
 */
export const getAmPm_S1 = (date = momentES()) => {
  const amPm = date.format("a");
  return `${amPm.charAt(0)}.${amPm.charAt(1)}.`;
};

/**
 * Obtener el tipo de hora con formato (mayusculas)
 * example => AM
 */
export const getAmPm_S2 = (date = momentES()) => {
  return date.format("A");
};

/**
 * Obtener una fecha completa con el formato
 * example => 23 Ene 2023 - 16:30 p.m.
 */
export const getDateFull_S1 = (date = momentES()) => {
  const day = date.format("DD");
  const yearTime = date.format("YYYY - hh:mm");
  return `${day} ${getMonth_S1(date)} ${yearTime} ${getAmPm_S1(date)}`;
};

/**
 * Obtener una fecha con el formato
 * example => 23 enero 2023
 * NOTA: por default el separador es un espacio en blanco
 */
export const getDate_S1 = (date = momentES(), separator = " ") => {
  const day = date.format("DD");
  const year = date.format("YYYY");
  return `${day}${separator}${getMonth_S2(date)}${separator}${year}`;
};

/**
 * Obtener una fecha con el formato
 * example => 23/10/2023
 */
export const getDate_S2 = (date = momentES()) => {
  const day = date.format("DD");
  const year = date.format("YYYY");
  const month = date.format("MM");
  return `${day}/${month}/${year}`;
};

/**
 * Obtener una fecha con el formato
 * example => 23 de enero del 2023
 */
export const getDate_S3 = (date = momentES()) => {
  if (["", null, undefined].includes(date)) return "Sin fecha";
  if (!date.isValid()) return "--/---/---";
  const day = date.format("DD");
  const year = date.format("YYYY");
  return `${day} de ${getMonth_S2(date)} del ${year}`;
};

/**
 * Obtener una hora con el formato
 * example => 04:30 PM
 */
export const getTime_S1 = (date = momentES()) => {
  return `${date.format("hh:mm")} ${getAmPm_S2(date)}`;
};
