import { useCouponsContext } from "../../../context";
import { isStringEmpty } from "../../../utils/handler_validations";
import { VeryBtnFill } from "../../common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../../common/v2/VeryBtnFill/classVeryBtnFill";
import { VeryBtnOutlined } from "../../common/v2/VeryBtnOutlined";
import { classVeryBtnOutlined } from "../../common/v2/VeryBtnOutlined/classVeryBtnOutlined";
import { VeryInput } from "../../common/v2/VeryInput";
import Styles from "./styles";

export const ModalPay = (props) => {
  const coupons = useCouponsContext();

  const renderItemData = (title = "", data = "") => {
    return (
      <div className="div-data">
        <label className="txt-title">{title}</label>
        <label className="txt-data">{data}</label>
      </div>
    );
  };

  return (
    <Styles.Modal
      isVisible={coupons.modalPay.show}
      title="Pagar cupón a taxista"
      onClickClose={() => coupons.hideModalPay()}
      buttons={[
        <VeryBtnOutlined
          CSS={["btnModal"]}
          colorTheme={classVeryBtnOutlined.COLOR_THEME.BLACK_S1}
          label="Regresar"
          textSize={15}
          isDisabled={coupons.modalPay.loading}
          onClick={() => coupons.hideModalPay()}
        />,
        <VeryBtnFill
          CSS={["btnModal"]}
          colorTheme={classVeryBtnFill.COLOR_THEME.BLACK_S1}
          label="Aceptar"
          textSize={15}
          isLoading={coupons.modalPay.loading}
          onClick={() => coupons.onClickBtnPay()}
        />,
      ]}
    >
      <label className="txt-message">
        Con esta acción confirmas que ya se cubrió el pago correspondiente al
        taxista.
      </label>
      <div className="div-details">
        {renderItemData("Cupón", coupons.modalPay.code)}
        {renderItemData("Pagar", `$${coupons.modalPay.quantity}`)}
        {!isStringEmpty(coupons.modalPay.driverName) &&
          renderItemData("Taxista", coupons.modalPay.driverName)}
      </div>
      <VeryInput
        CSS={["inputComment"]}
        isEditable
        multiline={4}
        size={{ text: 14, title: 15 }}
        title={"Nota"}
        placeHolder={"Agrega una nota al taxista"}
        text={coupons.inputCommentPay}
        onChangeText={(newText) => coupons.onChangeInputCommentPay(newText)}
        onEnter={() => coupons.onClickBtnPay()}
      />
    </Styles.Modal>
  );
};
