import styles from "./styles";
import { HeaderSearch } from "../../components/HeaderSearch";
import { useState, useEffect, useRef } from "react";
import {
  isStringEmpty,
  isValidNaturalNumber,
} from "../../utils/handler_validations";
import { DivTablePage } from "../../components/common/DivTablePage";
import { usePassengersContext } from "../../context";
import { VeryTable } from "../../components/common/v2/VeryTable";
import classVeryTable from "../../components/common/v2/VeryTable/classVeryTable";
import { PanelProfile } from "../../components/list-passengers-page/PanelProfile";
import { ModalAsk } from "../../components/common/ModalAsk";

export const ContentListPassengersPage = () => {
  const passengers = usePassengersContext();
  const refDivMain = useRef();

  return (
    <>
      <styles.DivMain
        reff={refDivMain}
        isLoading={passengers.value.page.loading}
        error={passengers.value.page.error}
        onClickBtnRetry={() => passengers.getListInitial()}
      >
        {/* HEADER */}
        <HeaderSearch
          inputSearch={{
            placeHolder: "Buscar usuario",
            text: passengers.textInputSearch,
            onChangeText: (newText) => {
              passengers.onChangeTextInputSearch(newText);
            },
            onClick: () => passengers.getListFiltered(),
          }}
        />
        {/* CONTENT */}
        <DivTablePage
          isLoading={passengers.value.contentPage.loading}
          error={passengers.value.contentPage.error}
          emptyMessage={passengers.getEmptyMsgPage()}
          onClickBtnRetry={() => passengers.getListFiltered()}
        >
          {/* PASSENGERS TABLE */}
          <VeryTable
            CSS={["passengersTable"]}
            rows={passengers.value.list}
            getRowId={(row) => row.rowId}
            disabledRowSelection={false}
            rowSelected={passengers.rowSelected}
            onChangeRowSelected={(newRowSelected) => {
              passengers.onChangeRowSelected(newRowSelected);
            }}
            columns={{
              // column profile
              col_name: classVeryTable.getColumnTemplate(
                "Pasajero",
                "Nombre del pasajero",
                1,
                150,
                130,
                true,
                true,
                (params) => {
                  return isStringEmpty(params.row.user?.fullName)
                    ? "Desconocido"
                    : params.row.user.fullName;
                },
                (params) => {
                  return classVeryTable.renderColProfile(
                    params.row.user?.profileImg,
                    params.value
                  );
                }
              ),
              // column tel
              col_tel: classVeryTable.getColumnTemplate(
                "Teléfono",
                "Teléfono del pasajero",
                0,
                120,
                100,
                true,
                true,
                (params) => {
                  return params.row.user?.tel;
                },
                (params) => {
                  return classVeryTable.renderColTel(params.value, " ");
                }
              ),
              // column total trips
              col_trips: classVeryTable.getColumnTemplate(
                "Viajes",
                "Total de viajes realizados",
                0,
                100,
                80,
                true,
                true,
                (params) => {
                  return passengers.getTotalCompletedTrips(params.row.user?.id);
                },
                (params) => {
                  return classVeryTable.renderColNumber(params.value, 0);
                }
              ),
              // column active coupons
              col_active_coupons: classVeryTable.getColumnTemplate(
                "Cupones activos",
                "Total de cupones vigentes",
                0,
                160,
                160,
                true,
                true,
                (params) => {
                  return passengers.getTotalActiveCoupons(params.row.user?.id);
                },
                (params) => {
                  return classVeryTable.renderColNumber(params.value, 0);
                }
              ),
              // column implemented coupons
              col_implemented_coupons: classVeryTable.getColumnTemplate(
                "Cupones implementados",
                "Total de cupones utilizados",
                0,
                200,
                200,
                true,
                true,
                (params) => {
                  return passengers.getTotalUsedCoupons(params.row.user?.id);
                },
                (params) => {
                  return classVeryTable.renderColNumber(params.value, 0);
                }
              ),
              // column total spent
              col_total_spent: classVeryTable.getColumnTemplate(
                "Pagado",
                "Total que ha gastado en viajes",
                0,
                100,
                80,
                true,
                true,
                (params) => {
                  return passengers.getTotalPaidTrips(params.row.user?.id);
                },
                (params) => {
                  return classVeryTable.renderColMoney(params.value, 0);
                }
              ),
              // column rating
              col_rating: classVeryTable.getColumnTemplate(
                "Calificación",
                "Calificación del pasajero",
                0,
                120,
                100,
                true,
                true,
                (params) => {
                  return passengers.getRating(params.row.user?.id);
                },
                (params) => {
                  return classVeryTable.renderColRating(params.value);
                }
              ),
            }}
          />
        </DivTablePage>
        {/* PANEL PROFILE */}
        <PanelProfile refSlide={refDivMain} />
      </styles.DivMain>

      {/* MODAL CONFIRM ACTION */}
      <ModalAsk />
    </>
  );
};
