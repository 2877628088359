import styled from "styled-components";
import { DivContentPage } from "../../components/common/DivContentPage";

const DivMain = styled(DivContentPage)`
  //border: 4px solid red;
  .tripsTable {
    .cellCost {
      .cell-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        //border: 1px solid red;
      }
      .div-contentTooltip {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 3px;
        //border: 1px solid red;
        .txt-light {
          font-family: "Roboto", Helvetica, sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.2;
          color: #13131d;
          text-align: left;
        }
        .txt-bold {
          font-weight: 700;
        }
        .txt-amount {
          color: #f04465;
        }
      }
    }
    .cellStatus .txtStatus {
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.2;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border-radius: 25px;
      color: #ffffff;
      background-color: #cecece;
      padding: 5px 9px 4px 9px;
      &.COMPLETED {
        background-color: #46bec3;
      }
      &.WAITING {
        background-color: #fecd56;
      }
      &.CANCELED {
        background-color: #f04465;
      }
      &.IN_PROGRESS {
        background-color: #88c053;
      }
      &.REJECTED {
        background-color: #9479da;
      }
    }
    .cellDate .txt-fullDate {
      flex: 1;
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #13131d;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: inherit;
      //border: 1px solid red;
      .txt-date {
        font-family: inherit;
        font-weight: 700;
        font-size: inherit;
        color: inherit;
        cursor: inherit;
      }
    }
    .cellRoute {
      justify-content: center;
      //border: 1px solid orange;
    }
  }
`;

export default {
  DivMain,
};
