import styled from "styled-components";

const DivMain = styled.div`
  width: 100%;
  padding: 0px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  column-gap: 10px;
  row-gap: 12px;
  .inputSearch {
    flex-basis: 50%;
    flex-shrink: 1;
    min-width: 250px;
    //border: 1px solid red;
    .btnSearch {
      width: fit-content;
      padding: 2px;
      border-radius: 100%;
      //border: 1px solid red;
    }
    .btnClearSearch {
      width: fit-content;
      padding: 2px;
      border-radius: 100%;
      //border: 1px solid red;
    }
  }
`;

export default { DivMain };
