import { momentES } from "./handler_dates";

export const isValidNaturalNumber = (number) => {
  if ([undefined, null, ""].includes(number)) return false;
  if (!["string", "number"].includes(typeof number)) return false;
  try {
    const numberString = `${number}`;
    for (let i = 0; i < numberString.length; i++) {
      if ("0123456789".indexOf(numberString.charAt(i)) === -1) return false;
    }
    return true;
  } catch (err) {
    return false;
  }
};

export const isArrayEmpty = (array) => {
  if (Array.isArray(array) && array.length) return false;
  return true;
};

export const isStringEmpty = (cad) => {
  if (cad && typeof cad === "string" && cad.trim().length !== 0) return false;
  return true;
};

export const decoJSON = (value) => {
  try {
    return JSON.parse(value);
  } catch (err) {
    return value;
  }
};

export const encoJSON = (value) => {
  return JSON.stringify(value);
};

export const encoURI = (value) => {
  if (isStringEmpty(value)) return "";
  return encodeURIComponent(value);
};

export const encoUriJson = (value) => {
  return encoURI(encoJSON(value));
};

export const isObject = (obj) => {
  if (obj && typeof obj === "object" && !Array.isArray(obj)) return true;
  return false;
};

export const isPositiveInt = (num) => {
  const regex = /^\d+$/;
  return regex.test(num) && Number(num) != 0;
};

export const isNumberPositive = (num) => {
  const numFixed = Number(num);
  return !isNaN(numFixed) && numFixed > 0;
};

export const inPage = (match) => {
  return [undefined, null].includes(match) ? false : true;
};

export const testEmail = (email = "", isRequired = false) => {
  // resp template
  const resp = (messageError = "") => ({
    error: !isStringEmpty(messageError),
    message: messageError,
  });
  // dato requerido no existe
  if (isRequired === true && isStringEmpty(email)) {
    return resp("El correo electrónico no esta establecido");
  }
  // no analizar
  if (isRequired !== true && isStringEmpty(email)) {
    return resp();
  }
  // cuando tiene espacios en blanco
  if (/\s/.test(email)) {
    return resp("El correo electrónico no debe tener espacios");
  }
  // cuando es incorrecto en sintaxis
  if (!/.+@.+/.test(email)) {
    return resp("El correo electrónico es incorrecto");
  }
  return resp();
};

/**
 * Obtener un correo formateado
 */
export const getFormattedEmail = (email = "") => {
  if (isStringEmpty(email)) return "No establecido";
  if (testEmail(email).error) return "---@---";
  return email;
};

/**
 * Obtener un nombre completo de usuario formateado
 */
export const getFormattedFullName = (
  name = "",
  lastName = "",
  defaultName = "Desconocido"
) => {
  const fullName = [];
  if (!isStringEmpty(name)) fullName.push(name);
  if (!isStringEmpty(lastName)) fullName.push(lastName);
  return fullName.length ? fullName.join(" ") : defaultName;
};

export const getFormattedNumber = (
  data = 0,
  withComas = true,
  decimals = 0
) => {
  if (withComas) {
    return getNotNegativeNumber(data).toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  }
  return getNotNegativeNumber(data).toFixed(decimals);
};

export const getNotNegativeNumber = (data = 0) => {
  return isNaN(Number(data)) || Number(data) < 0 ? 0 : Number(data);
};

export const getNumber = (data = 0) => {
  return isNaN(Number(data)) ? 0 : Number(data);
};

export const getString = (data = "", defaultData = "") => {
  if (isStringEmpty(data)) return defaultData;
  const dataParts = data
    .trim()
    .split(" ")
    .filter((part) => !isStringEmpty(part));
  return dataParts.length ? dataParts.join(" ") : defaultData;
};

export const getMomentES = (timestamp) => {
  if (["", null, undefined].includes(timestamp)) return undefined;
  return momentES(timestamp);
};

export const getObject = (data = {}) => {
  return isObject(data) ? data : {};
};

export const getArray = (data = []) => {
  return isArrayEmpty(data) ? [] : data;
};

/**
 * Concatena 2 o mas string separados por un espacio
 */
export const concatStrings = (strings = [], defaultStr = "") => {
  let result = "";
  strings.forEach((str) => {
    const newStr = getString(str);
    if (newStr !== "") result += " " + newStr;
  });
  result = result.trim();
  return result === "" ? defaultStr : result;
};
