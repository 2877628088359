import styles from "./styles";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import {
  isStringEmpty,
  isValidNaturalNumber,
} from "../../../utils/handler_validations";
import { classIconButton } from "./utils/classIconButton";

const IconButton = (props = classIconButton.propsType) => {
  const {
    className, // (string)
    size, // (number) -> tamaño del icono en px
    color, // (string)  -> consultar colores en la clase
    iconName, // (string)	-> consultar nombres en google icons
    iconType, // (string) -> consultar tipos de iconos en la clase
    transparent, // (boolean)
    borderType, // (string) -> consultar tipos de bordes en la clase
    isLoading, // (boolean)
    isDisabled, // (boolean)
    visibleBorde, // (boolean)
    spin, // (boolean) -> true genera una animacion circular infinita
    onClick, // (event) => {}
  } = props;

  const iconSize = isValidNaturalNumber(size) ? size : 18;

  // clases del icon
  const classesIconCSS = [];
  const icon_types = Object.values(classIconButton.iconType);
  classesIconCSS.push(
    !icon_types.includes(iconType) ? classIconButton.iconType.filled : iconType
  );
  // poner animacion circular ??
  if (spin === true) classesIconCSS.push("iconSpin");

  // clases del button
  const classesCSS = [];
  if (!isStringEmpty(className)) classesCSS.push(className);
  const icon_colors = Object.values(classIconButton.color);
  if (!icon_colors.includes(color)) {
    classesCSS.push(classIconButton.color.whiteS1);
  } else {
    classesCSS.push(color);
  }
  if (transparent === true) classesCSS.push("transparent");
  else classesCSS.push("fill");

  return isStringEmpty(iconName) ? null : (
    <styles.MainContainer
      className={classesCSS.join(" ")}
      transparent={transparent}
      borderType={borderType}
      visibleBorde={visibleBorde}
      icon={iconName}
      iconSize={iconSize}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={() => {
        if (onClick && !isLoading) onClick();
      }}
    >
      {isLoading ? (
        <Dots className="component-loading" />
      ) : (
        <span className={classesIconCSS.join(" ")}>{iconName}</span>
      )}
    </styles.MainContainer>
  );
};

export default IconButton;
