import axios from "axios";
import { decoJSON, isObject } from "./handler_validations";

/**
 * Obtener una URL con Query strings parameters
 */
export const getURL = (endpoint = "", paramsQuery = {}) => {
  let url = endpoint;
  const keys = Object.keys(paramsQuery);
  keys.forEach((key, index) => {
    if (index === 0) url += `?${key}=${paramsQuery[key]}`;
    else url += `&${key}=${paramsQuery[key]}`;
  });
  return url;
};

export const normalFetchGET = async (
  endpoint = "",
  paramsQuery = {},
  paramsHeaders = {}
) => {
  const resp = {};
  try {
    const respHTTP = await axios.get(getURL(endpoint, paramsQuery), {
      headers: paramsHeaders,
    });
    // request error
    if (respHTTP.status !== 200) {
      resp.error = {
        message: "No fue posible llevar a cabo la operación",
        details: respHTTP.statusText,
      };
      return resp;
    }
    // success
    resp.body = decoJSON(respHTTP.data);
    return resp;
  } catch (err) {
    resp.error = {
      message: "Error al obtener los datos",
      details: err.message,
    };
    return resp;
  }
};

export const fetchGET = async (
  endpoint = "",
  paramsQuery = {},
  paramsHeaders = {}
) => {
  const response = {};
  try {
    const respHTTP = await axios.get(getURL(endpoint, paramsQuery), {
      headers: paramsHeaders,
    });
    // cuando existe un error en la peticion realizada
    if (respHTTP.status !== 200) {
      response.error = {
        message: "No fue posible llevar a cabo la operación",
        details: respHTTP.statusText,
      };
      return response;
    }
    // verificar si existe error desde el backend
    if (respHTTP.data?.statusCode !== 200) {
      const errorBackend = decoJSON(respHTTP.data?.error); // decodificar error
      response.error = {
        message: errorBackend?.message ?? "",
        details: errorBackend?.details ?? "",
      };
      return response;
    }
    // success
    const bodyBackend = decoJSON(respHTTP.data?.body);
    if (isObject(bodyBackend)) response.body = bodyBackend;
    else response.body = {};
    response.messageSuccess = respHTTP.data?.messageSuccess ?? "";
    return response;
  } catch (err) {
    response.error = {
      message: "Error al obtener los datos",
      details: err.message,
    };
    return response;
  }
};

export const fetchPOST = async (
  endpoint = "",
  paramsBody = {},
  paramsHeaders = {}
) => {
  const response = {};
  try {
    // hacer peticion
    const respHTTP = await axios.post(endpoint, paramsBody, {
      headers: paramsHeaders,
    });
    // cuando existe un error en la peticion realizada
    if (respHTTP.status !== 200) {
      response.error = {
        message: "No fue posible llevar a cabo la operación",
        details: respHTTP.statusText,
      };
      return response;
    }
    // verificar si existe error desde el backend
    if (respHTTP.data?.statusCode !== 200) {
      const errorBackend = decoJSON(respHTTP.data?.error); // decodificar error
      response.error = {
        message: errorBackend?.message ?? "",
        details: errorBackend?.details ?? "",
      };
      return response;
    }
    // success
    const bodyBackend = decoJSON(respHTTP.data?.body);
    if (isObject(bodyBackend)) response.body = bodyBackend;
    else response.body = {};
    response.messageSuccess = respHTTP.data?.messageSuccess ?? "";
    return response;
  } catch (err) {
    response.error = {
      message: "Error al crear los datos",
      details: err.message,
    };
    return response;
  }
};

export const fetchPUT = async (
  endpoint = "",
  paramsBody = {},
  paramsHeaders = {}
) => {
  const response = {};
  try {
    // hacer peticion
    const respHTTP = await axios.put(endpoint, paramsBody, {
      headers: paramsHeaders,
    });
    // cuando existe un error en la peticion realizada
    if (respHTTP.status !== 200) {
      response.error = {
        message: "No fue posible llevar a cabo la operación",
        details: respHTTP.statusText,
      };
      return response;
    }
    // verificar si existe error desde el backend
    if (respHTTP.data?.statusCode !== 200) {
      const errorBackend = decoJSON(respHTTP.data?.error); // decodificar error
      response.error = {
        message: errorBackend?.message ?? "",
        details: errorBackend?.details ?? "",
      };
      return response;
    }
    // success
    const bodyBackend = decoJSON(respHTTP.data?.body);
    if (isObject(bodyBackend)) response.body = bodyBackend;
    else response.body = {};
    response.messageSuccess = respHTTP.data?.messageSuccess ?? "";
    return response;
  } catch (err) {
    response.error = {
      message: "Error al actualizar los datos",
      details: err.message,
    };
    return response;
  }
};

export const fetchDELETE = async (
  endpoint = "",
  paramsBody = {},
  paramsHeaders = {}
) => {
  const response = {};
  try {
    // hacer peticion
    const respHTTP = await axios.delete(endpoint, {
      data: paramsBody,
      headers: paramsHeaders,
    });
    // cuando existe un error en la peticion realizada
    if (respHTTP.status !== 200) {
      response.error = {
        message: "No fue posible llevar a cabo la operación",
        details: respHTTP.statusText,
      };
      return response;
    }
    // verificar si existe error desde el backend
    if (respHTTP.data?.statusCode !== 200) {
      const errorBackend = decoJSON(respHTTP.data?.error); // decodificar error
      response.error = {
        message: errorBackend?.message ?? "",
        details: errorBackend?.details ?? "",
      };
      return response;
    }
    // success
    const bodyBackend = decoJSON(respHTTP.data?.body);
    if (isObject(bodyBackend)) response.body = bodyBackend;
    else response.body = {};
    response.messageSuccess = respHTTP.data?.messageSuccess ?? "";
    return response;
  } catch (err) {
    response.error = {
      message: "Error al eliminar los datos",
      details: err.message,
    };
    return response;
  }
};
