import {
  isArrayEmpty,
  isObject,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import styles from "./styles";
import { VeryBtnFill } from "../VeryBtnFill";
import { classVeryBtnFill } from "../VeryBtnFill/classVeryBtnFill";
import { VeryBtnTransparent } from "../VeryBtnTransparent";
import { classVeryBtnTransparent } from "../VeryBtnTransparent/classVeryBtnTransparent";
import { classVeryIconGoogle } from "../VeryIconGoogle/classVeryIconGoogle";

const propsTemplate = () => ({
  CSS: [],
  isVisible: false,
  title: "",
  btnMain: { label: "", isLoading: false, onClick: () => {} },
  steps: [{ label: "", isCompleted: false }],
  stepSelected: 0,
  onClickBtnClose: () => {},
  onChangeStepSelected: (stepIndex = 0) => {},
});

const ModalFormSteps = (props = propsTemplate()) => {
  const customCSS = ["ModalFormSteps"];
  if (!isArrayEmpty(props.CSS)) customCSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) customCSS.push(props.className);
  return (
    <styles.DivMain
      CSS={customCSS}
      title={props.title}
      onClickClose={props.onClickBtnClose}
      isVisible={props.isVisible}
      buttons={[
        isObject(props.btnMain) && (
          <VeryBtnFill
            CSS={["btnMain"]}
            colorTheme={classVeryBtnFill.COLOR_THEME.BLACK_S1}
            isDisabled={
              isArrayEmpty(props.steps) ||
              props.steps.some((step) => {
                return step.isCompleted !== true;
              })
            }
            isLoading={props.btnMain.isLoading}
            label={props.btnMain.label}
            textSize={14}
            onClick={props.btnMain.onClick}
          />
        ),
      ]}
    >
      {/* STEPS */}
      {!isArrayEmpty(props.steps) && (
        <div className="divSteps">
          {props.steps.map((step, index) => {
            const classesCSS = ["btnStep"];
            const stepCompleted = step.isCompleted === true;
            if (stepCompleted) classesCSS.push("btnStepCompleted");
            if (props.stepSelected === index)
              classesCSS.push("btnStepSelected");
            return (
              <VeryBtnTransparent
                CSS={classesCSS}
                label={step.label}
                textSize={11}
                icon={{
                  name: stepCompleted ? "done" : "close",
                  type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED,
                  size: 12,
                }}
                onClick={() => {
                  props.onChangeStepSelected &&
                    props.onChangeStepSelected(index);
                }}
              />
            );
          })}
        </div>
      )}
      {/* PANEL SELECTED */}
      {props.children}
    </styles.DivMain>
  );
};

export default ModalFormSteps;
