import styled from "styled-components";

const MainContainer = styled.span`
  --iconSize: ${({ iconSize }) => iconSize}px;
  width: fit-content;
  min-width: min-content;
  padding: 0px;
  display: grid;
  place-items: center;
  font-size: var(--iconSize);
  cursor: default;

  /*------------------------------
	ANIMACION CIRCULAR INFINITA
	--------------------------------*/
  &.iconSpin {
    animation-duration: 1s;
    animation-name: iconSpin;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: linear;
  }
  @keyframes iconSpin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  /*------------------------------
	COLORES DE BOTON NO TRASPARENTE
	--------------------------------*/
  &.fill {
    transition: background 0.25s;
    &.greenS1 {
      background-color: #82bc83;
      color: #609461;
    }
    &.orangeS1 {
      background-color: #f9ad33;
      color: white;
    }
    &.whiteS1 {
      background-color: #FFFFFF;
      color: #c18626;
    }
    &.redS1 {
      background-color: red;
      color: white;
    }
    &.blackS1 {
      background-color: #1C1B1F;
      color: white;
    }
    &.grayS1 {
      background-color: gray;
      color: white;
    }
    &.yellowS1 {
      background-color: rgb(246, 158, 40);
      color: black;
    }
  }
  /*------------------------------
	COLORES DE BOTON TRASPARENTE
	--------------------------------*/
  &.transparent {
    background-color: transparent;
    transition: color 0.3s;
    &.greenS1 {
      color: #76d430;
    }
    &.orangeS1 {
      color: #f9ad33;
    }
    &.orangeS2 {
      color: #d28200;
    }
    &.whiteS1 {
      color: white;
    }
    &.redS1 {
      color: red;
    }
    &.blackS1 {
      color: #13131d;
    }
    &.grayS1 {
      color: gray;
    }
    &.yellowS1 {
      color: rgb(246, 158, 40);
    }
  }
`;

export default {
  MainContainer,
};
