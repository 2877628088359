import { Grow, Tooltip } from "@mui/material";
import Styles from "./styles";
import {
  isArrayEmpty,
  isStringEmpty,
} from "../../../../../utils/handler_validations";

const getPropsTemplate = () => ({
  divMainCSS: [""],
  divCellCSS: [""],
  contentCell: <></>,
  contentTooltip: <></>,
});

export const CellTooltip = (props = getPropsTemplate()) => {
  const divCellCSS = ["divCell"];
  if (!isArrayEmpty(props.divCellCSS)) {
    divCellCSS.push(props.divCellCSS.join(" "));
  }
  const divMainCSS = ["divTooltip"];
  if (!isArrayEmpty(props.divMainCSS)) {
    divMainCSS.push(props.divMainCSS.join(" "));
  }
  if (!isStringEmpty(props.className)) divMainCSS.push(props.className);

  return (
    <Styles.DivMain className={divMainCSS.join(" ")}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        placement="top"
        disableFocusListener
        disableTouchListener
        TransitionComponent={Grow}
        TransitionProps={{
          timeout: 500,
        }}
        title={props.contentTooltip}
      >
        <Styles.DivCell className={divCellCSS.join(" ")}>
          {props.contentCell}
        </Styles.DivCell>
      </Tooltip>
    </Styles.DivMain>
  );
};
