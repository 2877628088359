import { useMemo } from "react";
import { useCouponsContext } from "../../../context";
import { classVeryBtnOutlined } from "../../common/v2/VeryBtnOutlined/classVeryBtnOutlined";
import { VeryBtnTransparent } from "../../common/v2/VeryBtnTransparent";
import { VeryInput } from "../../common/v2/VeryInput";
import { VeryIconGoogle } from "../../common/v2/VeryIconGoogle";
import { classVeryIconGoogle } from "../../common/v2/VeryIconGoogle/classVeryIconGoogle";
import { isStringEmpty } from "../../../utils/handler_validations";
import { momentES } from "../../../utils/handler_dates";
import Styles from "./styles";
import { VeryBtnFill } from "../../common/v2/VeryBtnFill";
import CustomDatePicker from "../../common/custom-datePicker";

export const ModalCreate = (props) => {
  const coupons = useCouponsContext();
  const checkCodeJSX = useMemo(() => {
    return coupons.checkNewCode.loading ? (
      <VeryIconGoogle
        CSS={["iconCheckCode"]}
        size={20}
        name="data_saver_off"
        enabledSpin={true}
        type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED}
      />
    ) : (
      <VeryIconGoogle
        size={25}
        name="done"
        type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED}
        color={
          coupons.checkNewCode.isValid
            ? classVeryIconGoogle.ICON_COLOR.GREEN_S1
            : classVeryIconGoogle.ICON_COLOR.GRAY_S1
        }
      />
    );
  }, [coupons.checkNewCode]);

  return (
    <Styles.Modal
      CSS={["modalCreateCoupon"]}
      title="Agregar cupón"
      isVisible={coupons.modalCreate.show}
      onClickClose={(event) => coupons.hideModalCreate()}
      buttons={[
        <VeryBtnFill
          CSS={["btnModal"]}
          label="Agregar cupón"
          isLoading={coupons.modalCreate.loading}
          onClick={(event) => coupons.onClickBtnCreateCoupon()}
          colorTheme={classVeryBtnOutlined.COLOR_THEME.BLACK_S1}
        />,
      ]}
    >
      <VeryInput
        title="Código"
        isEditable
        placeHolder="Establece un código de 8 dígitos"
        text={coupons.newCode}
        textError={coupons.checkNewCode.inputError}
        onChangeText={(newCode) => coupons.onChangeNewCodeCoupon(newCode, true)}
        inRightComponent={
          <div className="div-actionsCode">
            <VeryBtnTransparent
              CSS={["btnAction"]}
              icon={{
                name: "autorenew",
                size: 20,
                spin: coupons.creatingNewCode,
              }}
              onClick={(event) => coupons.onClickGenerateNewCode()}
            />
            {checkCodeJSX}
          </div>
        }
      />
      <CustomDatePicker
        className="div-expirationDate"
        classNamePicker="pickerExpirationDate"
        title="Fecha de expiración"
        error={coupons.errorModalCreate.expirationDate}
        minDate={momentES().add(1, "days")}
        maxDate={null}
        selectedDate={coupons.modalCreate.expirationDate}
        onChange={(newDate) => coupons.onChangeExpDateNewCoupon(newDate)}
      />
      <VeryInput
        title="Monto de descuento"
        isEditable
        placeHolder="$ 0 MXN"
        text={coupons.modalCreate.amount}
        textError={coupons.errorModalCreate.amount}
        onChangeText={(newText) => coupons.onChangeAmountNewCoupon(newText)}
      />
    </Styles.Modal>
  );
};
