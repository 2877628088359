import styles from "./styles";
import logo from "../../assets/logos/very-yellow-icon-01.png";

const NotFound = (props) => {
  return (
    <styles.DivMain>
      <img src={logo} />
      <label>Lo sentimos, la URL que buscas no existe</label>
    </styles.DivMain>
  );
};

export default NotFound;
