import styles from "./styles";
import {
  isArrayEmpty,
  isObject,
  isStringEmpty,
} from "../../utils/handler_validations";
import { VeryBtnFill } from "../common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../common/v2/VeryBtnFill/classVeryBtnFill";
import { classVeryIconGoogle } from "../common/v2/VeryIconGoogle/classVeryIconGoogle";
import { VeryInput } from "../common/v2/VeryInput";
import { VeryBtnTransparent } from "../common/v2/VeryBtnTransparent";
import { classVeryBtnTransparent } from "../common/v2/VeryBtnTransparent/classVeryBtnTransparent";
import { classVeryInput } from "../common/v2/VeryInput/classVeryInput";
import { useRef } from "react";
import { useState } from "react";

const propsType = {
  CSS: [],
  inputSearch: {
    text: "",
    placeHolder: "",
    onChangeText: (newText = "") => {},
    onClick: () => {},
  },
};
export const HeaderSearch = (props = propsType) => {
  const [refInput, setRefInput] = useState(useRef());
  const customCSS = [];
  if (!isStringEmpty(props.className)) customCSS.push(props.className);
  if (!isArrayEmpty(props.CSS)) customCSS.push(props.CSS);

  return (
    <styles.DivMain className={customCSS.join(" ")}>
      {/* INPUT SEARCH */}
      {isObject(props.inputSearch) && (
        <VeryInput
          onChangeRefInput={(ref) => setRefInput(ref)}
          CSS={["inputSearch"]}
          size={{ text: 15 }}
          isEditable
          placeHolder={props.inputSearch.placeHolder}
          text={props.inputSearch.text}
          type={classVeryInput.TYPE_INPUT.NORMAL}
          inLeftComponent={
            <VeryBtnTransparent
              CSS={["btnSearch"]}
              colorTheme={classVeryBtnTransparent.COLOR_THEME.GRAY_S1}
              icon={{
                name: "search",
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
                size: 20,
              }}
              onClick={() => {
                if (refInput.current) refInput.current.focus();
                props.inputSearch.onClick && props.inputSearch.onClick();
              }}
            />
          }
          inRightComponent={
            <VeryBtnTransparent
              CSS={["btnClearSearch"]}
              colorTheme={classVeryBtnTransparent.COLOR_THEME.BLACK_S2}
              icon={{
                name: "close",
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED,
                size: 20,
              }}
              onClick={() => {
                if (refInput.current) refInput.current.focus();
                if (props.inputSearch.onChangeText) {
                  props.inputSearch.onChangeText("");
                }
              }}
            />
          }
          onChangeText={(newText) => {
            if (props.inputSearch.onChangeText) {
              props.inputSearch.onChangeText(newText);
            }
          }}
          onEnter={props.inputSearch.onClick}
        />
      )}
      {/* COMPONENTES EXTRAS DEL HEADER */}
      {props.children}
    </styles.DivMain>
  );
};
