import styled from "styled-components";

const DivMain = styled.div`
  //border-radius: 25px;
  //border: 1px solid red;
  .divSelect {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .txt-title {
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 700;
      font-size: 12px;
      text-align: left;
      margin-bottom: 5px;
    }
    .btnSelect.MuiButtonBase-root {
      width: 100%;
      min-width: min-content;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      padding: 7px 10px 6px 12px;
      box-shadow: unset;
      border-radius: 10px;
      background-color: transparent;
      border: 1px solid #eaeaea;
      transition: all 0.4s;
      cursor: pointer;
      label {
        flex: 1;
        font-family: "Roboto", Helvetica, sans-serif;
        font-weight: 400;
        font-size: 13px;
        color: #13131d;
        line-height: 1.2;
        text-align: left;
        cursor: inherit;
        transition: color 0.4s;
      }
      .MuiTouchRipple-root {
        color: #13131d78;
      }
      &:hover {
        border-color: #a0a0a0;
        label {
          color: #797878;
        }
      }
    }
    .txt-error {
      margin-top: 6px;
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 400;
      font-size: 12px;
      color: rgb(167, 35, 35);
      text-align: left;
    }
  }

  .MuiTooltip-tooltipPlacementBottom {
    margin-top: 0px !important;
  }
  .MuiTooltip-tooltip {
    max-height: 165px;
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 7px 0px;
    overflow: auto;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.194);
    //border: 1px solid blue;
    .btnYear.MuiButtonBase-root {
      padding: 7px 30px;
      border-radius: 0px;
      &.yearSelected {
        cursor: default;
        pointer-events: none;
        background-color: #c7c7c7;
        &:hover {
          background-color: #c7c7c7;
        }
      }
    }
  }
`;

export default {
  DivMain,
};
