import { VeryProfileImg } from "../VeryProfileImg";
import { VerySwitch } from "../VerySwitch";
import { VeryCheckbox } from "../VeryCheckbox";
import { VeryRating } from "../VeryRating";
import { getFormattedTel } from "../../../../utils/handler_tel";
import {
  getFormattedEmail,
  getFormattedNumber,
  getNotNegativeNumber,
  isArrayEmpty,
} from "../../../../utils/handler_validations";
import {
  getDate_S1,
  getTime_S1,
  momentES,
} from "../../../../utils/handler_dates";
import { MenuActions } from "./Menu";
import { CellTooltip } from "./CellTooltip";
import StylesCellTooltip from "./CellTooltip/styles";
import { getImageURL } from "../../../../utils/handler_files";

export default class classVeryTable {
  static getPropsTemplate = () => ({
    CSS: [],
    columns: { columnName: this.getColumnTemplate() },
    rows: [{}],
    getRowId: (row = {}) => -1,
    disabledRowSelection: false,
    rowSelected: -1,
    onChangeRowSelected: (newRowSelected = -1) => {},
  });

  static getPropsMenuActions = () => ({
    items: [{ label: "", loading: false, onClick: () => {} }],
  });

  /**
   * DATOS NECESARIOS PARA ESPECIFICAR UNA COLUMNA
   */
  static getColumnTemplate = (
    title = "",
    description = "",
    flex = 1,
    width = 120,
    minWidth = 100,
    isClickable = true,
    isSortable = true,
    getValue = (params) => undefined,
    renderCell = (params) => <></>
  ) => ({
    title,
    description,
    flex,
    width,
    minWidth,
    isClickable,
    isSortable,
    getValue,
    renderCell,
  });

  /**
   * CELDA PARA REPRESENTAR LA IMAGEN Y EL NOMBRE DE UN USUARIO
   */
  static renderColProfile = (keyImg = "", fullName = "") => {
    return (
      <CellTooltip
        divCellCSS={["fullName"]}
        contentCell={
          <>
            <VeryProfileImg
              src={getImageURL(process.env.REACT_APP_BUCKET, keyImg, 40, 40)}
              sizeImg={30}
              sizeIcon={22}
            />
            <StylesCellTooltip.LabelCell>
              {fullName}
            </StylesCellTooltip.LabelCell>
          </>
        }
        contentTooltip={
          <StylesCellTooltip.LabelTooltip>
            {fullName}
          </StylesCellTooltip.LabelTooltip>
        }
      />
    );
  };

  /**
   * CELDA PARA REPRESENTAR UN NUMERO DE TELEFONO
   */
  static renderColTel = (tel = "", separator = "") => {
    const formattedTel = getFormattedTel(tel, separator);
    return (
      <CellTooltip
        divCellCSS={["tel"]}
        contentCell={
          <StylesCellTooltip.LabelCell>
            {formattedTel}
          </StylesCellTooltip.LabelCell>
        }
        contentTooltip={
          <StylesCellTooltip.LabelTooltip>
            {formattedTel}
          </StylesCellTooltip.LabelTooltip>
        }
      />
    );
  };

  /**
   * CELDA PARA REPRESENTAR UN CORREO ELECTRONICO
   */
  static renderColEmail = (email = "") => {
    const formattedEmail = getFormattedEmail(email);
    return (
      <CellTooltip
        divCellCSS={["email"]}
        contentCell={
          <StylesCellTooltip.LabelCell>
            {formattedEmail}
          </StylesCellTooltip.LabelCell>
        }
        contentTooltip={
          <StylesCellTooltip.LabelTooltip>
            {formattedEmail}
          </StylesCellTooltip.LabelTooltip>
        }
      />
    );
  };

  /**
   * CELDA PARA REPRESENTAR UNA CALIFICACION CON ESTRELLAS
   */
  static renderColRating = (rating = 0) => {
    const valueRating = getNotNegativeNumber(rating);
    const formattedRating = valueRating.toFixed(1);
    return (
      <CellTooltip
        divCellCSS={["rating"]}
        contentCell={
          <>
            <VeryRating
              isEditable={false}
              maxValue={5}
              value={valueRating}
              stepValue={0.01}
              sizeIcon={13}
            />
            <StylesCellTooltip.LabelCell>
              {formattedRating}
            </StylesCellTooltip.LabelCell>
          </>
        }
        contentTooltip={
          <StylesCellTooltip.LabelTooltip>
            {formattedRating} estrellas
          </StylesCellTooltip.LabelTooltip>
        }
      />
    );
  };

  /**
   * CELDA PARA REPRESENTAR DINERO
   */
  static renderColMoney = (money = 0, decimals = 2) => {
    const formattedMoney = `$${getFormattedNumber(money, true, 2)}`;
    return (
      <CellTooltip
        divCellCSS={["money"]}
        contentCell={
          <StylesCellTooltip.LabelCell>
            {formattedMoney}
          </StylesCellTooltip.LabelCell>
        }
        contentTooltip={
          <StylesCellTooltip.LabelTooltip>
            {formattedMoney}
          </StylesCellTooltip.LabelTooltip>
        }
      />
    );
  };

  /**
   * CELDA PARA REPRESENTAR UN NUMERO
   */
  static renderColNumber = (num = 0, decimals = 2) => {
    const valueNum = getNotNegativeNumber(num);
    const formattedNumber = Number(valueNum.toFixed(decimals)).toLocaleString();
    return (
      <CellTooltip
        divCellCSS={["number"]}
        contentCell={
          <StylesCellTooltip.LabelCell>
            {formattedNumber}
          </StylesCellTooltip.LabelCell>
        }
        contentTooltip={
          <StylesCellTooltip.LabelTooltip>
            {formattedNumber}
          </StylesCellTooltip.LabelTooltip>
        }
      />
    );
  };

  /**
   * CELDA PARA REPRESENTAR FECHAS
   */
  static renderColDate = (dateTime = null) => {
    const existDateTime = ![undefined, null, ""].includes(dateTime);
    const formattedFullDate = { date: "---/---/---", time: "--:--" };
    if (existDateTime) {
      const date = momentES(dateTime);
      if (date.isValid()) {
        formattedFullDate.date = getDate_S1(date);
        formattedFullDate.time = getTime_S1(date);
      }
    }
    return (
      <CellTooltip
        divCellCSS={["date"]}
        contentCell={
          existDateTime ? (
            <>
              <label className="txtBold">{formattedFullDate.date},</label>
              <label className="txtNormal">{formattedFullDate.time}</label>{" "}
            </>
          ) : (
            <label className="txtNormal">Sin fecha</label>
          )
        }
        contentTooltip={
          <StylesCellTooltip.LabelTooltip>
            {existDateTime
              ? formattedFullDate.date + " " + formattedFullDate.time
              : "Sin fecha"}
          </StylesCellTooltip.LabelTooltip>
        }
      />
    );
  };

  /**
   * CELDA PARA REPRESENTAR UN MENU DE ACCIONES EN UN POP-UP
   */
  static renderColMenu = (items = this.getPropsMenuActions().items) => {
    if (isArrayEmpty(items)) return null;
    return (
      <StylesCellTooltip.DivCell className="divCell menu">
        <MenuActions items={items} />
      </StylesCellTooltip.DivCell>
    );
  };

  /**
   * CELDA PARA UN SWITCH
   */
  static renderColSwitch = (
    selected = false,
    loading = false,
    onClick = (toggleSelected = false) => {}
  ) => {
    return (
      <StylesCellTooltip.DivCell className="divCell switch">
        <VerySwitch
          selected={selected}
          isLoading={loading}
          onChangeSelected={(toggleSelected) => onClick(toggleSelected)}
        />
      </StylesCellTooltip.DivCell>
    );
  };

  /**
   * CELDA PARA UN SWITCH
   */
  static renderColCheckbox = (
    selected = false,
    loading = false,
    onClick = (toggleSelected = false) => {}
  ) => {
    return (
      <StylesCellTooltip.DivCell className="divCell checkbox">
        <VeryCheckbox
          selected={selected}
          isLoading={loading}
          onChangeSelected={(toggleSelected) => onClick(toggleSelected)}
        />
      </StylesCellTooltip.DivCell>
    );
  };
}
