import styles from "./styles";
import { VeryIconGoogle } from "../VeryIconGoogle";
import classVeryProfileImg from "./classVeryProfileImg";
import {
  isArrayEmpty,
  isNumberPositive,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import { classVeryIconGoogle } from "../VeryIconGoogle/classVeryIconGoogle";

export const VeryProfileImg = (
  props = classVeryProfileImg.getPropsTemplate()
) => {
  const CSS = ["veryProfileImg"];
  const sizeImg = isNumberPositive(props.sizeImg) ? Number(props.sizeImg) : 70;
  // add custom style
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) CSS.push(props.className);

  return (
    <styles.DivMain
      sizeImg={sizeImg}
      CSS={[CSS.join(" ")]}
      src={props.src}
      defaultComponent={
        <VeryIconGoogle
          CSS={["iconProfile"]}
          name={"person"}
          type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED}
          color={classVeryIconGoogle.ICON_COLOR.GRAY_S1}
          size={isNumberPositive(props.sizeIcon) ? Number(props.sizeIcon) : 45}
        />
      }
    />
  );
};
