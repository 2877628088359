import Styles from "./styles";
import { useState, useEffect } from "react";
import { isStringEmpty } from "../../../utils/handler_validations";
import { classCustomImg } from "./utils/classCustomImg";
import CustomLoading from "../custom-loading";

const CustomImg = (props = classCustomImg.propsType) => {
  const { className, width, propsLoading, src, componentDefault, borderType } =
    props;
  const [imgLoad, setImgLoad] = useState();

  return (
    <Styles.Container
      className={isStringEmpty(className) ? "" : className}
      width={width}
      borderType={borderType}
    >
      {!isStringEmpty(src) && imgLoad === undefined && (
        <CustomLoading
          className="iconLoading"
          size={propsLoading.size}
          color={propsLoading.color}
        />
      )}

      {(isStringEmpty(src) || imgLoad === false) && componentDefault}

      <Styles.Img
        className={"customImage"}
        src={src}
        onLoad={(event) => {
          setImgLoad(true);
        }}
        onError={(event) => {
          setImgLoad(false);
        }}
        show={imgLoad === true}
      />
    </Styles.Container>
  );
};

export default CustomImg;
