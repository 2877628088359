import styled from "styled-components";
import { ButtonBase, Menu } from "@mui/material";

const DivMain = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow: hidden;
  //border: 2px solid red;
`;

const DivPage = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  //border: 3px solid purple;
  > .div-headerPage {
    width: 100%;
    height: 90px;
    padding: 0px 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #eaeaea;

    .btnAddDriver {
      width: fit-content;
      padding: 13px 25px;
    }
  }
  /*==============================
	MODALES
	================================*/
  .modalCreateCoupon {
    width: 100%;
    padding: 40px 22px 20px 22px !important;
    .container-inputNewCode {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      .container-actions {
        position: absolute;
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
        margin-top: 15px;
        height: 45px;
        top: 0px;
        right: 10px;
      }
    }
  }
  .btn-cancel {
  }
  .btn-save {
  }
`;

export default {
  DivMain,
  DivPage,
};
