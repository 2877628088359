import { useSessionContext } from "../../context";
import { VeryBtnFill } from "../common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../common/v2/VeryBtnFill/classVeryBtnFill";
import { VeryBtnOutlined } from "../common/v2/VeryBtnOutlined";
import { classVeryBtnOutlined } from "../common/v2/VeryBtnOutlined/classVeryBtnOutlined";
import styles from "./styles";

export const ModalLogOut = (props) => {
  const session = useSessionContext();

  return (
    <styles.Modal
      isVisible={session.value.showModalLogOut}
      title="Cerrar sesión"
      onClickClose={() => session.setShowModalLogOut(false)}
      buttons={[
        <VeryBtnOutlined
          CSS={["btnModal"]}
          colorTheme={classVeryBtnOutlined.COLOR_THEME.BLACK_S1}
          label="Regresar"
          textSize={15}
          onClick={() => session.setShowModalLogOut(false)}
        />,
        <VeryBtnFill
          CSS={["btnModal"]}
          colorTheme={classVeryBtnFill.COLOR_THEME.BLACK_S1}
          label="Aceptar"
          textSize={15}
          onClick={() => session.close()}
        />,
      ]}
    >
      <label className="txt-msg1">
        ¿Estas seguro que quieres cerrar tu sesión?
      </label>
      <label className="txt-msg2">
        Ya no podrás recibir nuevos viajes mientras tienes tu sesión cerrada.
      </label>
    </styles.Modal>
  );
};
