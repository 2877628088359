import styles from "./styles";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { classCustomLoading } from "./utils/classCustomLoading";
import {
  isStringEmpty,
  isValidNaturalNumber,
} from "../../../utils/handler_validations";

const CustomLoading = (props = classCustomLoading.propsType) => {
  const {
    className, // (string)
    size, // (number) -> tamaño del loading en px
    color, // (string)  -> consultar colores en la clase
  } = props;

  const customSize = isValidNaturalNumber(size) ? size : 18;
  const classesCSS = ["loadingDots"];
  const colors = Object.values(classCustomLoading.color);
  if (!colors.includes(color)) {
    classesCSS.push(classCustomLoading.color.whiteS1);
  } else {
    classesCSS.push(color);
  }

  return (
    <styles.MainContainer
      className={isStringEmpty(className) ? "" : className}
      size={customSize}
    >
      <Dots className={classesCSS.join(" ")} />
    </styles.MainContainer>
  );
};

export default CustomLoading;
