import { fetchGET } from "../utils/handler_http";

/**
 * Obtener la lista de viajes
 */
export const getListTrips = async (jwt = "") => {
  const response = await fetchGET(
    process.env.REACT_APP_TRIPS_LIST_ENDPOINT,
    {},
    { Authorization: jwt }
  );
  return response;
};
