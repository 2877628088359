import styled from "styled-components";
import { DivContentPage } from "../../components/common/DivContentPage";

const DivMain = styled(DivContentPage)`
  //border: 4px solid red;
  .btnAddAdmin {
    width: 40px;
    height: 100%;
    border-radius: 10px;
    padding: 10px;
  }
`;
export default {
  DivMain,
};
