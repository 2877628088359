import styled from "styled-components";

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 5px;
  align-items: flex-start;
  .btn-datePicker {
    width: fit-content;
    padding: 3px;
  }
`;

const ContainerDatePicker = styled.div`
  background-color: #ffffff;
  border-radius: inherit;

  .MuiCalendarOrClockPicker-root {
    > div {
      width: fit-content;
      max-width: 400px;
    }
    .MuiCalendarPicker-root {
      width: fit-content;
      // div header (contiene selector de año y mes )
      .MuiPickersCalendarHeader-root {
        background-color: #ffffff;
        padding: 24px 20px;
        margin: 0px 0px 8px 0px;
        border-bottom: solid #eaeaea 1px;
        // label del mes-año
        .MuiPickersCalendarHeader-label {
          color: #13131d;
          font-family: "Roboto";
          font-weight: bold;
          margin-right: 0px;
        }
        // iconos flechas < >
        .MuiPickersArrowSwitcher-button {
          color: black;
          &:disabled {
            color: rgba(0, 0, 0, 0.4);
          }
        }
        // icono selector de año
        .MuiPickersCalendarHeader-switchViewButton {
          color: black;
          > * {
            font-size: 30px;
          }
        }
      }
      // div content calendar
      .MuiCalendarPicker-viewTransitionContainer {
        padding: 0px 10px 10px 10px;

        // div que contiene los años
        .MuiYearPicker-root {
          width: 250px;
        }
        // div que contiene los dias de la semana L M M J V S D
        .MuiDayPicker-header {
          margin-bottom: 7px;
          span {
            font-family: "Roboto";
            font-weight: 600;
            color: #13131d;
            font-size: 15px;
            width: 35px;
            height: fit-content;
          }
        }
        // div que contiene los dias de la semana 1 2 3 4 5 6 7
        .MuiDayPicker-weekContainer {
          margin: 4px 0px;
          button {
            border: 1px solid #92fd70;
            background-color: transparent;
            transition: background 0.3s;
            &:hover {
              background-color: #92fd70;
              border: inherit;
            }
            &:disabled {
              background-color: #eaeaea;
              border: 1px solid transparent;
            }
          }
          // dia actual
          .MuiPickersDay-today {
            background-color: #13131d !important;
            color: #ffffff !important;
          }
          // render day
          .MuiPickersDay-root {
            width: 35px;
            height: 35px;
            font-family: "Helvetica";
            font-size: 13px;
            color: black;
            // dia seleccionado
            &.Mui-selected {
              background-color: #92fd70;
              color: #ffffff;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;

export default {
  ContainerDatePicker,
  MainContainer,
};
