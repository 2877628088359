import { Auth } from "aws-amplify";
import { isStringEmpty } from "../utils/handler_validations";

export const signInAWS = async (email = "", password = "") => {
  const resp = {
    existError: true,
    newPassRequired: false,
    messageError: "",
    jwt: "",
  };
  try {
    const session = await Auth.signIn(email, password);
    resp.existError = false;
    resp.jwt = session.signInUserSession?.accessToken?.jwtToken;
    if (session.challengeName === "NEW_PASSWORD_REQUIRED") {
      resp.newPassRequired = true;
    }
    return resp;
  } catch (err) {
    //console.log("ERROR signInAWS(): ", err);
    switch (err?.code) {
      case "NotAuthorizedException":
        resp.messageError = "Correo o contraseña incorrectos";
        break;
      default:
        resp.messageError = "Hubo un problema al iniciar sesión";
    }
    return resp;
  }
};

export const getErrorResetPassword = (codeError = "") => {
  switch (codeError) {
    case "CodeMismatchException":
    case "ExpiredCodeException":
      return "El código ingresado no es correcto";
    case "LimitExceededException":
      return "Ha realizado demasiados intentos, intente más tarde";
    default:
      return "Algo salió mal en el servidor al establecer tu nueva contraseña";
  }
};

export const sendCodeResetPassword = async (email = "") => {
  try {
    await Auth.forgotPassword(email);
    return undefined;
  } catch (err) {
    //console.log("ERROR sendCodeResetPassword(): ", err);
    return getErrorResetPassword(err?.code);
  }
};

export const confirmResetPassword = async (
  email = "",
  newPassword = "",
  code = ""
) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    return undefined;
  } catch (err) {
    //console.log("ERROR resetPassword(): ", err);
    return getErrorResetPassword(err?.code);
  }
};

/*
 respondChallenge(email, newPassword, session);
 */
export const setInitialPassword = async (
  email = "",
  oldPassword = "",
  newPassword = ""
) => {
  // template response
  const setResp = (msgError = "") => ({
    error: !isStringEmpty(msgError),
    message: msgError,
  });
  try {
    const signInAWS = await Auth.signIn(email, oldPassword);
    await Auth.completeNewPassword(signInAWS, newPassword);
    // success
    return setResp();
  } catch (err) {
    //console.log("setInitialPassword() ERROR => ", err);
    return setResp(err.message);
  }
};
