import React from "react";
import PropTypes from "prop-types";
import {
  isArrayEmpty,
  isNumberPositive,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import styles from "./styles";
import { VeryIconGoogle } from "../VeryIconGoogle";
import { VeryLoading } from "../VeryLoading";
import { classVeryBtnTransparent } from "./classVeryBtnTransparent";

/**
 * Boton MUI background transparent con borde (texto + icon)
 */
export const VeryBtnTransparent = (
  props = classVeryBtnTransparent.propsTemplate()
) => {
  const theme = classVeryBtnTransparent.getColorTheme(
    props.colorTheme,
    props.isDisabled
  );
  const textSize = isNumberPositive(props.textSize)
    ? Number(props.textSize)
    : 14;

  const CSS = ["veryBtnTransparent"];
  // add custom style
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) CSS.push(props.className);
  // add style theme
  if (!isStringEmpty(theme.className)) CSS.push(theme.className);
  // add class is loading
  if (props.isLoading === true) CSS.push("isLoading");

  return (
    <styles.divMain
      className={CSS.join(" ")}
      iconPosition={classVeryBtnTransparent.getIconPosition(
        props.icon?.position
      )}
      textSize={textSize}
      disabled={props.isDisabled === true}
      onClick={(event) => props.onClick && props.onClick()}
    >
      {props.isLoading === true ? (
        <VeryLoading
          color={theme.loadingColor}
          weight={4}
          size={props.icon?.size}
        />
      ) : (
        !isStringEmpty(props.icon?.name) && (
          <VeryIconGoogle
            CSS={["btnIcon"]}
            name={props.icon.name}
            type={props.icon.type}
            color={theme.iconColor}
            size={props.icon?.size}
            enabledSpin={props.icon.spin}
          />
        )
      )}
      {!isStringEmpty(props.label) && (
        <label className="txtLabel">{props.label}</label>
      )}
    </styles.divMain>
  );
};
