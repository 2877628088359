import { ClickAwayListener, Grow, Tooltip } from "@mui/material";
import styles from "./styles";
import { classVeryIconGoogle } from "../../common/v2/VeryIconGoogle/classVeryIconGoogle";
import { useState, useEffect } from "react";
import { VeryBtnTransparent } from "../../common/v2/VeryBtnTransparent";
import { classVeryBtnTransparent } from "../../common/v2/VeryBtnTransparent/classVeryBtnTransparent";
import { VeryBtnFill } from "../../common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../../common/v2/VeryBtnFill/classVeryBtnFill";

const propsTemplate = () => ({
  onChangeValue: (newValue = "") => {},
});
const SelectTime = (props = propsTemplate()) => {
  const [showMenu, setShowMenu] = useState(false);
  const [optionSelected, setOptionSelected] = useState("TODAY");
  const [options] = useState([
    { label: "Hoy", value: "TODAY" },
    { label: "Semana", value: "WEEK" },
    { label: "Mes", value: "MONTH" },
  ]);

  useEffect(() => {
    props.onChangeValue && props.onChangeValue(optionSelected);
  }, [optionSelected]);

  const getLabelOptionSelected = () => {
    for (const option of options) {
      if (option.value === optionSelected) return option.label;
    }
    return "";
  };

  const renderOption = (option = { label: "", value: "" }, index = 0) => {
    const classesCSS = ["btnOption"];
    if (optionSelected === option.value) classesCSS.push("optionSelected");

    return (
      <VeryBtnFill
        key={"item-" + index}
        CSS={classesCSS}
        colorTheme={classVeryBtnFill.COLOR_THEME.WHITE_S1}
        label={option.label}
        textSize={14}
        onClick={() => {
          setShowMenu(false);
          setOptionSelected(option.value);
        }}
      />
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setShowMenu(false)}>
      <styles.DivMain>
        <Tooltip
          PopperProps={{ disablePortal: true }}
          open={showMenu}
          placement="bottom"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          TransitionComponent={Grow}
          TransitionProps={{ timeout: 500 }}
          title={options.map((option, index) => renderOption(option, index))}
        >
          <div>
            <VeryBtnTransparent
              CSS={["btnSelect"]}
              colorTheme={classVeryBtnTransparent.COLOR_THEME.BLACK_S1}
              label={getLabelOptionSelected()}
              textSize={14}
              icon={{
                name: "expand_more",
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED,
                position: classVeryBtnTransparent.ICON_POSITION.RIGHT,
                size: 20,
              }}
              onClick={() => setShowMenu(!showMenu)}
            />
          </div>
        </Tooltip>
      </styles.DivMain>
    </ClickAwayListener>
  );
};

export default SelectTime;
