import { createContext, useContext } from "react";
import { useCoupons } from "../hooks";
const Context = createContext();

export const CouponsProvider = ({ children }) => {
  return (
    <Context.Provider value={useCoupons()}>{children}</Context.Provider>
  );
};

export const useCouponsContext = () => useContext(Context);