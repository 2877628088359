import styled from "styled-components";

const MainContainer = styled.button`
  width: fit-content;
  min-width: min-content;
  display: flex;
  flex-direction: ${({ iconPosition }) => {
    return iconPosition === "top"
      ? "column"
      : iconPosition === "right"
      ? "row-reverse"
      : iconPosition === "bottom"
      ? "column-reverse"
      : "row";
  }};
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  color: white;
  margin-top: ${(props) => props.mt ?? "0px"};
  margin-bottom: ${(props) => props.mb ?? "0px"};
  border-radius: ${({ borderType }) => {
    return borderType === "rectangle"
      ? "0px"
      : borderType === "oval"
      ? "18px"
      : borderType === "circle"
      ? "50%"
      : "8px"; // default none
  }};
  border-width: ${({ visibleBorde }) => (visibleBorde ? "1px" : "0px")};
  background-color: ${(props) => props.backgroundColor ?? "#f9ad33"};
  cursor: ${({ disabled, isLoading }) => {
    return disabled || isLoading ? "not-allowed" : "pointer";
  }};
  transition: all 0.25s;

  * {
    cursor: inherit;
  }

  label {
    font-family: "Helvetica";
    color: inherit;
    font-size: ${({ fontSize }) => fontSize}px;
  }

  &.fill {
    transition: background 0.25s;
    &.greenS1 {
      background-color: #76d430;
      color: black;
      border-color: #c18626;
      font-weight: 600;
      &:hover {
        background-color: #31b404;
      }
      .loading {
        color: #366b37;
      }
    }
    &.greenS2 {
      background-color: #76d430;
      color: white;
      border-color: #c18626;
      font-weight: 600;
      &:hover {
        background-color: #31b404;
      }
      .loading {
        color: #366b37;
      }
    }
    &.orangeS1 {
      background-color: #f9ad33;
      color: white;
      font-weight: 500;
      border-color: #c18626;
      &:hover {
        background-color: #c18626;
      }
      .loading {
        color: #9e6914;
      }
    }
    &.orangeS2 {
      background-color: #f9ad33;
      color: black;
      font-weight: 500;
      border-color: #c18626;
      &:hover {
        background-color: #c18626;
      }
      .loading {
        color: #9e6914;
      }
    }
    &.whiteS1 {
      background-color: white;
      color: #c18626;
      border: solid black 1px;
      &:hover {
        background-color: #f4f4f4;
      }
    }
    &.whiteS2 {
      background-color: white;
      color: gray;
      font-weight: 600;
      border: solid black 1px;
      &:hover {
        background-color: #f4f4f4;
      }
    }
    &.blackS1 {
      background-color: #13131d;
      color: #ffffff;
      font-weight: 600;
      border: solid black 1px;
      &:hover {
        background-color: gray;
        color: #ffffff;
        border-color: gray;
      }
    }
    &.redS1 {
      background-color: #ff6347;
      color: white;
      font-weight: 600;
      border-color: transparent;
      &:hover {
        background-color: #d11507;
      }
      .loading {
        color: #9e1d14;
      }
    }
    &:disabled {
      background-color: lightgray;
      color: black;
    }
  }
  /*------------------------------
	COLORES DE BOTON TRASPARENTE
	--------------------------------*/
  &.transparent {
    background-color: transparent;
    color: black;
    border-bottom: solid black 2px;
    &:hover {
      border-bottom: solid gray 2px;
    }
    &.whiteS1 {
      color: white;
      border-bottom: solid white 3px;
      &:hover {
        color: gainsboro;
        border-bottom: solid gray 3px;
      }
    }
  }
`;
export default {
  MainContainer,
};
