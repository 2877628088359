import styled from "styled-components";

const divMain = styled.div`
  width: 265px;
  height: 100%;
  padding: 0px 30px 30px 30px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 5;
`;

const divMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  .ItemSideMenu {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
  }
`;

const Logo = styled.img`
  width: 100%;
  height: 90px;
  padding: 22px 0px;
  object-fit: contain;
  margin-bottom: 25px;
  border-bottom: 1px solid #eaeaea;
`;

export default {
  divMain,
  divMenu,
  Logo,
};
