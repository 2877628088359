import styled from "styled-components";
import { VeryModal } from "../../common/v2/VeryModal";

const Modal = styled(VeryModal)`
  .divContentModal {
    max-width: 400px;
  }

  .divBodyModal {
    align-items: flex-start !important;
    .txt-message {
      font-family: "Roboto", Helvetica, sans-serif;
      font-size: 15px;
      font-weight: 400;
      color: #13131d;
      text-align: left;
      line-height: 1.2;
      white-space: pre-line;
    }
    .div-details {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 6px;
      margin-top: 17px;
      //border: 1px solid red;
      .div-data {
        display: flex;
        flex-direction: row;
        gap: 5px;
        .txt-title,
        .txt-data {
          font-family: "Roboto", Helvetica, sans-serif;
          font-weight: 400;
          font-size: 15px;
          color: #13131d;
          line-height: 1.2;
        }
        .txt-data {
          font-weight: 700;
        }
      }
    }
  }

  .divButtons .btnModal {
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 10px;
  }
`;

export default {
  Modal,
};
