import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/index";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import { SessionProvider } from "./context";
// required viewer PDF
import { Worker } from "@react-pdf-viewer/core";
import { VeryNotifications } from "./components/common/v2/VeryNotifications";
import classVeryNotifications from "./components/common/v2/VeryNotifications/classVeryNotifications";
// momentJS
import "moment/locale/es";

Amplify.configure({
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  Auth: {
    storage: window.sessionStorage,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <VeryNotifications
        maxNotis={6}
        position={classVeryNotifications.POSITIONS.BOTTOM_RIGHT}
        duration={8000}
        fixedNotis={false}
        disabledDuplicateNotis={false}
      >
        <SessionProvider>
          <App />
        </SessionProvider>
      </VeryNotifications>
    </Worker>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
