import CSS from "./styles";
//import userResetPasswords from "../../../_utils/Auth/user-reset-passwords";
import { VeryBtnFill } from "../../common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../../common/v2/VeryBtnFill/classVeryBtnFill";

const propsTemplate = () => ({
  btnMain: { text: "", isLoading: false, onClick: () => {} },
});
export default function FooterLogin(props = propsTemplate()) {
  return (
    <CSS.divMain>
      {/* BUTTON MAIN */}
      <VeryBtnFill
        colorTheme={classVeryBtnFill.COLOR_THEME.BLACK_S1}
        isLoading={props.btnMain?.isLoading}
        label={props.btnMain?.text}
        textSize={0}
        onClick={props.btnMain?.onClick && props.btnMain.onClick}
      />
      {/* EXTRA COMPONENTS */}
      {props.children}
    </CSS.divMain>
  );
}

// await userResetPasswords({ username: "email" })

/*
// BTN REGRESAR
{(dataLogin.isStageNewPass() ||
	dataLogin.isStageRecoveryPass_stepEmail() ||
	dataLogin.isStageRecoveryPass_stepCode() ||
	dataLogin.isStageRecoveryPass_stepNewPass()) && (
	<ButtonLabel
		text="Regresar"
		onClick={() => dataLogin.onClickBtnRegresar()}
	/>
)}
// BTN SOLICITAR NEW CODE
{dataLogin.isStageRecoveryPass_stepCode() && (
	<ButtonLabel
		text="Solicitar nuevo código"
		onClick={() => dataLogin.onClickBtnResendCode()}
	/>
)}
*/
