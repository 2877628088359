import styled from "styled-components";

const imgFlagMexico = styled.img`
  width: 24px;
  object-fit: contain;
`;

export default {
  imgFlagMexico,
};
