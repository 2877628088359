import styled from "styled-components";

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 40px 25px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  //border: 1px solid red;
  .div-icon {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: #d9d9d9;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .panel-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    //border: 1px solid red;
    > .label-title {
      font-family: "Roboto";
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      margin-top: 5px;
      color: #a0a0a0;
    }
    > .label-quantity {
      font-family: "Roboto";
      font-size: 18px;
      font-weight: 700;
      text-align: left;
      color: #13131d;
    }
  }
`;

export default {
  MainContainer,
};
