import { useState } from "react";

export const useModalAsk = () => {
  const [value, setValue] = useState({
    title: "",
    message: "",
    action: "",
    show: false,
    execute: false,
    modeLoading: true,
  });

  const show = (action = "", title = "", message = "", modeLoading = true) => {
    setValue({
      title,
      message,
      action,
      show: true,
      execute: false,
      modeLoading,
    });
  };

  const close = () => {
    setValue((prev) => ({
      ...prev,
      show: false,
      execute: false,
    }));
  };

  const activeExecute = () => {
    setValue((prev) => ({
      ...prev,
      show: prev.modeLoading ? true : false,
      execute: true,
    }));
  };

  const clearExecute = () => {
    setValue((prev) => ({
      ...prev,
      execute: false,
    }));
  };

  return {
    value,
    show,
    close,
    activeExecute,
    clearExecute,
  };
};
