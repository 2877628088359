import styled from "styled-components";
import { VeryModal } from "../common/v2/VeryModal";

const Modal = styled(VeryModal)`
  .divContentModal {
    max-width: 400px;
  }

  .divBodyModal {
    align-items: flex-start !important;
    .txt-msg1,
    .txt-msg2 {
      font-family: "Roboto", Helvetica, sans-serif;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      line-height: 1.2;
      white-space: pre-line;
    }
    .txt-msg2 {
      margin-top: 18px;
    }
  }

  .divButtons .btnModal {
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 10px;
  }
`;

export default {
  Modal,
};
