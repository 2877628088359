import styled from "styled-components";

const DivMain = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  //border: 1px solid red;
  .div-header {
    width: 100%;
    padding: 10px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    .txt-title {
      flex-shrink: 2;
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: #13131d;
      text-align: left;
      line-height: 1.2;
    }
    .div-select {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      .btn {
        width: fit-content;
      }
    }
  }
  .div-separator {
    width: 100%;
    height: 1px;
    background-color: #eaeaea;
  }
  .div-chart {
    width: 100%;
    min-height: 200px;
    flex: 1;
    padding: 10px 15px 8px 5px;
    //border: 1px solid blue;
  }
`;

export default {
  DivMain,
};
