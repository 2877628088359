import CSS from "./styles";
import logo from "../../assets/logos/very-black-01.svg";
import { useState } from "react";
import ItemSideMenu from "../common/v2/ItemSideMenu";
import { classVeryIconGoogle } from "../common/v2/VeryIconGoogle/classVeryIconGoogle";

const SideMenu = (props) => {
  return (
    <CSS.divMain>
      {/* HEADER LOGO */}
      <CSS.Logo src={logo} />

      {/* MENU */}
      <CSS.divMenu>
        {/* LINK SECCION DEL DASHBOARD */}
        <ItemSideMenu
          path="/dashboard"
          label="Dashboard"
          icon={{
            name: "bar_chart_4_bars",
            type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
          }}
          className="ItemSideMenu"
        />
        {/* LINK SECCION DE VIAJES */}
        <ItemSideMenu
          path="/viajes"
          label="Viajes"
          icon={{
            name: "view_list",
            type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
          }}
          className="ItemSideMenu"
        />
        {/* LINK SECCION DE PASAJEROS */}
        <ItemSideMenu
          path="/pasajeros"
          label="Pasajeros"
          icon={{
            name: "group",
            type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
          }}
          className="ItemSideMenu"
        />
        {/* LINK SECCION DE CONDUCTORES */}
        <ItemSideMenu
          path="/conductores"
          label="Conductores"
          icon={{
            name: "perm_contact_calendar",
            type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
          }}
          className="ItemSideMenu"
        />
        {/* LINK SECCION DE ADMINS */}
        <ItemSideMenu
          path="/administradores"
          label="Administradores"
          icon={{
            name: "manage_accounts",
            type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
          }}
          className="ItemSideMenu"
        />
        {/* LINK SECCION DE CUPONES */}
        <ItemSideMenu
          path="/cupones"
          label="Cupones"
          icon={{
            name: "confirmation_number",
            type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
          }}
          className="ItemSideMenu"
        />
      </CSS.divMenu>
    </CSS.divMain>
  );
};

export default SideMenu;
