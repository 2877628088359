import {
  isArrayEmpty,
  isStringEmpty,
} from "../../../utils/handler_validations";
import { VeryLoading } from "../v2/VeryLoading";
import { classVeryLoading } from "../v2/VeryLoading/classVeryLoading";
import styles from "./styles";

const propsTemplate = () => ({
  CSS: [],
  size: 0,
  weight: 0,
});

export const DivLoading = (props = propsTemplate()) => {
  const customCSS = [];
  if (!isStringEmpty(props.className)) customCSS.push(props.className);
  if (!isArrayEmpty(props.CSS)) customCSS.push(props.CSS);

  return (
    <styles.DivMain className={customCSS.join(" ")}>
      <VeryLoading
        color={classVeryLoading.COLOR.BLACK_S1}
        size={props.size}
        weight={props.weight}
      />
    </styles.DivMain>
  );
};
