import styles from "./styles";

const propsTemplate = () => ({
  onClick: () => {},
});
const ItemMenuProfile = (props = propsTemplate()) => {
  return (
    <styles.DivMain
      onClick={(event) => {
        event.stopPropagation();
        props.onClick();
      }}
    >
      {props.children}
    </styles.DivMain>
  );
};

export default ItemMenuProfile;
