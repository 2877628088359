import { createContext, useContext } from "react";
import { useFormDriver } from "../hooks";
const Context = createContext();

export const FormDriverProvider = ({ children }) => {
  return (
    <Context.Provider value={useFormDriver()}>{children}</Context.Provider>
  );
};

export const useFormDriverContext = () => useContext(Context);
