import styled from "styled-components";
import stylesLogin from "../../../pages/login/styles";

const DivMain = styled(stylesLogin.DivPanel)`
  //border: 1px solid red;
  .veryGroupInput {
    width: 100%;
    .divInputs .divInputBase {
      min-width: 50px;
      flex-shrink: 1;
    }
  }
`;

const DivResendCode = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  //border: 1px solid red;
  .txtResendCode,
  .txtWaiting {
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: 400;
    text-align: center;
    line-height: 1.2;
    color: #a0a0a0;
  }
  .txtResendCode {
    font-size: 13px;
    padding-right: 0px;
  }
  .txtWaiting {
    font-size: 12px;
    padding-right: 5px;
  }
  .btnResendCode {
    width: fit-content;
  }
  .divTimer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1px;
    .txtTimer {
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 700;
      font-size: 13px;
      text-align: center;
      line-height: 1.2;
      color: #13131d;
    }
  }
`;

export default {
  DivMain,
  DivResendCode,
};
