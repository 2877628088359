import { isStringEmpty } from "./handler_validations";

const getErrorTemplate = () => ({ error: false, message: "", details: "" });

export const sendError = (errorMessage = "", errorDetails = "") => {
  const resp = getErrorTemplate();
  resp.error = !isStringEmpty(errorMessage);
  resp.message = errorMessage;
  resp.details = errorDetails;
  return resp;
};
