import { useMemo } from "react";
import { usePassengersContext } from "../../../context";
import { Grow, Slide, Tooltip } from "@mui/material";
import Styles from "./styles";
import { VeryBtnTransparent } from "../../common/v2/VeryBtnTransparent";
import { classVeryBtnTransparent } from "../../common/v2/VeryBtnTransparent/classVeryBtnTransparent";
import { classVeryIconGoogle } from "../../common/v2/VeryIconGoogle/classVeryIconGoogle";
import { VeryProfileImg } from "../../common/v2/VeryProfileImg";
import { getImageURL } from "../../../utils/handler_files";
import {
  getFormattedEmail,
  getFormattedNumber,
  getMomentES,
  isStringEmpty,
  testEmail,
} from "../../../utils/handler_validations";
import { VeryRating } from "../../common/v2/VeryRating";
import { VeryBtnFill } from "../../common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../../common/v2/VeryBtnFill/classVeryBtnFill";
import { useNavigate } from "react-router-dom";
import { createRef } from "react";
import { getDate_S3 } from "../../../utils/handler_dates";
import { getDigitsTel, getFormattedTel } from "../../../utils/handler_tel";
import { useVeryNotificationsContext } from "../../common/v2/VeryNotifications/useVeryNotifications";
import copy from "copy-to-clipboard";

const getPropsTemplate = () => ({
  refSlide: createRef(),
});

export const PanelProfile = (props = getPropsTemplate()) => {
  const dataPassengers = usePassengersContext();
  const notis = useVeryNotificationsContext();
  const navigate = useNavigate();
  const passenger = useMemo(() => {
    return dataPassengers.getPassengerSelected();
  }, [dataPassengers.rowSelected, dataPassengers.value.activeCoupons]);

  const onClickBtnTel = () => {
    if (isStringEmpty(getDigitsTel(passenger.tel))) {
      notis.createNotiError("Teléfono del usuario no establecido o incorrecto");
      return;
    }
    const respCopy = copy(getFormattedTel(passenger.tel, "-"));
    if (respCopy) {
      notis.createNotiSuccess("Teléfono del usuario copiado");
    } else {
      notis.createNotiError("No fue posible copiar el teléfono del usuario");
    }
  };

  const onClickBtnEmail = () => {
    if (testEmail(passenger.email, true).error) {
      notis.createNotiError("Correo del usuario no establecido o incorrecto");
      return;
    }
    const respCopy = copy(getFormattedEmail(passenger.email));
    if (respCopy) {
      notis.createNotiSuccess("Correo del usuario copiado");
    } else {
      notis.createNotiError("No fue posible copiar el correo del usuario");
    }
  };

  const renderItemPayment = (title = "", money = "") => {
    return (
      <div className="div-item">
        <label className="txt-item">{title}</label>
        <label className="txt-money">${money}</label>
      </div>
    );
  };

  const renderCouponsList = () => {
    return passenger.coupons.map((coupon, index) => {
      const rowCSS = ["div-rowCoupon"];
      if (coupon.canceled) rowCSS.push("couponCanceled");
      return (
        <div className={rowCSS.join(" ")} key={"c-" + coupon.code}>
          {/* COLUMN CODE */}
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            placement="top"
            disableFocusListener
            disableTouchListener
            TransitionComponent={Grow}
            TransitionProps={{
              timeout: 500,
            }}
            title={
              <>
                <label className="txt-title">Fecha de expiración</label>
                <label className="txt-date">
                  {getDate_S3(getMomentES(coupon.expirationDate))}
                </label>
              </>
            }
          >
            <div className="div-colCode">{coupon.code}</div>
          </Tooltip>
          {/* COLUMN AMOUNT */}
          <div className="div-colAmount">
            ${getFormattedNumber(coupon.amount, true, 2)}
          </div>
          {/* COLUMN BTN DISABLE */}
          <div className="div-colBtn">
            {coupon.canceled ? (
              <label className="txt-canceled">Cancelado</label>
            ) : (
              <VeryBtnTransparent
                CSS={["btnDisable"]}
                colorTheme={classVeryBtnTransparent.COLOR_THEME.GRAY_S1}
                textSize={13}
                label="Cancelar cupón"
                onClick={() =>
                  dataPassengers.onClickBtnDisableCoupon(
                    coupon.code,
                    passenger.id
                  )
                }
              />
            )}
          </div>
        </div>
      );
    });
  };

  const renderBtnCopy = (
    title = "",
    icon = { name: "", size: 0, type: "" },
    onClick = () => {}
  ) => {
    return (
      <Tooltip
        PopperProps={{ disablePortal: true }}
        placement="top"
        disableFocusListener
        disableTouchListener
        TransitionComponent={Grow}
        TransitionProps={{ timeout: 500 }}
        title={title}
      >
        <div>
          <VeryBtnFill
            CSS={["btnCopy"]}
            colorTheme={classVeryBtnFill.COLOR_THEME.GRAY_S1}
            icon={{
              name: icon.name,
              size: icon.size,
              type: icon.type,
            }}
            onClick={() => onClick()}
          />
        </div>
      </Tooltip>
    );
  };

  return (
    <Slide
      direction="left"
      in={dataPassengers.rowSelected >= 0}
      timeout={500}
      container={props.refSlide?.current}
      unmountOnExit
    >
      <Styles.DivMain>
        {/* CONTAINER HEADER */}
        <div className="div-header">
          <label className="txtTitle">Detalles de usuario pasajero</label>
          <VeryBtnTransparent
            CSS={["btnClose"]}
            colorTheme={classVeryBtnTransparent.COLOR_THEME.BLACK_S2}
            icon={{
              name: "close",
              type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
              size: 22,
            }}
            onClick={() => dataPassengers.onChangeRowSelected(-1)}
          />
        </div>
        {/* CONTAINER USER DETAILS */}
        <div className="div-userDetails">
          <VeryProfileImg
            CSS={["imgProfile"]}
            sizeIcon={50}
            sizeImg={90}
            src={getImageURL(
              process.env.REACT_APP_BUCKET,
              passenger.profileImg,
              100,
              100
            )}
          />
          <label className="txt-name">{passenger.fullName}</label>
          <label className="txt-email">
            {getFormattedEmail(passenger.email)}
          </label>
          <div className="div-rating">
            <VeryRating
              isEditable={false}
              sizeIcon={14}
              value={Number(passenger.rating)}
              maxValue={5}
              stepValue={0.05}
            />
            <label className="txt-rating">{passenger.rating}</label>
          </div>
          <div className="div-btns">
            {renderBtnCopy(
              "Copiar teléfono",
              {
                name: "call",
                size: 15,
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
              },
              onClickBtnTel
            )}
            {renderBtnCopy(
              "Copiar Correo",
              {
                name: "email",
                size: 15,
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
              },
              onClickBtnEmail
            )}
          </div>
        </div>
        {/* CONTAINER COMPLETED TRIPS */}
        <div className="div-completedTrips">
          <label className="txt-title">Viajes completados</label>
          <div className="div-data">
            <div className="div-color" />
            <label className="txt-number">
              {passenger.totalCompletedTrips}
            </label>
            <label className="txt-endTravels">Viajes finalizados</label>
            <VeryBtnTransparent
              CSS={["btn-completeTrips"]}
              colorTheme={classVeryBtnTransparent.COLOR_THEME.BLACK_S1}
              icon={{
                name: "chevron_right",
                type: classVeryIconGoogle.ICON_TYPE.OUTLINED,
                size: 20,
              }}
              onClick={() => navigate("/viajes ", { replace: true })}
            />
          </div>
        </div>
        {/* CONTAINER PAYMENTS */}
        <div className="div-payments">
          <label className="txt-title">Pagos</label>
          {renderItemPayment("Pagado en efectivo", passenger.paid.cash)}
          {renderItemPayment("Pagado con cupón", passenger.paid.coupons)}
        </div>
        {/* CONTAINER COUPONS */}
        <div className="div-coupons">
          <label className="txt-title">Cupones activos</label>
          <div className="div-table">{renderCouponsList()}</div>
        </div>
      </Styles.DivMain>
    </Slide>
  );
};
