export class classCustomImg {
  // props recibidas
  static propsType = {
    className: "", // string
    width: "", // string
    propsLoading: {
      size: 0, // number  -> tamañao del loading
      color: "", // string -> consultar colores en la clase del customLoading
    },
    src: "", // imagen a cargar (string)
    componentDefault: null, // componente a mostrar en caso de que no cargue src (JSX)
    borderType: "", // consultar tipos en la clase del customImage
  };
  // tipos de borde para el container
  static borderType = {
    rectangle: "rectangle",
    circle: "circle",
  };
}
