import styled from "styled-components";

const MainContainer = styled.button`
  --iconSize: ${({ iconSize }) => iconSize}px;
  width: fit-content;
  min-width: min-content;
  padding: 0px;
  cursor: ${({ isDisabled, isLoading }) => {
    return isDisabled || isLoading ? "not-allowed" : "pointer";
  }};
  border-radius: ${({ borderType }) => {
    return borderType === "rectangle"
      ? "6px"
      : borderType === "oval"
      ? "18px"
      : borderType === "circle"
      ? "50%"
      : "0px"; // default none
  }};
  border-width: ${({ visibleBorde }) => (visibleBorde ? "1px" : "0px")};
  border-style: solid;
  display: grid;
  place-items: center;
  > * {
    font-size: var(--iconSize);
    /*------------------------------
	  ANIMACION CIRCULAR INFINITA
	  --------------------------------*/
    &.iconSpin {
      animation-duration: 1s;
      animation-name: iconSpin;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-timing-function: linear;
    }
    @keyframes iconSpin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  /*------------------------------
	COLORES DE BOTON NO TRASPARENTE
	--------------------------------*/
  &.fill {
    transition: background 0.25s;
    &.greenS1 {
      background-color: #82bc83;
      color: #609461;
      border-color: #609461;
      &:hover {
        background-color: #609461;
      }
    }
    &.orangeS1 {
      background-color: #f9ad33;
      color: white;
      border-color: #c18626;
      &:hover {
        background-color: #c18626;
      }
    }
    &.whiteS1 {
      background-color: white;
      color: #c18626;
      border-color: #f0f0f0;
      &:hover {
        background-color: #f4f4f4;
      }
    }
    &.redS1 {
      background-color: red;
      color: white;
      border-color: transparent;
      &:hover {
        background-color: #d11507;
      }
    }
    &.blackS1 {
      color: white;
      background-color: black;
      &:hover {
        color: gray;
      }
    }
    &.grayS1 {
      background-color: gray;
      color: white;
      &:hover {
        color: gainsboro;
      }
    }
  }
  /*------------------------------
	COLORES DE BOTON TRASPARENTE
	--------------------------------*/
  &.transparent {
    background-color: transparent;
    transition: color 0.3s;
    &.greenS1 {
      color: #82bc83;
      border-color: #82bc83;
      &:hover {
        color: #609461;
      }
    }
    &.orangeS1 {
      color: #f9ad33;
      border-color: #f9ad33;
      &:hover {
        color: #c18626;
      }
    }
    &.whiteS1 {
      color: white;
      &:hover {
        color: #d5d1d1;
      }
    }
    &.redS1 {
      color: red;
      &:hover {
        color: #d11507;
      }
    }
    &.blackS1 {
      color: black;
      &:hover {
        color: gray;
      }
    }
    &.grayS1 {
      color: gray;
      &:hover {
        color: gainsboro;
      }
    }
  }
`;

export default {
  MainContainer,
};
