import { getFileTemplate } from "../../../../utils/handler_files";

export default class classVeryPickerImgPdf {
  static getPropsTemplate = () => ({
    CSS: [],
    disabledImgs: true,
    disabledPdfs: true,
    isRequired: false,
    title: "",
    error: "",
    sizesLabel: { error: 0, title: 0, description: 0 },
    originalFile: getFileTemplate(),
    onChangeFile: (selectedFile = getFileTemplate()) => {},
    onError: (error = "") => {},
  });
  static STEP = {
    UPLOAD: 1,
    PREVIEW_IMG: 2,
    PREVIEW_PDF: 3,
  };
}
