import styled from "styled-components";

const DivMain = styled.div`
  width: 100%;
  height: 100%;
  //border: 1px solid blue;

  // TOOLTIP
  > .MuiTooltip-popper {
    &.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip {
      margin-bottom: 0px !important;
    }
    &.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip {
      margin-top: 0px !important;
    }
    .MuiTooltip-tooltip {
      background-color: #f4f4f4;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 8px 12px;
      border: 1px solid #cacaca;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      cursor: default;
    }
  }
`;

const LabelTooltip = styled.label`
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  color: #13131d;
  cursor: inherit;
`;

const DivCell = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  //border: 1px solid orange;
`;

const LabelCell = styled.label`
  flex: 1;
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: inherit;
`;

export default {
  DivMain,
  DivCell,
  LabelTooltip,
  LabelCell,
};
