import { useMemo } from "react";
import { useDriversContext } from "../../../context";
import { Grow, Slide, Tooltip } from "@mui/material";
import Styles from "./styles";
import { VeryBtnTransparent } from "../../common/v2/VeryBtnTransparent";
import { classVeryBtnTransparent } from "../../common/v2/VeryBtnTransparent/classVeryBtnTransparent";
import { classVeryIconGoogle } from "../../common/v2/VeryIconGoogle/classVeryIconGoogle";
import { VeryProfileImg } from "../../common/v2/VeryProfileImg";
import {
  downloadFile,
  getFileExtension,
  getFileURL,
  getImageURL,
} from "../../../utils/handler_files";
import {
  getFormattedEmail,
  isStringEmpty,
  testEmail,
} from "../../../utils/handler_validations";
import { VeryRating } from "../../common/v2/VeryRating";
import { VeryBtnFill } from "../../common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../../common/v2/VeryBtnFill/classVeryBtnFill";
import { VeryIconGoogle } from "../../common/v2/VeryIconGoogle";
import { useNavigate } from "react-router-dom";
import { createRef } from "react";
import {
  getDigitsTel,
  getFormattedTel,
  testTel,
} from "../../../utils/handler_tel";
import { useVeryNotificationsContext } from "../../common/v2/VeryNotifications/useVeryNotifications";
import copy from "copy-to-clipboard";

const getPropsTemplate = () => ({
  refSlide: createRef(),
});

export const PanelProfile = (props = getPropsTemplate()) => {
  const dataDrivers = useDriversContext();
  const notis = useVeryNotificationsContext();
  const navigate = useNavigate();
  const driver = useMemo(() => {
    return dataDrivers.getDriverSelected();
  }, [dataDrivers.rowSelected]);

  const onClickBtnTel = () => {
    if (isStringEmpty(getDigitsTel(driver.tel))) {
      notis.createNotiError(
        "Teléfono del conductor no establecido o incorrecto"
      );
      return;
    }
    const respCopy = copy(getFormattedTel(driver.tel, "-"));
    if (respCopy) {
      notis.createNotiSuccess("Teléfono del conductor copiado");
    } else {
      notis.createNotiError("No fue posible copiar el teléfono del conductor");
    }
  };

  const onClickBtnEmail = () => {
    if (testEmail(driver.email, true).error) {
      notis.createNotiError("Correo del conductor no establecido o incorrecto");
      return;
    }
    const respCopy = copy(getFormattedEmail(driver.email));
    if (respCopy) {
      notis.createNotiSuccess("Correo del conductor copiado");
    } else {
      notis.createNotiError("No fue posible copiar el correo del conductor");
    }
  };

  const renderTagInTravel = () => {
    if (!driver.inTrip) return null;
    return (
      <div className="div-traveling">
        <label className="txt-traveling">En viaje</label>
      </div>
    );
  };

  const renderItemDocument = (name = "", keyS3 = "") => {
    return (
      <div className="div-item">
        <VeryIconGoogle
          name={isStringEmpty(keyS3) ? "close" : "check"}
          type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED}
          size={19}
          color={classVeryIconGoogle.ICON_COLOR.BLACK_S1}
        />
        <label className="txt-item">{name}</label>
        <VeryBtnTransparent
          label="Descargar"
          CSS={["btn-download"]}
          textSize={13}
          isDisabled={isStringEmpty(keyS3)}
          colorTheme={classVeryBtnTransparent.COLOR_THEME.GRAY_S1}
          onClick={() =>
            downloadFile(
              getFileURL(process.env.REACT_APP_BUCKET, keyS3),
              `${name}_${driver.fullName}`,
              getFileExtension(keyS3)
            )
          }
        />
      </div>
    );
  };

  const renderItemPayment = (title = "", money = "") => {
    return (
      <div className="div-item">
        <label className="txt-item">{title}</label>
        <label className="txt-money">${money}</label>
      </div>
    );
  };

  const renderBtnCopy = (
    title = "",
    icon = { name: "", size: 0, type: "" },
    onClick = () => {}
  ) => {
    return (
      <Tooltip
        PopperProps={{ disablePortal: true }}
        placement="top"
        disableFocusListener
        disableTouchListener
        TransitionComponent={Grow}
        TransitionProps={{ timeout: 500 }}
        title={title}
      >
        <div>
          <VeryBtnFill
            CSS={["btnCopy"]}
            colorTheme={classVeryBtnFill.COLOR_THEME.GRAY_S1}
            icon={{
              name: icon.name,
              size: icon.size,
              type: icon.type,
            }}
            onClick={() => onClick()}
          />
        </div>
      </Tooltip>
    );
  };

  return (
    <Slide
      direction="left"
      in={dataDrivers.rowSelected >= 0}
      timeout={500}
      container={props.refSlide?.current}
      unmountOnExit
    >
      <Styles.DivMain>
        {/* CONTAINER HEADER */}
        <div className="div-header">
          <label className="txtTitle">Detalles de conductor</label>
          <VeryBtnTransparent
            CSS={["btnClose"]}
            colorTheme={classVeryBtnTransparent.COLOR_THEME.BLACK_S2}
            icon={{
              name: "close",
              type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
              size: 22,
            }}
            onClick={() => dataDrivers.onChangeRowSelected(-1)}
          />
        </div>
        {/* CONTAINER USER DETAILS */}
        <div className="div-userDetails">
          {renderTagInTravel()}
          <VeryProfileImg
            CSS={["imgProfile"]}
            sizeIcon={50}
            sizeImg={90}
            src={getImageURL(
              process.env.REACT_APP_BUCKET,
              driver.profileImg,
              100,
              100
            )}
          />
          <label className="txt-name">{driver.fullName}</label>
          <label className="txt-email">{getFormattedEmail(driver.email)}</label>
          <div className="div-rating">
            <VeryRating
              isEditable={false}
              sizeIcon={14}
              value={Number(driver.rating)}
              maxValue={5}
              stepValue={0.05}
            />
            <label className="txt-rating">{driver.rating}</label>
          </div>
          <div className="div-btns">
            {renderBtnCopy(
              "Copiar teléfono",
              {
                name: "call",
                size: 15,
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
              },
              onClickBtnTel
            )}
            {renderBtnCopy(
              "Copiar Correo",
              {
                name: "email",
                size: 15,
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
              },
              onClickBtnEmail
            )}
          </div>
        </div>
        {/* CONTAINER DOCUMENTS */}
        <div className="div-documents">
          <label className="txt-title">Documentos</label>
          <div className="div-list">
            {renderItemDocument("INE", driver.documents.ine)}
            {renderItemDocument(
              "Licencia de manejo",
              driver.documents.driverLicence
            )}
            {renderItemDocument(
              "Comprobante de domicilio",
              driver.documents.address
            )}
            {renderItemDocument(
              "Antecedentes no penales",
              driver.documents.criminalLetter
            )}
          </div>
        </div>
        {/* CONTAINER VEHICLE */}
        <div className="div-vehicle">
          <label className="txt-title">Taxi registrado</label>
          <div className="div-list">
            <label className="txt-item">{driver.vehicle.model}</label>
            <label className="txt-item">{driver.vehicle.plates}</label>
            <div className="div-color">
              <label className="txt-color">Color {driver.vehicle.color}</label>
              <div className={`color ${driver.vehicle.colorCSS}`} />
            </div>
          </div>
        </div>
        {/* CONTAINER COMPLETED TRIPS */}
        <div className="div-completedTrips">
          <label className="txt-title">Viajes completados</label>
          <div className="div-data">
            <div className="div-color" />
            <label className="txt-number">{driver.completedTrips}</label>
            <label className="txt-endTravels">Viajes finalizados</label>
            <VeryBtnTransparent
              CSS={["btn-completeTrips"]}
              colorTheme={classVeryBtnTransparent.COLOR_THEME.BLACK_S1}
              icon={{
                name: "chevron_right",
                type: classVeryIconGoogle.ICON_TYPE.OUTLINED,
                size: 20,
              }}
              onClick={() => navigate("/viajes ", { replace: true })}
            />
          </div>
        </div>
        {/* CONTAINER PAYMENTS */}
        <div className="div-payments">
          <label className="txt-title">Ingresos</label>
          {renderItemPayment("Ingresos totales de viajes", driver.tripsEarning)}
          {renderItemPayment(
            "Descuentos aplicados con cupón",
            driver.couponsEarning
          )}
        </div>
      </Styles.DivMain>
    </Slide>
  );
};
