import styled from "styled-components";
import { VeryModal } from "../../common/v2/VeryModal";

const MainContainer = styled.div`
  height: 77%;
  width: 400px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  margin: 0px;
  border-radius: 10px;
  //overflow: auto;
  padding: 25px 20px 10px 20px;

  // HEADER
  .div-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 15px;
    //border: 1px solid green;
    .div-code,
    .div-amount {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      //border: 1px solid green;
      .txt-title {
        font-family: "Roboto", Helvetica, sans-serif;
        font-weight: 400;
        font-size: 12px;
        color: #13131d;
        line-height: 1.2;
        text-align: left;
      }
    }
    .div-code {
      .txt-code {
        font-family: "Roboto", Helvetica, sans-serif;
        font-weight: 700;
        font-size: 29px;
        color: #13131d;
        line-height: 1.2;
        text-align: left;
      }
    }
    .div-amount {
      .div-valueAmount {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        .txt-value {
          font-family: "Roboto", Helvetica, sans-serif;
          font-weight: 700;
          font-size: 29px;
          color: #16ca5e;
          line-height: 1.2;
          text-align: left;
        }
      }
    }
  }

  // COUPON DETAILS
  .div-couponDetails {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 15px;
    margin-top: 30px;
    padding-bottom: 20px;
    border-bottom: solid #eaeaea 1px;
    .div-date {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;
      overflow: hidden;
      //border: 1px solid green;
      > .txt-title {
        width: 100%;
        color: #13131d;
        font-family: "Roboto", Helvetica, sans-serif;
        font-weight: 700;
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      > .txt-date {
        width: 100%;
        color: #13131d;
        font-family: "Roboto", Helvetica, sans-serif;
        font-size: 12px;
        font-weight: 400;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &.expiratedDate {
          color: #f04465;
          font-weight: 700;
        }
      }
    }
    // TOOLTIPS DATE
    .MuiTooltip-popper {
      &.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip {
        margin-bottom: 2px !important;
      }
      &.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip {
        margin-top: 2px !important;
      }
      .MuiTooltip-tooltip {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;
        padding: 8px 12px;
        background-color: #f4f4f4;
        border-radius: 5px;
        border: 1px solid #cacaca;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        cursor: default;
        .txt-title {
          color: #13131d;
          font-family: "Roboto", Helvetica, sans-serif;
          font-weight: 700;
          font-size: 12px;
        }
        .txt-date {
          color: #13131d;
          font-family: "Roboto", Helvetica, sans-serif;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

  // LOGS HISTORY
  .div-logsHistory {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    overflow: auto;
    gap: 10px;
    //border: 1px solid green;
    .txt-title {
      font-family: "Roboto", Helvetica, sans-serif;
      font-weight: 700;
      font-size: 12px;
      color: #13131d;
    }
    .div-list {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      overflow: auto;
      list-style-position: outside;
      padding-left: 1rem;
      margin: 0px;
      //border: 1px solid green;
      li {
        .txt-item {
          color: #13131d;
          font-family: "Roboto", Helvetica, sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.2;
          text-align: left;
          //border: 1px solid green;
          .txt-log,
          .txt-statusType {
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            text-align: inherit;
          }
          .txt-statusType {
            font-weight: 700;
          }
        }
      }
    }
  }

  // CONTAINER DE LA VISTA DE VISTA DEFAULT (CUPON NO SELECCIONADO)
  .div-panelEmpty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 5px;
    .txt-title {
      color: #000000;
      opacity: 0.3;
      font-family: "Roboto", Helvetica, sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.2;
      text-align: center;
    }
  }
`;

const Modal = styled(VeryModal)`
  .divContentModal {
    max-width: 400px;
  }

  .divBodyModal {
    align-items: flex-start !important;
    .txt-msg1,
    .txt-msg2 {
      font-family: "Roboto", Helvetica, sans-serif;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      line-height: 1.2;
      white-space: pre-line;
    }
    .txt-msg2 {
      margin-top: 18px;
    }
    .info {
      gap: 6px;
      display: flex;
      flex-direction: column;
      padding: 10px 3px 3px 3px;
      width: 100%;
      //border: 1px solid red;
      .divData {
        display: flex;
        flex-direction: row;
        gap: 4px;
        .data {
          font-family: "Roboto", Helvetica, sans-serif;
          color: #13131d;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }

  .divButtons .btnModal {
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 10px;
  }
`;

export default {
  MainContainer,
  Modal,
};
