import { useEffect, useState } from "react";
import {
  isObject,
  isStringEmpty,
  isValidNaturalNumber,
} from "../utils/handler_validations";
import classLoginPage from "../components/login-page/classLoginPage";
import { sessionDB } from "../services";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "../context/session.context";

const getValueTemplate = () => ({
  step: classLoginPage.STEP_RECOVERY_PASS.EMAIL,
  loading: false,
  loadinResendCode: false,
  messageError: "",
});
const getDataTemplate = () => ({
  email: "",
  oldEmail: "",
  newPass: "",
  confirmPass: "",
  code: classLoginPage.getInputsCode(),
});

export const useRecoveryPass = () => {
  const [value, setValue] = useState(getValueTemplate());
  const [data, setData] = useState(getDataTemplate());
  const [errorData, setErrorData] = useState(getDataTemplate());
  const [timerResendCode, setTimerResendCode] = useState(0);
  const [timerIdResendCode, setTimerIdResendCode] = useState();
  const navigate = useNavigate();
  const session = useSessionContext();

  // temporizador de resend code
  useEffect(() => {
    if (timerIdResendCode !== undefined) clearTimeout(timerIdResendCode);
    if (isWaitingSendCode()) {
      const id = setTimeout(() => {
        setTimerResendCode((prev) => prev - 1);
      }, 1000);
      setTimerIdResendCode(id);
    }
  }, [timerResendCode]);

  // limpiar error data al cambiar de step
  useEffect(() => {
    setErrorData(getDataTemplate());
  }, [value.step]);

  const init = () => {
    setValue(getValueTemplate());
    setData(getDataTemplate());
    setErrorData(getDataTemplate());
  };

  /*====================================
  OBTENCION DE VALORES
  ======================================*/
  const getSubtitleHeader = () => {
    switch (value.step) {
      case classLoginPage.STEP_RECOVERY_PASS.EMAIL:
        return "Ingresa tu email para enviarte un código de acceso.";
      case classLoginPage.STEP_RECOVERY_PASS.CODE:
        return "Ingresa el código de confirmación enviado a tu correo.";
      default:
        return "Ingresa una nueva contraseña para iniciar sesión.";
    }
  };

  const getLabelBtnMain = () => {
    switch (value.step) {
      case classLoginPage.STEP_RECOVERY_PASS.NEW_PASS:
        return "Establecer";
      default:
        return "Continuar";
    }
  };

  const getTitleForm = () => {
    switch (value.step) {
      case classLoginPage.STEP_RECOVERY_PASS.EMAIL:
        return "Ingresa tus datos";
      case classLoginPage.STEP_RECOVERY_PASS.NEW_PASS:
        return "Contraseña nueva";
      default:
        return "";
    }
  };

  const getCode = () => {
    return data.code.map((input) => input.value).join("");
  };

  /*====================================
  ESTABLECER VALORES
  ======================================*/
  const setInputData = (values = getDataTemplate()) => {
    setData((prev) => ({
      ...prev,
      ...values,
    }));
  };

  const setValueSingleInputCode = (newValue = "", indexInput) => {
    setData((prev) => {
      const newValuesCode = prev.code.slice();
      newValuesCode[indexInput].value = newValue;
      return {
        ...prev,
        code: newValuesCode,
      };
    });
  };

  const setInputError = (values = getDataTemplate()) => {
    setErrorData((prev) => ({
      ...prev,
      ...values,
    }));
  };

  const setStep = (step = 0) => {
    setValue((prev) => ({
      ...prev,
      loading: false,
      loadinResendCode: false,
      messageError: "",
      step: step,
    }));
  };

  const sendCodeReset = async (applyLoading = true) => {
    setValue((prev) => ({
      ...prev,
      loading: applyLoading === true,
      loadinResendCode: true,
      messageError: "",
    }));
    const errorMsg = await sessionDB.sendCodeResetPassword(data.email);
    if (errorMsg) {
      setValue((prev) => ({
        ...prev,
        loading: false,
        loadinResendCode: false,
        messageError: errorMsg,
      }));
      return false;
    }
    // success
    setValue((prev) => ({
      ...prev,
      loading: false,
      loadinResendCode: false,
      messageError: "",
    }));
    setTimerResendCode(45);
    return true;
  };

  /*====================================
  EJECUCION DE EVENTOS
  ======================================*/
  // enviar codigo por primera vez (step email)
  const execStepEmail = async () => {
    if (isValidData({ email: true })) {
      if (data.email !== data.oldEmail) {
        const respSend = await sendCodeReset(true);
        if (respSend) {
          setData((prev) => ({
            ...prev,
            oldEmail: prev.email,
          }));
          setStep(classLoginPage.STEP_RECOVERY_PASS.CODE);
        }
      } else {
        setStep(classLoginPage.STEP_RECOVERY_PASS.CODE);
      }
    }
  };

  // evaluar code y continuar a setNewpass (step code)
  const execStepCode = () => {
    if (isValidData({ code: true })) {
      setStep(classLoginPage.STEP_RECOVERY_PASS.NEW_PASS);
    }
  };

  // save new password (step pass) => ejecutar desde useLogin
  const execStepNewPass = async () => {
    if (isValidData({ newPass: true, confirmPass: true })) {
      setValue((prev) => ({
        ...prev,
        loading: true,
        messageError: "",
      }));
      const errorMsg = await sessionDB.confirmResetPassword(
        data.email,
        data.newPass,
        getCode()
      );
      // error ?
      if (errorMsg) {
        setValue((prev) => ({
          ...prev,
          loading: false,
          messageError: errorMsg,
        }));
        return;
      }
      //  success => auto-logueo
      const resp = await session.create(data.email, data.newPass);
      // exist error ?
      if (resp.error) {
        setValue((prev) => ({
          ...prev,
          loading: false,
          messageError: `${resp.error.message}. ${resp.error.details}`,
        }));
        return;
      }
      // success
      setValue((prev) => ({
        ...prev,
        loading: false,
        messageError: "",
      }));
      navigate("/viajes", { replace: true });
    }
  };

  const onClickBtnMain = () => {
    switch (value.step) {
      case classLoginPage.STEP_RECOVERY_PASS.EMAIL:
        execStepEmail();
        break;
      case classLoginPage.STEP_RECOVERY_PASS.CODE:
        execStepCode();
        break;
      default:
        execStepNewPass();
    }
  };

  // regresar al step email (step code)
  const backStepCode = () => {
    setValue((prev) => ({
      ...prev,
      loading: false,
      messageError: "",
      step: classLoginPage.STEP_RECOVERY_PASS.EMAIL,
    }));
    setData((prev) => ({
      ...getDataTemplate(),
      email: prev.email,
      oldEmail: prev.oldEmail,
    }));
  };

  // regresar al step code (step pass)
  const backStepPass = () => {
    setValue((prev) => ({
      ...prev,
      loading: false,
      messageError: "",
      step: classLoginPage.STEP_RECOVERY_PASS.CODE,
    }));
    setData((prev) => ({
      ...getDataTemplate(),
      email: prev.email,
      oldEmail: prev.oldEmail,
      code: prev.code,
    }));
  };

  const onClickBtnRegresar = () => {
    switch (value.step) {
      case classLoginPage.STEP_RECOVERY_PASS.CODE:
        backStepCode();
        break;
      case classLoginPage.STEP_RECOVERY_PASS.NEW_PASS:
        backStepPass();
        break;
    }
  };

  /*====================================
  VALIDACIONES
  ======================================*/
  const isValidData = (fields = getDataTemplate()) => {
    const newError = { ...errorData };
    let valid = true;
    // valid email
    if (fields.email) {
      if (/\s/.test(data.email)) {
        newError.email = "El correo ingresado no debe tener espacios";
        valid = false;
      } else if (!/.+@.+/.test(data.email)) {
        newError.email = "El correo electrónico es incorrecto.";
        valid = false;
      }
    }
    // valid newPassword
    if (fields.newPass) {
      if (isStringEmpty(data.newPass)) {
        newError.newPass = "Favor de ingresar su nueva contraseña";
        valid = false;
      } else if (!/\d/.test(data.newPass)) {
        newError.newPass = "Su contraseña debe tener al menos un digito";
        valid = false;
      } else if (!/[A-Z]|Ñ/.test(data.newPass)) {
        newError.newPass =
          "Su contraseña debe tener al menos una letra mayúscula";
        valid = false;
      } else if (!/[a-z]|ñ/.test(data.newPass)) {
        newError.newPass =
          "Su contraseña debe tener al menos una letra minúscula";
        valid = false;
      } else if (/\s/.test(data.newPass)) {
        newError.newPass = "Su contraseña no debe tener espacios";
        valid = false;
      } else if (data.newPass.length < 8) {
        newError.newPass = "Su contraseña debe tener al menos 8 caracteres";
        valid = false;
      }
    }
    // valid confirm password
    if (fields.confirmPass) {
      if (isStringEmpty(data.confirmPass)) {
        newError.confirmPass = "Favor de ingresar de nuevo su contraseña";
        valid = false;
      }
      if (data.confirmPass !== data.newPass) {
        newError.confirmPass = "Su contraseña no coincide";
        valid = false;
      }
    }
    // valid code
    if (fields.code) {
      const code = getCode();
      if (isStringEmpty(code)) {
        newError.code = "Favor de ingresar el código de verificación";
        valid = false;
      } else if (!/^\d+$/.test(code)) {
        newError.code = "El código de verificación solo debe contener digitos";
        valid = false;
      } else if (code.length !== 6) {
        newError.code =
          "El código de verificación debe tener exactamente 6 digitos";
        valid = false;
      }
    }
    setErrorData(newError);
    return valid;
  };

  const isWaitingSendCode = () => {
    return timerResendCode !== 0;
  };

  const inStepEmail = () => {
    return value.step === classLoginPage.STEP_RECOVERY_PASS.EMAIL;
  };
  const inStepCode = () => {
    return value.step === classLoginPage.STEP_RECOVERY_PASS.CODE;
  };
  const inStepNewPass = () => {
    return value.step === classLoginPage.STEP_RECOVERY_PASS.NEW_PASS;
  };

  return {
    value,
    data,
    errorData,
    timerResendCode,
    setInputData,
    setValueSingleInputCode,
    setInputError,
    init,

    onClickBtnRegresar,
    onClickBtnMain,

    sendCodeReset,

    getSubtitleHeader,
    getLabelBtnMain,
    getTitleForm,
    getCode,

    inStepEmail,
    inStepCode,
    inStepNewPass,
    isWaitingSendCode,
  };
};
