import { useEffect, useState } from "react";
import {
  isObject,
  isStringEmpty,
  isValidNaturalNumber,
} from "../utils/handler_validations";
import { sessionDB } from "../services";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "../context/session.context";
import { useLoginContext } from "../context/login.context";
import { useVeryNotificationsContext } from "../components/common/v2/VeryNotifications/useVeryNotifications";

const getValueTemplate = () => ({
  loading: false,
});
const getDataTemplate = () => ({
  newPass: "",
  confirmPass: "",
});

export const useNewPass = () => {
  const [value, setValue] = useState(getValueTemplate());
  const [data, setData] = useState(getDataTemplate());
  const [errorData, setErrorData] = useState(getDataTemplate());
  const navigate = useNavigate();
  const session = useSessionContext();
  const dataLogin = useLoginContext();
  const notis = useVeryNotificationsContext();

  const init = () => {
    setValue(getValueTemplate());
    setData(getDataTemplate());
    setErrorData(getDataTemplate());
  };

  /*====================================
  ESTABLECER VALORES
  ======================================*/
  const setInputData = (values = getDataTemplate()) => {
    setData((prev) => ({
      ...prev,
      ...values,
    }));
  };

  const setInputError = (values = getDataTemplate()) => {
    setErrorData((prev) => ({
      ...prev,
      ...values,
    }));
  };

  /*====================================
  EJECUCION DE EVENTOS
  ======================================*/
  const onClickBtnMain = async () => {
    if (isValidData({ newPass: true, confirmPass: true })) {
      setValue((prev) => ({
        ...prev,
        loading: true,
      }));
      const respDB = await sessionDB.setInitialPassword(
        dataLogin.data.email,
        dataLogin.data.pass,
        data.newPass
      );
      // cuando hay error
      if (respDB.error) {
        setValue((prev) => ({
          ...prev,
          loading: false,
        }));
        notis.createNotiError(
          "No fue posible establecer tu nueva contraseña",
          respDB.message
        );
        return;
      }
      //  success
      notis.createNotiSuccess("Tu contraseña fue actualizada");
      // auto-logueo
      const respSession = await session.create(
        dataLogin.data.email,
        data.newPass
      );
      // cuando hay error
      if (respSession.error) {
        notis.createNotiError(
          "Algo salio mal al iniciar sesión con tu nueva contraseña. Favor de intentarlo de nuevo",
          `${respSession.error.message}. ${respSession.error.details}`
        );
        setTimeout(() => init(), 500);
        dataLogin.setShowScreenNewPass(false);
        return;
      }
      // success
      navigate("/viajes", { replace: true });
    }
  };

  /*====================================
  VALIDACIONES
  ======================================*/
  const isValidData = (fields = getDataTemplate()) => {
    const newError = { ...errorData };
    let valid = true;
    // valid newPassword
    if (fields.newPass) {
      if (isStringEmpty(data.newPass)) {
        newError.newPass = "Favor de ingresar su nueva contraseña";
        valid = false;
      } else if (!/\d/.test(data.newPass)) {
        newError.newPass = "Su contraseña debe tener al menos un digito";
        valid = false;
      } else if (!/[A-Z]|Ñ/.test(data.newPass)) {
        newError.newPass =
          "Su contraseña debe tener al menos una letra mayúscula";
        valid = false;
      } else if (!/[a-z]|ñ/.test(data.newPass)) {
        newError.newPass =
          "Su contraseña debe tener al menos una letra minúscula";
        valid = false;
      } else if (/\s/.test(data.newPass)) {
        newError.newPass = "Su contraseña no debe tener espacios";
        valid = false;
      } else if (data.newPass.length < 8) {
        newError.newPass = "Su contraseña debe tener al menos 8 caracteres";
        valid = false;
      }
    }
    // valid confirm password
    if (fields.confirmPass) {
      if (isStringEmpty(data.confirmPass)) {
        newError.confirmPass = "Favor de ingresar de nuevo su contraseña";
        valid = false;
      }
      if (data.confirmPass !== data.newPass) {
        newError.confirmPass = "Su contraseña no coincide";
        valid = false;
      }
    }
    setErrorData(newError);
    return valid;
  };

  return {
    value,
    data,
    errorData,
    setInputData,
    setInputError,
    init,

    onClickBtnMain,
  };
};
