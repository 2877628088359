import styled from "styled-components";
import { DivContentPage } from "../../components/common/DivContentPage";

const DivMain = styled(DivContentPage)`
  font-family: "Roboto", "Helvetica";
  width: 100%;
  display: flex;
  > main {
    width: 100%;
    min-width: min-content;
    height: 296px;
    display: flex;
    flex-direction: column;
    gap: 17px;
    padding: 15px 35px;
    justify-content: center;
    border: solid #eaeaea 1px;
    border-radius: 10px;
    .panel-sup {
      width: 100%;
      height: 10%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .panel-info {
        width: 340px;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        .title {
          font-size: 18px;
          font-family: "Roboto";
          font-weight: 700;
          color: #13131d;
        }
        .role {
          background-color: #88c053;
          color: #ffffff;
          font-size: 12px;
          font-family: "Roboto";
          font-weight: 700;
          padding: 4px 17px 4px 17px;
          border-radius: 25px;
        }
      }
      .btnEditProfile {
        width: 7%;
        height: 30px;
        background-color: #f8f8fa;
        border: solid #eaeaea 1px;
        label {
          font-family: "Roboto";
          font-weight: 400;
        }
      }
    }

    .containerForm {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .title {
        font-size: 14px;
        font-family: "Roboto";
        font-weight: 700;
        color: #13131d4d;
      }
      label {
        font-size: 18px;
        font-family: "Roboto";
        font-weight: 400;
        color: #13131d;
      }
      .container-name {
        width: 600px;
        height: 50px;
        display: flex;
        flex-direction: row;
        .firstname,
        .lastname {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
      .container-more {
        width: 600px;
        height: 50px;
        display: flex;
        flex-direction: row;
        .email,
        .phone {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }
  }
`;

export default {
  DivMain,
};
