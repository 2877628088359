import React from "react";
import Login from "./pages/login";
import DriversPage from "./pages/list-drivers";
import PassengersPage from "./pages/list-passengers";
import TripsPage from "./pages/list-services";
import { Routes, Route } from "react-router-dom";
import AdminsPage from "./pages/list-admins";
import ListCoupons from "./pages/coupons";
import NotFound from "./pages/not-found";
import PageHome from "./pages/home";
import Dashboard from "./pages/dashboard";
import Profile from "./pages/profile";

const AppRoutes = () => {
  return (
    <>
      {/* URLs principales */}
      <Routes>
        <Route path={"login"} exact element={<Login />} />
        <Route path={"/"} element={<PageHome />}>
          {/* CONTENIDO DE LA PAGINA */}
          <Route path={"dashboard"} exact element={<Dashboard />} />
          <Route path={"viajes"} exact element={<TripsPage />} />
          <Route path={"pasajeros"} exact element={<PassengersPage />} />
          <Route path={"conductores"} exact element={<DriversPage />} />
          <Route path={"administradores"} exact element={<AdminsPage />} />
          <Route path={"cupones"} exact element={<ListCoupons />} />
          <Route path={"perfil"} exact element={<Profile />} />
          {/* URL NOT FOUND */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
