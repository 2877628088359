import styled from "styled-components";

const DivMain = styled.div`
  width: 100%;
  padding: 2px 10px 2px 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  //border: 1px solid blue;
  .txtTitle,
  .txtQuantity {
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #13131d;
  }
  .txtTitle {
    flex: 1;
  }
  .txtQuantity {
    font-weight: 700;
  }
`;

export default {
  DivMain,
};
