import { useEffect, useState } from "react";
import styles from "./styles";
import { isStringEmpty } from "../../../utils/handler_validations";
import { classVeryIconGoogle } from "../../common/v2/VeryIconGoogle/classVeryIconGoogle";
import { useCouponsContext } from "../../../context";
import { useMemo } from "react";
import { VeryIconGoogle } from "../../common/v2/VeryIconGoogle";
import { Grow, Tooltip } from "@mui/material";

const PanelCoupon = (props) => {
  const coupons = useCouponsContext();
  const coupon = useMemo(
    () => coupons.getCouponSelected(),
    [coupons.rowSelected, coupons.value.list]
  );

  // renderizar una fecha
  const renderDate = (
    title = "",
    titleTooltip = "",
    date = "",
    dateTooltip = "",
    isExpiratedDate = false
  ) => {
    const txtDateCSS = ["txt-date"];
    if (isExpiratedDate) txtDateCSS.push("expiratedDate");

    return (
      <Tooltip
        PopperProps={{ disablePortal: true }}
        placement="top"
        disableFocusListener
        disableTouchListener
        TransitionComponent={Grow}
        TransitionProps={{ timeout: 500 }}
        title={
          <>
            <label className="txt-title">{titleTooltip}</label>
            <label className="txt-date">{dateTooltip}</label>
          </>
        }
      >
        <div className="div-date">
          <label className="txt-title">{title}</label>
          <label className={txtDateCSS.join(" ")}>{date}</label>
        </div>
      </Tooltip>
    );
  };

  // items de la lista del historial de logs
  const renderLogs = () => {
    const items = [];
    coupon.logsHistory?.forEach((log, index) => {
      const customLog = coupons.getCustomItemLog(log);
      if (customLog) {
        items.push(
          <li key={"log-" + index}>
            <label className="txt-item">
              <label className="txt-log">
                {`${customLog.userType} ${customLog.userName} `}
              </label>
              <label className="txt-statusType">{customLog.statusType}</label>
              <label className="txt-log">{` el ${customLog.date}`}</label>
            </label>
          </li>
        );
      }
    });

    return items;
  };

  return (
    <styles.MainContainer>
      {isStringEmpty(coupon.code) ? (
        <>
          {/* PANEL EMPTY */}
          <div className="div-panelEmpty">
            <VeryIconGoogle
              name="confirmation_number"
              type={classVeryIconGoogle.ICON_TYPE.OUTLINED}
              size={30}
              color={classVeryIconGoogle.ICON_COLOR.GRAY_S1}
            />
            <label className="txt-title">Selecciona un cupón</label>
          </div>
        </>
      ) : (
        <>
          {/* CONTAINER HEADER */}
          <div className="div-header">
            <div className="div-code">
              <label className="txt-title">Cupón</label>
              <label className="txt-code">{coupon.code}</label>
            </div>
            <div className="div-amount">
              <label className="txt-title" />
              <div className="div-valueAmount">
                <label className="txt-value">${coupon.amount}</label>
                <VeryIconGoogle
                  name="confirmation_number"
                  type={classVeryIconGoogle.ICON_TYPE.FILLED}
                  size={13}
                  color={classVeryIconGoogle.ICON_COLOR.GREEN_S2}
                />
              </div>
            </div>
          </div>

          {/* CONTAINER DETAILS COUPON */}
          <div className="div-couponDetails">
            {renderDate(
              "Fecha de creación",
              "Fecha de creación",
              coupon.creationDate?.short,
              coupon.creationDate?.full
            )}
            {renderDate(
              coupon.customDate?.title,
              coupon.customDate?.titleTooltip,
              coupon.customDate?.short,
              coupon.customDate?.full,
              coupon.customDate?.isExpiratedDate
            )}
          </div>

          {/* CONTAINER LOGS HISTORY */}
          <div className="div-logsHistory">
            <label className="txt-title">Historial</label>
            <ul className="div-list">{renderLogs()}</ul>
          </div>
        </>
      )}
    </styles.MainContainer>
  );
};

export default PanelCoupon;
