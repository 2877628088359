import {
  decoJSON,
  isObject,
  isPositiveInt,
  isStringEmpty,
  isValidNaturalNumber,
} from "./handler_validations";

const getDataUserTemplate = () => ({
  id: 0,
  name: "",
  lastName: "",
  email: "",
  tel: "",
  profileImg: "",
});

// validar la data del user
const getDataUser = (user = getDataUserTemplate()) => {
  const data = getDataUserTemplate();
  // validar ID
  if (isPositiveInt(user?.id)) data.id = Number(user.id);
  // validar name
  if (!isStringEmpty(user?.name)) data.name = user.name.trim();
  // validar lastName
  if (!isStringEmpty(user?.lastName)) data.lastName = user.lastName.trim();
  // validar tel
  if (!isStringEmpty(user?.tel)) data.tel = user.tel.trim();
  // validar email
  if (!isStringEmpty(user?.email)) data.email = user.email.trim();
  // validar img
  if (!isStringEmpty(user?.profileImg))
    data.profileImg = user.profileImg.trim();
  return data;
};

export default {
  getDataUserTemplate,
  getDataUser,
};
