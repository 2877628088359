import styles from "./styles";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { classVeryIconGoogle } from "../../components/common/v2/VeryIconGoogle/classVeryIconGoogle";
import { VeryBtnFill } from "../../components/common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../../components/common/v2/VeryBtnFill/classVeryBtnFill";
import CustomCard from "../../components/dashboard-page/custom-card";
import CustomCard2 from "../../components/dashboard-page/custom-card2";
import SelectTime from "../../components/dashboard-page/SelectTime";
import { useDashboardContext } from "../../context";
import { TripsChart } from "../../components/dashboard-page/TripsChart";
import { DriversChart } from "../../components/dashboard-page/DriversChart";
import { useMemo } from "react";
import { ItemReport } from "../../components/dashboard-page/ItemReport";
import {
  getFormattedNumber,
  getNotNegativeNumber,
} from "../../utils/handler_validations";

export default function ContentDashboardPage(props) {
  const dataDashboard = useDashboardContext();
  const navigate = useNavigate();
  // total ganancia
  const totalEarning = useMemo(() => {
    return dataDashboard.getTotalEarning();
  }, [dataDashboard.filters?.earning]);
  // data del reporte
  const reportData = useMemo(() => {
    return dataDashboard.getReport();
  }, [dataDashboard.filters?.report]);
  // data panel viajes en transcurso
  const inProgressTrips = useMemo(() => {
    return {
      total: getFormattedNumber(
        dataDashboard.value.inProgressTrips.total,
        true,
        0
      ),
      earning: getFormattedNumber(
        dataDashboard.value.inProgressTrips.earning,
        true,
        2
      ),
      coupons: {
        total: getFormattedNumber(
          dataDashboard.value.inProgressTrips.coupons.total,
          true,
          0
        ),
        amount: getFormattedNumber(
          dataDashboard.value.inProgressTrips.coupons.amount,
          true,
          2
        ),
      },
    };
  }, [dataDashboard.value.inProgressTrips]);

  return (
    <styles.DivMain
      isLoading={dataDashboard.value.page.loading}
      error={dataDashboard.value.page.error}
    >
      {/* CONTAINER TOP CARDS */}
      <div className="panel-cards">
        <CustomCard
          title="Conductores activos"
          quantity={dataDashboard.value.activeDrivers}
          icon={{
            name: "perm_contact_calendar",
            size: 22,
            type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
            color: classVeryIconGoogle.ICON_COLOR.BLACK_S1,
          }}
        />
        <CustomCard
          title="Conductores conectados"
          quantity={dataDashboard.value.connectedDrivers}
          icon={{
            name: "interactive_space",
            size: 22,
            type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
            color: classVeryIconGoogle.ICON_COLOR.BLACK_S1,
          }}
        />
        <CustomCard
          title="Viajes en espera"
          quantity={dataDashboard.value.waitingTrips}
          icon={{
            name: "hourglass_empty",
            size: 22,
            type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
            color: classVeryIconGoogle.ICON_COLOR.BLACK_S1,
          }}
        />
        <CustomCard
          title="Total de usuarios"
          quantity={dataDashboard.value.totalUsers}
          icon={{
            name: "group",
            size: 22,
            type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
            color: classVeryIconGoogle.ICON_COLOR.BLACK_S1,
          }}
        />
      </div>
      {/* CONTAINER GRAPHICS + INFO LATERAL */}
      <div className="panel-data">
        {/* CONTAINER GRAPHICS */}
        <div className="panel-graph">
          <TripsChart />
          <DriversChart />
        </div>
        {/* CONTAINER INFO LATERAL */}
        <div className="panel-info">
          {/* TOTAL EARNING */}
          <CustomCard2
            title="Ingreso total"
            quantity={totalEarning}
            icon={{
              name: "monetization_on",
              size: 22,
              type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
            }}
          />
          {/* IN PROGRESS TRIPS */}
          <div className="panel-viajes">
            <VeryBtnFill
              CSS={["btnViajes"]}
              label={"Viajes en transcurso actuales"}
              colorTheme={classVeryBtnFill.COLOR_THEME.BLACK_S1}
              textSize={13}
              icon={{
                name: "chevron_right",
                size: 15,
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED,
              }}
              onClick={() => navigate("/viajes ", { replace: true })}
            />
            <div className="panel-content">
              <label className="txtTripsQuantity">
                {inProgressTrips.total} Viajes
              </label>
              <div className="panel-recuadros">
                <div className="divPanel">
                  <label className="txtTitle">Movimientos en efectivo</label>
                  <div className="divContent">
                    <div className="divAmount">
                      <label className="txtMXN">MXN</label>
                      <label className="txtAmount">
                        ${inProgressTrips.earning}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="divPanel">
                  <label className="txtTitle">Cupones implementados</label>
                  <div className="divContent">
                    <label className="txtCouponsQuantity">
                      {inProgressTrips.coupons.total} cupones
                    </label>
                    <div className="divAmount divAmountCoupon">
                      <label className="txtMXN">MXN</label>
                      <label className="txtAmount">
                        ${inProgressTrips.coupons.amount}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* REPORT */}
          <div className="panel-reporte">
            <div className="panel-sup">
              <label className="txtTitle">Reporte General</label>
              <SelectTime
                onChangeValue={(newValue) => {
                  dataDashboard.changeFilters({
                    report: newValue,
                  });
                }}
              />
            </div>
            <div className="panel-inf">
              <ItemReport
                title="Viajes completados"
                quantity={reportData.completed}
                icon={{
                  name: "assignment_turned_in",
                  size: 21,
                  type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
                  color: classVeryIconGoogle.ICON_COLOR.GRAY_S1,
                }}
              />
              <ItemReport
                title="Viajes cancelados"
                quantity={reportData.canceled}
                icon={{
                  name: "disabled_by_default",
                  size: 21,
                  type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
                  color: classVeryIconGoogle.ICON_COLOR.GRAY_S1,
                }}
              />
              <ItemReport
                title="Viajes rechazados"
                quantity={reportData.rejected}
                icon={{
                  name: "do_not_disturb_on",
                  size: 21,
                  type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
                  color: classVeryIconGoogle.ICON_COLOR.GRAY_S1,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </styles.DivMain>
  );
}
