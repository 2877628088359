import React from "react";
import {
  isArrayEmpty,
  isNumberPositive,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import styles from "./styles";
import { Checkbox } from "@mui/material";
import { VeryLoading } from "../VeryLoading";
import { classVeryLoading } from "../VeryLoading/classVeryLoading";
import classVeryCheckbox from "./classVeryCheckbox";


export const VeryCheckbox = (props = classVeryCheckbox.getPropsTemplate()) => {
  const CSS = ["veryCheckbox"];
  // add custom style
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) CSS.push(props.className);

  return (
    <styles.DivMain className={CSS.join(" ")}>
      <Checkbox
        className="btnCheckbox"
        disabled={props.isDisabled === true}
        checked={props.selected === true}
        onChange={(event, selected) => {
          if (props.isLoading === true) return;
          props.onChangeSelected && props.onChangeSelected(selected);
        }}
      />
      {props.isLoading === true && (
        <VeryLoading
          CSS={["loadingCheckbox"]}
          color={classVeryLoading.COLOR.BLACK_S1}
          size={15}
          weight={3}
        />
      )}
    </styles.DivMain>
  );
};