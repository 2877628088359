import { ModalAskProvider, PassengersProvider } from "../../context";
import { ContentListPassengersPage } from "./content";

const PassengersPage = (props) => {
  return (
    <ModalAskProvider>
      <PassengersProvider>
        <ContentListPassengersPage />
      </PassengersProvider>
    </ModalAskProvider>
  );
};

export default PassengersPage;
