import { useState, useRef, useEffect } from "react";
import styles from "./styles";
import { isStringEmpty } from "../../utils/handler_validations";
import { VeryBtnFill } from "../../components/common/v2/VeryBtnFill";
import { useFormAdminContext, useSessionContext } from "../../context";
import CustomImg from "../../components/common/custom-img";
import { classCustomImg } from "../../components/common/custom-img/utils/classCustomImg";
import { classCustomLoading } from "../../components/common/custom-loading/utils/classCustomLoading";
import { VeryIconGoogle } from "../../components/common/v2/VeryIconGoogle";
import { classVeryIconGoogle } from "../../components/common/v2/VeryIconGoogle/classVeryIconGoogle";
import { getFileExtension } from "../../utils/handler_files";
import { getDigitsTel, getFormattedTel } from "../../utils/handler_tel";

const ContentProfilePage = () => {
  const session = useSessionContext();
  const formAdmin = useFormAdminContext();

  useEffect(() => {
    formAdmin.setInitialData({
      profileImg: {
        src: session.getProfileImgURL(1000, 1000),
        ext: getFileExtension(session.value.user.profileImg),
      },
      name: session.getName(),
      lastName: session.getLastName(),
      email: session.getEmail(),
      tel: getDigitsTel(session.value.user.tel),
    });
  }, [session.value.user]);

  return (
    <>
      <styles.DivMain isLoading={false} error={{ message: "", details: "" }}>
        <main>
          <div className="panel-sup">
            <div className="panel-info">
              <label className="title">Información personal</label>
              <label className="role">ADMINISTRADOR</label>
            </div>
            <VeryBtnFill
              CSS={["btnEditProfile"]}
              label="Editar"
              textSize={14}
              colorTheme="GRAY_S2"
              onClick={() => formAdmin.setShowModal(true)}
            />
          </div>

          {/* IMG PROFILE */}
          <CustomImg
            className="divImgProfile"
            borderType={classCustomImg.borderType.circle}
            propsLoading={{
              size: 20,
              color: classCustomLoading.color.orangeS1,
            }}
            width="80px"
            src={session.getProfileImgURL(250, 250)}
            componentDefault={
              <VeryIconGoogle
                name="person"
                color={classVeryIconGoogle.ICON_COLOR.BLACK_S1}
                type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED}
                size={20}
              />
            }
          />

          {/* container form */}
          <div className="containerForm">
            <div className="container-name">
              <div className="firstname">
                <label className="title">Nombre</label>
                <label>{session.getName()}</label>
              </div>
              <div className="lastname">
                <label className="title">Apellidos</label>
                <label>{session.getLastName()}</label>
              </div>
            </div>
            <div className="container-more">
              <div className="email">
                <label className="title">Email</label>
                <label>{session.getEmail()}</label>
              </div>
              <div className="phone">
                <label className="title">Teléfono</label>
                <label>{getFormattedTel(session.value.user.tel, " ")}</label>
              </div>
            </div>
          </div>
        </main>
      </styles.DivMain>
    </>
  );
};

export default ContentProfilePage;
