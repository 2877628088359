import React from "react";
import {
  isArrayEmpty,
  isNumberPositive,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import styles from "./styles";
import { VeryIconGoogle } from "../VeryIconGoogle";
import { classVeryBtnFill } from "./classVeryBtnFill";
import { VeryLoading } from "../VeryLoading";

export const VeryBtnFill = (props = classVeryBtnFill.propsTemplate()) => {
  const theme = classVeryBtnFill.getColorTheme(
    props.colorTheme,
    props.isDisabled
  );
  const textSize = isNumberPositive(props.textSize)
    ? Number(props.textSize)
    : 16;

  const CSS = ["veryBtnFill"];
  // add custom style
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) CSS.push(props.className);
  // add style theme
  if (!isStringEmpty(theme.className)) CSS.push(theme.className);
  // add class is loading
  if (props.isLoading === true) CSS.push("isLoading");

  return (
    <styles.divMain
      className={CSS.join(" ")}
      iconPosition={classVeryBtnFill.getIconPosition(props.icon?.position)}
      textSize={textSize}
      disabled={props.isDisabled === true}
      onClick={(event) => props.onClick && props.onClick()}
    >
      {props.isLoading === true ? (
        <VeryLoading
          color={theme.loadingColor}
          weight={4}
          size={props.icon?.size}
        />
      ) : (
        !isStringEmpty(props.icon?.name) && (
          <VeryIconGoogle
            name={props.icon.name}
            type={props.icon.type}
            color={theme.iconColor}
            size={props.icon?.size}
          />
        )
      )}
      {!isStringEmpty(props.label) && (
        <label className="txtLabel">{props.label}</label>
      )}
    </styles.divMain>
  );
};
