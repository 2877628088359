import CSS from "./styles";
import logo from "../assets/logos/very-yellow-02.svg";

export default function SplashScreen() {
  return (
    <CSS.divMain>
      <img className="imgLogo" src={logo} />
      {/*<CSS.linearProgress />*/}
      <label className="txtLoading">Cargando...</label>
    </CSS.divMain>
  );
}
