import styled from "styled-components";

const divMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: inherit;
  //border: 2px solid blue;
`;

export default {
  divMain,
};
