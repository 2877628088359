export class classCustomIcon {
  // props recibidas
  static propsType = {
    className: "",
    size: "",
    color: "",
    iconName: "",
    iconType: "",
    transparent: false,
    spin: false,
  };
  // tipos de iconos disponibles
  static iconType = {
    outlined: "material-icons-outlined",
    filled: "material-icons",
    rounded: "material-icons-round",
    sharp: "material-icons-sharp",
    //twoTone: "material-icons-two-tone",
  };
  // colores para el icono
  static color = {
    orangeS1: "orangeS1",
    orangeS2: "orangeS2",
    whiteS1: "whiteS1",
    greenS1: "greenS1",
    redS1: "redS1",
    blackS1: "blackS1",
    grayS1: "grayS1",
    yellowS1: "yellowS1",
  };
}
