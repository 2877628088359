import { isStringEmpty } from "../../../utils/handler_validations";
import { VeryLoading } from "../v2/VeryLoading";
import { classVeryLoading } from "../v2/VeryLoading/classVeryLoading";
import styles from "./styles";
import logo from "../../../assets/logos/very-yellow-icon-01.png";
import { VeryBtnTransparent } from "../v2/VeryBtnTransparent";
import { classVeryBtnTransparent } from "../v2/VeryBtnTransparent/classVeryBtnTransparent";
import { useEffect, useState } from "react";
import { DivLoading } from "../DivLoading";
import { VeryBtnFill } from "../v2/VeryBtnFill";
import { classVeryBtnFill } from "../v2/VeryBtnFill/classVeryBtnFill";
import { VeryIconGoogle } from "../v2/VeryIconGoogle";
import { classVeryIconGoogle } from "../v2/VeryIconGoogle/classVeryIconGoogle";

const propsTemplate = () => ({
  isLoading: false,
  error: { message: "", details: "" },
  emptyMessage: { text: "", icon: { name: "", type: "" } },
  onClickBtnRetry: () => {},
});

export const DivTablePage = (props = propsTemplate()) => {
  const [showModalError, setShowModalError] = useState(false);

  useEffect(() => {
    setShowModalError(false);
  }, [props.error]);

  return (
    <>
      <styles.DivMain className={props.className}>
        {props.isLoading ? (
          <DivLoading size={70} weight={4} />
        ) : !isStringEmpty(props.error?.message) ? (
          <div className="divError">
            <img src={logo} />
            <label className="txtMessage">{props.error.message}</label>
            <div className="divButtons">
              <VeryBtnTransparent
                CSS={["btnMoreInfo"]}
                colorTheme={classVeryBtnTransparent.COLOR_THEME.BLACK_S1}
                label="Más información"
                textSize={13}
                onClick={() => setShowModalError(true)}
              />
              <VeryBtnFill
                CSS={["btnRetry"]}
                colorTheme={classVeryBtnFill.COLOR_THEME.BLACK_S1}
                label="Reintentar"
                textSize={14}
                onClick={props.onClickBtnRetry}
              />
            </div>
          </div>
        ) : !isStringEmpty(props.emptyMessage?.text) ? (
          <div className="divEmptyMessage">
            <VeryIconGoogle
              name={props.emptyMessage.icon?.name}
              type={props.emptyMessage.icon?.type}
              size={70}
              color={classVeryIconGoogle.ICON_COLOR.GRAY_S1}
            />
            <label className="txtMessage">{props.emptyMessage.text}</label>
          </div>
        ) : (
          props.children
        )}
      </styles.DivMain>

      {/* MODAL ERROR */}
      <styles.ModalError
        isVisible={showModalError}
        title="Detalles del error"
        onClickClose={() => setShowModalError(false)}
      >
        <label className="txtError">
          {isStringEmpty(props.error?.details)
            ? "Lo sentimos no hay detalles de este error"
            : props.error.details}
        </label>
      </styles.ModalError>
    </>
  );
};
