import { sendError } from "./handler_error";
import {
  encoURI,
  getArray,
  getNumber,
  isStringEmpty,
} from "./handler_validations";
import { normalFetchGET } from "./handler_http";

/*====================================================================
  HANDLE ADDRESS DATA (API GOOGLE MAPS)
======================================================================*/
const getAddressComponentsTemplate = () => {
  let list = [{ long_name: "", types: [""] }];
  list = [];
  return list;
};

/**
 * datos de una dirección obtenida con la API Geocoding Google Maps
 * El parametro "addressComponents" se requiere solo para formatear
 * los datos de la respuesta de la API
 */
export const getAddressTemplate = (
  addressComponents = getAddressComponentsTemplate()
) => {
  const data = {
    country: "", // pais
    state: "", // estado
    city: "", // ciudad o pueblo
    district: "", // colonia
    postalCode: "", // codigo postal
    street: "", // calle o avenida
    streetNumber: "", // numero de calle o avenida
    name: "", // nombre del lugar
  };
  for (const comp of getArray(addressComponents)) {
    const value = comp.long_name;
    for (const compType of comp.types) {
      let nextComp = true;
      switch (compType) {
        case "country": // Pais
          data.country = value;
          break;
        case "administrative_area_level_1": // Estado
          data.state = value;
          break;
        case "locality": // Ciudad o pueblo
          data.city = value;
          break;
        case "sublocality": // Colonia
          data.district = value;
          break;
        case "route": // Calle o avenida
          data.street = value;
          break;
        case "street_number": // Numero de la direccion (ejem. num de calle)
          data.streetNumber = value;
          break;
        case "postal_code": // Codigo postal
          data.postalCode = value;
          break;
        case "establishment":
        case "point_of_interest":
          data.name = value;
          break;
        default:
          nextComp = false;
          break;
      }
      if (nextComp) break;
    }
  }
  // finally
  return data;
};

/**
 * datos de una ubicacion obtenidos con la API Geocoding Google Maps
 * ejemplo => { coords: {} , address: {} }
 */
export const getLocationTemplate = (
  coords = getCoordsTemplate(),
  address = getAddressTemplate()
) => ({
  coords,
  address,
});

/**
 * Devuelve una dirección corta con el formato => name calle num, colonia
 * Ejemplo => Calle San Pedro #23, Ciudad del Valle
 */
export const getFormattedAddress_S1 = (
  addressData = getAddressTemplate(),
  showStreetNumber = true
) => {
  const address = [];
  if (!isStringEmpty(addressData.name)) {
    address.push(addressData.name);
  }
  if (!isStringEmpty(addressData.street)) {
    let street = addressData.street;
    if (showStreetNumber && !isStringEmpty(addressData.streetNumber)) {
      street += ` #${addressData.streetNumber}`;
    }
    address.push(street);
  }
  // exist district
  if (!isStringEmpty(addressData.district)) {
    if (!address.length) address.push("Calle desconocida");
    address.push(addressData.district);
    return address.join(", ");
  }
  // exist name or street
  if (address.length) {
    return address.join(", ");
  }
  // no exist data
  return "Dirección desconocida";
};

/**
 * Devuelve una dirección corta con el formato => ciudad, estado, pais, CP
 * Ejemplo => Tepic, Nayarit, Mexico, 63457
 */
export const getFormattedAddress_S2 = (addressData = getAddressTemplate()) => {
  const address = [];
  if (!isStringEmpty(addressData.city)) {
    address.push(addressData.city);
  }
  if (!isStringEmpty(addressData.state)) {
    address.push(addressData.state);
  }
  if (!isStringEmpty(addressData.country)) {
    address.push(addressData.country);
  }
  if (!isStringEmpty(addressData.postalCode)) {
    address.push(addressData.postalCode);
  }
  return address.length > 1 ? address.join(", ") : "";
};

/**
 * Devuelve una dirección larga con el formato => calle num, colonia, ciudad, estado
 * Ejemplo => Calle San Pedro #23, Ciudad del Valle, Tepic, Nayarit
 */
export const getFormattedAddress_S3 = (addressData = getAddressTemplate()) => {
  const address = getFormattedAddress_S1(addressData);
  const extraAddress = [];
  if (!isStringEmpty(addressData.city)) {
    extraAddress.push(addressData.city);
  }
  if (!isStringEmpty(addressData.state)) {
    extraAddress.push(addressData.state);
  }
  return extraAddress.length
    ? `${address}, ${extraAddress.join(", ")}`
    : "Ubicación desconocida";
};

/**
 * Devuelve una dirección corta con el formato => name/colonia/calle - ciudad
 * Ejemplo => Ciudad del Valle - Tepic
 */
export const getFormattedAddress_S4 = (addressData = getAddressTemplate()) => {
  const address = [];
  if (!isStringEmpty(addressData.name)) {
    address.push(addressData.name);
  } else if (!isStringEmpty(addressData.district)) {
    address.push(addressData.district);
  } else if (!isStringEmpty(addressData.street)) {
    address.push(addressData.street);
  }
  // exist city
  if (!isStringEmpty(addressData.city)) {
    if (!address.length) address.push("Colonia desconocida");
    address.push(addressData.city);
    return address.join(" - ");
  }
  // NO exist city
  if (address.length) {
    address.push("Ciudad desconocida");
    return address.join(" - ");
  }
  // NO location
  return "Ubicación desconocida";
};

/*====================================================================
  HANDLE COORDS DATA
======================================================================*/
export const getCoordsTemplate = (latitude = 0, longitude = 0) => ({
  latitude: getNumber(latitude),
  longitude: getNumber(longitude),
});

export const existCoords = (coords = getCoordsTemplate()) => {
  const lat = getNumber(coords.latitude);
  const long = getNumber(coords.longitude);
  return lat === 0 && long === 0 ? false : true;
};

/*====================================================================
  HANDLE GENERAL
======================================================================*/
// devuelve un posible error de la respuesta de la API Geocoding Google Maps
const getErrorStatusGGM = (status = "") => {
  switch (status) {
    case "OK":
    case "ZERO_RESULTS":
      return sendError();
    case "OVER_DAILY_LIMIT":
      return sendError("Error 432. Favor de reportar esto a soporte");
    case "OVER_QUERY_LIMIT":
      return sendError("Error 447. Favor de reportar esto a soporte");
    case "REQUEST_DENIED":
      return sendError("Error 458. Favor de reportar esto a soporte");
    case "INVALID_REQUEST":
      return sendError("Error 461. Favor de reportar esto a soporte");
    case "UNKNOWN_ERROR":
      return sendError(
        "Comprueba tu conexión a Internet y vuelve a intentarlo"
      );
    default:
      return sendError("Error 479. Favor de reportar esto a soporte");
  }
};

/**
 * Obtener los datos de una dirección a partir de una coordenada
 * { latitude , longitude } mediante la API Geocoding de Google Maps
 */
export const getAddress = async (
  geocodingAPI,
  coords = getCoordsTemplate()
) => {
  // resp template
  const sendResp = (
    error = { message: "", details: "" },
    data = getAddressTemplate()
  ) => ({
    error: error === null ? undefined : error,
    data: error === null ? data : undefined,
  });

  return new Promise(async (resolve) => {
    // geocoder empty
    //console.log("geocoding API => ", geocodingAPI);
    if (!geocodingAPI || geocodingAPI === null) {
      resolve(
        sendResp({
          message: "No fue posible obtener los datos de la ubicación",
          details: "Error 481. Favor de notificar esto a soporte",
        })
      );
      return;
    }
    // coords empty
    if (!existCoords(coords)) {
      resolve(
        sendResp({
          message: "No fue posible obtener los datos de la ubicación",
          details: "Las coordenadas no fueron especificadas",
        })
      );
      return;
    }
    const geocoder = new geocodingAPI.Geocoder();
    try {
      await geocoder.geocode(
        {
          location: { lat: coords.latitude, lng: coords.longitude },
        },
        (results, status) => {
          // request API status ERROR
          const respStatus = getErrorStatusGGM(status);
          if (respStatus.error) {
            //console.log("==== ERROR getAddress() ====");
            //console.log(respStatus.message);
            resolve(
              sendResp({
                message: "No fue posible obtener los datos de la ubicación",
                details: respStatus.message,
              })
            );
            return;
          }
          // empty results
          if (!results.length) {
            resolve(
              sendResp({
                message: "No fue posible obtener los datos de la ubicación",
                details: "Favor de notificar esto a soporte",
              })
            );
            return;
          }
          // success
          //console.log("<<< resp getAddress_S2() >>>");
          //console.log(resp.body.results[0].address_components);
          resolve(
            sendResp(null, getAddressTemplate(results[0].address_components))
          );
          return;
        }
      );
    } catch (error) {
      //console.log("AQUI => ", error);
      resolve(
        sendResp({
          message: "No fue posible obtener los datos de la ubicación",
          details: "Error 481. Favor de reportar esto a soporte",
        })
      );
      return;
    }
  });
};
