import ModalAdmin from "../../components/modal-admin";
import {
  AdminsProvider,
  FormAdminProvider,
  ModalAskProvider,
} from "../../context";
import ContentAdminsPage from "./content";

const AdminsPage = () => {
  return (
    <ModalAskProvider>
      <FormAdminProvider>
        <AdminsProvider>
          <ContentAdminsPage />
          <ModalAdmin />
        </AdminsProvider>
      </FormAdminProvider>
    </ModalAskProvider>
  );
};

export default AdminsPage;
