import { CouponsProvider } from "../../context";
import ContentCouponsPage from "./content";

const CouponsPage = (props) => {
  return (
    <CouponsProvider>
      <ContentCouponsPage />
    </CouponsProvider>
  );
};

export default CouponsPage;
