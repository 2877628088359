import { createRef } from "react";
export class classVeryInput {
  // props recibidas
  static propsTemplate = () => ({
    onChangeRefInput: (ref = createRef()) => {},
    CSS: [],
    title: "",
    text: "",
    placeHolder: "",
    textError: "",
    inLeftComponent: <></>,
    inRightComponent: <></>,
    multiline: 3,
    size: { title: 14, text: 16, textError: 12 },
    type: "",
    isDisabled: false,
    isEditable: false,
    isRequired: false,
    onLostFocus: () => {},
    onWonFocus: () => {},
    onEnter: () => {},
    onChangeText: (newText = "") => {},
  });
  static TYPE_INPUT = {
    NORMAL: "text",
    PASS: "password",
  };
  static getTypeInput = (type = "") => {
    return Object.values(this.TYPE_INPUT).includes(type)
      ? type
      : this.TYPE_INPUT.NORMAL;
  };
}
