import { Outlet } from "react-router-dom";
import styles from "./styles";
import { VeryBtnFill } from "../../components/common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../../components/common/v2/VeryBtnFill/classVeryBtnFill";
import { useState } from "react";
import MenuProfile from "../../components/home-page/MenuProfile";
import { useFormDriverContext } from "../../context";

const ContentHomePage = () => {
  const formDriver = useFormDriverContext();
  const [showMenuProfile, setShowMenuProfile] = useState(false);

  return (
    <styles.DivPage>
      {/* HEADER */}
      <div className="div-headerPage">
        {/* BTN ADD DRIVER */}
        <VeryBtnFill
          CSS={["btnAddDriver"]}
          colorTheme={classVeryBtnFill.COLOR_THEME.BLACK_S1}
          label="Registrar conductor"
          textSize={14}
          onClick={() => formDriver.showModalModeAdd()}
        />
        {/* BTN PROFILE */}
        <MenuProfile
          show={showMenuProfile}
          onChangeShow={(isVisible) => setShowMenuProfile(isVisible)}
        />
      </div>
      {/* CONTENT */}
      <Outlet />
    </styles.DivPage>
  );
};

export default ContentHomePage;
