import CSS from "./styles";
import { useEffect, useState, useRef } from "react";
import FormLoginPage from "../FormLogin";
import FooterLogin from "../FooterLogin";
import {
  useLoginContext,
  useRecoveryPassContext,
} from "../../../context/login.context";
import HeaderLoginPage from "../HeaderLogin";
import { VeryInput } from "../../common/v2/VeryInput";
import { VeryBtnTransparent } from "../../common/v2/VeryBtnTransparent";
import { classVeryBtnTransparent } from "../../common/v2/VeryBtnTransparent/classVeryBtnTransparent";
import { VeryInputPass } from "../../common/v2/VeryInputPass";
import { Slide } from "@mui/material";
import NavLogin from "../NavLogin";
import stylesLogin from "../../../pages/login/styles";
import { VeryGroupInput } from "../../common/v2/VeryGroupInput";
import { VeryIconGoogle } from "../../common/v2/VeryIconGoogle";
import { classVeryIconGoogle } from "../../common/v2/VeryIconGoogle/classVeryIconGoogle";

const propsTemplate = () => ({
  refTransition: undefined,
});

export default function RecoveryPass(props = propsTemplate()) {
  const dataRecoveryPass = useRecoveryPassContext();
  const dataLogin = useLoginContext();
  const [refInputConfirmPass, setRefInputConfirmPass] = useState(useRef());

  return (
    <>
      {/* FORM RECOVERY PASSWORD */}
      <Slide
        direction="left"
        in={dataLogin.value.showScreenRecoveryPass === true}
        container={props.refTransition?.current}
        timeout={700}
        unmountOnExit
      >
        <stylesLogin.DivScrollPanel>
          {/* NAV */}
          <NavLogin
            btnBack={{
              onClick: dataRecoveryPass.inStepEmail()
                ? undefined
                : dataRecoveryPass.onClickBtnRegresar,
              isDisabled: dataRecoveryPass.value.loading,
            }}
            btnClose={{
              onClick: () => {
                setTimeout(() => dataRecoveryPass.init(), 500);
                dataLogin.setShowScreenRecoveryPass(false);
              },
              isDisabled: dataRecoveryPass.value.loading,
            }}
          />

          <CSS.DivMain>
            {/* HEADER */}
            <HeaderLoginPage
              title="Recuperar cuenta"
              subtitle={dataRecoveryPass.getSubtitleHeader()}
            />

            {/* INPUTS */}
            <FormLoginPage
              title={dataRecoveryPass.getTitleForm()}
              error={dataRecoveryPass.value.messageError}
            >
              {dataRecoveryPass.inStepEmail() ? (
                <>
                  {/* INPUT EMAIL */}
                  <VeryInput
                    isEditable
                    text={dataRecoveryPass.data.email}
                    onChangeText={(newValue) =>
                      dataRecoveryPass.setInputData({ email: newValue })
                    }
                    title="Correo electrónico"
                    textError={dataRecoveryPass.errorData.email}
                    placeHolder="Ingresa tu email"
                    onLostFocus={() =>
                      dataRecoveryPass.setInputError({ email: "" })
                    }
                    onEnter={dataRecoveryPass.onClickBtnMain}
                  />
                </>
              ) : dataRecoveryPass.inStepCode() ? (
                <>
                  {/* INPUT CODE */}
                  <VeryGroupInput
                    inputs={dataRecoveryPass.data.code}
                    error={dataRecoveryPass.errorData.code}
                    isDisabled={false}
                    sizeLabels={{ input: 46, error: 0 }}
                    onChangeValue={(newValue, indexInput) => {
                      dataRecoveryPass.setValueSingleInputCode(
                        newValue,
                        indexInput
                      );
                    }}
                    onEnter={() => dataRecoveryPass.onClickBtnMain()}
                    onLostFocus={() =>
                      dataRecoveryPass.setInputError({ code: "" })
                    }
                  />
                  {/* RESEND CODE  */}
                  <CSS.DivResendCode>
                    {dataRecoveryPass.isWaitingSendCode() ? (
                      <>
                        {/* WAITING */}
                        <label className="txtWaiting">
                          Podrás volver a enviar un código en
                        </label>
                        <div className="divTimer">
                          <VeryIconGoogle
                            name="timer"
                            color={classVeryIconGoogle.ICON_COLOR.BLACK_S1}
                            type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED}
                            size={16}
                          />
                          <label className="txtTimer">
                            {dataRecoveryPass.timerResendCode}s
                          </label>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* BUTTON */}
                        <label className="txtResendCode">
                          No me llegó ningún correo.
                        </label>
                        <VeryBtnTransparent
                          CSS={["btnResendCode"]}
                          colorTheme={
                            classVeryBtnTransparent.COLOR_THEME.BLACK_S1
                          }
                          label="Solicitar nuevo código"
                          isDisabled={dataRecoveryPass.value.loadingResendCode}
                          onClick={() => dataRecoveryPass.sendCodeReset(false)}
                          textSize={13}
                        />
                      </>
                    )}
                  </CSS.DivResendCode>
                </>
              ) : (
                <>
                  {/* INPUT NEW PASSWORD */}
                  <VeryInputPass
                    isEditable
                    title="Contraseña"
                    text={dataRecoveryPass.data.newPass}
                    onChangeText={(newValue) =>
                      dataRecoveryPass.setInputData({ newPass: newValue })
                    }
                    textError={dataRecoveryPass.errorData.newPass}
                    placeHolder="Ingresa contraseña"
                    onLostFocus={() =>
                      dataRecoveryPass.setInputError({ newPass: "" })
                    }
                    onEnter={() => {
                      if (refInputConfirmPass.current) {
                        refInputConfirmPass.current.focus();
                      }
                    }}
                  />
                  {/* INPUT CONFIRM PASSWORD */}
                  <VeryInputPass
                    onChangeRefInput={(ref) => setRefInputConfirmPass(ref)}
                    isEditable
                    title="Confirmar contraseña"
                    text={dataRecoveryPass.data.confirmPass}
                    onChangeText={(newValue) =>
                      dataRecoveryPass.setInputData({ confirmPass: newValue })
                    }
                    textError={dataRecoveryPass.errorData.confirmPass}
                    placeHolder="Confirma contraseña"
                    onLostFocus={() =>
                      dataRecoveryPass.setInputError({ confirmPass: "" })
                    }
                    onEnter={() => dataRecoveryPass.onClickBtnMain()}
                  />
                </>
              )}
            </FormLoginPage>

            {/* FOOTER */}
            <FooterLogin
              btnMain={{
                text: dataRecoveryPass.getLabelBtnMain(),
                isLoading: dataRecoveryPass.value.loading,
                onClick: () => dataRecoveryPass.onClickBtnMain(),
              }}
            />
          </CSS.DivMain>
        </stylesLogin.DivScrollPanel>
      </Slide>
    </>
  );
}
