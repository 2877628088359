import { useEffect, useState } from "react";
import {
  getFormattedEmail,
  getFormattedNumber,
  getNotNegativeNumber,
  getObject,
  getString,
  isArrayEmpty,
  isObject,
  isPositiveInt,
  isStringEmpty,
  isValidNaturalNumber,
} from "../utils/handler_validations";
import { couponDB, passengerDB } from "../services";
import { useSessionContext } from "../context/session.context";
import { useVeryNotificationsContext } from "../components/common/v2/VeryNotifications/useVeryNotifications";
import { classVeryIconGoogle } from "../components/common/v2/VeryIconGoogle/classVeryIconGoogle";
import { getFormattedTel } from "../utils/handler_tel";
import { useModalAskContext } from "../context/modalAsk.context";

const getLoadErrorTemplate = (initialLoading = false) => ({
  loading: initialLoading === true,
  error: { message: "", details: "" },
});

const getRowPassengerTemplate = () => ({
  rowId: -1,
  user: {
    id: 0,
    fullName: "",
    tel: "",
    email: "",
    profileImg: "",
  },
});

const getActiveCouponTemplate = () => ({
  code: "",
  amount: 0,
  expirationDate: null,
});

export const usePassengers = () => {
  const [textInputSearch, setTextInputSearch] = useState("");
  const [value, setValue] = useState({
    page: getLoadErrorTemplate(true),
    contentPage: getLoadErrorTemplate(false),
    list: [getRowPassengerTemplate()], // lista con la data de los pasajeros
    statistics: {}, // lista con las estadisticas de cada pasajero
    activeCoupons: {}, // lista con los cupones activos de cada pasajero
  });
  // handle disable active coupon
  const [actionDisableCoupon, setActionDisableCoupon] = useState({
    code: "",
    userId: 0,
  });
  const [rowSelected, setRowSelected] = useState(-1);
  const session = useSessionContext();
  const notis = useVeryNotificationsContext();
  const modalAsk = useModalAskContext();

  useEffect(() => {
    getListInitial();
  }, []);

  // accion confirmada
  useEffect(() => {
    if (modalAsk.value.execute) {
      if (modalAsk.value.action === "DISABLE_COUPON") {
        execActionDisableCoupon();
      }
    }
  }, [modalAsk.value.execute]);

  /*===========================================
	HANDLE ACTION DISABLE COUPON 
	=============================================*/
  // event click del btn
  const onClickBtnDisableCoupon = (code = "", userId = 0) => {
    setActionDisableCoupon({ code, userId });
    modalAsk.show(
      "DISABLE_COUPON",
      "Cancelar un cupón de usuario",
      "Estas a punto de cancelar un cupón de usuario, esta acción ya no es reversible " +
        `\n\nFavor de confirmar`,
      true
    );
  };

  // realizar el disable active coupon en backend
  const execActionDisableCoupon = async () => {
    const resp = await couponDB.disabledCoupon(
      actionDisableCoupon.code,
      session.value.jwt
    );
    // cuando hubo error
    if (resp.error) {
      notis.createNotiError(resp.error.message, resp.error.details);
      modalAsk.clearExecute();
      return;
    }
    // success
    notis.createNotiSuccess(
      `El cupón "${actionDisableCoupon.code}" fue cancelado`
    );
    modalAsk.close();
    const list = getListActiveCoupons(actionDisableCoupon.userId).slice();
    list.forEach((coupon) => {
      if (coupon.code === actionDisableCoupon.code) coupon.canceled = true;
    });
    setValue((prev) => ({
      ...prev,
      activeCoupons: {
        ...prev.activeCoupons,
        [actionDisableCoupon.userId]: list,
      },
    }));
  };

  /*===========================================
	GETS
	=============================================*/
  const getTotalCompletedTrips = (passengerId = 0) => {
    return getNotNegativeNumber(value.statistics[passengerId]?.completedTrips);
  };

  const getTotalUsedCoupons = (passengerId = 0) => {
    return getNotNegativeNumber(value.statistics[passengerId]?.usedCoupons);
  };

  const getTotalActiveCoupons = (passengerId = 0) => {
    return getListActiveCoupons(passengerId).length;
  };

  const getListActiveCoupons = (passengerId = 0) => {
    let list = [getActiveCouponTemplate()];
    list = value.activeCoupons[passengerId];
    return isArrayEmpty(list) ? [] : list;
  };

  const getTotalPaidTrips = (passengerId = 0) => {
    return getNotNegativeNumber(value.statistics[passengerId]?.tripsPaid);
  };

  const getTotalPaidCoupons = (passengerId = 0) => {
    return getNotNegativeNumber(value.statistics[passengerId]?.couponsPaid);
  };

  const getRating = (passengerId = 0) => {
    return getNotNegativeNumber(value.statistics[passengerId]?.rating);
  };

  const getEmptyMsgPage = () => {
    const emptyObj = { text: "", icon: { name: "", type: "" } };
    if (value.list === undefined) {
      emptyObj.text = "No se encontraron usuarios";
      emptyObj.icon.name = "content_paste";
      emptyObj.icon.type = classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED;
    } else if (isArrayEmpty(value.list)) {
      emptyObj.text = "Ningún usuario coincide con la búsqueda";
      emptyObj.icon.name = "content_paste_search";
      emptyObj.icon.type = classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED;
    }
    return emptyObj;
  };

  const getPassengerSelected = () => {
    const data = {
      id: 0,
      profileImg: "",
      fullName: "Desconocido",
      email: "",
      tel: "",
      rating: "0",
      totalCompletedTrips: "0",
      paid: { cash: "0", coupons: "0" },
      coupons: [],
    };
    if (isArrayEmpty(value.list) || rowSelected < 0) return data;
    const dataUser = value.list[rowSelected].user;
    if (!isPositiveInt(dataUser?.id)) return data;
    data.id = dataUser.id;
    data.profileImg = dataUser.profileImg;
    if (!isStringEmpty(dataUser.fullName)) {
      data.fullName = dataUser.fullName;
    }
    data.email = getString(dataUser.email);
    data.tel = getString(dataUser.tel);
    data.rating = getRating(data.id).toFixed(1);
    data.totalCompletedTrips = getFormattedNumber(
      getTotalCompletedTrips(data.id),
      true,
      0
    );
    data.paid.cash = getFormattedNumber(
      getTotalPaidTrips(data.id) - getTotalPaidCoupons(data.id),
      true,
      2
    );
    data.paid.coupons = getFormattedNumber(
      getTotalPaidCoupons(data.id),
      true,
      2
    );
    data.coupons = getListActiveCoupons(data.id);
    return data;
  };

  /**
   * Obtener la lista de pasajeros formateada para ser manipulada por la tabla
   */
  const getFormattedList = (passengerList = [], initial = false) => {
    let list = isArrayEmpty(passengerList)
      ? initial
        ? undefined
        : []
      : passengerList;
    return list?.map((row, index) => {
      const dataRow = getRowPassengerTemplate();
      dataRow.rowId = index;
      dataRow.user.id = getNotNegativeNumber(row.id);
      dataRow.user.fullName = getString(row.fullName);
      dataRow.user.tel = getString(row.tel);
      dataRow.user.email = getString(row.email);
      dataRow.user.profileImg = getString(row.profileImg);
      return dataRow;
    });
  };

  const getListFiltered = async () => {
    setValue((prev) => ({
      ...prev,
      contentPage: getLoadErrorTemplate(true),
    }));
    setRowSelected(-1);
    const resp = await passengerDB.getListPassengers(
      textInputSearch,
      session.value.jwt
    );
    // existe error ?
    if (resp.error) {
      setValue((prev) => ({
        ...prev,
        contentPage: {
          loading: false,
          error: { message: resp.error.message, details: resp.error.details },
        },
      }));
      return;
    }
    // success
    setValue((prev) => ({
      ...prev,
      contentPage: getLoadErrorTemplate(false),
      list: getFormattedList(resp.body.passengers, false),
      statistics: getObject(resp.body.statistics),
      activeCoupons: getObject(resp.body.activeCoupons),
    }));
  };

  const getListInitial = async () => {
    setValue((prev) => ({
      ...prev,
      page: getLoadErrorTemplate(true),
    }));
    const resp = await passengerDB.getListPassengers("", session.value.jwt);
    // existe error ?
    if (resp.error) {
      setValue((prev) => ({
        ...prev,
        page: {
          loading: false,
          error: { message: resp.error.message, details: resp.error.details },
        },
      }));
      return;
    }
    // success
    setValue((prev) => ({
      ...prev,
      page: getLoadErrorTemplate(false),
      list: getFormattedList(resp.body.passengers, true),
      statistics: getObject(resp.body.statistics),
      activeCoupons: getObject(resp.body.activeCoupons),
    }));
  };

  const onChangeRowSelected = (newRowSelected = -1) => {
    setRowSelected(newRowSelected);
  };

  const onChangeTextInputSearch = (newText = "") => {
    setTextInputSearch(newText);
  };

  return {
    value,
    textInputSearch,
    rowSelected,

    getTotalCompletedTrips,
    getTotalActiveCoupons,
    getTotalUsedCoupons,
    getTotalPaidTrips,
    getRating,
    getListFiltered,
    getListInitial,
    getEmptyMsgPage,
    getPassengerSelected,

    onChangeTextInputSearch,
    onChangeRowSelected,
    onClickBtnDisableCoupon,
  };
};
