import CSS from "./styles";
import logo from "../../../assets/logos/very-black-01.svg";
import { isStringEmpty } from "../../../utils/handler_validations";

const propsTemplate = () => ({
  title: "",
  subtitle: "",
});

export default function HeaderLoginPage(props = propsTemplate()) {
  return (
    <CSS.divMain>
      <img className="imgLogo" src={logo} />
      {!isStringEmpty(props.title) && (
        <label className="txtTitle">{props.title}</label>
      )}
      {!isStringEmpty(props.subtitle) && (
        <label className="txtSubtitle">{props.subtitle}</label>
      )}
    </CSS.divMain>
  );
}
