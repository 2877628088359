import {
  getFormattedNumber,
  getNotNegativeNumber,
  isArrayEmpty,
  isPositiveInt,
  isStringEmpty,
} from "../../../utils/handler_validations";
import styles from "./styles";
import { VeryIconGoogle } from "../../common/v2/VeryIconGoogle";
import SelectTime from "../SelectTime";
import { useDashboardContext } from "../../../context";
import { useMemo } from "react";

const propsType = () => ({
  className: "",
  title: "",
  quantity: 0,
  icon: {
    name: "",
    type: "",
    iconColor: "",
    size: 10,
  },
});
const CustomCard2 = (props = propsType()) => {
  const dataDashboard = useDashboardContext();
  const clasesCSS = ["containerContent"];
  const earning = useMemo(() => {
    return getFormattedNumber(getNotNegativeNumber(props.quantity), true, 2);
  }, [props.quantity]);

  if (!isStringEmpty(props.className)) clasesCSS.push(props.className);

  return (
    <styles.MainContainer className={clasesCSS.join(" ")}>
      <div className="panel-icon">
        {/* icono de la tarjeta */}
        {!isStringEmpty(props.icon?.name) && (
          <VeryIconGoogle
            CSS={["icon"]}
            name={props.icon.name}
            type={props.icon.type}
            color={props.icon.iconColor}
            size={props.icon?.size}
          />
        )}
      </div>
      <div className="panel-text">
        <div className="panel-sup">
          {/* cantidad de la tarjeta */}
          <label className="label-quantity">${earning}</label>
          <SelectTime
            onChangeValue={(newValue) => {
              dataDashboard.changeFilters({
                earning: newValue,
              });
            }}
          />
        </div>
        {/* titulo de la tarjeta */}
        {!isStringEmpty(props.title) && (
          <label className="label-title">{props.title.trim()}</label>
        )}
      </div>
    </styles.MainContainer>
  );
};

export default CustomCard2;
