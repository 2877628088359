import styles from "./styles";
import { VeryInput } from "../common/v2/VeryInput";
import { VeryInputTel } from "../common/v2/VeryInputTel";
import { useState, useEffect } from "react";
import { classVeryInput } from "../common/v2/VeryInput/classVeryInput";
import {
  isArrayEmpty,
  isPositiveInt,
  isStringEmpty,
} from "../../utils/handler_validations";
import SelectYear from "./SelectYear";
import SelectColor from "./SelectColor";
import { useFormDriverContext } from "../../context/formDriver.context";
import { VeryPickerImgPdf } from "../common/v2/VeryPickerImgPdf";

const propsTemplate = () => ({
  CSS: [],
});

const ModalDriver = (props = propsTemplate()) => {
  const formDriver = useFormDriverContext();
  const customCSS = ["ModalAddDriver"];

  if (!isArrayEmpty(props.CSS)) customCSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) customCSS.push(props.className);

  return (
    <styles.DivMain
      CSS={customCSS}
      isVisible={formDriver.value.show}
      title={
        formDriver.value.modeEdit ? "Editar conductor" : "Registrar conductor"
      }
      btnMain={{
        label: formDriver.value.modeEdit
          ? "Editar conductor"
          : "Registrar conductor",
        isLoading: formDriver.value.loading,
        onClick: () => formDriver.onClickBtnMain(),
      }}
      stepSelected={formDriver.value.stepSelected}
      onClickBtnClose={() => formDriver.hideModal()}
      onChangeStepSelected={(newStep) => formDriver.updateStep(newStep)}
      steps={Object.values(formDriver.steps)}
    >
      {/* PANEL PERSONAL DATA */}
      <styles.PanelPersonal isActiveStep={formDriver.value.stepSelected === 0}>
        {/* PICKER IMG PROFILE */}
        <VeryPickerImgPdf
          title={"Fotografía del conductor"}
          isRequired
          disabledImgs={false}
          disabledPdfs={true}
          error={formDriver.errorData.profileImg}
          originalFile={formDriver.originalData.profileImg}
          onChangeFile={(selectedFile) => {
            formDriver.updateData({ profileImg: selectedFile });
          }}
          onError={(msgError) => {
            formDriver.updateErrorsData({ profileImg: msgError });
          }}
        />
        {/* INPUT NAME + LASTNAME */}
        <div className="divName">
          <VeryInput
            CSS={["divInputName"]}
            isEditable
            isRequired
            placeHolder="Ingresa el nombre"
            text={formDriver.data.name}
            textError={formDriver.errorData.name}
            type={classVeryInput.TYPE_INPUT.NORMAL}
            title="Nombre"
            onChangeText={(newText) => formDriver.updateData({ name: newText })}
          />
          <VeryInput
            CSS={["divInputLastName"]}
            isEditable
            isRequired
            placeHolder="Ingresa los apellidos"
            text={formDriver.data.lastName}
            textError={formDriver.errorData.lastName}
            type={classVeryInput.TYPE_INPUT.NORMAL}
            title="Apellidos"
            onChangeText={(newText) =>
              formDriver.updateData({ lastName: newText })
            }
          />
        </div>
        {/* INPUT EMAIL */}
        <VeryInput
          CSS={["divInputEmail"]}
          isEditable={!formDriver.value.modeEdit}
          isRequired={!formDriver.value.modeEdit}
          placeHolder="Ingresa el correo"
          text={formDriver.data.email}
          textError={formDriver.errorData.email}
          type={classVeryInput.TYPE_INPUT.NORMAL}
          title="Correo electrónico"
          onChangeText={(newText) => {
            formDriver.updateData({ email: newText });
          }}
        />
        {/* INPUT TEL */}
        <VeryInputTel
          CSS={["divInputTel"]}
          isEditable={!formDriver.value.modeEdit}
          isRequired={!formDriver.value.modeEdit}
          text={formDriver.data.tel}
          textError={formDriver.errorData.tel}
          onChangeText={(newText) => {
            formDriver.updateData({ tel: newText });
          }}
        />
      </styles.PanelPersonal>

      {/* PANEL VEHICLE DATA */}
      <styles.PanelVehicle isActiveStep={formDriver.value.stepSelected === 1}>
        {/* INPUT MODEL */}
        <VeryInput
          CSS={["divinputModel"]}
          isEditable
          isRequired
          placeHolder="Ingresa la marca y modelo del auto"
          text={formDriver.data.vehicleModel}
          textError={formDriver.errorData.vehicleModel}
          type={classVeryInput.TYPE_INPUT.NORMAL}
          title="Marca / Modelo"
          onChangeText={(newText) => {
            formDriver.updateData({ vehicleModel: newText });
          }}
        />
        {/* SELECT YEAR + SELECT COLOR */}
        <div className="divSelects">
          <SelectYear
            textError={formDriver.errorData.vehicleYear}
            selectedYear={formDriver.data.vehicleYear}
            onChangeYear={(newYear) => {
              formDriver.updateData({ vehicleYear: newYear });
            }}
          />
          <SelectColor
            textError={formDriver.errorData.vehicleColor}
            selectedValue={formDriver.data.vehicleColor}
            onChangeValue={(newValue) => {
              formDriver.updateData({ vehicleColor: newValue });
            }}
          />
        </div>
        {/* INPUT PLATES */}
        <VeryInput
          CSS={["divInputPlates"]}
          isEditable
          placeHolder="Ingresa las placas del auto"
          text={formDriver.data.vehiclePlates}
          textError={formDriver.errorData.vehiclePlates}
          type={classVeryInput.TYPE_INPUT.NORMAL}
          title="Placas"
          onChangeText={(newText) => {
            formDriver.updateData({ vehiclePlates: newText });
          }}
        />
      </styles.PanelVehicle>

      {/* PANEL DOCUMENTS */}
      <styles.PanelDocuments isActiveStep={formDriver.value.stepSelected === 2}>
        {/* WARNING TEXT */}
        <label className="txtWarning">
          Por cuestiones de seguridad para todos nuestros usuarios, es necesario
          que los conductores cumplan con estos requisitos
        </label>
        {/* INE + LICENCE */}
        <div className="divPickersFile">
          <div className="rowPickersFile">
            <VeryPickerImgPdf
              title={"INE"}
              isRequired
              disabledImgs={false}
              disabledPdfs={false}
              error={formDriver.errorData.ine}
              originalFile={formDriver.originalData.ine}
              onChangeFile={(selectedFile) => {
                formDriver.updateData({ ine: selectedFile });
              }}
              onError={(msgError) => {
                formDriver.updateErrorsData({ ine: msgError });
              }}
            />
            <VeryPickerImgPdf
              title={"Licencia de manejo"}
              isRequired
              disabledImgs={false}
              disabledPdfs={false}
              error={formDriver.errorData.driverLicence}
              originalFile={formDriver.originalData.driverLicence}
              onChangeFile={(selectedFile) => {
                formDriver.updateData({ driverLicence: selectedFile });
              }}
              onError={(msgError) => {
                formDriver.updateErrorsData({ driverLicence: msgError });
              }}
            />
          </div>
          {/* CARTA + DOMICILIO */}
          <div className="rowPickersFile">
            <VeryPickerImgPdf
              title={"Carta de antecedentes no penales"}
              disabledImgs={false}
              disabledPdfs={false}
              error={formDriver.errorData.criminalLetter}
              originalFile={formDriver.originalData.criminalLetter}
              onChangeFile={(selectedFile) => {
                formDriver.updateData({ criminalLetter: selectedFile });
              }}
              onError={(msgError) => {
                formDriver.updateErrorsData({ criminalLetter: msgError });
              }}
            />
            <VeryPickerImgPdf
              title={"Comprobante de domicilio"}
              disabledImgs={false}
              disabledPdfs={false}
              error={formDriver.errorData.address}
              originalFile={formDriver.originalData.address}
              onChangeFile={(selectedFile) => {
                formDriver.updateData({ address: selectedFile });
              }}
              onError={(msgError) => {
                formDriver.updateErrorsData({ address: msgError });
              }}
            />
          </div>
        </div>
      </styles.PanelDocuments>
    </styles.DivMain>
  );
};

export default ModalDriver;
