import { useEffect, useState } from "react";
import {
  isArrayEmpty,
  isNumberPositive,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import styles from "./styles";
import classVeryTable from "./classVeryTable";
import { VeryIconGoogle } from "../VeryIconGoogle";
import { classVeryIconGoogle } from "../VeryIconGoogle/classVeryIconGoogle";

export const VeryTable = (props = classVeryTable.getPropsTemplate()) => {
  const [columns, setColumns] = useState([]);
  const CSS = ["veryTable"];
  // add custom style
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) CSS.push(props.className);

  // cada que cambien las columnas
  useEffect(() => {
    const columnsName = Object.keys(props.columns ?? {});
    setColumns(
      columnsName.map((nameCol) => {
        const dataCol = props.columns[nameCol];
        return {
          field: nameCol,
          headerName: dataCol.title,
          description: dataCol.description,
          flex: dataCol.flex,
          width: dataCol.width,
          minWidth: dataCol.minWidth,
          disableColumnMenu: true,
          sortable: dataCol.isSortable === true,
          editable: false,
          filterable: false,
          hideable: false,
          valueGetter: dataCol.getValue,
          renderCell: dataCol.renderCell,
        };
      })
    );
  }, [props.columns]);

  const renderSortIconASC = () => {
    return (
      <VeryIconGoogle
        CSS={["iconASC"]}
        name="switch_right"
        type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED}
        size={21}
        color={classVeryIconGoogle.ICON_COLOR.BLACK_S1}
      />
    );
  };

  const renderSortIconDESC = () => {
    return (
      <VeryIconGoogle
        CSS={["iconDESC"]}
        name="switch_right"
        type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED}
        size={21}
        color={classVeryIconGoogle.ICON_COLOR.BLACK_S1}
      />
    );
  };

  return (
    <styles.DivMain
      className={CSS.join(" ")}
      hideFooterSelectedRowCount={true}
      columns={columns}
      rows={props.rows}
      disableRowSelectionOnClick={props.disabledRowSelection === true}
      rowSelectionModel={props.rowSelected}
      onCellClick={(params) => {
        if (
          props.columns[params.field].isClickable &&
          props.onChangeRowSelected
        ) {
          if (props.rowSelected === params.id) {
            props.onChangeRowSelected(-1);
          } else {
            props.onChangeRowSelected(params.id);
          }
        }
      }}
      density="standard"
      slots={{
        columnSortedAscendingIcon: renderSortIconASC,
        columnSortedDescendingIcon: renderSortIconDESC,
      }}
      localeText={{
        MuiTablePagination: {
          labelRowsPerPage: "Filas por página",
          labelDisplayedRows: (paginationData) => {
            return `${paginationData.from} - ${paginationData.to} de ${paginationData.count}`;
          },
        },
      }}
      getRowId={props.getRowId}
      getRowHeight={(params) => "auto"}
    />
  );
};
