import styled from "styled-components";

const DivMain = styled.div`
  border-radius: 50%;
  //border: 1px solid red;
  .btnMenu.veryBtnTransparent {
    width: 28px;
    height: 28px;
    padding: 0px;
    border-radius: 50%;
  }

  .MuiTooltip-tooltipPlacementRight {
    margin-right: 0px !important;
  }
  .MuiTooltip-tooltip {
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 7px 0px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }
  .btnItem.veryBtnFill {
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 6px 14px;
    border-radius: 0px;
    .txtLabel {
      font-weight: 400;
      line-height: 1.2;
    }
  }
`;

export default {
  DivMain,
};
