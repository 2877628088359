import { useSnackbar } from "notistack";
import { createContext, useContext } from "react";
const Context = createContext();

export const useVeryNotifications = () => {
  const notis = useSnackbar();

  const createNotiSuccess = (message = "", details = "") => {
    notis.enqueueSnackbar(message, {
      variant: "success",
      details: details,
    });
  };

  const createNotiError = (message = "", details = "") => {
    notis.enqueueSnackbar(message, {
      variant: "error",
      details: details,
    });
  };

  const closeAll = () => {
    notis.closeSnackbar();
  };

  return {
    createNotiSuccess,
    createNotiError,
    closeAll,
  };
};

export const VeryNotificationsProvider = (props) => {
  return (
    <Context.Provider value={useVeryNotifications()}>
      {props.children}
    </Context.Provider>
  );
};

export const useVeryNotificationsContext = () => useContext(Context);
