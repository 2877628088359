export default class classVeryNotifications {
  static getPropsTemplate = () => ({
    maxNotis: 3,
    position: 0,
    duration: 0,
    fixedNotis: false,
    disabledDuplicateNotis: false,
  });

  static POSITIONS = {
    TOP_LEFT: 1,
    TOP_CENTER: 2,
    TOP_RIGHT: 3,
    BOTTOM_LEFT: 4,
    BOTTOM_CENTER: 5,
    BOTTOM_RIGHT: 6,
  };
  static getPosition = (position = 0) => {
    switch (position) {
      case this.POSITIONS.TOP_LEFT:
        return { vertical: "top", horizontal: "left" };
      case this.POSITIONS.TOP_CENTER:
        return { vertical: "top", horizontal: "center" };
      case this.POSITIONS.BOTTOM_LEFT:
        return { vertical: "bottom", horizontal: "left" };
      case this.POSITIONS.BOTTOM_CENTER:
        return { vertical: "bottom", horizontal: "center" };
      case this.POSITIONS.BOTTOM_RIGHT:
        return { vertical: "bottom", horizontal: "right" };
      default:
        return { vertical: "top", horizontal: "right" };
    }
  };
}
