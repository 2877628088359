import styled from "styled-components";

const DivMain = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  //width: 300px;
  min-width: 325px;
  border-radius: 5px;
  right: 30px;
  top: 20px;
  bottom: 20px;
  gap: 5px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  padding: 5px 20px 10px 20px;
  overflow: auto;

  .div-header {
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //border: solid blue 1px;
    .btnClose {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      padding: 0px;
    }
    .txtTitle {
      font-size: 13px;
      font-weight: 700;
      font-family: "Roboto", Helvetica, sans-serif;
    }
  }

  .div-userDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px 18px 15px 18px;
    border-radius: 10px;
    background: #f6f8fa;
    gap: 3px;
    //border: solid red 1px;
    .div-traveling {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      position: absolute;
      right: 30px;
      top: 60px;
      width: 60px;
      height: 16px;
      background: #16ca5e;
      .txt-traveling {
        color: #ffffff;
        font-size: 11px;
        font-weight: 700;
        font-family: "Roboto";
      }
    }
    .txt-name {
      font-size: 13px;
      font-weight: 700;
      font-family: "Roboto", Helvetica, sans-serif;
      margin-top: 4px;
    }
    .txt-email {
      font-size: 14px;
      font-weight: 300;
      font-family: "Roboto", Helvetica, sans-serif;
    }
    .div-rating {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 5px 0px 12px 0px;
      gap: 8px;
      //border: solid red 1px;
      .txt-rating {
        font-size: 13px;
        font-weight: 500;
        font-family: "Roboto", Helvetica, sans-serif;
      }
    }
    .div-btns {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      //border: solid gray 1px;
      // TOOLTIP
      > .MuiTooltip-popper {
        &.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip {
          margin-bottom: 5px !important;
        }
        &.MuiTooltip-popper[data-popper-placement*="bottom"]
          .MuiTooltip-tooltip {
          margin-top: 5px !important;
        }
        .MuiTooltip-tooltip {
          background-color: #f4f4f4;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          border: 1px solid #cacaca;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
          cursor: default;
          font-family: "Roboto", Helvetica, sans-serif;
          font-weight: 700;
          font-size: 11px;
          line-height: 1.2;
          text-align: center;
          color: #13131d;
        }
      }
      .btnCopy {
        width: 30px;
        height: 30px;
        padding: 0px;
        border-radius: 50%;
      }
    }
  }

  .div-documents {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 18px;
    gap: 10px;
    .txt-title {
      font-size: 12px;
      font-weight: 700;
      font-family: "Roboto", Helvetica, sans-serif;
    }
    .div-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 15px;
      //border: solid gray 1px;
      .div-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        //border: solid gray 1px;
        .txt-item {
          flex: 1;
          margin-left: 5px;
          font-size: 13px;
          font-weight: 400;
          font-family: "Roboto", Helvetica, sans-serif;
          text-align: left;
          line-height: 1.2;
          //border: solid gray 1px;
        }
        .btn-download {
          width: fit-content;
          .txtLabel {
            text-decoration: none;
          }
          &.Mui-disabled {
            opacity: 0.25;
          }
        }
      }
    }
  }

  .div-vehicle {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 18px;
    gap: 8px;
    .txt-title {
      font-size: 12px;
      font-weight: 700;
      font-family: "Roboto", Helvetica, sans-serif;
    }
    .div-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
      //border: solid gray 1px;
      .txt-item {
        width: 100%;
        font-size: 13px;
        font-weight: 400;
        font-family: "Roboto", Helvetica, sans-serif;
        text-align: left;
        line-height: 1.2;
      }
      .div-color {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        //border: solid gray 1px;
        .txt-color {
          flex-shrink: 1;
          font-size: 13px;
          font-weight: 400;
          font-family: "Roboto", Helvetica, sans-serif;
          text-align: left;
          line-height: 1.2;
        }
        .color {
          width: 15px;
          height: 15px;
          border-radius: 4px;
          &.fillAmarillo {
            background-color: #ffff00;
            border: 1px solid #bdbd02;
          }
          &.fillVerde {
            background-color: #10e710;
            border: 1px solid #016501;
          }
          &.fillRojo {
            background-color: #ed4040;
            border: 1px solid #5c0101;
          }
          &.fillBlanco {
            background-color: #ffffff;
            border: 1px solid #b5b5b5;
          }
        }
      }
    }
  }

  .div-completedTrips {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    gap: 12px;
    //border: solid red 1px;
    .txt-title {
      font-size: 12px;
      font-weight: 700;
      font-family: "Roboto", Helvetica, sans-serif;
    }
    .div-data {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      //border: 1px solid red;
      .div-color {
        width: 15px;
        height: 15px;
        border-radius: 4px;
        margin-right: 10px;
        background-color: #46bec3;
      }
      .txt-number {
        font-size: 22px;
        font-weight: 700;
        font-family: "Roboto", Helvetica, sans-serif;
        margin-right: 17px;
      }
      .txt-endTravels {
        flex: 1;
        font-size: 12px;
        font-weight: 400;
        font-family: "Roboto", Helvetica, sans-serif;
        //margin-right: 60px;
      }
      .btn-completeTrips {
        width: 25px;
        height: 25px;
        padding: 0px;
        border-radius: 100%;
      }
    }
  }

  .div-payments {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 10px;
    //border: solid red 1px;
    .txt-title {
      font-size: 12px;
      font-weight: 700;
      font-family: "Roboto", Helvetica, sans-serif;
    }
    .div-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      //border: solid red 1px;
      .txt-item {
        width: 75%;
        font-size: 13px;
        font-weight: 400;
        font-family: "Roboto", Helvetica, sans-serif;
        text-align: left;
        line-height: 1.2;
        //border: solid red 1px;
      }
      .txt-money {
        flex-grow: 1;
        font-size: 13px;
        font-weight: 700;
        font-family: "Roboto", Helvetica, sans-serif;
        text-align: right;
        line-height: 1.2;
        //border: solid red 1px;
      }
    }
  }
`;

export default {
  DivMain,
};
