import styles from "./styles";
import "react-activity/dist/library.css";
import {
  isStringEmpty,
  isValidNaturalNumber,
} from "../../../utils/handler_validations";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CustomInput from "../custom-input";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useRef } from "react";
import IconButton from "../icon-button";
import { classIconButton } from "../icon-button/utils/classIconButton";
import { useState } from "react";
import { ClickAwayListener } from "@mui/material";
import classCustomInput from "../custom-input/classCustomInput";
import { VeryInput } from "../v2/VeryInput";
import { VeryBtnTransparent } from "../v2/VeryBtnTransparent";
import { classVeryIconGoogle } from "../v2/VeryIconGoogle/classVeryIconGoogle";

const propsType = {
  className: "",
  classNamePicker: "",
  title: "",
  error: "",
  minDate: moment(),
  maxDate: moment(),
  selectedDate: moment(),
  onChange: (newSelectedDate = moment()) => {},
};

const CustomDatePicker = (props = propsType) => {
  const {
    className, // clase CSS del container main
    classNamePicker, // clase CSS del picker popup
    title, // titulo del input
    error, // mensaje de error del input
    minDate, // fecha minima seleccionable
    maxDate, // fecha maxima seleccionable
    selectedDate, // fecha seleccionada
    onChange,
  } = props;

  const [openPicker, setOpenPicker] = useState(false);
  const refDatePicker = useRef(null);

  // formatear fecha seleccionada
  let formatDate = "";
  if ([undefined, null].includes(selectedDate) || !selectedDate.isValid()) {
    formatDate = "";
  } else {
    formatDate = selectedDate.format("DD/MMM/YYYY");
    const partsDate = formatDate.split("/");
    let month = partsDate[1].replace(".", "");
    month = month.charAt(0).toUpperCase() + month.substring(1);
    partsDate[1] = month;
    formatDate = partsDate.join("/");
  }

  return (
    <styles.MainContainer
      className={isStringEmpty(className) ? "" : className}
      ref={refDatePicker}
    >
      {/* fecha seleccionada */}
      <VeryInput
        CSS={["input-date"]}
        title={isStringEmpty(title) ? "" : title}
        text={formatDate}
        placeHolder={"Selecciona una fecha de vencimiento"}
        textError={isStringEmpty(error) ? "" : error}
        isEditable={false}
        inRightComponent={
          <VeryBtnTransparent
            CSS={["btn-datePicker"]}
            icon={{
              name: "calendar_add_on",
              size: 20,
              type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
            }}
            onClick={(event) => setOpenPicker(true)}
          />
        }
      />
      {/* popup datePicker */}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          value={selectedDate}
          open={openPicker}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(newDate) => {
            onChange(newDate);
            setOpenPicker(false);
          }}
          components={{
            PaperContent: (props) => {
              return (
                <ClickAwayListener
                  onClickAway={(event) => setOpenPicker(false)}
                >
                  <styles.ContainerDatePicker
                    className={
                      isStringEmpty(classNamePicker) ? "" : classNamePicker
                    }
                    {...props}
                  />
                </ClickAwayListener>
              );
            },
          }}
          PaperProps={{
            style: {
              borderRadius: "8px",
            },
          }}
          PopperProps={{
            anchorEl: refDatePicker?.current,
          }}
          renderInput={(params) => null}
        />
      </LocalizationProvider>
    </styles.MainContainer>
  );
};

export default CustomDatePicker;
