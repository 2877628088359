import { useModalAskContext } from "../../../context";
import { VeryBtnFill } from "../v2/VeryBtnFill";
import { classVeryBtnFill } from "../v2/VeryBtnFill/classVeryBtnFill";
import { VeryBtnOutlined } from "../v2/VeryBtnOutlined";
import { classVeryBtnOutlined } from "../v2/VeryBtnOutlined/classVeryBtnOutlined";
import Styles from "./styles";

export const ModalAsk = (props) => {
  const modal = useModalAskContext();

  return (
    <Styles.Modal
      isVisible={modal.value.show}
      title={modal.value.title}
      onClickClose={() => {
        if (modal.value.modeLoading && modal.value.execute) return;
        modal.close();
      }}
      buttons={[
        // boton Regresar
        <VeryBtnOutlined
          CSS={["btnModal"]}
          colorTheme={classVeryBtnOutlined.COLOR_THEME.BLACK_S1}
          label="Regresar"
          textSize={15}
          isDisabled={modal.value.modeLoading && modal.value.execute}
          onClick={() => modal.close()}
        />,
        // boton Aceptar
        <VeryBtnFill
          CSS={["btnModal"]}
          colorTheme={classVeryBtnFill.COLOR_THEME.BLACK_S1}
          label="Aceptar"
          textSize={15}
          isLoading={modal.value.modeLoading && modal.value.execute}
          onClick={() => modal.activeExecute()}
        />,
      ]}
    >
      <label className="txt-message">{modal.value.message}</label>
      {props.children}
    </Styles.Modal>
  );
};
