import { classVeryIconGoogle } from "../VeryIconGoogle/classVeryIconGoogle";
import { classVeryLoading } from "../VeryLoading/classVeryLoading";

export class classVeryBtnOutlined {
  // props recibidas
  static propsTemplate = () => ({
    isDisabled: false,
    isLoading: false,
    label: "",
    icon: { name: "", type: "", size: 0, position: "" },
    colorTheme: "",
    textSize: 0,
    CSS: [],
    onClick: () => {},
  });
  // tema de colores
  static COLOR_THEME = {
    BLACK_S1: "BLACK_S1",
  };
  // personalizado color del icono (ligado al theme)
  static ICON_COLOR = {
    BLACK_S1: classVeryIconGoogle.ICON_COLOR.BLACK_S1,
    DISABLED_S1: classVeryIconGoogle.ICON_COLOR.GRAY_S1,
  };
  // personalizado color del loading (ligado al theme)
  static LOADING_COLOR = {
    BLACK_S1: classVeryLoading.COLOR.BLACK_S1,
    DISABLED_S1: classVeryLoading.COLOR.GRAY_S2,
  };
  static getColorTheme(theme = "", isDisabled = false) {
    const themeBtn = { className: "", iconColor: "", loadingColor: "" };
    if (!isDisabled) {
      themeBtn.className = Object.values(this.COLOR_THEME).includes(theme)
        ? theme
        : this.COLOR_THEME.BLACK_S1;
      themeBtn.iconColor = this.ICON_COLOR[themeBtn.className];
      themeBtn.loadingColor = this.LOADING_COLOR[themeBtn.className];
    } else {
      themeBtn.iconColor = this.ICON_COLOR.DISABLED_S1;
      themeBtn.loadingColor = this.LOADING_COLOR.DISABLED_S1;
    }
    return themeBtn;
  }
  // posicion del icono en relacion al texto
  static ICON_POSITION = {
    LEFT: "row",
    RIGHT: "row-reverse",
    TOP: "column",
    BOTTOM: "column-reverse",
  };
  static getIconPosition(position = "") {
    return Object.values(this.ICON_POSITION).includes(position)
      ? position
      : this.ICON_POSITION.LEFT;
  }
}
