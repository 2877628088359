import styled from "styled-components";
import ModalFormSteps from "../common/v2/ModalFormSteps";

const DivMain = styled(ModalFormSteps)`
  .divContentModal {
    width: calc(100% - 40px);
    max-width: 700px;
    height: calc(100% - 40px);
    max-height: 775px;
  }
`;

const PanelPersonal = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  gap: 21px;
  padding: 3px 15px;
  //border: 1px solid red;
  .divName {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    > * {
      flex: 1;
      min-width: 200px;
    }
  }
`;

export default {
  DivMain,
  PanelPersonal,
};
