import { getFileTemplate } from "../../utils/handler_files";

const getData = () => ({
  profileImg: getFileTemplate(),
  name: "",
  lastName: "",
  email: "",
  tel: "",
});

const getErrorData = () => ({
  profileImg: "",
  name: "Campo obligatorio",
  lastName: "Campo obligatorio",
  email: "Campo obligatorio",
  tel: "",
});

const getTestData = () => ({
  error: false,
  message: "",
});

export default {
  getData,
  getErrorData,
  getTestData,
};
