import styled from "styled-components";

const divMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .imgLogo {
    height: 70px;
    max-width: 200px;
    object-fit: contain;
  }
  .txtTitle {
    align-self: flex-start;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    color: #13131d;
    text-align: left;
    margin-top: 45px;
    margin-bottom: 8px;
  }
  .txtSubtitle {
    align-self: flex-start;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.2;
    color: #13131d;
    text-align: left;
  }
`;

export default {
  divMain,
};
