import {
  isArrayEmpty,
  isNumberPositive,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import styles from "./styles";
import classVeryImg from "./classVeryImg";
import { useState } from "react";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";

export const VeryImg = (props = classVeryImg.getPropsTemplate()) => {
  const [imgSrc, setImgSrc] = useState("");
  const [imgLoaded, setImgLoaded] = useState();
  const CSS = ["veryImg"];
  // add custom style
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS.join(" "));
  if (!isStringEmpty(props.className)) CSS.push(props.className);

  useEffect(() => {
    if (isStringEmpty(props.src)) {
      setImgLoaded(false);
      setImgSrc("");
    } else {
      setImgLoaded();
      setImgSrc(props.src);
    }
  }, [props.src]);

  return (
    <styles.DivMain className={CSS.join(" ")} showImg={imgLoaded === true}>
      {/* IMAGE */}
      <img
        className={"customImg"}
        src={imgSrc}
        onLoad={(event) => {
          setImgLoaded(true);
        }}
        onError={(event) => {
          setImgLoaded(false);
        }}
      />
      {/* SKELETON (LOADING) */}
      {imgLoaded === undefined && (
        <Skeleton className="divSkeleton" animation={"wave"} />
      )}
      {/* DEFAULT COMPONENT (ERROR) */}
      {imgLoaded === false && props.defaultComponent}
    </styles.DivMain>
  );
};
