import AppRoutes from "../AppRoutes";
import CSS from "./styles";
import SplashScreen from "../splash-screen";
import { useSessionContext } from "../context";

export default function App() {
  const session = useSessionContext();

  return (
    <CSS.divMain>
      {session.isUserAuth === undefined || session.waitTimerSplashScreen ? (
        <SplashScreen />
      ) : (
        <AppRoutes />
      )}
    </CSS.divMain>
  );
}
