import { useState, forwardRef } from "react";
import Styles from "./styles";
import { VeryBtnTransparent } from "../../VeryBtnTransparent";
import { classVeryBtnTransparent } from "../../VeryBtnTransparent/classVeryBtnTransparent";
import { classVeryIconGoogle } from "../../VeryIconGoogle/classVeryIconGoogle";
import { useSnackbar } from "notistack";
import { Collapse } from "@mui/material";
import { isStringEmpty } from "../../../../../utils/handler_validations";
import { VeryIconGoogle } from "../../VeryIconGoogle";

export const CustomNotification = forwardRef((props, refNoti) => {
  const notis = useSnackbar();
  const [showDetails, setShowDetails] = useState(false);
  const existDetails = !isStringEmpty(props.details);
  const divMainCSS = [];
  switch (props.variant) {
    case "success":
      divMainCSS.push("notiSuccess");
      break;
    default:
      divMainCSS.push("notiError");
  }
  const btnExpandCSS = ["btn"];
  if (showDetails) btnExpandCSS.push("btnExpanded");

  const onClickBtnExpand = () => {
    setShowDetails(!showDetails);
  };

  const onClickBtnClose = () => {
    notis.closeSnackbar(props.id);
  };

  return (
    <Styles.DivMain className={divMainCSS.join(" ")} ref={refNoti}>
      {/* HEADER */}
      <div className="divNotiHeader">
        {/* ICON */}
        {props.variant === "success" ? (
          <VeryIconGoogle
            name="task_alt"
            type={classVeryIconGoogle.ICON_TYPE.OUTLINED}
            size={20}
            color={classVeryIconGoogle.ICON_COLOR.WHITE_S1}
          />
        ) : (
          <VeryIconGoogle
            name="report"
            type={classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED}
            size={23}
            color={classVeryIconGoogle.ICON_COLOR.WHITE_S1}
          />
        )}
        {/* MESSAGE */}
        <label className="txtNotiTitle">{props.message}</label>
        {/* BUTTONS */}
        <div className="divNotiButtons">
          {existDetails && (
            <VeryBtnTransparent
              CSS={btnExpandCSS}
              colorTheme={classVeryBtnTransparent.COLOR_THEME.WHITE_S1}
              icon={{
                name: "expand_more",
                type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED,
                size: 24,
              }}
              onClick={onClickBtnExpand}
            />
          )}
          <VeryBtnTransparent
            CSS={["btn"]}
            colorTheme={classVeryBtnTransparent.COLOR_THEME.WHITE_S1}
            icon={{
              name: "close",
              type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_ROUNDED,
              size: 18,
            }}
            onClick={onClickBtnClose}
          />
        </div>
      </div>
      {/* CONTENT */}
      {existDetails && (
        <Collapse
          className="divNotiCollapse"
          in={showDetails}
          timeout="auto"
          unmountOnExit
        >
          <div className="divNotiContent">
            <label className="txtNotiDetails">{props.details}</label>
          </div>
        </Collapse>
      )}
    </Styles.DivMain>
  );
});
