import { isStringEmpty } from "./handler_validations";

export const testTel = (tel = "", isRequired = false) => {
  // resp template
  const resp = (messageError = "") => ({
    error: !isStringEmpty(messageError),
    message: messageError,
  });
  // dato requerido no existe
  if (isRequired === true && isStringEmpty(tel)) {
    return resp("El teléfono no esta establecido");
  }
  // no analizar
  if (isRequired !== true && isStringEmpty(tel)) {
    return resp();
  }
  // cuando tiene espacios en blanco
  if (/\s/.test(tel)) {
    return resp("El teléfono no debe tener espacios");
  }
  // cuando contiene algo que NO es digito
  if (!/^\d+$/.test(tel)) {
    return resp("El teléfono solo debe tener dígitos");
  }
  // cuando no tiene longitud valida
  if (tel.length !== 10) {
    return resp("El teléfono debe tener exactamente 10 dígitos");
  }
  return resp();
};

/**
 * Obtener un numero de telefono sin lada separado por el elemento indicado
 * (guion por default)
 * example => 245-678-23-56
 */
export const getFormattedTel = (tel = "", separator = "-") => {
  if (isStringEmpty(tel)) return "No establecido";
  const newTel = tel.replace("+52", "");
  if (testTel(newTel).error) return "--- --- -- --";
  return (
    newTel.slice(0, 3) +
    separator +
    newTel.slice(3, 6) +
    separator +
    newTel.slice(6, 8) +
    separator +
    newTel.slice(8, 10)
  );
};

/**
 * funcion para obtener un telefono sin lada (solo digitos)
 * example => 2456782356
 */
export const getDigitsTel = (tel = "") => {
  const newTel = tel.replace("+52", "");
  if (testTel(newTel, true).error) return "";
  return newTel;
};
