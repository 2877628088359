export class classIconButton {
  // props recibidas
  static propsType = {
    className: "",
    size: "",
    color: "",
    iconName: "",
    iconType: "",
    transparent: false,
    borderType: "",
    isLoading: false,
    isDisabled: false,
    visibleBorde: false,
    spin: false,
    onClick: (event) => {},
  };
  // tipos de iconos disponibles
  static iconType = {
    outlined: "material-icons-outlined",
    filled: "material-icons",
    rounded: "material-icons-round",
    sharp: "material-icons-sharp",
    //twoTone: "material-icons-two-tone",
  };
  // colores para el icono
  static color = {
    orangeS1: "orangeS1",
    whiteS1: "whiteS1",
    greenS1: "greenS1",
    redS1: "redS1",
    blackS1: "blackS1",
    grayS1: "grayS1",
  };
  // tipos de bordes del boton
  static borderType = {
    rectangle: "rectangle",
    oval: "oval",
    circle: "circle",
  };
}
