import { Modal } from "@mui/material";
import styled from "styled-components";

const DivMain = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  .MuiModal-backdrop {
    background-color: #13131db2;
  }
  .divContentModal {
    width: fit-content;
    min-width: 120px;
    max-width: calc(100% - 80px);
    height: fit-content;
    min-height: 100px;
    max-height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 25px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.15);
    overflow: auto;

    .divHeaderModal {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      .txtTitle {
        font-family: "Roboto", Helvetica, sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.2;
        color: #13131d;
        text-align: left;
      }
      .btnCloseModal {
        width: fit-content;
        border-radius: 100%;
        padding: 1px;
      }
    }
    .dividerTitle {
      width: 100%;
      margin: 18px 0px 16px 0px;
      border: 1px solid #eaeaea;
    }
    .divBodyModal {
      width: 100%;
      min-height: 20px;
      flex: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      //border: 1px solid red;
    }
    .divButtons {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      gap: 12px;
      margin-top: 30px;
      > * {
        flex: 1;
        height: unset !important;
      }
    }
  }
`;

const MainContainer = styled.div`
  display: grid !important;
  place-items: center !important;
  position: fixed !important;
  z-index: 10 !important;
  top: 0px;
  left: 0px;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
  .containerContent {
    width: 80%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    background-color: white;
    max-height: 85%;
    overflow: auto;
    justify-content: center;
    border-radius: 15px;

    > .label-title {
      font-family: "Roboto";
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #eaeaea;
    }
    > .label-subtitle {
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      padding-top: 30px;
    }
    > .label-message {
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      margin-top: 18px;
      line-height: 1.3;
      white-space: pre-line;
    }
    > .containerButtons {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      justify-content: center;
      gap: 15px;
      margin-top: 25px;
      margin-bottom: 5px;
      > * {
        flex-basis: 50%;
        border-radius: 10px;
        padding: 10px 15px;
        width: fit-content;
      }
    }
  }
`;

export default {
  DivMain,
  MainContainer,
};
