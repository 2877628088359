export class classCustomLoading {
  // props recibidas
  static propsType = {
    className: "",
    size: 0,
    color: "",
  };
  // colores para el loading
  static color = {
    orangeS1: "orangeS1",
    whiteS1: "whiteS1",
  };
}
