import styled from "styled-components";

const DivMain = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  padding: 3px 3px 0px 3px;
  overflow: auto;
  //border: 1px solid red;
  .txtError {
    font-family: "Roboto", Helvetica, sans-serif;
    font-size: ${({ sizeTxtError }) => sizeTxtError}px;
    font-weight: 400;
    color: #a72323;
    text-align: left;
    line-height: 1.2;
  }

  &.veryGroupInputError {
    .divInputs {
      .MuiInputBase-root {
        border-color: #a72323;
        &:hover {
          box-shadow: 0px 0px 5px 1px #a7232347;
        }
      }
    }
  }
`;

const DivInputs = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 14px;
  padding-bottom: 3px;
  //border: 1px solid red;

  .MuiInputBase-root {
    flex-shrink: 0;
    padding: 0px;
    border-radius: 10px;
    border: 1.5px solid #d9d9d9;
    transition: all 0.4s;
    font-family: "Roboto", Helvetica, sans-serif;
    font-size: ${({ sizeTxtInput }) => sizeTxtInput}px;
    font-weight: 700;
    color: #13131d;
    .MuiInputBase-input {
      text-align: center;
      padding: 16px 0px;
      &::placeholder {
        color: #717172;
        opacity: 1;
      }
    }
    &:hover {
      box-shadow: 0px 0px 5px 1px #13131d42;
    }
    &.Mui-focused {
      border-color: #13131d;
    }
    &.Mui-disabled {
      border-color: #abaaaa;
      color: #888888;
      &:hover {
        box-shadow: unset;
      }
      .MuiInputBase-input {
        -webkit-text-fill-color: unset;
      }
    }
  }
`;

export default {
  DivMain,
  DivInputs,
};
