import React from "react";
import CSS from "./styles";
import { VeryIconGoogle } from "../VeryIconGoogle";
import { classVeryIconGoogle } from "../VeryIconGoogle/classVeryIconGoogle.jsx";
import { inPage, isStringEmpty } from "../../../../utils/handler_validations";
import { useMatch, useNavigate } from "react-router-dom";

const propsTemplate = () => ({
  path: "",
  label: "",
  icon: {
    name: "",
    type: "",
  },
  className: "",
});

export default function ItemSideMenu(props = propsTemplate()) {
  const router = useNavigate();
  const isSelected = inPage(useMatch(props.path));

  const onClick = () => {
    router(props.path, { replace: true });
  };

  const customClassName = [];
  if (isSelected) customClassName.push("isSelected");
  if (!isStringEmpty(props.className)) customClassName.push(props.className);

  return (
    <CSS.divMain
      className={customClassName.join(" ")}
      onClick={(event) => !isSelected && onClick()}
    >
      <VeryIconGoogle
        name={props.icon.name}
        type={props.icon.type}
        color={
          isSelected
            ? classVeryIconGoogle.ICON_COLOR.WHITE_S1
            : classVeryIconGoogle.ICON_COLOR.BLACK_S1
        }
        size={22}
      />
      {props.label}
    </CSS.divMain>
  );
}
