import styles from "./styles";
import { useState, useEffect } from "react";
import classCustomButton from "../../components/common/custom-button/utils/classCustomButton";
import {
  isStringEmpty,
  isValidNaturalNumber,
} from "../../utils/handler_validations";
import PanelCoupon from "../../components/coupons/PanelCoupon";
import CustomSelect from "../../components/common/custom-select";
import classCustomSelect from "../../components/common/custom-select/classCustomSelect";
import { VeryBtnFill } from "../../components/common/v2/VeryBtnFill";
import { classVeryBtnFill } from "../../components/common/v2/VeryBtnFill/classVeryBtnFill";
import { HeaderSearch } from "../../components/HeaderSearch";
import { DivTablePage } from "../../components/common/DivTablePage";
import { VeryTable } from "../../components/common/v2/VeryTable";
import { useCouponsContext } from "../../context";
import classVeryTable from "../../components/common/v2/VeryTable/classVeryTable";
import { CellTooltip } from "../../components/common/v2/VeryTable/CellTooltip";
import StylesCellTooltip from "../../components/common/v2/VeryTable/CellTooltip/styles";
import { ModalDisabled } from "../../components/coupons/ModalDisable";
import { ModalPay } from "../../components/coupons/ModalPaid";
import { ModalCreate } from "../../components/coupons/ModalCreate";

const ContentCouponsPage = (props) => {
  const [itemsSelectStatus] = useState([
    { label: "Creados", value: "CREATED" },
    { label: "Reclamados", value: "IN_WALLET" },
    { label: "Redimidos", value: "REDEEMED" },
    { label: "Implementados", value: "IMPLEMENTED" },
    { label: "Pagados", value: "PAID" },
    { label: "Cancelados", value: "DISABLED" },
  ]);
  // hooks
  const coupons = useCouponsContext();

  return (
    <>
      <styles.DivMain
        isLoading={coupons.value.page.loading}
        error={coupons.value.page.error}
        onClickBtnRetry={() => coupons.getListInitial()}
      >
        {/* HEADER */}
        <HeaderSearch
          CSS={["divHeader"]}
          inputSearch={{
            placeHolder: "Buscar cupón",
            text: coupons.inputSearch,
            onChangeText: (newText) => coupons.onChangeInputSearch(newText),
            onClick: () => coupons.getListFiltered(),
          }}
        >
          {/* select de estatus */}
          <CustomSelect
            multiple
            positionDropdown={classCustomSelect.positionDropdown.bottomCenter}
            triggerType={classCustomSelect.triggerType.hover}
            initialSelectedItems={{}}
            maxWidthDropdown=""
            maxWidthItems=""
            maxHeightDropdown=""
            itemsSelectType={classCustomSelect.itemType.checkbox}
            classNameSelect="selectStatus"
            classNameDropdown="dropdownStatus"
            alignmentItemsOverflow={classCustomSelect.alignItemsType.rows}
            items={itemsSelectStatus}
            borderType={""}
            selectButton={{
              label: "Estado",
              iconPosition: classCustomButton.positionIcon.right,
              size: 14,
              transparent: false,
              className: "btnSelectStatus",
              color: classCustomButton.color.whiteS2,
            }}
            defaultItem={{
              label: "Todos",
              showLabelInSelect: false,
              itemType: classCustomSelect.itemType.marginCheckbox,
            }}
            onChange={(selectedItems) => {
              coupons.onChangeFilterStatus(selectedItems);
            }}
          />
          {/* boton para crear cupon */}
          <VeryBtnFill
            CSS={["btnAddCoupon"]}
            label={"Agregar cupón"}
            colorTheme={classVeryBtnFill.COLOR_THEME.BLACK_S1}
            textSize={14}
            onClick={() => coupons.showModalCreate()}
          />
        </HeaderSearch>

        {/* CONTENT */}
        <DivTablePage
          isLoading={coupons.value.contentPage.loading}
          error={coupons.value.contentPage.error}
          emptyMessage={coupons.getEmptyMsgPage()}
          onClickBtnRetry={() => coupons.getListFiltered()}
        >
          <styles.ContentContainer>
            {/* COUPONS TABLE */}
            <VeryTable
              CSS={["couponsTable"]}
              rows={coupons.value.list}
              getRowId={(row) => row.rowId}
              disabledRowSelection={false}
              rowSelected={coupons.rowSelected}
              onChangeRowSelected={(newRowSelected) => {
                coupons.onChangeRowSelected(newRowSelected);
              }}
              columns={{
                // column code
                col_code: classVeryTable.getColumnTemplate(
                  "Código",
                  "Código del cupón",
                  1,
                  120,
                  100,
                  true,
                  true,
                  (params) => {
                    return params.row.code;
                  },
                  (params) => {
                    return (
                      <CellTooltip
                        divCellCSS={["cellCode"]}
                        contentCell={
                          <StylesCellTooltip.LabelCell className="txtCode">
                            {params.value}
                          </StylesCellTooltip.LabelCell>
                        }
                        contentTooltip={
                          <StylesCellTooltip.LabelTooltip>
                            {params.value}
                          </StylesCellTooltip.LabelTooltip>
                        }
                      />
                    );
                  }
                ),
                // column amount
                col_amount: classVeryTable.getColumnTemplate(
                  "Descuento",
                  "Monto del cupón",
                  1,
                  100,
                  80,
                  true,
                  true,
                  (params) => {
                    return params.row.amount;
                  },
                  (params) => {
                    return classVeryTable.renderColMoney(params.value, 2);
                  }
                ),
                // column status
                col_status: classVeryTable.getColumnTemplate(
                  "Estatus",
                  "Estatus del cupón",
                  1,
                  120,
                  100,
                  true,
                  true,
                  (params) => {
                    return coupons.getStatusCoupon_S1(
                      params.row.status,
                      params.row.enabled
                    );
                  },
                  (params) => {
                    const cellCSS = ["txtStatus"];
                    const status = coupons.getStatusCoupon_S2(
                      params.row.status,
                      params.row.enabled
                    );
                    if (!isStringEmpty(status)) cellCSS.push(status);
                    return (
                      <CellTooltip
                        divCellCSS={["cellStatus"]}
                        contentCell={
                          <div className={cellCSS.join(" ")}>
                            {params.value}
                          </div>
                        }
                        contentTooltip={
                          <StylesCellTooltip.LabelTooltip>
                            {params.value}
                          </StylesCellTooltip.LabelTooltip>
                        }
                      />
                    );
                  }
                ),
                // column paid
                col_paid: classVeryTable.getColumnTemplate(
                  "Pagado",
                  "Selecciona la casilla para pagar el cupón",
                  0,
                  80,
                  60,
                  false,
                  true,
                  (params) => {
                    return coupons.isCouponPaid(
                      params.row.status,
                      params.row.enabled
                    );
                  },
                  (params) => {
                    //const driverId = params?.row?.driver?.id;
                    if (
                      !coupons.isCouponUsed(
                        params.row.status,
                        params.row.enabled
                      )
                    ) {
                      return null;
                    }
                    return classVeryTable.renderColCheckbox(
                      params.value,
                      false,
                      () => {
                        if (!params.value) {
                          coupons.showModalPay(
                            params.row.code,
                            params.row.amount,
                            params.row.tripId
                          );
                        }
                      }
                    );
                  }
                ),
                // column menu
                col_menu: classVeryTable.getColumnTemplate(
                  "",
                  "",
                  0,
                  60,
                  40,
                  false,
                  false,
                  undefined,
                  (params) => {
                    const items = [];
                    if (
                      coupons.isCouponDisableable(
                        params.row.status,
                        params.row.enabled
                      )
                    ) {
                      items.push({
                        label: "Cancelar cupón",
                        loading: false,
                        onClick: () => {
                          coupons.showModalDisabled(
                            params.row.code,
                            params.row.amount,
                            params.row.status
                          );
                        },
                      });
                    }
                    return classVeryTable.renderColMenu(items);
                  }
                ),
              }}
            />
            {/* PANEL COUPON SELECTED */}
            <PanelCoupon />
          </styles.ContentContainer>
        </DivTablePage>
      </styles.DivMain>

      {/* MODAL DISABLED COUPON */}
      <ModalDisabled />

      {/* MODAL PAID COUPON */}
      <ModalPay />

      {/* modal para crear cupon */}
      <ModalCreate />
    </>
  );
};

export default ContentCouponsPage;
