import {
  fetchDELETE,
  fetchGET,
  fetchPOST,
  fetchPUT,
} from "../utils/handler_http";
import { encoJSON, encoURI, encoUriJson } from "../utils/handler_validations";
import { getFileSendDB } from "../utils/handler_files";

const getDataCreateTemplate = () => ({
  user: { name: "", lastName: "", email: "", tel: "" },
  vehicle: { model: "", year: "", color: "", plates: "" },
});

const getUpdateDriverTemplate = () => ({
  user: { id: 0, name: "", lastName: "" },
  vehicle: { model: "", year: 0, color: "", plates: "" },
});

/**
 * Obtener la data de un driver de la lista
 */
export const getDriverTemplate = () => ({
  id: 0,
  fullName: "",
  profileImg: "",
  tel: "",
  enabled: false,
});

export const FILE_TYPE = {
  INE: "INE",
  DRIVER_LICENCE: "DRIVER_LICENCE",
  CRIMINAL_LETTER: "CRIMINAL_LETTER",
  ADDRESS: "ADDRESS",
  PROFILE_IMG: "PROFILE_IMG",
};

/**
 * Crear un nuevo registro de usuario conductor
 */
export const create = async (data = getDataCreateTemplate(), jwt = "") => {
  data.vehicle.color = data.vehicle.color.toUpperCase();
  const paramsBody = {
    name: encoURI(data.user.name),
    lastName: encoURI(data.user.lastName),
    tel: encoURI(data.user.tel),
    email: encoURI(data.user.email),
    vehicleModel: encoURI(data.vehicle.model),
    vehicleYear: encoJSON(data.vehicle.year),
    vehicleColor: encoURI(data.vehicle.color),
    vehiclePlates: encoURI(data.vehicle.plates),
  };
  const response = await fetchPOST(
    process.env.REACT_APP_DRIVER_ENDPOINT,
    paramsBody,
    { Authorization: jwt }
  );
  return response;
};

/**
 * Crear un nuevo registro de usuario conductor
 */
export const updateDriver = async (
  data = getUpdateDriverTemplate(),
  jwt = ""
) => {
  const paramsBody = {
    driverId: encoJSON(data.user.id),
    name: encoURI(data.user.name),
    lastName: encoURI(data.user.lastName),
    vehicleModel: encoURI(data.vehicle.model),
    vehicleYear: encoJSON(data.vehicle.year),
    vehicleColor: encoURI(data.vehicle.color),
    vehiclePlates: encoURI(data.vehicle.plates),
  };
  const response = await fetchPUT(
    process.env.REACT_APP_DRIVER_ENDPOINT,
    paramsBody,
    { Authorization: jwt }
  );
  return response;
};

/**
 * Actualizar un archivo del conductor
 */
export const updateFile = async (
  driverId = 0,
  updateType = "",
  file = getFileSendDB(),
  jwt = ""
) => {
  const fileDB = { fullBase64: file.fullBase64, ext: file.ext };
  const paramsBody = {
    driverId: encoJSON(driverId),
    updateType: encoURI(updateType),
    file: encoUriJson(fileDB),
  };
  const resp = await fetchPUT(
    process.env.REACT_APP_DRIVER_FILE_ENDPOINT,
    paramsBody,
    { Authorization: jwt }
  );
  return resp;
};

/**
 * Eliminar un archivo del conductor
 */
export const deleteFile = async (driverId = 0, deleteType = "", jwt = "") => {
  const paramsBody = {
    driverId: encoJSON(driverId),
    deleteType: encoURI(deleteType),
  };
  const resp = await fetchDELETE(
    process.env.REACT_APP_DRIVER_FILE_ENDPOINT,
    paramsBody,
    { Authorization: jwt }
  );
  return resp;
};

/**
 * Obtener la lista de drivers filtrada por nombre, apellido o tel
 */
export const getListDrivers = async (filterValue = "", jwt = "") => {
  const paramsQuery = {
    filterValue: encoURI(filterValue),
  };
  const response = await fetchGET(
    process.env.REACT_APP_DRIVERS_LIST_ENDPOINT,
    paramsQuery,
    { Authorization: jwt }
  );
  return response;
};
