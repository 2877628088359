import { useMemo } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getURL } from "../utils/handler_http";
import { useSessionContext } from "../context/session.context";
import { useVeryNotificationsContext } from "../components/common/v2/VeryNotifications/useVeryNotifications";
import { decoJSON, getObject, getString } from "../utils/handler_validations";

export const useSocket = () => {
  const session = useSessionContext();
  const toast = useVeryNotificationsContext();

  const url = useMemo(() => {
    return getURL(process.env.REACT_APP_WEB_SOCKET_ENDPOINT, {
      Authorization: session.value.jwt,
      userType: "ADMIN",
    });
  }, [session.value.jwt]);

  const socket = useWebSocket(url, {
    onOpen: () => {
      console.log("====== OPEN socket ======");
    },
    onClose: (event) => {
      console.log("====== CLOSE socket ======");
      //console.log(event);
    },
    share: true, // multiples instancias con un solo WebSocket
    retryOnError: true,
    reconnectAttempts: 21, // 20 default
    reconnectInterval: 3000, // 5000 ms default
    onReconnectStop: (attempts) => {
      // al alcanzar el limite de intentos
      toast.createNotiError(
        "Se ha perdido la conexión con el servidor",
        "Favor de verificar su conexión de Internet y recargar la página"
      );
    },
    shouldReconnect: (eventError) => true,
  });

  /*==========================================
    HANDLE GENERAL
  ============================================*/
  const getResponse = (newMessageSocket) => {
    const data = getObject(decoJSON(newMessageSocket?.data));
    const type = getString(data.responseType);
    delete data.responseType;
    return { type, data };
  };

  // ver response socket en la consola
  const showResponse = (response = getResponse()) => {
    console.log("==== NEW message socket ====");
    console.log("responseType => ", response.type);
    console.log(response.data);
  };

  const send = (route = "", addAuthorization = true, data = {}) => {
    if (socket.readyState !== ReadyState.OPEN) {
      toast.createNotiError(
        "No hemos podido establecer conexión con el servidor",
        "Verifique su conexión a Internet y favor de esperar un momento en lo que se restablece o vuelva a recargar la página"
      );
      return false;
    }
    socket.sendJsonMessage({
      route: route,
      Authorization: addAuthorization ? session.value.jwt : undefined,
      ...data,
    });
    return true;
  };

  return {
    url,
    send,
    getResponse,
    showResponse,
  };
};
