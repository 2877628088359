import CSS from "./styles";
import { isStringEmpty } from "../../../utils/handler_validations";

const propsTemplate = () => ({
  title: "",
});

export default function FormLoginPage(props = propsTemplate()) {
  return (
    <CSS.divMain>
      {/* SEPARATOR TITLE */}
      {!isStringEmpty(props.title) && (
        <div className="separatorTitle">{props.title}</div>
      )}
      {/* INPUTS */}
      <div className="divInputs">{props.children}</div>
    </CSS.divMain>
  );
}
