import { ButtonBase } from "@mui/material";
import styled from "styled-components";

const divMain = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 100%;
    min-width: min-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    padding: 13px 20px;
    font-family: "Roboto", Helvetica, sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: unset;
    text-transform: unset;
    box-shadow: unset;
    color: #13131d;
    border-radius: 10px;
    background-color: transparent;
    transition: background-color 0.4s;
    &:hover {
      background-color: #eaeaea;
    }

    &.isSelected {
      background-color: #13131d;
      cursor: default;
      color: #ffffff;
      .MuiTouchRipple-root {
        color: transparent;
      }
      &:hover {
        background-color: #13131d;
      }
    }
  }
`;

export default {
  divMain,
};
