import styled from "styled-components";

const DivMain = styled.div`
  //border-radius: 25px;
  //border: 1px solid red;
  .btnSelect.MuiButtonBase-root {
    gap: 4px;
    .txtLabel {
      text-decoration: none;
    }
    //border: 1px solid red;
  }

  .MuiTooltip-tooltipPlacementBottom {
    margin-top: 0px !important;
  }
  .MuiTooltip-tooltip {
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 7px 0px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.194);
    //border: 1px solid blue;
    .btnOption.MuiButtonBase-root {
      padding: 7px 12px;
      border-radius: 0px;
      &.optionSelected {
        cursor: default;
        pointer-events: none;
        background-color: #c7c7c7;
        &:hover {
          background-color: #c7c7c7;
        }
      }
    }
  }
`;

export default {
  DivMain,
};
