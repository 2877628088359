import React from "react";
import { classVeryInputPass } from "./classVeryInputPass";
import { VeryInput } from "../VeryInput";
import {
  isArrayEmpty,
  isStringEmpty,
} from "../../../../utils/handler_validations";
import { classVeryIconGoogle } from "../VeryIconGoogle/classVeryIconGoogle";
import { VeryBtnTransparent } from "../VeryBtnTransparent";
import { classVeryBtnTransparent } from "../VeryBtnTransparent/classVeryBtnTransparent";
import Styles from "./styles";
import { classVeryInput } from "../VeryInput/classVeryInput";
import { useState } from "react";

export const VeryInputPass = (props = classVeryInputPass.propsTemplate()) => {
  const [showText, setShowText] = useState(false);
  // clases del icon
  const CSS = [];
  // custom CSS
  if (!isArrayEmpty(props.CSS)) CSS.push(props.CSS);

  return (
    <VeryInput
      onChangeRefInput={props.onChangeRefInput}
      CSS={CSS.join(" ")}
      type={
        showText
          ? classVeryInput.TYPE_INPUT.NORMAL
          : classVeryInput.TYPE_INPUT.PASS
      }
      size={props.size}
      title={isStringEmpty(props.title) ? "Contraseña" : props.title}
      text={props.text}
      textError={props.textError}
      placeHolder={
        isStringEmpty(props.placeHolder)
          ? "Ingresa tu contraseña"
          : props.placeHolder
      }
      inRightComponent={
        <Styles.divBtnEye>
          <VeryBtnTransparent
            CSS={["btnEye"]}
            colorTheme={classVeryBtnTransparent.COLOR_THEME.BLACK_S1}
            icon={{
              name: showText ? "visibility_off" : "visibility",
              type: classVeryIconGoogle.ICON_TYPE.SYMBOLS_OUTLINED,
              size: 16,
            }}
            onClick={() => setShowText(!showText)}
          />
        </Styles.divBtnEye>
      }
      isEditable={props.isEditable}
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
      onLostFocus={props.onLostFocus}
      onWonFocus={props.onWonFocus}
      onEnter={props.onEnter}
      onChangeText={props.onChangeText}
    />
  );
};
