import styled from "styled-components";

const DivPage = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 30px;
  gap: 40px;
  //border: 2px solid red;
  .swiperLogin {
    width: 60%;
    flex-grow: 1;
    height: 100%;
    background-color: #13131dd7;
    border-radius: 30px;
    //border: 3px solid red;
    .divSlide {
      position: relative;
      //border: 4px solid orange;
      .imgSlide {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .divDataSlide {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 40px 50px 40px 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        overflow: auto;
        background-color: #00000017;
        //border: 2px solid green;
        &.divOpacity_LOW {
          background-color: #00000040;
        }
        &.divOpacity_HIGH {
          background-color: #0000006a;
        }
        label {
          font-family: "Roboto", Helvetica, sans-serif;
          line-height: 1.2;
          color: #d0d0d0;
          white-space: pre-line;
        }
        .txtMessage {
          font-weight: 700;
          font-size: 21px;
          line-height: 1.3;
          text-align: justify;
          //border: 1px solid yellow;
        }
        .divInfo {
          width: calc(100% - 120px);
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 4px;
          margin-top: 32px;
          //border: 1px solid yellow;
          .txtTitle {
            font-weight: 700;
            font-size: 17px;
            color: #ffffff;
          }
          .txtDesc {
            font-weight: 400;
            font-size: 13px;
          }
        }
      }
    }
    .swiper-pagination {
      top: 0px;
      bottom: unset;
      width: 100% !important;
      padding: 9px 4px;
      background-color: #13131d50;
      .swiper-pagination-bullet {
        background-color: #ffffff;
        width: 10px;
        height: 10px;
      }
    }
    .divCustomNavigation {
      position: absolute;
      bottom: 30px;
      right: 35px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 15px;
      z-index: 5;
      //border: 1px solid orange;
      .btnNav {
        width: 45px;
        height: 45px;
        border-radius: 100%;
        padding: 0px;
        background-color: #13131d7a;
        &:hover {
          background-color: #757070;
        }
      }
    }
  }
`;

const DivForm = styled.div`
  position: relative;
  flex: 1;
  flex-grow: 10;
  min-width: 250px;
  max-width: 550px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  label {
    font-family: "Roboto", Helvetica, sans-serif;
  }
`;

const DivScrollPanel = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  padding: 3px;
  background-color: #ffffff;
`;

const DivPanel = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default {
  DivPage,
  DivForm,
  DivScrollPanel,
  DivPanel,
};
